// Create a new file, e.g., context.js
import React, { createContext, useContext, useState } from 'react';
import arrow from '../assets/images/arrowMeme.png'
import twitter from '../assets/images/twittermeme.png'
import telegram from '../assets/images/Telegrammeme.png'
import tiktok from '../assets/images/Tiktokmeme.png'
import youtube from '../assets/images/Youtubememe.png'
import instagram from '../assets/images/Instagram.png'
import warpcast from '../assets/images/Wmeme.png'
import Contract from '../assets/images/Contract.png'
import Database from '../assets/images/Database.png'
import Circulating from '../assets/images/Circulating.png'
import Burned from '../assets/images/Burned.png'
import Liquidity from '../assets/images/Liquidity.png'
import Renounced from '../assets/images/Renounced.png'
import { Images1 } from '../components/MemeCoin/Images';
import { Images2 } from "../components/MemeCoin/Images";
import { Images3 } from '../components/MemeCoin/Images'
import { Images4 } from "../components/MemeCoin/Images";
import { Images5 } from "../components/MemeCoin/Images";
import { Images6 } from "../components/MemeCoin/Images";
import { Images7 } from "../components/MemeCoin/Images";
import { Images8 } from "../components/MemeCoin/Images";
import { Images9 } from "../components/MemeCoin/Images";
import { Images10 } from '../components/MemeCoin/Images';
import logo from "../assets/images/FaviconNew.png"
import MyContext from '../context/MyContext';
import '../assets/CSS/Donwloadtemple.css'
import Logo from '../assets/images/FaviconNew.png'
import { NewmaskImage } from '../components/MemeCoin/Images';
import Newtelegram from '../assets/images/Newtelegram.png'

import { Newlogo } from '../components/MemeCoin/Images'
import ServiceIcon from '../assets/images/servicesicon.svg';



import Twitter from '../assets/images/TwitterT1.png'
import { Template1mainimage } from '../components/Base64/Images'
import { Tokenomic1 } from '../components/Base64/Images'
import { Tokenomics2 } from '../components/Base64/Images'
import { Tokenomics3 } from '../components/Base64/Images'
import ContractImage1 from '../assets/images/ContractIamge1T1.png'
import ContractImage2 from '../assets/images/ContractImage1T1.png'
import ContractImage3 from '../assets/images/ContractImageIamge1T1.png'
import { Template1Footer } from '../components/Base64/Images'
import { Template1Solly } from '../components/Base64/Images'
import styles from '../assets/CSS/Template1.module.css';

import { Template2Logo } from '../components/Base64/Images'
import { Template2telegram } from '../components/Base64/Images'
import { Template2twitter } from '../components/Base64/Images'
import Template2unicorn from '../assets/images/Template2unicorn.png'
import Template2M from '../assets/images/Template2M.png'
import Template2Forg from '../assets/images/Template2forg.png'
import { Template2sectionbg } from '../components/Base64/Images'
import Template2Section3bg from '../assets/images/Template2Section3bg.png'
import Template2SectionMain from '../assets/images/Template2SectionMainbg.png'



import { Template3BG } from '../components/Base64/Images'
import { Template3Container } from '../components/Base64/Images'
import { Template3Sunppd } from '../components/Base64/Images'
import Template3Telegram from '../assets/images/TelegramT3.png'
import Template3Twitter from '../assets/images/Template3x.png'
import Template3Chart from '../assets/images/Template3Chart.png'
import Template3Svg from '../assets/images/Template3Svg.png'
import Template3BrownContainer from '../assets/images/Template3BrownContainer.png'
import Template3Vector from '../assets/images/Template3Vector.png'
import { Template3Maks } from '../components/Base64/Images'
import Template3MaskSvg from '../assets/images/Template3Makssvg.png'
import Template3footersvg from '../assets/images/Template3Footersvg.png'
import Template3footerbg from '../assets/images/Template3Footerbg.jpg'
import Template3Footercontainer1 from '../assets/images/Template3Footercontainer1.png'
import Template3FooterContainer2 from '../assets/images/Template3FooterContainer2.png'
import Template3Footertwitter from '../assets/images/Template3Footertwitter.png'
import { Template3FooterSunppd } from '../components/Base64/Images'
import { Template3FooterEmail } from '../components/Base64/Images'
import { Template3SectionSnuppd } from '../components/Base64/Images'
import Template3Section3svg from '../assets/images/Template3Section3svg.png'
import { Template3DogImage } from '../components/Base64/Images'


import { T4Navlogo } from '../components/MemeCoin/Images.js'
import { T4MainSectionImage } from '../components/MemeCoin/Images.js'
import { T4Memelogo } from '../components/MemeCoin/Images.js'
import { T4MemeImage1 } from '../components/MemeCoin/Images.js'
import { T4twitter } from '../components/MemeCoin/Images.js'
import { T4telegram } from '../components/MemeCoin/Images.js'
import { T4Instagram } from '../components/MemeCoin/Images.js'
import { T4trend } from '../components/MemeCoin/Images.js'
import { T4Section3bgImage } from '../components/MemeCoin/Images.js'
import { T4MemeImage2 } from '../components/MemeCoin/Images.js'
import { T4MemeImage3 } from '../components/MemeCoin/Images.js'
import { T4footertelegram } from '../components/MemeCoin/Images.js'
import { T4footerTwitter } from '../components/MemeCoin/Images.js'
import { T4footerInstagram } from '../components/MemeCoin/Images.js'
import { T4footertrend } from '../components/MemeCoin/Images.js'



import { T5image1 } from '../components/MemeCoin/Images.js'
import { T5image2 } from '../components/MemeCoin/Images.js'
import T5image3 from '../assets/images/T5image3.png'
import T5image4 from '../assets/images/T5image4.png'
import T5image5 from '../assets/images/T5image5.png'
import T5image6 from '../assets/images/T5image6.png'
import T5image7 from '../assets/images/T5image7.png'
import T5image8 from '../assets/images/T5image8.png'
import T5image9 from '../assets/images/T5image9.png'
import T5image10 from '../assets/images/T5image10.png'
import T5image11 from '../assets/images/T5image11.png'
import T5image12 from '../assets/images/T5image12.png'
import T5image13 from '../assets/images/T5image13.png'
import T5image14 from '../assets/images/T5image14.png'
import T5image15 from '../assets/images/T5image15.png'
import T5image16 from '../assets/images/T5image16.png'
import T5image17 from '../assets/images/T5image17png.png'
import { T5image18 } from '../components/MemeCoin/Images.js'
import T5image19 from '../assets/images/T5image19.png'
import { T5image20 } from '../components/MemeCoin/Images.js'
import T5image21 from '../assets/images/T5image21.png'
import { T5image22 } from '../components/MemeCoin/Images.js'
import T5image23 from '../assets/images/T5image23.png'




const PublishContext = createContext();


export const PublishProvider = ({ children }) => {



    const { googletransalte, featureimages, setFeatureImages,
        introImages, setIntroIamge,
        buttonshowdoblur, setButtonshowdoblur,
        buttonshowdowcolor, setButtonshowdocolor,
        buttonshowdospread, setButtonshowdospread,
        buttonshowdoleft, setButtonshowdoleft,
        buttonshowdoTop, setButtonshowdoTop,
        featureIcon1, setfeatureIcon1,
        featureIcon2, setfeatureIcon2,
        featureIcon3, setfeatureIcon3,
        featureIcon4, setfeatureIcon4,
        memecoin, setMemecoin, t4stepimage,
        newnavbarmenu, setNewnavbarmenu,
        alignMedia, setAlignMedia,
        imagePosition, setImagePosition,
        selectedFeature, setSelectedFeature,
        buttonvarticalpadding, setButtonvarticalpadding,
        buttonhorizantlepadding, setButtonhorizantlepadding,
        Animated, setAnimated,
        selectedStats, setSelectedStats,
        selectedLogo, setSelectedLogo,
        selectedFaq, setSelectedFaq,
        selectedFooter, setSelectedFooter,
        showdovalue, setshowdovalue,
        butttonshowdo, setButtonshowdo,
        buttonborder, setbuttonborder,
        buttonborderradius, setButtonborderradios,
        buttonbordercolor, setbuttonbordercolor,
        buttoncolor, setButtoncolor,
        button1font, setButton1font,
        buttontext, setbuttontext, Section6,
        selectedHero, setSelectedHero,
        statsbordercolor, setStatsbordercolor,
        statsshowdowrange, setStatsshowdowrange,
        statsborder, setStatsborder,
        statsborderradius, setStatsborderradius,
        statsbackgroundcolor, setStatsbackgroundcolor,
        statevaluecolor, setStatsvaluecolor,
        statslabelcolor, setStatslabelcolor,
        captioncolor, setCaptioncolor,
        selectedLi, setSelectedLi,
        checked, setChecked,
        conatcmenuborder, setcontactmenuborder,
        contactheadingcolor, setContactheadingcolor,
        contenttextheadingcolor, setContenttextheadingcolor,
        contenttextinfocolor, setContenttextinfocolor,
        statethree, setStatsthree,
        statetwo, setStatstwo,
        stateOne, setStatsOne,
        footerMargintop, setFooterMarginTop,
        footerMarginBottom, setFooterMarginBottom,
        faqMargintop, setFaqMarginTop,
        faqMarginBottom, setFaqMarginBottom,
        statsMarginBottom, setStatsMarginBottom,
        statsMarginTop, setStatsMarginTop,
        serviceMarginBottom, setServiceMarginBottom,
        serviceMarginTop, setServiceMarginTop,
        trustmargintop, setTrustmargintop,
        trustmarginbottom, setTrustmarginBottom,
        marginTop, setMarginTop,
        marginBottom, setmarginBottom,
        intromargintop, setIntromargintop,
        intromarginbottom, setIntromarginbottom,
        showdowrange, setShowdorange,
        footerbodytextfont, setFooterbodytextfont,
        faqtitletextfont, setFaqtitletextfont,
        faqbodytextfont, setFaqbodytextfont,
        statsbodytextfont, setStatsbodytextfont,
        statstitletextfont, setStatstitletextfont,
        serviceBodytextfont, setserviceBodytextfont,
        servicetitletextfont, setServicestitletextfont,
        trusttitletextfont, setTrusttitletextfont,
        trusttextfont, setTrusttextfont,
        introinfotextfont, setintroinfotextfont,
        introtextfont, setIntrotextfont,
        newfooterhead, setNewfooterhead,
        newfooteritem, setNewfooteritem,
        faqCaption, setFaqCaption,
        footerLinkgroups, setfooterLinkgroups,
        statscaption, setStatsCaption,
        servicecaption, setServicesCaption,
        trustCaption, setTrustCaption,
        introcaption, setIntrocaption,
        footerlegalhead, setFooterleagalhead,
        footerCompanyhead, setFooterCompanyhead,
        footerfeaturehead, setFooterfeaturehead,
        headertextfont, setHeadertextfont,
        selectedOption1, setSelectedOption1,
        intromenutextcolor, setIntromenutextcolor,
        intromenubgcolor, setIntromenubgcolor,
        newBackgroundcolor, setNewbackgroundcolor,
        colorheaderDiv, setcolorheaderdiv,
        newservicesImage, setNewserviceImage,
        headernotice, setheadernotice,
        newlogo, setNewlogo,
        newtrustlogo, setNewtrustlogo,
        newfootersocialIcon, setNewfootersocialIcon,
        newFaq, setNewFaq,
        newlegacy1, setNewlgacy1,
        newlegacy, setNewlgacy,
        removelegacy, setRemovelegacy,
        removelegacynum, setremoveleagacynum,
        trustLogo, settTrustlogos,
        newservicesinfo, setNewservicesinfo,
        newservicesheading, setNewservicesheading,
        introbuttonapprovel, setIntrobuttonapprovel,
        addintrobutton, setAddintrobutton,
        addbuttonapprovel, setAddbuttonapprovel,
        removeSocialIcon1, setRemoveSocialIcon1,
        removeSocialIcon2, setRemoveSocialIcon2,
        removeSocialIcon3, setRemoveSocialIcon3,
        removeSocialIcon4, setRemoveSocialIcon4,
        navAddbutton, setNavaddbutton,
        footeryear, setFooteryear,
        faqtitle, setFaqtitle,
        faqIntro, setFaqIntro,
        newIntroImage, setNewIntroImage,
        leagacydiv, setLeagacydiv,
        addnavmenu, setAddnavmenu,
        navadddiv, setNavnavadddiv,
        removelogos, setRemovelogos,
        removeFaq1, setRemovefaq1,
        removeFaq2, setRemovefaq2,
        removeFaq3, setRemovefaq3,
        removeFaq4, setRemovefaq4,
        statsnumremove1, setStatsnumremove1,
        statsnumremove2, setStatsnumremove2,
        statsnumremove3, setStatsnumremove3,
        serviceremove1, settServiceremove1,
        serviceremove2, settServiceremove2,
        serviceremove3, settServiceremove3,
        serviceremove4, settServiceremove4,
        introbtn1remove, setIntrobtnremove1,
        introbtn2remove, setIntrobtnremove2,
        navbarremove, setNavbarremove,
        navremovebtn1, setNavremovebtn1,
        navremovebtn2, setNavremovebtn2,
        navbuttonincraese, setNavbuttonincrease,
        inttrobtn1, setIntrobtn1,
        introbtn2, setIntrobtn2,
        statsintro, setStatsinto,
        statsnumber1, setStatsnumber1,
        statsnumber2, setStatsnumber2,
        statsnumber3, setStatsnumber3,
        statsnumber4, setStatsnumber4,
        statsnumber5, setStatsnumber5,
        statsnumber6, setStatsnumber6,
        navbtn1, setNavbtn1,
        navbtn2, setNavbtn2,
        faqA1, setfaqA1,
        faqA2, setfaqA2,
        faqA3, setfaqA3,
        faqA4, setfaqA4,
        faqQ1, setFaqQ1,
        faqQ2, setFaqQ2,
        faqQ3, setFaqQ3,
        faqQ4, setFaqQ4,
        serviceHeading1, setServiceHeading1,
        serviceinfo1, setServiceinfo1,
        serviceHeading2, setServiceHeading2,
        serviceinfo2, setServiceinfo2,
        serviceHeading3, setServiceHeading3,
        serviceinfo3, setServiceinfo3,
        serviceHeading4, setServiceHeading4,
        serviceinfo4, setServiceinfo4,
        servicesIntro1, setServicesIntro1,
        servicesIntro, setservicesIntro,
        menu1, setmenu1,
        headerdivtextcolor, setHeaderDivTextColor,
        headerdivtext, setHeaderDivText,
        headerdivbgcolor, setHeaderdivbgcolor,
        navbartitle, setNavbartitle,
        headerdiv, setHeaderdiv,
        section1, setsection1,
        section2, setsection2,
        section3, setsection3,
        section4, setsection4,
        section5, setsection5,
        section6, setsection6,
        section7, setsection7,
        contentdesing, setContentdesing,
        mainnavbarbutton, setMainnavbarbutton,
        images, setImages,
        serachtearm1, setSearchteam,
        searchTerm, setSearchTerm,
        visibleComponents, setVisibleComponents,
        footercompany, setfootercompany,
        footerlegal, setfooterlegal,
        footerfeature, setfooterfeature,
        serv_list_obj, Setserv_list_obj,
        introo, setIntroo,
        footerfeatures, setfooterfeatures,
        navbarmenu, setnavbarmenu,
        footertext, setfootertext,
        footercolor, setfootercolor,
        showWebsite, setShowWebsite,
        loading, setLoading,
        location, setLocation,
        title, setTitle,
        intro, setIntro,
        about, setAbout,
        services, setServices,
        portfolio, setPortfolio,
        reviews, setReviews,
        contact, setContact,
        faq, setFaq,
        menu, setmenu,
        byTheNumber, setByTheNumber,
        navbartextcolor, setnavbartextcolor,
        welcometilte, setwelcometitle,
        titlecolor, settitlecolor,
        introcolor, setintrocolor,
        introtext, setintrotext,
        legacycolor, setlegacycolor,
        featurecolor, setfeaturecolor,
        featuretext, setfeaturetext,
        faqcolor, setfaqcolor,
        faqtext, setfaqtext,
        legacytext, setlegacytext,
        serviceheader, setserviceheader,
        legacy, setleagacy,
        trustcolor, settrustcolor,
        trusttext, settrusttextcolor,
        trust, settrust,
        trsuttitle, settrusttile,

        memecoinname, SetMemecoinName,
        memecoinsymbol, setMemecoinSymbol,
        memecoinNavbar, setMemecoinNavbar,
        memecoinAbout, setMemecoinAbout,
        memeCaption, memecoinValue,
        memecoincontractaddress, setMemecoincontractAddress,
        memecoinInitialsupply, setMemecoinInitialsupply,
        memecoinCirculatingSupply, setMemecoinCirculatingSupply,
        memecoinSupplyBurned, setMemecoinSupplyBurned,
        memecoinLiquidity, setMemecoinLiquidity,
        memecoinlogoImage, setMemecoinlogoImage,
        memecoinaboutbgImage, memecoinAminitionImage,
        memecoinaboutleftimage, memecoinaboutrightimage,
        memecoinavisionleftimage, memecoinavisionrightimage,
        memecointokenomicsimage, memecoinContractImage,
        memecoinDatabaseImage, memecoinCirculatingImage,
        memecoinBurnedImage, memecoinLiquidityImage,
        memecoinRenouncedImage, memenavbgcolor,
        memeaboutbgcolor, memecointokonomicsbgimage,
        memecoinanvfont, memenavtextcolor,
        memecoinabouttextcolor, memecoinaboutfont,
        memecoinsection, memecoinvisionfont,
        memecoinvisiontextcolor, memecointokenfont,
        memecointoketextcolor, memecoinstepfont,
        memecoinsteptextcolor, randomId, setRandomId,
        iSremove1, setIsremove1,
        iSremove2, setIsremove2,
        iSremove3, setIsremove3,
        iSremove4, setIsremove4,
        iSremove5, setIsremove5,
        iSremove6, setIsremove6,
        newaddtokest, newiconsImage,
        step1, setStep1,
        step2, setStep2,
        step3, setStep3,
        step4, setStep4,
        stepheading, setStepheading,
        memeherobutton,
        memefooterabout, setMemefooterabout,
        memetwitterlink, setMemetwitterlink,
        memetelegramlink, setMemetelegramlink,
        memeYoutubelink, setMemeYoutubelink,
        memetiktoklink, setMemetiktoklink,
        memeinstagramlink, setMemeinstagramlink,
        memewarpcastlink, setMemewarpcastlink,
        setMemecoinsection, memeaboutbgimage,
        memestepbgimage, memefooterbgimage,
        memenavbarbgcolor, memebackground,
        memeherobgcolor, memeherobackground,
        memeaboutbackground, memetokenomicsbgcolor,
        memetokenomicsbackground, memestepbgcolor,
        memestepbackground, Backgroundimage, memefooterbgcolor,
        tokenvalubgcolor,
        memefootrbackground, memenavfontsize,
        memeMainfontsize, memeaboutfontSize,
        memetokenfontSize, memestepfontSize,
        memefooterfontSize, memefootertextcolor,
        memefootertextfont,


        template1footerabout, setTemplate1footerAbout,
        template1tokenomisupply, setTemplate1tokenomisupply,
        template1tokenomicontract, setTemplate1tokenomicontract,
        template1tokenomiTax, setTemplate1tokenomiTax,
        template1footerImage, setTemplate1FooterImage,
        template1stepcontract, setTemplatestepcontract,
        template1stepAdd, setTemplate1Add,

        template1tokenomics, setTemplate1Tokenomics,
        template1tokenomicssub1, setTemplate1tokenomicssub1,
        template1tokenomicssub2, setTemplate1tokenomicssub2,
        template1tokenomicssub3, setTemplate1tokenomicssub3,
        template1tokenimage2, setTemplate1tokenimage2,
        template1tokenimage3, setTemplate1tokenimage3,
        template1Info, setTemplate1Info,
        template1caption, setTemplate1caption,
        selectMemeCoinTemplates,

        template2tokenAddress, setTemplate2tokenAddress,
        template2info, setTemplate2info,
        template2memecaption, setTemplate2memecaption,
        template2caption, setTemplate2Caption,
        template2twitterlink, setTemplate2twitterlink,
        template2telegramlink, setTemplate2telegramlink,
        template2tokentax, setTemplate2tokentax,
        template2tokenlp, setTemplate2tokenlp,
        template2tokenMeme, setTemplate2tokenMeme,
        template2tokentatesupply, setTemplate2tokentatesupply,
        template2tokensupply, setTemplate2tokensupply,
        template2tokenheading, setTemplate2tokenheading,
        template2step1, setTemplate2step1,
        template2step3, setTemplate2step3,
        template2step2, setTemplate2step2,
        template2step4, setTemplate2step4,
        template2stepheading, setTemplate2stepheading,
        template2footerlogo, setTemplate2footerlogo,
        template2footeringo, setTemplate2footerinfo,
        template2coingecko, setTemplate2coingecko,
        template2cmc, setTemplate2cmc,
        template2uniswap, setTemplateuniswap,
        template3memeaddress, setTemplate3memeadress,
        template3email, setTemplate3Email,
        memestepbackgroundt3, setMemestepbackgroundt3,
        template3footerinfo, setTemplate3Footerinfo,
        template3footerheading, setTemplate3footerheading,
        template3stepsection2bg, setTemplate3stepsection2bg,
        template3stepsection2color, setTemplate3section2color,
        template3stepsection2, setTemplate3stepsection2,
        template3stepquestion, setTemplate3question,
        template3step3section2heading, setTemplate3stepsection2heading,
        template3stepsection1, setTemplate3stepsection1,
        template3stepimage, setTemplate3stepimage,
        template3section1, setTemplate3section1,
        template3Section2, setTemplate3section2,
        template3Section3, setTemplate3Section3,
        template3Section4, setTemplate3Section4,
        template3telegramlink, setTemplate3telegramlink,
        template3twitterlink, setTemplate3twitterlink,
        template3uniswaplink, setTemplate3uniswaplink,
        template3heading, setTemplate3Heading,
        statsbackgroundcolorT3,
        memecloudimage, memetokencaption, memetokenbuy,
        t4navbutton,
        t4memeaddress, t4caption,
        t4mainbutton1, t4mainbutton2,
        t4token, t4step1,
        t4step2, t4step3,
        t4step4, t4step5,
        t4footer1, t4footer2,
        t4footer3, t4footer4,

        t5Nav1, t5nav2,
        t5about1, t5about2,

        t5Token1,
        t5Token3, t5Token4,
        t5Token5, t5Token6,

        t5step1, t5step2,
        t5step3, t5step4,


    } = useContext(MyContext);
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsopen4] = useState(false)
    const [isOpen, setIsopen] = useState(false);

    const randomIndex = Math.floor(Math.random() * 5);
    const randomIndex1 = Math.floor((Math.random() * 5) + 5);


    const hexToRgb = (hex) => {
        const bigint = parseInt(hex.slice(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return `${r}, ${g}, ${b}`;
    };


    const test = template2footerlogo.split('');
    const test1 = test.splice(3, 0, `${memecoinsymbol}`);
    const test2 = test.join('');
    console.log('template2footerlogo', test2);

    //    console.log('template2step1',template2step1.split(''));
    for (var i = 0; i < template2step4.length; i++) {
        console.log('template2step1', template2step4[i], i)
    };
    const step1Test1 = template2step1.slice(0, 21);
    const step1Test2 = template2step1.slice(22, 41);
    const step1Test3 = template2step1.slice(42, 84);
    const step1Test4 = template2step1.slice(85, 127);
    const step1Test5 = template2step1.slice(128, 165);
    const Step1Test6 = template2step1.slice(166, 203);
    const Step1Test7 = template2step1.slice(204,);




    console.log('Download', selectedLi, selectedHero, selectedLogo, selectedFeature, selectedStats, navbarmenu,);

    const publishWebsite = async () => {



        // ---------------------------------------Navbar-------------------------/
        const Navbarmenu = navbarmenu.map((menu) => {
            return menu
        })

        const NavTemplate1 = `
            <!DOCTYPE html>
            <html>
            <head>
              <title>${navbartitle}</title>
               <link rel="icon" type="image/x-icon" href="${logo}">
              <style>
              nav {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: transparent;
                position: relative;
                padding: 0 !important;
                width:100%
              }
              .logo{
                display: flex;
                align-items: center;
                justify-content: start;
              }
              .logo a img{
                height: 40px;
                width: 40px;
              }
            
              nav .nav-item li {
                display: inline-block;
                list-style: none;
                margin: 5px;
              }
            
              nav .nav-item li a {
                display: inline-block;
                color: #000;
                font-size: 20px;
                text-decoration: none;
              }
              .navbar-nav .nav-item a:hover{
                text-decoration: underline;
              }
              .buttoncolor{
                margin: 2px;
                color: rgb(0, 0, 0);
                border: none;
                cursor: pointer;
                display: inline-flex;
                outline: none;
                padding: 0.7em 0.8em;
                position: relative;
                font-size: 14px;
                background:#f0f0f0;
                box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0);
                box-sizing: border-box;
                transition: all 0.2s ease-in-out;
                align-items: center;
                border-color: rgb(227, 227, 227);
                border-style: solid;
                border-width: 0;
                border-radius: 8px;
                justify-content: center;
                text-decoration: none;
              }
              nav .nav-item .nav-link button:hover{
                background-color: lightslategray;
              }
            
              /* =====================Subheader============== */
              .Subheader{
                background-color: #fafafa;
                height: 7px;
              background: var(--bg-header);
              box-shadow: 0 1px #eaeaea;
              left: 0;
              position: -webkit-sticky;
              position: sticky;
              right: 0;
              top: 0;
              z-index: 100;
             }
            
              /* =========== Welcome to cofee hub=========== */
              /* .buttoncolor{
                background-color:#f8f9fa;
              } */
              .menu{
                border-radius: 20px;
                border: 1px solid #f1f3f5;
              }
              .heading{
                font-size: 56px;
                font-weight: 600;
                color: #212529;
              }
              .heading2{
                font-size: 29px;
                font-weight: 600;
                color: #212529;
              }
              .card{
                background-color: #f8f9fa;
                background-image: linear-gradient(45deg, rgb(255, 255, 255) 0%, rgb(241, 242, 243) 100%);;
                border: 1px solid rgb(235, 235, 235);
                border-radius: 10px;
                margin: 16px;
                padding: 16px;
                box-shadow: 0px 19px 30px -10px rgba(21, 21, 25, 0.21);;
              }
            
              .footer-links  p{
                margin: 0;
                padding: 0;
              }
              .footer-links  p a{
                text-decoration: transparent;
                color: #212529;
              }
              .footer-links  p a:hover{
                text-decoration:underline;
              }
            
            
              .scrolling-text {
                white-space: nowrap;
                
                animation: scroll-left 60s linear infinite;
             }
            
             @keyframes scroll-left {
                0% { transform: translateX(100%); }
                100% { transform: translateX(-100%); }
             }.container {
              width: 80%; 
              margin: 0 auto; 
              overflow: hidden; 
            
              padding: 1px; 
             }
             <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
             </style>            
            </head>
            <body>
                 ${headerdiv == true ? `
                <div style=' width: 100%; height: 20px; background-color: ${headerdivbgcolor}; color: ${headerdivtextcolor}; display:flex;justify-content:center;align-items:center; color: ${navbartextcolor}; font-family: ${headertextfont};'>${headerdivtext}</div>` :
                ``}
            <nav class="navbar navbar-expand-lg " style='min-width: 100%; background-color: ${titlecolor}; border-bottom: 1px solid ${conatcmenuborder},padding:50px;padding-top:0px'>
             <div class="container" style='background-color:${titlecolor},min-width: 100%;' >
                 <a class="navbar-brand" href="#">
                 <img src="${newlogo.length > 0 ? newlogo : Newlogo}" alt="logoimage" height="24px" width="24px"  class="me-1" color='black'>
                     <h3 class="d-inline "  style="margin-left: 10px; font-size: 20px; background-color: ${titlecolor}; color: ${navbartextcolor}; font-family: ${headertextfont};">${navbartitle}</h3>
                 </a>
                 <button class="navbar-toggler bg-dark" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                     <span class="navbar-toggler-icon"></span>
                 </button>
                 <div class="collapse navbar-collapse" id="navbarNav">
                     <ul class="navbar-nav ms-auto" style='background-color:${titlecolor};font-size:14px;justify-content: center; justify-items: center;cursor: pointer;' >
                     ${navbarmenu.map((item, index) => `
                     <li class="nav-item" key=${index}>
                             <a class="nav-link  " href="#" style="color: ${navbartextcolor}; font-family: ${headertextfont}; margin: 5px;">${item}</a>
                         </li>
               `).join('')}
                         
                         <li class="nav-item" style=' display: ${navremovebtn1 ? 'none' : ''}; '>
                                                            <a class="nav-link  " href="#" >
                                                            <button class="buttoncolor" style=' font-family: ${button1font};background-color: ${buttoncolor};border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: ${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur}); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px ' >${navbtn1}</button></a>
                                    </li>
                                  <li class="nav-item" style=' display: ${navremovebtn2 ? 'none' : ''}; '>
                                                            <a class="nav-link  " href="#" >
                                                            <button class="buttoncolor" style=' font-family: ${button1font};background-color: ${buttoncolor};border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: ${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur}); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px ' >${navbtn2}</button></a>
                                    </li>
                            
                                 ${navAddbutton.map((button, index) => `
                                          <li class="nav-item" style=' display: ${addbuttonapprovel == false ? 'none' : ''}; '>
                                                            <a class="nav-link  " href="#" >
                                                            <button class="buttoncolor" style=' font-family: ${button1font};background-color: ${buttoncolor};border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: ${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur}); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px ' >${button}</button></a>
                                    </li>
                                   `).join('')}
                     </ul>
                 </div>
             </div>
         </nav>

         <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
        <script src="https:cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
        <script>
            function scrollText(element, speed) {
                let scrollAmount = 0;
                const scrollDelay = speed;
                const textWidth = element.offsetWidth;
                const containerWidth = element.parentElement.offsetWidth;
   
                function scroll() {
                    scrollAmount++;
                   
   
                    if (scrollAmount >= textWidth + containerWidth) {
                        scrollAmount = 0;
                    }
                }
   
                setInterval(scroll, scrollDelay);
            }
   
            const scrollingText = document.getElementById('scrollingText');
            scrollText(scrollingText, 0);  Adjust speed as needed
        </script>

            </body>
            </html>
            `
        const NavTemplate2 = `
               <!DOCTYPE html>
               <html lang="en">
               <head>
                   <meta charset="UTF-8">
                   <meta http-equiv="X-UA-Compatible" content="IE=edge">
                   <meta name="viewport" content="width=device-width, initial-scale=1.0">
                   <title>${navbartitle}</title>
               <link rel="icon" type="image/x-icon" href="${logo}">
                   <style>
                   /* Navbar no 5 */
                   ._58ce83d8 {
                     top: 0;
                     left: 0;
                     right: 0;
                     display: flex;
                     z-index: 99;
                     
                     min-height: 70px;
                     align-items: center;
                    
                   }
                   ._5859e61e {
                     width: 100%;
                     display: flex;
                     flex-direction: column;
                     justify-content: space-between;
                   }
                   ._157dc3d3 {
                     height: 70px;
                     display: flex;
                     padding: 0 30px;
                     position: relative;
                     flex-grow: 1;
                     align-items: center;
                     border-radius: 5px;
                     flex-direction: row;
                     --nav-flyout-bg: #fff;
                   }
                   
                   .um-nav-wrapper {
                     color: #212529;
                     display: flex;
                     flex-grow: 1;
                     align-items: center;
                   }
                   .um-nav-wrapper.um-classic .um-nav-children {
                     margin-right: auto;
                   }
                   ._32caed59 {
                     margin: 0;
                     margin-right: auto;
                   }
                   ._32caed59 > a,
                   ._32caed59 > span {
                     color: inherit;
                     display: flex;
                     align-items: center;
                     line-height: 0;
                   }
                   ._32caed59 > a > div + span,
                   ._32caed59 > span > div + span {
                     margin-left: calc(15px * 1);
                   }
                   .um-nav-links {
                     margin: 0;
                     display: flex;
                     padding: 0;
                     font-size: 15px;
                     list-style: none;
                     align-items: center;
                   }
                   .um-nav-links > li {
                     position: relative;
                   }
                   ._b9923f60 {
                     display: block;
                     height: 24px;
                     width: 24px;
                   }
                   .um-nav-buttons {
                     display: flex;
                     align-items: center;
                     margin-left: 20;
                   }
                   
                   .btn {
                     color: #212529;
                     border: none;
                     cursor: pointer;
                     margin: 0 5px;
                     display: inline-flex;
                     outline: none;
                     padding: 4px 10px;
                   
                     position: relative;
                     background: #eee;
                     box-sizing: border-box;
                     transition: all 0.2s ease-in-out;
                     align-items: center;
                     border-radius: 3px;
                     justify-content: center;
                     text-decoration: none;
                   }
                   .um-nav-drawerTrigger {
                     width: 3em;
                     border: none;
                     height: 3em;
                     margin: 0;
                     padding: 0;
                     position: relative;
                     background: transparent;
                     border-radius: 4px;
                   }
                   .um-nav-drawerTrigger::after {
                     top: 9px;
                     left: 7px;
                     width: 26px;
                     height: 4px;
                     content: "";
                     opacity: 0.8;
                     position: absolute;
                     background: #343a40;
                     box-shadow: 0 8px 0 #343a40, 0 16px 0 #343a40;
                     border-radius: 2px;
                   }
                   
                   @keyframes keyframes-navbar {
                     0% {
                       opacity: 0;
                       transform: scale(0.9) translate(0, -10px);
                     }
                     100% {
                       opacity: 1;
                       transform: scale(1) translate(0, 0);
                     }
                   }
                   .um-nav-drawer {
                     position: absolute;
                     top: 20%;
                     right: 20px;
                     background-color: #fff;
                     border: 1px solid red;
                   }
                   
                   .um-nav-drawer header {
                     display: flex;
                     padding: 5px;
                     border-bottom: 1px solid #f1f3f5;
                     justify-content: flex-end;
                   }
                   .um-nav-drawer header .um-nav-drawerClose {
                     all: unset;
                     width: 40px;
                     border: none;
                     cursor: pointer;
                     height: 40px;
                     margin: 0;
                     padding: 0;
                     font-size: 30px;
                     background: transparent;
                     text-align: center;
                   }
                   .um-nav-drawer .um-drawer-links {
                     display: flex;
                     list-style: none;
                     flex-direction: column;
                   }
                   .um-nav-drawer .um-drawer-links > li {
                     border-bottom: 1px solid #f1f3f5;
                   }
                   .um-nav-drawer .um-drawer-links > li > a {
                     font: inherit;
                     display: block;
                     padding: 12px 20px;
                     font-size: 1em;
                   }
                   .um-nav-drawer .um-drawer-buttons {
                     margin: 25px;
                     display: flex;
                     flex-wrap: wrap;
                     justify-content: flex-end;
                   }
                   .um-nav-drawer .um-drawer-buttons button {
                     width: 100%;
                     flex-grow: 1;
                     font-size: 15px !important;
                     margin-bottom: 10px;
                   }
                   .btn span {
                     text-decoration: none;
                   }
                   @media (max-width: 992px) {
                     .um-nav-links,
                     .um-nav-buttons {
                       display: none !important;
                     }
                   }
                   @media (min-width: 992px) {
                     .drawerTrigger  {
                       display: none !important;
                     }
                   }
                   
                   .wr {
                     width: 100%;
                     margin: 0 auto;
                     padding: 0 30px;
                     position: relative;
                     max-width: 550px;
                     box-sizing: border-box;
                   }
                   ._18a9f441 {
                     display: flex;
                     justify-content: center;
                   }
                   ._f6f5267b {
                     border: 1px solid #f1f3f5;
                     display: flex;
                     padding:6px 20px;
                     z-index: 10;
                     position: relative;
                     flex-grow: 1;
                     margin-top: 20px;
                     max-width: 100%;
                     background: #fff;
                     box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.2);
                     align-items: center;
                     border-radius: 6px;
                     flex-direction: row;
                     --nav-flyout-bg: #fff;
                   }
                   
                   ._f4c879d8 {
                       display: flex;
                       z-index: 10;
                       position: relative;
                       flex-grow: 1;
                       box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 40px -10px;
                       align-items: center;
                       flex-direction: row;
                       --nav-flyout-bg: #fff;
                       border: 1px solid #f1f3f5;
                       padding: 15px 20px;
                       background: #fff;
                       border-radius: 5px;
                       margin-top: 15px;
                   }._9698a7cf {
                       margin: 0px auto 0px 0px;
                   }
                   ._9698a7cf > a, ._9698a7cf > span {
                      
                       color: inherit;
                       display: flex;
                       align-items: center;
                       line-height: 0;
                   }
                   </style>
                   <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
               </head>
               <body>
              
               <div class="container navbar-expand-lg" style=" background-color:${introcolor};border-bottom: 1px solid ${conatcmenuborder};min-width: 100%;">
               <section class="_58ce83d8">
                   <div class="wr">
                       <div class="_18a9f441">
                           <div class="_f6f5267b" style="background-color: ${titlecolor}; border: 1px solid ${conatcmenuborder};min-width:33rem;">
                               <div class="um-nav-wrapper">
                                   <nav class="um-nav-children">
                                       <div class="_32caed59" style="gap: 1px; font-size: 14px;">
                                           <a>
                                               <div class="_8c82ce03" style="scale: 1;">
                                                   <img src="${newlogo.length > 0 ? newlogo : Newlogo}" class="_b9923f60" alt="11.svg" />
                                               </div>
                                               <span class="_11650ac9" style="margin-left: 10px; color: black; font-size: 20px; background-color: ${titlecolor}; color: ${navbartextcolor}; font-family: ${headertextfont};">${navbartitle}</span>
                                           </a>
                                       </div>
                                <button onClick={handleToggle} class="um-nav-drawerTrigger" title="Open Mobile Menu"></button>
                                        
                                   </nav>
                               </div>
                              
                           </div>
                       </div>
                   </div>
               </section>
           </div>
           

  
       
           <script>
               let isOpen = false;
       
               function toggleDrawer() {
                const drawer = document.getElementById('navDrawer');
                if (drawer.style.display === 'none' || drawer.style.display === '') {
                    drawer.style.display = 'block';
                } else {
                    drawer.style.display = 'none';
                }
            }
                 
       
            function handleClose() {
                document.getElementById('navDrawer').style.display = 'none';
            }
           </script>
           <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
               </body>
               </html>
               `
        const NavTemplate3 = `
               <!DOCTYPE html>
               <html lang="en">
               <head>
                   <meta charset="UTF-8" />
                   <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                   <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                   <title>${navbartitle}</title>
               <link rel="icon" type="image/x-icon" href="${logo}">
                   <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
                     <style>
                     ._58ce83d8 {
                        top: 0;
                        left: 0;
                        right: 0;
                        display: flex;
                        z-index: 99;
                        
                        min-height: 70px;
                        align-items: center;
                       
                      }
                      ._5859e61e {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                      }
                      ._157dc3d3 {
                        height: 70px;
                        display: flex;
                        padding: 0 30px;
                        position: relative;
                        flex-grow: 1;
                        align-items: center;
                        border-radius: 5px;
                        flex-direction: row;
                        --nav-flyout-bg: #fff;
                      }
                      .nav-item  {
                        display: inline-block;
                        list-style: none;
                        margin: 5px;
                      }
                      
                      .um-nav-wrapper {
                        color: #212529;
                        display: flex;
                        flex-grow: 1;
                        align-items: center;
                      }
                      .um-nav-wrapper.um-classic .um-nav-children {
                        margin-right: auto;
                      }
                      ._32caed59 {
                        margin: 0;
                        margin-right: auto;
                      }
                      ._32caed59 > a,
                      ._32caed59 > span {
                        color: inherit;
                        display: flex;
                        align-items: center;
                        line-height: 0;
                      }
                      ._32caed59 > a > div + span,
                      ._32caed59 > span > div + span {
                        margin-left: calc(15px * 1);
                      }
                      .um-nav-links {
                        margin: 0;
                        display: flex;
                        padding: 0;
                        font-size: 15px;
                        list-style: none;
                        align-items: center;
                      }
                      .um-nav-links > li {
                        position: relative;
                      }
                      ._b9923f60 {
                        display: block;
                        height: 24px;
                        width: 24px;
                      }
                      .um-nav-buttons {
                        display: flex;
                        align-items: center;
                        margin-left: 20;
                      }
                      
                      .btn {
                        color: #212529;
                        border: none;
                        cursor: pointer;
                        margin: 0 5px;
                        display: inline-flex;
                        outline: none;
                        padding: 4px 10px;
                      
                        position: relative;
                        background: #eee;
                        box-sizing: border-box;
                        transition: all 0.2s ease-in-out;
                        align-items: center;
                        border-radius: 3px;
                        justify-content: center;
                        text-decoration: none;
                      }
                      .um-nav-drawerTrigger {
                        width: 3em;
                        border: none;
                        height: 3em;
                        margin: 0;
                        padding: 0;
                        position: relative;
                        background: transparent;
                        border-radius: 4px;
                      }
                      .um-nav-drawerTrigger::after {
                        top: 9px;
                        left: 7px;
                        width: 26px;
                        height: 4px;
                        content: "";
                        opacity: 0.8;
                        position: absolute;
                        background: #343a40;
                        box-shadow: 0 8px 0 #343a40, 0 16px 0 #343a40;
                        border-radius: 2px;
                      }
                      
                      @keyframes keyframes-navbar {
                        0% {
                          opacity: 0;
                          transform: scale(0.9) translate(0, -10px);
                        }
                        100% {
                          opacity: 1;
                          transform: scale(1) translate(0, 0);
                        }
                      }
                      .um-nav-drawer {
                        position: absolute;
                        top: 20%;
                        right: 20px;
                        background-color: #fff;
                        border: 1px solid red;
                      }
                      
                      .um-nav-drawer header {
                        display: flex;
                        padding: 5px;
                        border-bottom: 1px solid #f1f3f5;
                        justify-content: flex-end;
                      }
                      .um-nav-drawer header .um-nav-drawerClose {
                        all: unset;
                        width: 40px;
                        border: none;
                        cursor: pointer;
                        height: 40px;
                        margin: 0;
                        padding: 0;
                        font-size: 30px;
                        background: transparent;
                        text-align: center;
                      }
                      .um-nav-drawer .um-drawer-links {
                        display: flex;
                        list-style: none;
                        flex-direction: column;
                      }
                      .um-nav-drawer .um-drawer-links > li {
                        border-bottom: 1px solid #f1f3f5;
                      }
                      .um-nav-drawer .um-drawer-links > li > a {
                        font: inherit;
                        display: block;
                        padding: 12px 20px;
                        font-size: 1em;
                      }
                      .um-nav-drawer .um-drawer-buttons {
                        margin: 25px;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-end;
                      }
                      .um-nav-drawer .um-drawer-buttons button {
                        width: 100%;
                        flex-grow: 1;
                        font-size: 15px !important;
                        margin-bottom: 10px;
                      }
                      .btn span {
                        text-decoration: none;
                      }
                      @media (max-width: 992px) {
                        .um-nav-links,
                        .um-nav-buttons {
                          display: none !important;
                        }
                      }
                      @media (min-width: 992px) {
                        .drawerTrigger  {
                          display: none !important;
                        }
                      }
                      
                      .wr {
                        width: 100%;
                        margin: 0 auto;
                        padding: 0 30px;
                        position: relative;
                    
                        box-sizing: border-box;
                      }
                      ._18a9f441 {
                        display: flex;
                        justify-content: center;
                      }
                      ._f6f5267b {
                        border: 1px solid #f1f3f5;
                        display: flex;
                        padding:6px 20px;
                        z-index: 10;
                        position: relative;
                        flex-grow: 1;
                        margin-top: 20px;
                        max-width: 700px;
                        background: #fff;
                        box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.2);
                        align-items: center;
                        border-radius: 6px;
                        flex-direction: row;
                        --nav-flyout-bg: #fff;
                      }
                      
                      ._f4c879d8 {
                          display: flex;
                          z-index: 10;
                          position: relative;
                          flex-grow: 1;
                          box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 40px -10px;
                          align-items: center;
                          flex-direction: row;
                          --nav-flyout-bg: #fff;
                          border: 1px solid #f1f3f5;
                          padding: 15px 20px;
                          background: #fff;
                          border-radius: 5px;
                          margin-top: 15px;
                      }._9698a7cf {
                          margin: 0px auto 0px 0px;
                      }
                      ._9698a7cf > a, ._9698a7cf > span {
                         
                          color: inherit;
                          display: flex;
                          align-items: center;
                          line-height: 0;
                      }
                      </style>
               </head>
               <body>
               <div class="container navbar-expand-lg" style="background-color:${introcolor}; borderBottom: 1px solid ${conatcmenuborder};min-width: 100%;">
    <div class="_58ce83d8">
        <div class="wr" style="maxWidth: 100%;">
            <div class="_f4c879d8" style="background-color: ${titlecolor}; border: 1px solid ${conatcmenuborder}; height:4rem;">
                <div class="_9698a7cf" style="gap: 1px; font-size: 16px;">
                    <a class="d-flex align-items-center">
                        <div class="_f7a17dd3" style="scale: 1;">
                            <img src='${newlogo.length > 0 ? newlogo : Newlogo}' class="_246d637b" style="width: 24px; height: 24px;" alt="11.svg" />
                        </div>
                        <span class="_3c86000f" style="marginLeft: 10px; color: black; font-size: 20px; backgroundColor: ${titlecolor}; color: ${navbartextcolor}; font-family: ${headertextfont}; text-decoration:none;">${navbartitle}</span>
                    </a>
                </div>
                <ul class="um-nav-links">
                ${navbarmenu.map((item, index) => `
                  <li>
                  <a href="#" style="color: ${navbartextcolor}; font-family: ${headertextfont}; margin: 5px; text-decoration:none;">${item}</a></li>

            `).join('')}
                    
                
                <div class="um-nav-buttons">
                <li class="nav-item" style=' display: ${navremovebtn1 ? 'none' : ''};list-style:none '>
                <a class="nav-link  m-0 p-0" href="#" ><button class="buttoncolor" 
                 style='font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: ${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur}); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px ;' >${navbtn1}</button></a>
            </li>
            <li class="nav-item" style=' display: ${navremovebtn2 ? 'none' : ''};  list-style:none'>
            <a class="nav-link  m-0 p-0" href="#"><button class="buttoncolor"
              style='font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: ${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur}); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px ;'>${navbtn2}</button></a>
        </li>
        ${navAddbutton.map((button, index) => `
          <li class="nav-item" style=' display: ${addbuttonapprovel == false ? 'none' : ''};  list-style:none'>
            <a class="nav-link  m-0 p-0" href="#"><button class="buttoncolor"  style='font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: ${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur}); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px ;'>${button}</button></a>
        </li>
   `).join('')}
        </ul>
                </div>
                
                </div>
            </div>
        </div>
    </div>
</div>

<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script> 
               </body>
               </html>
               `

        const NavTemplate4 = `
               <!DOCTYPE html>
               <html lang="en">
               <head>
                   <meta charset="UTF-8">
                   <meta http-equiv="X-UA-Compatible" content="IE=edge">
                   <meta name="viewport" content="width=device-width, initial-scale=1.0">
                  <title>${navbartitle}</title>
               <link rel="icon" type="image/x-icon" href="${logo}">
                   <link rel="stylesheet" href="Navbar.css">
                   <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
                   <style>
                   /* Navbar no 5 */
                   ._58ce83d8 {
                     top: 0;
                     left: 0;
                     right: 0;
                     display: flex;
                     z-index: 99;
                     
                     min-height: 70px;
                     align-items: center;
                    
                   }
                   ._5859e61e {
                     width: 100%;
                     display: flex;
                     position: relative;
                     flex-direction: column;
                     justify-content: space-between;
                   }
                   .nav-item  {
                    display: inline-block;
                    list-style: none;
                    margin: 5px;
                  }
                   ._157dc3d3 {
                     height: 70px;
                     display: flex;
                     padding: 0 30px;
                     position: relative;
                     flex-grow: 1;
                     align-items: center;
                     border-radius: 5px;
                     flex-direction: row;
                     --nav-flyout-bg: #fff;
                   }
                   
                   .um-nav-wrapper {
                     color: #212529;
                     display: flex;
                     flex-grow: 1;
                     align-items: center;
                   }
                   .um-nav-wrapper.um-classic .um-nav-children {
                     margin-right: auto;
                   }
                   ._32caed59 {
                     margin: 0;
                     margin-right: auto;
                   }
                   ._32caed59 > a,
                   ._32caed59 > span {
                     color: inherit;
                     display: flex;
                     align-items: center;
                     line-height: 0;
                   }
                   ._32caed59 > a > div + span,
                   ._32caed59 > span > div + span {
                     margin-left: calc(15px * 1);
                   }
                   .um-nav-links {
                     margin: 3px 0px 0px;
                     display: flex;
                     padding: 0;
                     font-size: 15px;
                     list-style-type: none;
                     align-items: center;
                   }
                   .um-nav-links > li {
                     position: relative;
                     list-style-type: none;
                     margin: 3px 0px 0px;

                   }
                   ._b9923f60 {
                     display: block;
                     height: 24px;
                     width: 24px;
                   }
                   .um-nav-buttons {
                     display: flex;
                     align-items: center;
                     margin-left: 20;
                   }
                   
                   .btn {
                     color: #212529;
                     border: none;
                     cursor: pointer;
                     margin: 0 5px;
                     display: inline-flex;
                     outline: none;
                     padding: 4px 10px;
                   
                     position: relative;
                     background: #eee;
                     box-sizing: border-box;
                     transition: all 0.2s ease-in-out;
                     align-items: center;
                     border-radius: 3px;
                     justify-content: center;
                     text-decoration: none;
                   }
                   .um-nav-drawerTrigger {
                     width: 3em;
                     border: none;
                     height: 3em;
                     margin: 0;
                     padding: 0;
                     position: relative;
                     background: transparent;
                     border-radius: 4px;
                   }
                   .um-nav-drawerTrigger::after {
                     top: 9px;
                     left: 7px;
                     width: 26px;
                     height: 4px;
                     content: "";
                     opacity: 0.8;
                     position: absolute;
                     background: #343a40;
                     box-shadow: 0 8px 0 #343a40, 0 16px 0 #343a40;
                     border-radius: 2px;
                   }
                   
                   @keyframes keyframes-navbar {
                     0% {
                       opacity: 0;
                       transform: scale(0.9) translate(0, -10px);
                     }
                     100% {
                       opacity: 1;
                       transform: scale(1) translate(0, 0);
                     }
                   }
                   .um-nav-drawer {
                     position: absolute;
                     top: 20%;
                     right: 20px;
                     background-color: #fff;
                     border: 1px solid red;
                   }
                   
                   .um-nav-drawer header {
                     display: flex;
                     padding: 5px;
                     border-bottom: 1px solid #f1f3f5;
                     justify-content: flex-end;
                   }
                   .um-nav-drawer header .um-nav-drawerClose {
                     all: unset;
                     width: 40px;
                     border: none;
                     cursor: pointer;
                     height: 40px;
                     margin: 0;
                     padding: 0;
                     font-size: 30px;
                     background: transparent;
                     text-align: center;
                   }
                   .um-nav-drawer .um-drawer-links {
                     display: flex;
                     list-style: none;
                     flex-direction: column;
                   }
                   .um-nav-drawer .um-drawer-links > li {
                     border-bottom: 1px solid #f1f3f5;
                   }
                   .um-nav-drawer .um-drawer-links > li > a {
                     font: inherit;
                     display: block;
                     padding: 12px 20px;
                     font-size: 1em;
                   }
                   .um-nav-drawer .um-drawer-buttons {
                     margin: 25px;
                     display: flex;
                     flex-wrap: wrap;
                     justify-content: flex-end;
                   }
                   .um-nav-drawer .um-drawer-buttons button {
                     width: 100%;
                     flex-grow: 1;
                     font-size: 15px !important;
                     margin-bottom: 10px;
                   }
                   .btn span {
                     text-decoration: none;
                   }
                   @media (max-width: 992px) {
                     .um-nav-links,
                     .um-nav-buttons {
                       display: none !important;
                     }
                   }
                   @media (min-width: 992px) {
                     .drawerTrigger  {
                       display: none !important;
                     }
                   }
                   
                   .wr {
                     width: 100%;
                     margin: 0 auto;
                     padding: 0 30px;
                     position: relative;
                     max-width: 550px;
                     box-sizing: border-box;
                   }
                   ._18a9f441 {
                     display: flex;
                     justify-content: center;
                   }
                   ._f6f5267b {
                     border: 1px solid #f1f3f5;
                     display: flex;
                     padding:6px 20px;
                     z-index: 10;
                     position: relative;
                     flex-grow: 1;
                     margin-top: 20px;
                     max-width: 700px;
                     background: #fff;
                     box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.2);
                     align-items: center;
                     border-radius: 6px;
                     flex-direction: row;
                     --nav-flyout-bg: #fff;
                   }
                   
                   ._f4c879d8 {
                       display: flex;
                       z-index: 10;
                       position: relative;
                       flex-grow: 1;
                       box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 40px -10px;
                       align-items: center;
                       flex-direction: row;
                       --nav-flyout-bg: #fff;
                       border: 1px solid #f1f3f5;
                       padding: 15px 20px;
                       background: #fff;
                       border-radius: 5px;
                       margin-top: 15px;
                       margin:5px;
                       widht:100%;
                   }
                   ._9698a7cf {
                       margin: 0px auto 0px 0px;
                   }
                   ._9698a7cf > a, ._9698a7cf > span {
                      
                       color: inherit;
                       display: flex;
                       align-items: center;
                       line-height: 0;
                   }
                   </style>
                   </head>
                   <body>
                   <div class='container'>
                   <div class="_58ce83d8" style="background-color: ${titlecolor}; border-bottom: 1px solid ${conatcmenuborder},min-width: 100%;">
            <div class="_5859e61e">
            <div class="_157dc3d3">
                <nav class="_e45b5504 um-nav-wrapper um-classic">
                <div class="um-nav-children">
                    <div class="_32caed59" style="gap: 1px; font-size: 14px">
                        <a>
                            <div class="_8c82ce03" style="scale: 1">
                                <img src="${newlogo.length > 0 ? newlogo : Newlogo}" class="_b9923f60" alt="11.svg">
                            </div>
                            <span class="_11650ac9" style="font-family: ${headertextfont};color: ${navbartextcolor};">${navbartitle}</span>
                        </a>
                    </div>
                </div>
                <ul class="um-nav-links">
                ${navbarmenu.map((item, index) => `
                <li>
                <a href="#" style="color: ${navbartextcolor}; font-family: ${headertextfont}; margin: 5px; text-decoration:none">${item}</a></li>

            `).join('')}
            
            <div class="um-nav-buttons">
            <li class="nav-item" style=' display: ${navremovebtn1 ? 'none' : ''};list-style:none '>
            <a class="nav-link  m-0 p-0" href="#" ><button class="buttoncolor" 
             style='font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: ${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur}); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px ;'>${navbtn1}</button></a>
        </li>
        <li class="nav-item" style=' display: ${navremovebtn2 ? 'none' : ''};  list-style:none'>
        <a class="nav-link  m-0 p-0" href="#"><button class="buttoncolor" 
         style='font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: ${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur}); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px ;'>${navbtn2}</button></a>
    </li>
        ${navAddbutton.map((button, index) => `
          <li class="nav-item" style=' display: ${addbuttonapprovel == false ? 'none' : ''};  list-style:none'>
            <a class="nav-link  m-0 p-0" href="#"><button class="buttoncolor" 
             style='font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: ${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur}); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px ;'>${button}</button></a>
        </li>
   `).join('')}
    </ul>
            </div>
            </nav>
        </div>
    </div>
</div>
</div>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
                   </body>
                   </html>
                                 `
        const NavTemplate5 = `
               <!DOCTYPE html>
               <html lang="en">
               <head>
                   <meta charset="UTF-8">
                   <meta http-equiv="X-UA-Compatible" content="IE=edge">
                   <meta name="viewport" content="width=device-width, initial-scale=1.0">
                   <title>${navbartitle}</title>
               <link rel="icon" type="image/x-icon" href="${logo}">
                   <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
                   <style>
                   .um-nav-drawer {
                    background-color: #f0f0f0;
                    padding: 20px;
                    border: 1px solid #ccc;
                    display: none; /* Initially hidden */
                }
                .um-nav-drawerClose {
                    background: none;
                    border: none;
                    cursor: pointer;
                }
                .um-drawer-links {
                    list-style-type: none;
                    padding: 0;
                }
                .um-drawer-links li {
                    margin: 10px 0;
                }
                .um-drawer-links a {
                    text-decoration: none;
                    color: #333;
                }
                .um-drawer-buttons .btn {
                    background-color: #007bff;
                    border: none;
                    color: white;
                    padding: 10px 20px;
                    margin: 5px 0;
                    cursor: pointer;
                    border-radius: 5px;
                }
                .um-drawer-buttons .btn:hover {
                    background-color: #0056b3;
                }
                /* Navbar no 5 */
                ._58ce83d8 {
                  top: 0;
                  left: 0;
                  right: 0;
                  display: flex;
                  z-index: 99;
                  
                  min-height: 70px;
                  align-items: center;
                 
                }
                ._5859e61e {
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                }
                ._157dc3d3 {
                  height: 70px;
                  display: flex;
                  padding: 0 30px;
                  position: relative;
                  flex-grow: 1;
                  align-items: center;
                  border-radius: 5px;
                  flex-direction: row;
                  --nav-flyout-bg: #fff;
                }
                
                .um-nav-wrapper {
                  color: #212529;
                  display: flex;
                  flex-grow: 1;
                  align-items: center;
                }
                .um-nav-wrapper.um-classic .um-nav-children {
                  margin-right: auto;
                }
                ._32caed59 {
                  margin: 0;
                  margin-right: auto;
                }
                ._32caed59 > a,
                ._32caed59 > span {
                  color: inherit;
                  display: flex;
                  align-items: center;
                  line-height: 0;
                }
                ._32caed59 > a > div + span,
                ._32caed59 > span > div + span {
                  margin-left: calc(15px * 1);
                }
                .um-nav-links {
                  margin: 0;
                  display: flex;
                  padding: 0;
                  font-size: 15px;
                  list-style: none;
                  align-items: center;
                }
                .um-nav-links > li {
                  position: relative;
                }
                ._b9923f60 {
                  display: block;
                  height: 24px;
                  width: 24px;
                }
                .um-nav-buttons {
                  display: flex;
                  align-items: center;
                  margin-left: 20;
                }
                
                .btn {
                  color: #212529;
                  border: none;
                  cursor: pointer;
                  margin: 0 5px;
                  display: inline-flex;
                  outline: none;
                  padding: 4px 10px;
                
                  position: relative;
                  background: #eee;
                  box-sizing: border-box;
                  transition: all 0.2s ease-in-out;
                  align-items: center;
                  border-radius: 3px;
                  justify-content: center;
                  text-decoration: none;
                }
                .um-nav-drawerTrigger {
                  width: 3em;
                  border: none;
                  height: 3em;
                  margin: 0;
                  padding: 0;
                  position: relative;
                  background: transparent;
                  border-radius: 4px;
                }
                .um-nav-drawerTrigger::after {
                  top: 9px;
                  left: 7px;
                  width: 26px;
                  height: 4px;
                  content: "";
                  opacity: 0.8;
                  position: absolute;
                  background: #343a40;
                  box-shadow: 0 8px 0 #343a40, 0 16px 0 #343a40;
                  border-radius: 2px;
                }
                
                @keyframes keyframes-navbar {
                  0% {
                    opacity: 0;
                    transform: scale(0.9) translate(0, -10px);
                  }
                  100% {
                    opacity: 1;
                    transform: scale(1) translate(0, 0);
                  }
                }
                .um-nav-drawer {
                  position: absolute;
                  top: 20%;
                  right: 20px;
                  background-color: #fff;
                  border: 1px solid red;
                }
                
                .um-nav-drawer header {
                  display: flex;
                  padding: 5px;
                  border-bottom: 1px solid #f1f3f5;
                  justify-content: flex-end;
                }
                .um-nav-drawer header .um-nav-drawerClose {
                  all: unset;
                  width: 40px;
                  border: none;
                  cursor: pointer;
                  height: 40px;
                  margin: 0;
                  padding: 0;
                  font-size: 30px;
                  background: transparent;
                  text-align: center;
                }
                .um-nav-drawer .um-drawer-links {
                  display: flex;
                  list-style: none;
                  flex-direction: column;
                }
                .um-nav-drawer .um-drawer-links > li {
                  border-bottom: 1px solid #f1f3f5;
                }
                .um-nav-drawer .um-drawer-links > li > a {
                  font: inherit;
                  display: block;
                  padding: 12px 20px;
                  font-size: 1em;
                }
                .um-nav-drawer .um-drawer-buttons {
                  margin: 25px;
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: flex-end;
                }
                .um-nav-drawer .um-drawer-buttons button {
                  width: 100%;
                  flex-grow: 1;
                  font-size: 15px !important;
                  margin-bottom: 10px;
                }
                .btn span {
                  text-decoration: none;
                }
                @media (max-width: 992px) {
                  .um-nav-links,
                  .um-nav-buttons {
                    display: none !important;
                  }
                }
                @media (min-width: 992px) {
                  .drawerTrigger  {
                    display: none !important;
                  }
                }
                
              
                ._18a9f441 {
                  display: flex;
                  justify-content: center;
                }
                ._f6f5267b {
                    border: 1px solid #f1f3f5;
                    display: flex;
                    padding:6px 20px;
                    z-index: 10;
                    position: absolute;
                    flex-grow: 1;
                    margin-top: 10px;
                    margin-left:250px;
                    width: 600px;
                    margin-bottom: 15px;
                    background: #fff;
                    box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.2);
                    align-items: center;
                    border-radius: 6px;
                    flex-direction: row;
                    --nav-flyout-bg: #fff;
                  }
                
                ._f4c879d8 {
                    display: flex;
                    z-index: 10;
                    position: relative;
                    flex-grow: 1;
                    box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 40px -10px;
                    align-items: center;
                    flex-direction: row;
                    --nav-flyout-bg: #fff;
                    border: 1px solid #f1f3f5;
                    padding: 15px 20px;
                    background: #fff;
                    border-radius: 5px;
                    margin-top: 15px;
                }._9698a7cf {
                    margin: 0px auto 0px 0px;
                }
                ._9698a7cf > a, ._9698a7cf > span {
                   
                    color: inherit;
                    display: flex;
                    align-items: center;
                    line-height: 0;
                }
                   </style>
               </head>
               <body>
                
               <div  class='container' style=" border-bottom: 1px solid ${conatcmenuborder};min-width: 100%;">
               <section class="_58ce83d8" style="position: absolute;">
                   <div class="wr" style="margin: 0px auto 0px 0px;">
                       <div class="_18a9f441" style="justify-content: start;">
                           <div class="_f6f5267b" style="background-color: ${titlecolor}; border: 1px solid ${conatcmenuborder};min-wdth:33rem">
                               <div class="um-nav-wrapper">
                                   <nav class="um-nav-children">
                                       <div class="_32caed59" style="gap: 1px; font-size: 14px;">
                                           <a>
                                               <div class="_8c82ce03" style="scale: 1;">
                                                   <img src="${newlogo.length > 0 ? newlogo : Newlogo}" class="_b9923f60" alt="11.svg" />
                                               </div>
                                               <span class="_11650ac9" style="font-family: ${headertextfont};">${navbartitle}</span>
                                           </a>
                                       </div>
                                   </nav>
                               </div>
                               <button onclick="handleToggle()" class="um-nav-drawerTrigger" title="Open Mobile Menu"></button>
                               ${isOpen ? `
                               <div class="_e45b5504 um-nav-drawer" style='background-color: ${titlecolor};'>
                                   <header>
                                       <button class="um-nav-drawerClose" onclick="handleClose()">
                                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                               <line x1="18" y1="6" x2="6" y2="18"></line>
                                               <line x1="6" y1="6" x2="18" y2="18"></line>
                                           </svg>
                                       </button>
                                   </header>
                                   <div class="um-drawer-links" style='background-color: ${titlecolor};'>
                                   ${navbarmenu.map((item, index) => `
                                                       <li>
                                                       <a href="#" style="color: ${navbartextcolor}; font-family: ${headertextfont}; margin: 5px;text0decoration:none;">${item}</a></li>

                                  `).join('')}
            
                                   </div>
                                   <div class="um-drawer-buttons" >
                                       <button id="d_btn_x2cz0o6ryp" class="_e72e75b2 btn"
                                            style="display:${navremovebtn1 ? 'none' : ''}   ;font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: ${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur}); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">${navbtn1}
                                       </button>
                                       <button id="d_btn_gni5jwa3za" class="_89517563 btn"
                                            style="display:${navremovebtn1 ? 'none' : ''}   ;font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: ${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur}); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">${navbtn2}
                                       </button>
                                               ${navAddbutton.map((button, index) => `
                                                      <button id="d_btn_x2cz0o6ryp" class="_e72e75b2 btn"
                                            style="display: ${addbuttonapprovel == false ? 'none' : ''};font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: ${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur}); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">${button}
                                       </button>
                                               `).join('')}
                                   </div>
                               </div>` : ''}
                           </div>
                       </div>
                   
               </section>
           </div>
         </div>  
    <script>
        function toggleDrawer() {
            const drawer = document.getElementById('navDrawer');
            if (drawer.style.display === 'none' || drawer.style.display === '') {
                drawer.style.display = 'block';
            } else {
                drawer.style.display = 'none';
            }
        }

        function handleClose() {
            document.getElementById('navDrawer').style.display = 'none';
        }
    </script>

                            </div>
                        </div>
                    </div>
                </section>
                <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
              </body>
               </html>
                   `
        const NavTemplate6 = `

    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>${navbartitle}</title>
               <link rel="icon" type="image/x-icon" href="${logo}">
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
        <style>
        /* Navbar no 5 */
        ._58ce83d8 {
            top: 0;
            left: 0;
            right: 0;
            display: flex;
            z-index: 99;
            
            min-height: 70px;
            align-items: center;
           
          }
          ._5859e61e {
            width: 100%;
            display: flex;
            position: relative;
            flex-direction: column;
            justify-content: space-between;
          }
          .nav-item  {
           display: inline-block;
           list-style: none;
           margin: 5px;
         }
          ._157dc3d3 {
            height: 70px;
            display: flex;
            padding: 0 30px;
            position: relative;
            flex-grow: 1;
            align-items: center;
            border-radius: 5px;
            flex-direction: row;
            --nav-flyout-bg: #fff;
          }
          
          .um-nav-wrapper {
            color: #212529;
            display: flex;
            flex-grow: 1;
            align-items: center;
          }
          .um-nav-wrapper.um-classic .um-nav-children {
            margin-right: auto;
          }
          ._32caed59 {
            margin: 0;
            margin-right: auto;
          }
          ._32caed59 > a,
          ._32caed59 > span {
            color: inherit;
            display: flex;
            align-items: center;
            line-height: 0;
          }
          ._32caed59 > a > div + span,
          ._32caed59 > span > div + span {
            margin-left: calc(15px * 1);
          }
          .um-nav-links {
            margin: 3px 0px 0px;
            display: flex;
            padding: 0;
            font-size: 15px;
            list-style-type: none;
            align-items: center;
          }
          .um-nav-links > li {
            position: relative;
            list-style-type: none;
            margin: 3px 0px 0px;

          }
          ._b9923f60 {
            display: block;
            height: 24px;
            width: 24px;
          }
          .um-nav-buttons {
            display: flex;
            align-items: center;
            margin-left: 20;
          }
          
          .btn {
            color: #212529;
            border: none;
            cursor: pointer;
            margin: 0 5px;
            display: inline-flex;
            outline: none;
            padding: 4px 10px;
          
            position: relative;
            background: #eee;
            box-sizing: border-box;
            transition: all 0.2s ease-in-out;
            align-items: center;
            border-radius: 3px;
            justify-content: center;
            text-decoration: none;
          }
          .um-nav-drawerTrigger {
            width: 3em;
            border: none;
            height: 3em;
            margin: 0;
            padding: 0;
            position: relative;
            background: transparent;
            border-radius: 4px;
          }
          .um-nav-drawerTrigger::after {
            top: 9px;
            left: 7px;
            width: 26px;
            height: 4px;
            content: "";
            opacity: 0.8;
            position: absolute;
            background: #343a40;
            box-shadow: 0 8px 0 #343a40, 0 16px 0 #343a40;
            border-radius: 2px;
          }
          
          @keyframes keyframes-navbar {
            0% {
              opacity: 0;
              transform: scale(0.9) translate(0, -10px);
            }
            100% {
              opacity: 1;
              transform: scale(1) translate(0, 0);
            }
          }
          .um-nav-drawer {
            position: absolute;
            top: 20%;
            right: 20px;
            background-color: #fff;
            border: 1px solid red;
          }
          
          .um-nav-drawer header {
            display: flex;
            padding: 5px;
            border-bottom: 1px solid #f1f3f5;
            justify-content: flex-end;
          }
          .um-nav-drawer header .um-nav-drawerClose {
            all: unset;
            width: 40px;
            border: none;
            cursor: pointer;
            height: 40px;
            margin: 0;
            padding: 0;
            font-size: 30px;
            background: transparent;
            text-align: center;
          }
          .um-nav-drawer .um-drawer-links {
            display: flex;
            list-style: none;
            flex-direction: column;
          }
          .um-nav-drawer .um-drawer-links > li {
            border-bottom: 1px solid #f1f3f5;
          }
          .um-nav-drawer .um-drawer-links > li > a {
            font: inherit;
            display: block;
            padding: 12px 20px;
            font-size: 1em;
          }
          .um-nav-drawer .um-drawer-buttons {
            margin: 25px;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
          }
          .um-nav-drawer .um-drawer-buttons button {
            width: 100%;
            flex-grow: 1;
            font-size: 15px !important;
            margin-bottom: 10px;
          }
          .btn span {
            text-decoration: none;
          }
          @media (max-width: 992px) {
            .um-nav-links,
            .um-nav-buttons {
              display: none !important;
            }
          }
          @media (min-width: 992px) {
            .drawerTrigger  {
              display: none !important;
            }
          }
          
          .wr {
            width: 100%;
            margin: 0 auto;
            padding: 0 30px;
            position: relative;
            max-width: 550px;
            box-sizing: border-box;
          }
          ._18a9f441 {
            display: flex;
            justify-content: center;
          }
          ._f6f5267b {
            border: 1px solid #f1f3f5;
            display: flex;
            padding:6px 20px;
            z-index: 10;
            position: relative;
            flex-grow: 1;
            margin-top: 20px;
            max-width: 700px;
            background: #fff;
            box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.2);
            align-items: center;
            border-radius: 6px;
            flex-direction: row;
            --nav-flyout-bg: #fff;
          }
          
          ._f4c879d8 {
              display: flex;
              z-index: 10;
              position: relative;
              flex-grow: 1;
              box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 40px -10px;
              align-items: center;
              flex-direction: row;
              --nav-flyout-bg: #fff;
              border: 1px solid #f1f3f5;
              padding: 15px 20px;
              background: #fff;
              border-radius: 5px;
              margin-top: 15px;
              margin:5px;
              widht:100%;
          }
          ._9698a7cf {
              margin: 0px auto 0px 0px;
          }
          ._9698a7cf > a, ._9698a7cf > span {
             
              color: inherit;
              display: flex;
              align-items: center;
              line-height: 0;
          }
        </style>
    </head>
    <body>
    <div class='container'>  
    <div class="_58ce83d8 " style=' background-color: ${titlecolor}, border-bottom: 1px solid ${conatcmenuborder} ' >
    <div class="_5859e61e ">
        <div class="_157dc3d3 px-3">
            <nav class="_e45b5504 um-nav-wrapper um-classic">
                <div class="um-nav-children">
                    <div class="_32caed59" style=' gap: 1px, fontSize: 14px ' >
                        <a>
                            <div class="_8c82ce03" style=' scale: '1' '>
                                <img src="${newlogo.length > 0 ? newlogo : Newlogo}" class="_b9923f60" alt="11.svg" />
                            </div>
                            <span class="_11650ac9" style=' font-family: ${headertextfont} '>AutoPro Hub</span>
                        </a>
                    </div>
                </div>
                <ul class="um-nav-links">
                ${navbarmenu.map((item, index) => `
                                  <li key=${index}>
                                  <a href="#" style="color: ${navbartextcolor}; font-family: ${headertextfont}; margin: 5px; text-decoration:none;">${item}</a></li>

                   `).join('')}

            
            <div class="um-nav-buttons">
            <li class="nav-item" style=' display: ${navremovebtn1 ? 'none' : ''};list-style:none ;'>
            <a class="nav-link  m-0 p-0" href="#" ><button class="buttoncolor" 
             style='font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: ${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur}); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px ;' >${navbtn1}</button></a>
        </li>
        <li class="nav-item" style=' display: ${navremovebtn2 ? 'none' : ''};  list-style:none;'>
        <a class="nav-link  m-0 p-0" href="#"><button class="buttoncolor"
          style='font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: ${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur}); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px ;'>${navbtn2}</button></a>
    </li> 
    ${navAddbutton.map((button, index) => `
      <li class="nav-item" style=' display: ${addbuttonapprovel == false ? 'none' : ''};  list-style:none' >
        <a class="nav-link  m-0 p-0" href="#"><button class="buttoncolor" 
         style='font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: ${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur}); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px ;'>${navbtn2}</button></a>
    </li> 
`).join('')}
    </ul>
            </div>
            </nav>
            
        </div>
    </div>

</div>
</div>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
    </body>
    </html>
        `

        if (selectedLi === null) {
            var NavbarTemplate = NavTemplate1
        };
        if (selectedLi === 0) {
            var NavbarTemplate = NavTemplate2
        };
        if (selectedLi === 1) {
            var NavbarTemplate = NavTemplate3
        };
        if (selectedLi === 2) {
            var NavbarTemplate = NavTemplate4
        };
        if (selectedLi === 3) {
            var NavbarTemplate = NavTemplate5

        };
        if (selectedLi === 4) {
            var NavbarTemplate = NavTemplate6
        };
        // -----------------------------------Hero--------------------------------------/

        var HeroTemplate1 = `
    <!DOCTYPE html>
    <html lang="en"><head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>${title}</title>
<link rel="stylesheet" href="https:cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css" integrity="sha512-DTOQO9RWCH3ppGqcWaEA1BIZOC6xxalwEsw9c2QQeAIftl+Vegovlnee1c9QX4TctnWMn13TZye+giMm8e2LwA==" crossorigin="anonymous" referrerpolicy="no-referrer">
<link rel="stylesheet" href="https:cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
<style> 


/* ========= Navigation =========== */
nav {
display: flex;
align-items: center;
justify-content: space-between;
background-color: transparent;
position: relative;
padding: 0 !important;
width:100%
}
.logo{
display: flex;
align-items: center;
justify-content: start;
}
.logo a img{
height: 40px;
width: 40px;
}

nav .nav-item li {
display: inline-block;
list-style: none;
margin: 5px;
}

nav .nav-item li a {
display: inline-block;
color: #000;
font-size: 20px;
text-decoration: none;
}
.navbar-nav .nav-item a:hover{
text-decoration: underline;
}
.buttoncolor{
margin: 2px;
color: rgb(0, 0, 0);
border: none;
cursor: pointer;
display: inline-flex;
outline: none;
padding: 0.7em 0.8em;
position: relative;
font-size: 14px;
background:#f0f0f0;
box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0);
box-sizing: border-box;
transition: all 0.2s ease-in-out;
align-items: center;
border-color: rgb(227, 227, 227);
border-style: solid;
border-width: 0;
border-radius: 8px;
justify-content: center;
text-decoration: none;
}
nav .nav-item .nav-link button:hover{
background-color: lightslategray;
}

/* =====================Subheader============== */
.Subheader{
background-color: #fafafa;
height: 7px;
background: var(--bg-header);
box-shadow: 0 1px #eaeaea;
left: 0;
position: -webkit-sticky;
position: sticky;
right: 0;
top: 0;
z-index: 100;
}

/* =========== Welcome to cofee hub=========== */
/* .buttoncolor{
background-color:#f8f9fa;
} */
.menu{
border-radius: 20px;
border: 1px solid #f1f3f5;
}
.heading{
font-size: 56px;
font-weight: 600;
color: #212529;
}
.heading2{
font-size: 29px;
font-weight: 600;
color: #212529;
}
.card{
background-color: #f8f9fa;
background-image: linear-gradient(45deg, rgb(255, 255, 255) 0%, rgb(241, 242, 243) 100%);;
border: 1px solid rgb(235, 235, 235);
border-radius: 10px;
margin: 16px;
padding: 16px;
box-shadow: 0px 19px 30px -10px rgba(21, 21, 25, 0.21);;
}

.footer-links  p{
margin: 0;
padding: 0;
}
.footer-links  p a{
text-decoration: transparent;
color: #212529;
}
.footer-links  p a:hover{
text-decoration:underline;
}


.scrolling-text {
white-space: nowrap;
animation: scroll-left 60s linear infinite;
}

@keyframes scroll-left {
0% { transform: translateX(100%); }
100% { transform: translateX(-100%); }
}.container {
width: 80%; 
margin: 0 auto; 
overflow: hidden; 

padding: 10px; 
}

</style>
</head>

<body class="bg-white">

<!-- =========== Navigation ========== -->


<!-- ====================== Welcome to Cofee ============================== -->
<div class="container"   style=' background-color: ${introcolor};min-width: 100%; padding: 50px;padding-top:0px'>
    <div class="row" style="margin-top: ${intromargintop}px; margin-bottom: ${intromarginbottom}px;">
        <div class="col-md-6 d-flex align-items-center flex-column justify-content-center">
            <div style="width: 100%;">
                ${selectedOption1 === 'A' ?
                `<button class="mt-5 btn menu" style="font-size: 12px; color: ${intromenutextcolor}; background-color: ${intromenubgcolor}; border: 2px solid ${conatcmenuborder};border-radius:30px">${menu}--<span style="color: ${contactheadingcolor};">${menu1}</span></button>`
                :
                `<div>
                        <button class="mt-5 btn menu" style="font-size: 12px; border-style: none; color: ${intromenutextcolor}; background-color: ${intromenubgcolor};">${menu}</button>
                        <button class="mt-5 btn menu" style="font-size: 12px; border-style: none;">${menu1}</button>
                    </div>`
            }
            </div>
            <p style="color: #9c36b5; position: relative;">${introcaption}</p>
            <h1 style="font-size: 48px; font-weight: 780; color: ${contenttextheadingcolor}; font-family: ${introtextfont};">${title}</h1>
            <p class="fs-0" style="color: ${contenttextinfocolor}; font-family: ${introinfotextfont};">${intro}</p>
        </div>
        <div class="col-md-6 p-4">
            ${newIntroImage.length > 0 ?
                `<div>
                    ${newIntroImage.map((image, index) => `
                        <img
                            style="height: 390px; width: 100%; border-radius: 10px;"
                            key=${index}
                            src=${image}
                            alt="Image ${index}"
                            class="media_image__kN9DM"
                        />
                    `).join('')}
                </div>`
                :
                `<img style="height: 390px; width: 100%; border-radius: 10px;" src="${introImages}" class="img-fluid shadow" alt="" />`
            }
        </div>
        <div class="twobutton" style="width: 100%; color: {introtext}; display: flex; gap: 0px 10px;">
            <a href="#">
                <button class="buttoncolor" style="display: ${introbtn1remove ? 'none' : ''}; font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">
                    ${inttrobtn1}
                </button>
            </a>
            <a href="#">
                <button class="buttoncolor" style="display: ${introbtn2remove ? 'none' : ''}; font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">
                    ${introbtn2}
                </button>
            </a>
            ${addintrobutton.map((button, index) => `
                <a href="#" key=${index} style="display: ${introbuttonapprovel == false ? 'none' : ''};">
                    <button class="buttoncolor" style="font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">
                        ${button}
                    </button>
                </a>
            `).join('')}
        </div>
    </div>
</div>



<script src="https:cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
<script>
    function scrollText(element, speed) {
        let scrollAmount = 0;
        const scrollDelay = speed;
        const textWidth = element.offsetWidth;
        const containerWidth = element.parentElement.offsetWidth;

        function scroll() {
            scrollAmount++;
           

            if (scrollAmount >= textWidth + containerWidth) {
                scrollAmount = 0;
            }
        }

        setInterval(scroll, scrollDelay);
    }

    const scrollingText = document.getElementById('scrollingText');
    scrollText(scrollingText, 0);  Adjust speed as needed
</script>


</body></html>
`;
        var HeroTemplate2 = `
     <!DOCTYPE html>
     <html lang="en">
     <head>
     <title>${title}</title>
         <meta charset="UTF-8">
         <meta http-equiv="X-UA-Compatible" content="IE=edge">
         <meta name="viewport" content="width=device-width, initial-scale=1.0">
         <title>Document</title>
         <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
         <style>
         ._f497df412 {
            display: flex;
            padding: 8px;
            background: rgb(255, 255, 255);
            box-shadow: 0px 19px 30px -10px rgba(21, 21, 25, 0.21);
            box-sizing: border-box;
            border-color: rgb(255, 255, 255);
            border-style: solid;
            border-width: 0px;
            border-radius: 10px;
        }
        .buttoncolor:hover {
            background-color: rgb(219, 214, 214);
          }
          .buttoncolor {
            margin: 5px;
            color: rgb(0, 0, 0);
            border: none;
            cursor: pointer;
            display: inline-flex;
            outline: none;
            /* padding: 0.7em 0.8em; */
            padding: 4px;
            position: relative;
            /* font-size: 13px; */
            background: rgb(236, 235, 235);
            box-shadow: 0px 2px 5px 0px rgba(221, 219, 219, 0.959);
            box-sizing: border-box;
            transition: all 0.2s ease-in-out;
            align-items: center;
            border-color: rgb(220, 213, 213);
            border-style: solid;
            border-width: 1px;
            border-radius: 8px;
            justify-content: center;
            text-decoration: none;
            width: 90px;
            height: 35px;
            overflow: hidden;
          }
          .container {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          
            padding: 10px;
          }
          ._e51b713c {
            width: 100%;
            height: 100%;
            object-fit: cover;
            box-sizing: border-box;
          }
          
         </style>
     </head>
      <body>
     <div id="app">
     <div class="container" style=' backgroundColor: ${introcolor};min-width: 100%;padding: 50px;padding-top:0px; '>
     <div class="row" style="margin-top: ${intromargintop}px; margin-bottom: ${intromarginbottom}px;">
         <div class="col-md-6 d-flex align-items-center flex-column justify-content-center">
             <div style="width: 100%;">
                 ${selectedOption1 == 'A' ?
                `<button class="mt-5 btn menu" style="font-size: 12px; color: ${intromenutextcolor}; background-color: ${intromenubgcolor}; border: 2px solid ${conatcmenuborder};">${menu}--<span style="color: ${contactheadingcolor};">${menu1}</span></button>`
                :
                `<div>
                         <button class="mt-5 btn menu" style="font-size: 12px; border-style: none; color: ${intromenutextcolor}; background-color: ${intromenubgcolor};">${menu}</button>
                         <button class="mt-5 btn menu" style="font-size: 12px; border-style: none;">${menu1}</button>
                     </div>`
            }
             </div>
             <p style="color: ${captioncolor ? captioncolor : '#9c36b5'}; position: relative;">${introcaption}</p>
             <h1 style="font-size: 48px; font-weight: 780; color: ${contenttextheadingcolor}; font-family: ${introtextfont};">${title}</h1>
             <p class="fs-0" style="color: ${contenttextinfocolor}; font-family: ${introinfotextfont};">${intro}</p>
             <div class="twobutton" style="width: 100%; color: ${introtext}; display: flex; gap: 0px 10px;">
                 <a href="#"><button class="buttoncolor" style="display: ${introbtn1remove ? 'none' : ''}; font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">${inttrobtn1}</button></a>
                 <a href="#"><button class="buttoncolor" style="display: ${introbtn2remove ? 'none' : ''}; font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">${introbtn2}</button></a>
                 ${addintrobutton.map((button, index) => `
                     <a href="#" key=${index} style="display: ${introbuttonapprovel == false ? 'none' : ''};">
                         <button class="buttoncolor" style="font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">${button}</button>
                     </a>
                 `).join('')}
             </div>
         </div>
         <div class="col-md-6 p-4">
             <div class="_f497df412">
                  ${newIntroImage.length > 0 ? `
              <div>
                  ${newIntroImage.map((image, index) => `
                      <img  src="${image}" alt="Image ${index}" class="_e51b713c">
                  `).join('')}
              </div>
          ` : `
              <img style="height: 500px;" src="${introImages}" class="img-fluid" alt="">
          `}
             </div>
         </div>
     </div>
 </div>
 
 
 <script>
     document.addEventListener("DOMContentLoaded", function() {
         // Add event listeners and functionality here
         const menuBtn = document.getElementById("menuBtn");
         const menu1Btn = document.getElementById("menu1Btn");
         const introCaption = document.getElementById("introCaption");
         const title = document.getElementById("title");
         const introText = document.getElementById("introText");
 
         menuBtn.addEventListener("click", function() {
             introCaption.textContent = "Updated Intro Caption";
             title.textContent = "Updated Title";
             introText.textContent = "Updated Intro Text";
         });
 
         menu1Btn.addEventListener("click", function() {
             introCaption.textContent = "Another Updated Caption";
             title.textContent = "Another Updated Title";
             introText.textContent = "Another Updated Intro Text";
         });
 
         const btn1 = document.getElementById("btn1");
         const btn2 = document.getElementById("btn2");
 
         btn1.addEventListener("click", function() {
             alert("Button 1 clicked");
         });
 
         btn2.addEventListener("click", function() {
             alert("Button 2 clicked");
         });
     });
 </script>
 <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
 </body>
     </html>
     `
        var HeroTemplate3 = `
     <!DOCTYPE html>
     <html lang="en">
     <head>
     
         <meta charset="UTF-8">
         <meta http-equiv="X-UA-Compatible" content="IE=edge">
         <meta name="viewport" content="width=device-width, initial-scale=1.0">
         <title>Document</title>
         <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
          <style>
          .media_image__kN9DM {
            background: #e6e6e6;
            border-radius: 3px;
            box-sizing: border-box;
            height: 28px;
            max-height: 28px;
            max-width: 38px;
            object-fit: contain;
            padding: 0px 3px;
          }
          .buttoncolor:hover {
            background-color: rgb(219, 214, 214);
          }
          .buttoncolor {
            margin: 5px;
            color: rgb(0, 0, 0);
            border: none;
            cursor: pointer;
            display: inline-flex;
            outline: none;
            /* padding: 0.7em 0.8em; */
            padding: 4px;
            position: relative;
            /* font-size: 13px; */
            background: rgb(236, 235, 235);
            box-shadow: 0px 2px 5px 0px rgba(221, 219, 219, 0.959);
            box-sizing: border-box;
            transition: all 0.2s ease-in-out;
            align-items: center;
            border-color: rgb(220, 213, 213);
            border-style: solid;
            border-width: 1px;
            border-radius: 8px;
            justify-content: center;
            text-decoration: none;
            width: 90px;
            height: 35px;
            overflow: hidden;
          }
          .container {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          
            padding: 10px;
          }
          </style>
     </head>
     <body>
     <div class="container" style='background-color:${introcolor};min-width: 100%; padding: 50px;padding-top:0px'>
     <div class="row" style="margin-top: 10px; margin-bottom: 20px;">
       <div class="col-md-12 d-flex align-items-center flex-column justify-content-center">
         <div class="text-center" style="width: 100%;">
           ${selectedOption1 === 'A' ? `
             <button class="mt-5 btn menu" style="font-size: 12px; color: ${intromenutextcolor}; background-color: ${intromenubgcolor}; border: 2px solid ${conatcmenuborder};">
               ${menu}--<span style="color: ${contactheadingcolor};">${menu1}</span>
             </button>` : `
             <div>
               <button class="mt-5 btn menu" style="font-size: 12px; border-style: none; color: ${intromenutextcolor}; background-color: ${intromenubgcolor};">${menu}</button>
               <button class="mt-5 btn menu" style="font-size: 12px; border-style: none;">${menu1}</button>
             </div>
           `}
         </div>
         <p style="color: ${captioncolor ? captioncolor : '#9c36b5'}; position: relative;">${introcaption}</p>
         <h1 style="font-size: 48px; font-weight: 780; color: ${contenttextheadingcolor}; font-family: ${introtextfont};">${title}</h1>
         <p class="fs-0" style="color: ${contenttextinfocolor}; font-family: ${introinfotextfont};">${intro}</p>
         <div class="twobutton d-flex align-items-center justify-content-center" style="width: 100%; color: ${introtext}; display: flex; gap: 0px 10px;">
           <a href="#" style='link-style:none'>
             <button class="buttoncolor" style="display: ${introbtn1remove ? 'none' : 'block'}; font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">${inttrobtn1}</button>
           </a>
           <a href="#" style='link-style:none'>
             <button class="buttoncolor" style="display: ${introbtn2remove ? 'none' : 'block'}; font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">${introbtn2}</button>
           </a>
           ${introbuttonapprovel ? addintrobutton.map((button, index) => `
             <a href="#"  style="display: block;">
               <button class="buttoncolor" style="font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">${button}</button>
             </a>`).join('') : ''}
         </div>
       </div>
       <div class="col-md-12 p-4">
          ${newIntroImage.length > 0 ? `
              <div>
                  ${newIntroImage.map((image, index) => `
                      <img  src="${image}" alt="Image ${index}" style="height: 390px; width:100%;border-radius: 10px" class="_e51b713c">
                  `).join('')}
              </div>
          ` : `
              <img style="height: 390px; width:100%;border-radius: 10px" src="${introImages}" class="img-fluid" alt="">
          `}
       </div>
     </div>
   </div>
   <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
     </body>
     </html>
     `
        var HeroTemplate4 = `
     <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Styled Section</title>
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
     <style>
     .container {
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
      
        padding: 10px;
      }
      .buttoncolor {
        margin: 5px;
        color: rgb(0, 0, 0);
        border: none;
        cursor: pointer;
        display: inline-flex;
        outline: none;
        /* padding: 0.7em 0.8em; */
        padding: 4px;
        position: relative;
        /* font-size: 13px; */
        background: rgb(236, 235, 235);
        box-shadow: 0px 2px 5px 0px rgba(221, 219, 219, 0.959);
        box-sizing: border-box;
        transition: all 0.2s ease-in-out;
        align-items: center;
        border-color: rgb(220, 213, 213);
        border-style: solid;
        border-width: 1px;
        border-radius: 8px;
        justify-content: center;
        text-decoration: none;
        width: 90px;
        height: 35px;
        overflow: hidden;
      }
      .buttoncolor:hover {
        background-color: rgb(219, 214, 214);
      }
      ._f497df413 {
        max-width: 50vw;
        box-sizing: border-box;
        flex-shrink: 0;
        padding-right: 50px;
        padding-top: 50px;
      }
      ._e51b713c {
        width: 100%;
        height: 100%;
        object-fit: cover;
        box-sizing: border-box;
      }
     </style>
</head>
<body>
    <div class="container" style='background-color:${introcolor};min-width: 100%;padding: 50px;padding-top:0px'>
        <div class="row" style="margin-top: 10px; margin-bottom: 20px;">
            <div class="col-md-6 d-flex align-items-center flex-column justify-content-center">
                <div style="width: 100%;">
                    ${selectedOption1 === 'A' ? `
                        <button class="mt-5 btn menu" style="font-size: 12px; color: ${intromenutextcolor}; background-color: ${intromenubgcolor}; border: 2px solid ${conatcmenuborder};">
                            ${menu}--<span style="color: ${contactheadingcolor};">${menu1}</span>
                        </button>
                    ` : `
                        <div>
                            <button class="mt-5 btn menu" style="font-size: 12px; border-style: none; color: ${intromenutextcolor}; background-color: ${intromenubgcolor};">${menu}</button>
                            <button class="mt-5 btn menu" style="font-size: 12px; border-style: none;">${menu1}</button>
                        </div>
                    `}
                </div>
                <p style="color: ${captioncolor ? captioncolor : '#9c36b5'}; position: relative;">${introcaption}</p>
                <h1 style="font-size: 48px; font-weight: 780; color: ${contenttextheadingcolor}; font-family: ${introtextfont};">${title}</h1>
                <p class="fs-0" style="color: ${contenttextinfocolor}; font-family: ${introinfotextfont};">${intro}</p>
                <div class="twobutton" style="width: 100%; color: ${introtext}; display: flex; gap: 0px 10px;">
                    <a href="#">
                        <button class="buttoncolor" style="display: ${introbtn1remove ? 'none' : 'block'}; font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">
                            ${inttrobtn1}
                        </button>
                    </a>
                    <a href="#">
                        <button class="buttoncolor" style="display: ${introbtn2remove ? 'none' : 'block'}; font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">
                            ${introbtn2}
                        </button>
                    </a>
                    ${introbuttonapprovel ? addintrobutton.map((button, index) => `
                        <a href="#"  style="display: block;">
                            <button class="buttoncolor" style="font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">
                                ${button}
                            </button>
                        </a>
                    `).join('') : ''}
                </div>
            </div>
            <div class="col-md-6 p-4">
                <div class="_f497df413">
                    ${newIntroImage.length > 0 ? `
              <div>
                  ${newIntroImage.map((image, index) => `
                      <img  src="${image}" alt="Image ${index}" class="_e51b713c">
                  `).join('')}
              </div>
          ` : `
              <img style="height: 500px;" src="${introImages}" class="img-fluid" alt="">
          `}
                </div>
            </div>
        </div>
    </div>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
</body>
</html>
`
        var HeroTemplate5 = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Styled Section</title>
        <style>
            .twobutton {
                display: flex;
                gap: 0px 10px;
            }
            .container {
                width: 100%;
                margin: 0 auto;
                overflow: hidden;
              
                padding: 10px;
              }
              .buttoncolor {
                margin: 5px;
                color: rgb(0, 0, 0);
                border: none;
                cursor: pointer;
                display: inline-flex;
                outline: none;
                /* padding: 0.7em 0.8em; */
                padding: 4px;
                position: relative;
                /* font-size: 13px; */
                background: rgb(236, 235, 235);
                box-shadow: 0px 2px 5px 0px rgba(221, 219, 219, 0.959);
                box-sizing: border-box;
                transition: all 0.2s ease-in-out;
                align-items: center;
                border-color: rgb(220, 213, 213);
                border-style: solid;
                border-width: 1px;
                border-radius: 8px;
                justify-content: center;
                text-decoration: none;
                width: 90px;
                height: 35px;
                overflow: hidden;
              }
              .buttoncolor:hover {
                background-color: rgb(219, 214, 214);
              }
              ._f497df41 {
                right: 0;
                bottom: 0;
                position: relative;
                clip-path: polygon(10% 0, 0 100%, 100% 100%, 100% 0);
                object-fit: cover;
              }
              ._e51b713c {
                width: 100%;
                height: 100%;
                object-fit: cover;
                box-sizing: border-box;
              }
              
        </style>
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
    </head>
    <body>
        <div class="container py-0" style="background-color:${introcolor};min-width: 100%; padding: 50px;padding-top:0px;">
            <div class="row" style="margin-top: 20px; margin-bottom: 20px;">
                <div class="col-md-6 d-flex align-items-center flex-column justify-content-center">
                    <div style="width: 100%;">
                        ${selectedOption1 === 'A' ? `
                            <button class="mt-5 btn menu" style="font-size: 12px; color: ${intromenutextcolor}; background-color: ${intromenubgcolor}; border: 2px solid ${conatcmenuborder};">
                                ${menu}--<span style="color: ${contactheadingcolor};">${menu1}</span>
                            </button>
                        ` : `
                            <div>
                                <button class="mt-5 btn menu" style="font-size: 12px; border-style: none; color: ${intromenutextcolor}; background-color: ${intromenubgcolor};">${menu}</button>
                                <button class="mt-5 btn menu" style="font-size: 12px; border-style: none;">${menu1}</button>
                            </div>
                        `}
                    </div>
                    <p style="color: ${captioncolor ? captioncolor : '#9c36b5'}; position: relative;">${introcaption}</p>
                    <h1 style="font-size: 48px; font-weight: 780; color: ${contenttextheadingcolor}; font-family: ${introtextfont};">${title}</h1>
                    <p class="fs-0" style="color: ${contenttextinfocolor}; font-family: ${introinfotextfont};">${intro}</p>
                    <div class="twobutton" style="width: 100%; color: ${introtext};">
                        <a href="#">
                            <button class="buttoncolor" style="display: ${introbtn1remove ? 'none' : 'block'}; font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">
                                ${inttrobtn1}
                            </button>
                        </a>
                        <a href="#">
                            <button class="buttoncolor" style="display: ${introbtn2remove ? 'none' : 'block'}; font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">
                                ${introbtn2}
                            </button>
                        </a>
                        ${introbuttonapprovel ? addintrobutton.map((button, index) => `
                            <a href="#"  style="display: block;">
                                <button class="buttoncolor" style="font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">
                                    ${button}
                                </button>
                            </a>
                        `).join('') : ''}
                    </div>
                </div>
                <div class="col-md-6 p-4">
                    <div class="_f497df41">
                         ${newIntroImage.length > 0 ? `
              <div>
                  ${newIntroImage.map((image, index) => `
                      <img  src="${image}" alt="Image ${index}" class="_e51b713c">
                  `).join('')}
              </div>
          ` : `
              <img style="height: 500px;" src="${introImages}" class="img-fluid" alt="">
          `}
                    </div>
                </div>
            </div>
        </div>
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
    </body>
    </html>
    `

        var HeroTemplate6 = `
    <!DOCTYPE html>
    <html>
    <head>
     <style>
     .container {
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
      
        padding: 10px;
      }
      .buttoncolor {
        margin: 5px;
        color: rgb(0, 0, 0);
        border: none;
        cursor: pointer;
        display: inline-flex;
        outline: none;
        /* padding: 0.7em 0.8em; */
        padding: 4px;
        position: relative;
        /* font-size: 13px; */
        background: rgb(236, 235, 235);
        box-shadow: 0px 2px 5px 0px rgba(221, 219, 219, 0.959);
        box-sizing: border-box;
        transition: all 0.2s ease-in-out;
        align-items: center;
        border-color: rgb(220, 213, 213);
        border-style: solid;
        border-width: 1px;
        border-radius: 8px;
        justify-content: center;
        text-decoration: none;
        width: 90px;
        height: 35px;
        overflow: hidden;
      }
      .buttoncolor:hover {
        background-color: rgb(219, 214, 214);
      }
      ._e51b713c {
        width: 100%;
        height: 100%;
        object-fit: cover;
        box-sizing: border-box;
      }
      ._f497df414 {
        max-width: 50vw;
        box-sizing: border-box;
        flex-shrink: 0;
      }
     </style>
     <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
    </head>
    <body>
    <div class="container"  style='background-color:${introcolor};min-width: 100%;padding: 50px;padding-top:0px;'>
    <div class="row" style="margin-top: ${intromargintop}px; margin-bottom: ${intromarginbottom}px;">
        <div class="col-md-6 d-flex align-items-center flex-column justify-content-center">
            <div style="width: 100%;">
                <button class="mt-5 btn menu" style="font-size: 12px; color: ${intromenutextcolor}; background-color: ${intromenubgcolor}; border: 2px solid ${conatcmenuborder};">${menu}--<span style="color: ${contactheadingcolor};">${menu1}</span></button>
            </div>
            <p style="color: ${captioncolor ? captioncolor : '#9c36b5'}; position: relative;">${introcaption}</p>
            <h1 style="font-size: 48px; font-weight: 780; color: ${contenttextheadingcolor}; font-family: ${introtextfont};">${title}</h1>
            <p class="fs-0" style="color: ${contenttextinfocolor}; font-family: ${introinfotextfont};">${intro}</p>
            <div class="twobutton" style="width: 100%; color: ${introtext}; display: flex; gap: 0px 10px;">
                <a href="#"><button class="buttoncolor" style="font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">${inttrobtn1}</button></a>
                <a href="#"><button class="buttoncolor" style="font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">${introbtn2}</button></a>
                ${addintrobutton.map((button, index) => (
            `<a href="#" key=${index}><button class="buttoncolor" style="font-family: ${button1font}; background-color: ${buttoncolor}; border: ${buttonborder}px solid ${buttonbordercolor}; border-radius: ${buttonborderradius}px; box-shadow: 0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21); width: 100%; padding-top: ${buttonvarticalpadding}px; padding-bottom: ${buttonvarticalpadding}px; padding-left: ${buttonhorizantlepadding}px; padding-right: ${buttonhorizantlepadding}px;">${button}</button></a>`
        ))}
            </div>
        </div>
        <div class="col-md-6">
            <div class="_f497df414">
                 ${newIntroImage.length > 0 ? `
              <div>
                  ${newIntroImage.map((image, index) => `
                      <img  src="${image}" alt="Image ${index}" class="_e51b713c">
                  `).join('')}
              </div>
          ` : `
              <img style="height: 500px;" src="${introImages}" class="img-fluid" alt="">
          `}
            </div>
        </div>
    </div>
</div>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
    </body>
    </html>
    `

        if (selectedHero === null) {
            var HeroTemplate = HeroTemplate1
        }
        if (selectedHero === 0) {
            var HeroTemplate = HeroTemplate2
        }
        if (selectedHero === 1) {
            var HeroTemplate = HeroTemplate3
        }
        if (selectedHero === 2) {
            var HeroTemplate = HeroTemplate4
        }
        if (selectedHero === 3) {
            var HeroTemplate = HeroTemplate5
        };
        if (selectedHero === 4) {
            var HeroTemplate = HeroTemplate6
        }
        //  -----------------------------------------------------trusttedby---------------------------------------//
        const Trustlogo = trustLogo.map((logo) => {
            return logo
        });
        const Newtrustlogo = newtrustlogo.map((logo) => {
            return logo
        })
        const Trustedtemplate1 = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Document</title>
         <style>
         nav {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: transparent;
          position: relative;
          padding: 0 !important;
          width:100%
          }
          .logo{
          display: flex;
          align-items: center;
          justify-content: start;
          }
          .logo a img{
          height: 40px;
          width: 40px;
          }
          
          nav .nav-item li {
          display: inline-block;
          list-style: none;
          margin: 5px;
          }
          
          nav .nav-item li a {
          display: inline-block;
          color: #000;
          font-size: 20px;
          text-decoration: none;
          }
          .navbar-nav .nav-item a:hover{
          text-decoration: underline;
          }
          .buttoncolor{
          margin: 2px;
          color: rgb(0, 0, 0);
          border: none;
          cursor: pointer;
          display: inline-flex;
          outline: none;
          padding: 0.7em 0.8em;
          position: relative;
          font-size: 14px;
          background:#f0f0f0;
          box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0);
          box-sizing: border-box;
          transition: all 0.2s ease-in-out;
          align-items: center;
          border-color: rgb(227, 227, 227);
          border-style: solid;
          border-width: 0;
          border-radius: 8px;
          justify-content: center;
          text-decoration: none;
          }
          nav .nav-item .nav-link button:hover{
          background-color: lightslategray;
          }
          
          /* =====================Subheader============== */
          .Subheader{
          background-color: #fafafa;
          height: 7px;
          background: var(--bg-header);
          box-shadow: 0 1px #eaeaea;
          left: 0;
          position: -webkit-sticky;
          position: sticky;
          right: 0;
          top: 0;
          z-index: 100;
          }
          
          /* =========== Welcome to cofee hub=========== */
          /* .buttoncolor{
          background-color:#f8f9fa;
          } */
          .menu{
          border-radius: 20px;
          border: 1px solid #f1f3f5;
          }
          .heading{
          font-size: 56px;
          font-weight: 600;
          color: #212529;
          }
          .heading2{
          font-size: 29px;
          font-weight: 600;
          color: #212529;
          }
          .card{
          background-color: #f8f9fa;
          background-image: linear-gradient(45deg, rgb(255, 255, 255) 0%, rgb(241, 242, 243) 100%);;
          border: 1px solid rgb(235, 235, 235);
          border-radius: 10px;
          margin: 16px;
          padding: 16px;
          box-shadow: 0px 19px 30px -10px rgba(21, 21, 25, 0.21);;
          }
          
          .footer-links  p{
          margin: 0;
          padding: 0;
          }
          .footer-links  p a{
          text-decoration: transparent;
          color: #212529;
          }
          .footer-links  p a:hover{
          text-decoration:underline;
          }
          
          
          .scrolling-text {
          white-space: nowrap;
          
          animation: scroll-left 60s linear infinite;
          }
          
          @keyframes scroll-left {
          0% { transform: translateX(100%); }
          100% { transform: translateX(-100%); }
          }.container {
          width: 80%; 
          margin: 0 auto; 
          overflow: hidden; 
          
          padding: 10px; 
          }
          .container {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          
            padding: 10px;
          }
          </style>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
    </head>
    <body>
    <div class="container" style="background-color: ${trustcolor};min-width: 100%;padding: 50px,padding-top:0px;position: relative,">
    <div style="margin-top: ${trustmargintop}px; margin-bottom: ${trustmarginbottom}px;">
        <p class="text-center" style="color: #9c36b5;">${trustCaption}</p>
        <h2 class="text-center heading" style="color: ${contenttextheadingcolor}; font-family: ${trusttextfont};">${trust}</h2>
        <p class="text-center" style="color: ${contenttextinfocolor}; font-family: ${trusttitletextfont};">
            ${trsuttitle}
        </p>
        <div class="scrolling-text" id="scrollingText">
            ${trustLogo.map((Logos, index) => `
                <img src="${Logos}" alt="logos">
            `).join('')}
            ${newtrustlogo.map((Logos, index) => `
                <img src="${Logos}" alt="logos" style="width: 100px; height: 40px;">
            `).join('')}
        </div>
    </div>
</div>

 
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
    </body>
     </html>`

        const Trustedtemplate2 = `
       <!DOCTYPE html>
       <html>
       <head>
        <style>
        .container {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          
            padding: 10px;
          }
          .scrolling-text {
            white-space: nowrap;
            animation: scroll-left 20s linear infinite;
          }
          const scrollingText = document.getElementById('scrollingText');
          scrollText(scrollingText, 0); 
          
          </style>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
       </head>
       <body>
       <div class="container" style="background-color: ${trustcolor};min-width: 100%; padding: 50px,padding-top:0px">
    <div style="margin-top: ${trustmargintop}px; margin-bottom: ${trustmarginbottom}px;">
        <p class="text-center" style="color: #9c36b5;">${trustCaption}</p>
        <h2 class="text-center heading" style="color: ${contenttextheadingcolor}; font-family: ${trusttextfont};">${trust}</h2>
        <p class="text-center" style="color: ${contenttextinfocolor}; font-family: ${trusttitletextfont};">
            ${trsuttitle}
        </p>
        <div class="scrolling-text" id="scrollingText">
            ${trustLogo.map((Logos, index) => `
                <img src="${Logos}" style="height: 24px; width: 150px;" alt="logos">
            `).join('')}
            ${newtrustlogo.map((Logos, index) => `
                <img src="${Logos}" alt="logos" style="height: 24px; width: 150px;">
            `).join('')}
        </div>
    </div>
</div>

   
       </body>
       <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
       </html>`

        const Trustedtemplate3 = `
        <!DOCTYPE html>
        <html>
        <head>
         <style>
         .container {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          
            padding: 10px;
          }
         </style>
         <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
        </head>
        <body>
        <div class="container" style="background-color: ${trustcolor}; min-width: 100%; padding: 50px;padding-top:0px;">
        <div style="margin-top: ${trustmargintop}px; margin-bottom: ${trustmarginbottom}px;">
            <p class="text-center" style="color: #9c36b5;">${trustCaption}</p>
            <h2 class="text-center heading" style="color: ${contenttextheadingcolor}; font-family: ${trusttextfont};">${trust}</h2>
            <p class="text-center" style="color: ${contenttextinfocolor}; font-family: ${trusttitletextfont};">
                ${trsuttitle}
            </p>
            <div class="text-center" id="scrollingText">
                ${trustLogo.map((Logos, index) => `
                    <img src="${Logos}" style="height: 24px; width: 150px;" alt="logos">
                `).join('')}
                ${newtrustlogo.map((Logos, index) => `
                    <img src="${Logos}" alt="logos" style="height: 24px; width: 150px;">
                `).join('')}
            </div>
        </div>
    </div>
    
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
        </body>
        </html>`

        const Trustedtemplate4 = `
        <!DOCTYPE htm>
        <html>
        <head>
          <style>
          .container {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          
            padding: 10px;
          }
          .scrolling-text {
            white-space: nowrap;
            animation: scroll-left 20s linear infinite;
          }
          @keyframes scroll-left {
            0% {
              transform: translateX(100%);
            }
          
            100% {
              transform: translateX(-100%);
            }
          }
          </style>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
        </head>
        <body>
        <div class="container" style="background-color: ${trustcolor}; min-width: 100%;padding: 50px;padding-top:0px;">
    <div style="margin-top: ${trustmargintop}px; margin-bottom: ${trustmarginbottom}px;">
        <p class="text-center" style="color: #9c36b5;">${trustCaption}</p>
        <h2 class="text-center heading" style="color: ${contenttextheadingcolor}; font-family: ${trusttextfont};">${trust}</h2>
        <p class="text-center" style="color: ${contenttextinfocolor}; font-family: ${trusttitletextfont};">
            ${trsuttitle}
        </p>
        <div class="scrolling-text" id="scrollingText">
            ${trustLogo.map((Logos, index) => `
                <img src="${Logos}" style="height: 24px; width: 150px;" alt="logos">
            `).join('')}
            ${newtrustlogo.map((Logos, index) => `
                <img src="${Logos}" alt="logos" style="height: 24px; width: 150px;">
            `).join('')}
        </div>
    </div>
</div>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
        </body>
        </html>`

        if (selectedLogo === null) {
            var TrustTemplate = Trustedtemplate1
        };
        if (selectedLogo === 0) {
            var TrustTemplate = Trustedtemplate2
        };

        if (selectedLogo === 1) {
            var TrustTemplate = Trustedtemplate3
        };

        if (selectedLogo === 2) {
            var TrustTemplate = Trustedtemplate4
        }

        // -------------------------------service----------------------------------------------//

        const ServiceTemplate1 = `
    <!DOCTYPE html>
    <html>
    <head>
    <style>
    nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: transparent;
      position: relative;
      padding: 0 !important;
      width:100%
      }
      .logo{
      display: flex;
      align-items: center;
      justify-content: start;
      }
      .logo a img{
      height: 40px;
      width: 40px;
      }
      
      nav .nav-item li {
      display: inline-block;
      list-style: none;
      margin: 5px;
      }
      
      nav .nav-item li a {
      display: inline-block;
      color: #000;
      font-size: 20px;
      text-decoration: none;
      }
      .navbar-nav .nav-item a:hover{
      text-decoration: underline;
      }
      .buttoncolor{
      margin: 2px;
      color: rgb(0, 0, 0);
      border: none;
      cursor: pointer;
      display: inline-flex;
      outline: none;
      padding: 0.7em 0.8em;
      position: relative;
      font-size: 14px;
      background:#f0f0f0;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0);
      box-sizing: border-box;
      transition: all 0.2s ease-in-out;
      align-items: center;
      border-color: rgb(227, 227, 227);
      border-style: solid;
      border-width: 0;
      border-radius: 8px;
      justify-content: center;
      text-decoration: none;
      }
      nav .nav-item .nav-link button:hover{
      background-color: lightslategray;
      }
      
      /* =====================Subheader============== */
      .Subheader{
      background-color: #fafafa;
      height: 7px;
      background: var(--bg-header);
      box-shadow: 0 1px #eaeaea;
      left: 0;
      position: -webkit-sticky;
      position: sticky;
      right: 0;
      top: 0;
      z-index: 100;
      }
      
      /* =========== Welcome to cofee hub=========== */
      /* .buttoncolor{
      background-color:#f8f9fa;
      } */
      .menu{
      border-radius: 20px;
      border: 1px solid #f1f3f5;
      }
      .heading{
      font-size: 56px;
      font-weight: 600;
      color: #212529;
      }
      .heading2{
      font-size: 29px;
      font-weight: 600;
      color: #212529;
      }
      .card{
      background-color: #f8f9fa;
      background-image: linear-gradient(45deg, rgb(255, 255, 255) 0%, rgb(241, 242, 243) 100%);;
      border: 1px solid rgb(235, 235, 235);
      border-radius: 10px;
      margin: 16px;
      padding: 16px;
      box-shadow: 0px 19px 30px -10px rgba(21, 21, 25, 0.21);;
      }
      
      .footer-links  p{
      margin: 0;
      padding: 0;
      }
      .footer-links  p a{
      text-decoration: transparent;
      color: #212529;
      }
      .footer-links  p a:hover{
      text-decoration:underline;
      }
      
      
      .scrolling-text {
      white-space: nowrap;
      
      animation: scroll-left 60s linear infinite;
      }
      
      @keyframes scroll-left {
      0% { transform: translateX(100%); }
      100% { transform: translateX(-100%); }
      }
      .container {
      width: 80%; 
      margin: 0 auto; 
      overflow: hidden; 
      
      padding: 10px; 
      }
      </style>
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
    </head>
    <body>
     <div class="container shadow" style='background-color:${featurecolor};min-width: 100%;position:relative;margin:-2px;padding: 50px;padding-top:0px;'>
    <div class="row" style="margin-bottom: ${serviceMarginBottom}px; margin-top: ${serviceMarginTop}px;">
    <div class="col-md-6 d-flex flex-column justify-content-start">
        <p class='mt-5' style="color: ${captioncolor};">${servicecaption}</p>
        <h1 class="heading" style="font-size: 33px; color: ${contenttextheadingcolor}; font-family: ${servicetitletextfont};">${serviceheader}</h1>
        <p class="fs-6 lh-sm" style="color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${servicesIntro1}</p>

        <div class="d-flex">
            <div style="display: ${serviceremove1 ? 'none' : 'flex'};">
                <div class="flex-shrink-0">
                    
                   <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.86 121.64"  width="15px"><title>direction-right</title><path d="M121.62,59,2.78.2A1.92,1.92,0,0,0,.2,1.08a1.89,1.89,0,0,0,0,1.76h0l30.87,58L.23,118.8h0a1.89,1.89,0,0,0,0,1.76,1.92,1.92,0,0,0,2.58.88l118.84-58.8a2,2,0,0,0,0-3.64Z"/></svg>
                </div>
                <div class="flex-grow-1 ms-3">
                    <h6 class="m-0 p-0 fs-6" style="color: ${contenttextheadingcolor}; font-family: ${serviceBodytextfont};">${serviceHeading1}</h6>
                    <span style="margin-top: 2px; font-size: 13px; color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${serviceinfo1}</span>
                </div>
            </div>
        </div>
        <div class="d-flex">
            <div style="display: ${serviceremove2 ? 'none' : 'flex'};">
                <div class="flex-shrink-0">
                    
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.86 121.64"  width="15px"><title>direction-right</title><path d="M121.62,59,2.78.2A1.92,1.92,0,0,0,.2,1.08a1.89,1.89,0,0,0,0,1.76h0l30.87,58L.23,118.8h0a1.89,1.89,0,0,0,0,1.76,1.92,1.92,0,0,0,2.58.88l118.84-58.8a2,2,0,0,0,0-3.64Z"/></svg>
                </div>
                <div class="flex-grow-1 ms-3">
                    <h6 class="m-0 p-0 fs-6" style="color: ${contenttextheadingcolor}; font-family: ${serviceBodytextfont};">${serviceHeading2}</h6>
                    <span style="margin-top: 2px; font-size: 13px; color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${serviceinfo2}</span>
                </div>
            </div>
        </div>
        <div class="d-flex">

            <div style="display: ${serviceremove3 ? 'none' : 'flex'};">
                <div class="flex-shrink-0">
                    
                    <IconPickerItem value=${featureIcon1}></IconPickerItem>
                    
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.86 121.64"  width="15px"><title>direction-right</title><path d="M121.62,59,2.78.2A1.92,1.92,0,0,0,.2,1.08a1.89,1.89,0,0,0,0,1.76h0l30.87,58L.23,118.8h0a1.89,1.89,0,0,0,0,1.76,1.92,1.92,0,0,0,2.58.88l118.84-58.8a2,2,0,0,0,0-3.64Z"/></svg>
                </div>
                <div class="flex-grow-1 ms-3">
                    <h6 class="m-0 p-0 fs-6" style="color: ${contenttextheadingcolor}; font-family: ${serviceBodytextfont};">${serviceHeading3}</h6>
                    <span style="margin-top: 2px; font-size: 13px; color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${serviceinfo3}</span>
                </div>
            </div>
        </div>
        <div class="d-flex">
            <div style="display: ${serviceremove4 ? 'none' : 'flex'};">
                <div class="flex-shrink-0">
                    
                    <IconPickerItem value=${featureIcon1}></IconPickerItem>
                    
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.86 121.64" width="15px"><title>direction-right</title><path d="M121.62,59,2.78.2A1.92,1.92,0,0,0,.2,1.08a1.89,1.89,0,0,0,0,1.76h0l30.87,58L.23,118.8h0a1.89,1.89,0,0,0,0,1.76,1.92,1.92,0,0,0,2.58.88l118.84-58.8a2,2,0,0,0,0-3.64Z"/></svg>
                </div>
                <div class="flex-grow-1 ms-3">
                    <h6 class="m-0 p-0 fs-6" style="color: ${contenttextheadingcolor}; font-family: ${serviceBodytextfont};">${serviceHeading4}</h6>
                    <span style="margin-top: 2px; font-size: 13px; color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${serviceinfo4}</span>
                </div>
            </div>
        </div>
        
        ${newservicesheading.map((heading, index) => `
            <div class="d-flex" key="${index}">
                <div>
                    <div class="flex-shrink-0">
                        <img src="pointer" alt="...">
                         <img src=${ServiceIcon} alt='Icon' height:'20px'; width:'20px'></img>
                    </div>
                    <div class="flex-grow-1 ms-3">
                        <h6 class="m-0 p-0 fs-6" style="color: ${contenttextheadingcolor}; font-family: ${serviceBodytextfont};">${heading.heading}</h6>
                        <span style="margin-top: 2px; font-size: 13px; color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${heading.info}</span>
                    </div>
                </div>
            </div>
        `).join('')}
        
        <div style="width: 100%;">
            <a href="#"><button class="mt-5 buttoncolor">More info</button></a>
        </div>
    </div>
    <div class="col-md-6 p-6">
        <!-- Conditional rendering for the image -->
        ${newservicesImage.length > 0 ?
                `<img src="${newservicesImage}" class="img-fluid shadow rounded p-6" alt="" style="margin-top: 100px; height: 350px;" />` :
                `<img src="${featureimages}" class="img-fluid shadow rounded p-6" alt="" style="margin-top: 100px; height: 350px;" />`}
    </div>
</div>
</div>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
    </body>
    </html>`

        const ServiceTemplate2 = `
      <!DOCTYPE html>
      <html>
      <head>
       <style>
       .container {
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
      
        padding: 10px;
      }
      ._c33e3ab2 {
        display: flex;
        flex-wrap: wrap;
      }
      ._5a4edc19 {
        display: flex;
        flex-grow: 1;
        flex-basis: 0;
        align-items: flex-start;
        flex-direction: column;
       
      }
      ._e519f50a {
        font-size: 0.9em;
      }
      .heading {
        font-size: 56px;
        font-weight: 600;
        color: #212529;
      }
      ._c8e71cbb {
        width: calc(50% - 4em);
        margin-left: 3em;
      }
      </style>
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
      </head>
      <body>
      <div class="container" style="background-color: ${featurecolor}; min-width: 100%; padding: 50px;position:relative;margin:-2px;padding-top:0px;">
      <div class="row" style="margin-bottom: ${serviceMarginBottom}px; margin-top: ${serviceMarginTop}px;">
          <div class="_c33e3ab2" style="flex-direction: ${imagePosition ? 'row-reverse' : 'row'};">
              <div class="_5a4edc19" style="justify-content: ${alignMedia === 'start' ? 'flex-start' : alignMedia === 'end' ? 'flex-end' : alignMedia === 'center' ? 'center' : 'flex-start'};">
                  <div class="_c6ab3272">
                      <header class="_e519f50a">
                          <p class="" style="color: ${captioncolor};">${servicecaption}</p>
                          <h1 class="heading" style="font-size: 33px; color: ${contenttextheadingcolor}; font-family: ${servicetitletextfont};">${serviceheader}</h1>
                          <p class="fs-6 lh-sm" style="color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${servicesIntro1}</p>
                      </header>
                      <button id="btn_gzt74ocmkq" class="_974be402 btn">
                          <span>Read More</span>
                      </button>
                  </div>
              </div>
              <div class="_c8e71cbb">
                  <!-- Conditional rendering for the image -->
                  ${newservicesImage.length > 0 ?
                `<img src="${newservicesImage}" class="img-fluid shadow rounded p-6" alt="" style="margin-top: 100px; height: 350px;" />` :
                `<img src="${featureimages}" class="img-fluid shadow rounded p-6" alt="" style="margin-top: 100px; height: 350px;" />`}
              </div>
          </div>
      </div>
  </div>
  <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
      </body>
      </html>`

        const ServiceTemplate3 = `
       <!DOCTYPE html>
       <html>
       <head>
         <style>
         .container {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          
            padding: 10px;
          }
          ._c33e3ab2 {
            display: flex;
            flex-wrap: wrap;
          }
          ._5a4edc19 {
            display: flex;
            flex-grow: 1;
            flex-basis: 0;
            align-items: flex-start;
            flex-direction: column;
           
          }
          ._e519f50a {
            font-size: 0.9em;
          }
          .heading {
            font-size: 56px;
            font-weight: 600;
            color: #212529;
          }
          ._c8e71cbb {
            width: calc(50% - 4em);
            margin-left: 3em;
          }
          </style>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
       </head>
       <body>
       <div class="container" style="background-color: ${featurecolor};min-width: 100%; padding: 50px;position:relative;margin:-2px;padding-top:0px;">
    <div class="row" style="margin-bottom: ${serviceMarginBottom}px; margin-top: ${serviceMarginTop}px; margin: 20px;">
        <div class="_c33e3ab2 " style="flex-direction: ${imagePosition ? 'row-reverse' : 'row'};">
            <div class="_5a4edc19" style="justify-content: ${alignMedia === 'start' ? 'flex-start' : alignMedia === 'end' ? 'flex-end' : alignMedia === 'center' ? 'center' : 'flex-start'};">
                <div class="_c6ab3272">
                    <header class="_e519f50a">
                        <p class="" style="color: ${captioncolor};">${servicecaption}</p>
                        <h1 class="heading" style="font-size: 33px; color: ${contenttextheadingcolor}; font-family: ${servicetitletextfont};">${serviceheader}</h1>
                        <p class="fs-6 lh-sm" style="color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${servicesIntro1}</p>
                    </header>
                    <button id="btn_gzt74ocmkq" class="_974be402 btn">
                        <span>Read More</span>
                    </button>
                </div>
            </div>
            <div class="_c8e71cbb">
                <!-- Conditional rendering for the image -->
                ${newservicesImage.length > 0 ?
                `<img src="${newservicesImage}" class="img-fluid shadow rounded p-6" alt="" style="margin-top: 100px; height: 350px;" />` :
                `<img src="${featureimages}" class="img-fluid shadow rounded p-6" alt="" style="margin-top: 100px; height: 350px;" />`}
            </div>
        </div>
    </div>
</div>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
       </body>
       </html>`

        const ServiceTemplate4 = `
       <!DOCTYPE html>
       <html>
       <head>
        <style>
        .container {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          
            padding: 10px;
          }
          ._c33e3ab2 {
            display: flex;
            flex-wrap: wrap;
          }
          ._5a4edc19 {
            display: flex;
            flex-grow: 1;
            flex-basis: 0;
            align-items: flex-start;
            flex-direction: column;
           
          }
          ._e519f50a {
            font-size: 0.9em;
          }
          ._c8e71cbb {
            width: calc(50% - 4em);
            margin-left: 3em;
          }
        </style>
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
       </head>
       <body>
       <div class="container" style="background-color: ${featurecolor};min-width: 100%; padding: 50px;position:relative;margin:-2px;padding-top:0px;">
    <div class="row" style="margin-bottom: ${serviceMarginBottom}px; margin-top: ${serviceMarginTop}px;">
        <div class="_c33e3ab2" style="flex-direction: ${imagePosition ? 'row-reverse' : 'row'};">
            <div class="_5a4edc19" style="justify-content: ${alignMedia === 'start' ? 'flex-start' : alignMedia === 'end' ? 'flex-end' : alignMedia === 'center' ? 'center' : 'flex-start'};">
                <div class="_c6ab3272">
                    <header class="_e519f50a">
                        <p class="" style="color: ${captioncolor};">${servicecaption}</p>
                        <h1 class="heading" style="font-size: 33px; color: ${contenttextheadingcolor}; font-family: ${servicetitletextfont};">${serviceheader}</h1>
                        <p class="fs-6 lh-sm" style="color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${servicesIntro1}</p>
                    </header>
                    <button id="btn_gzt74ocmkq" class="_974be402 btn">
                        <span>Read More</span>
                    </button>
                   
                    <div class="flex-grow-1 ms-3">
                        <h6 class="m-0 p-0 fs-6" style="color: ${contenttextheadingcolor}; font-family: ${serviceBodytextfont};">${serviceHeading1}</h6>
                        <span style="margin-top: 2px; font-size: 13px; color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${serviceinfo1}</span>
                    </div>
                    <div class="flex-grow-1 ms-3">
                        <h6 class="m-0 p-0 fs-6" style="color: ${contenttextheadingcolor}; font-family: ${serviceBodytextfont};">${serviceHeading2}</h6>
                        <span style="margin-top: 2px; font-size: 13px; color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${serviceinfo2}</span>
                    </div>
                    <div class="flex-grow-1 ms-3">
                        <h6 class="m-0 p-0 fs-6" style="color: ${contenttextheadingcolor}; font-family: ${serviceBodytextfont};">${serviceHeading3}</h6>
                        <span style="margin-top: 2px; font-size: 13px; color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${serviceinfo3}</span>
                    </div>
                    <div class="flex-grow-1 ms-3">
                        <h6 class="m-0 p-0 fs-6" style="color: ${contenttextheadingcolor}; font-family: ${serviceBodytextfont};">${serviceHeading4}</h6>
                        <span style="margin-top: 2px; font-size: 13px; color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${serviceinfo4}</span>
                    </div>
                </div>
            </div>
            <div class="_c8e71cbb">
               
                ${newservicesImage.length > 0 ?
                `<img src="${newservicesImage}" class="img-fluid shadow rounded p-6" alt="" style="margin-top: 100px; height: 350px;" />` :
                `<img src="${featureimages}" class="img-fluid shadow rounded p-6" alt="" style="margin-top: 100px; height: 350px;" />`}
            </div>
        </div>
    </div>
</div>

<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>  
       </body>
       </html>`
        const ServiceTemplate5 = `
       <!DOCTYPE html>
       <html>
       <head>
        <style>
        .container {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          
            padding: 10px;
          }
          ._c33e3ab2 {
            display: flex;
            flex-wrap: wrap;
          }
          ._5a4edc19 {
            display: flex;
            flex-grow: 1;
            flex-basis: 0;
            align-items: flex-start;
            flex-direction: column;
           
          }
          ._e519f50a {
            font-size: 0.9em;
          }
          ._c8e71cbb {
            width: calc(50% - 4em);
            margin-left: 3em;
          }
          </style>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
       </head>
       <body>
       <div class="container" style="background-color: ${featurecolor};min-width: 100%; padding: 50px;position:relative;margin:-2px;padding-top:0px;">
    <div class="row" style="margin-bottom: ${serviceMarginBottom}px; margin-top: ${serviceMarginTop}px;">
        <div class="_c33e3ab2" style="flex-direction: ${imagePosition ? 'row-reverse' : 'row'};">
            <div class="_5a4edc19" style="justify-content: ${alignMedia === 'start' ? 'flex-start' : alignMedia === 'end' ? 'flex-end' : alignMedia === 'center' ? 'center' : 'flex-start'};">
                <div class="_c6ab3272">
                    <header class="_e519f50a">
                        <p class="" style="color: ${captioncolor};">${servicecaption}</p>
                        <h1 class="heading" style="font-size: 33px; color: ${contenttextheadingcolor}; font-family: ${servicetitletextfont};">${serviceheader}</h1>
                        <p class="fs-6 lh-sm" style="color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${servicesIntro1}</p>
                    </header>
                    <button id="btn_gzt74ocmkq" class="_974be402 btn">
                        <span>Read More</span>
                    </button>
                   
                    <div class="flex-grow-1 ms-3">
                        <h6 class="m-0 p-0 fs-6" style="color: ${contenttextheadingcolor}; font-family: ${serviceBodytextfont};">${serviceHeading1}</h6>
                        <span style="margin-top: 2px; font-size: 13px; color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${serviceinfo1}</span>
                    </div>
                    <div class="flex-grow-1 ms-3">
                        <h6 class="m-0 p-0 fs-6" style="color: ${contenttextheadingcolor}; font-family: ${serviceBodytextfont};">${serviceHeading2}</h6>
                        <span style="margin-top: 2px; font-size: 13px; color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${serviceinfo2}</span>
                    </div>
                    <div class="flex-grow-1 ms-3">
                        <h6 class="m-0 p-0 fs-6" style="color: ${contenttextheadingcolor}; font-family: ${serviceBodytextfont};">${serviceHeading3}</h6>
                        <span style="margin-top: 2px; font-size: 13px; color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${serviceinfo3}</span>
                    </div>
                    <div class="flex-grow-1 ms-3">
                        <h6 class="m-0 p-0 fs-6" style="color: ${contenttextheadingcolor}; font-family: ${serviceBodytextfont};">${serviceHeading4}</h6>
                        <span style="margin-top: 2px; font-size: 13px; color: ${contenttextinfocolor}; font-family: ${serviceBodytextfont};">${serviceinfo4}</span>
                    </div>
                </div>
            </div>
            <div class="_c8e71cbb">
                ${newservicesImage.length > 0 ?
                `<img src="${newservicesImage}" class="img-fluid shadow rounded p-6" alt="" style="margin-top: 100px; height: 350px;" />` :
                `<img src="${featureimages}" class="img-fluid shadow rounded p-6" alt="" style="margin-top: 100px; height: 350px;" />`}
            </div>
        </div>
    </div>
</div>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
   
       </body>
       </html>`

        if (selectedFeature === null) {
            var ServiceTemplate = ServiceTemplate1
        };
        if (selectedFeature === 0) {
            var ServiceTemplate = ServiceTemplate2
        };
        if (selectedFeature === 1) {
            var ServiceTemplate = ServiceTemplate3
        };
        if (selectedFeature === 2) {
            var ServiceTemplate = ServiceTemplate4
        };
        if (selectedFeature === 3) {
            var ServiceTemplate = ServiceTemplate5
        };

        //  ---------------------------------------Stats-------------------------------//
        var StatsTemplate1 = `
        <!DOCTYPE html>
        <html>
        <head>
        <style>
        nav {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: transparent;
          position: relative;
          padding: 0 !important;
          width:100%
          }
          .logo{
          display: flex;
          align-items: center;
          justify-content: start;
          }
          .logo a img{
          height: 40px;
          width: 40px;
          }
          
          nav .nav-item li {
          display: inline-block;
          list-style: none;
          margin: 5px;
          }
          
          nav .nav-item li a {
          display: inline-block;
          color: #000;
          font-size: 20px;
          text-decoration: none;
          }
          .navbar-nav .nav-item a:hover{
          text-decoration: underline;
          }
          .buttoncolor{
          margin: 2px;
          color: rgb(0, 0, 0);
          border: none;
          cursor: pointer;
          display: inline-flex;
          outline: none;
          padding: 0.7em 0.8em;
          position: relative;
          font-size: 14px;
          background:#f0f0f0;
          box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0);
          box-sizing: border-box;
          transition: all 0.2s ease-in-out;
          align-items: center;
          border-color: rgb(227, 227, 227);
          border-style: solid;
          border-width: 0;
          border-radius: 8px;
          justify-content: center;
          text-decoration: none;
          }
          nav .nav-item .nav-link button:hover{
          background-color: lightslategray;
          }
          
          /* =====================Subheader============== */
          .Subheader{
          background-color: #fafafa;
          height: 7px;
          background: var(--bg-header);
          box-shadow: 0 1px #eaeaea;
          left: 0;
          position: -webkit-sticky;
          position: sticky;
          right: 0;
          top: 0;
          z-index: 100;
          }
          
          /* =========== Welcome to cofee hub=========== */
          /* .buttoncolor{
          background-color:#f8f9fa;
          } */
          .menu{
          border-radius: 20px;
          border: 1px solid #f1f3f5;
          }
          .heading{
          font-size: 56px;
          font-weight: 600;
          color: #212529;
          }
          .heading2{
          font-size: 29px;
          font-weight: 600;
          color: #212529;
          }
          .card{
          
          border-radius: 10px;
          margin: 16px;
          padding: 16px;
          box-shadow: 0px 19px 30px -10px rgba(21, 21, 25, 0.21);;
          }
          
          .footer-links  p{
          margin: 0;
          padding: 0;
          }
          .footer-links  p a{
          text-decoration: transparent;
          color: #212529;
          }
          .footer-links  p a:hover{
          text-decoration:underline;
          }
          
          
          .scrolling-text {
          white-space: nowrap;
          
          animation: scroll-left 60s linear infinite;
          }
          
          @keyframes scroll-left {
          0% { transform: translateX(100%); }
          100% { transform: translateX(-100%); }
          }.container {
          width: 80%; 
          margin: 0 auto; 
          overflow: hidden; 
          
          padding: 10px; 
          }
           .Statscard {
  background-color: #f8f9fa;
  /* background-image: linear-gradient(
    45deg,
    rgb(255, 255, 255) 0%,
    rgb(241, 242, 243) 100%
  ); */
  border: 1px solid rgb(235, 235, 235);
  border-radius: 10px;
  margin: 16px;
  padding: 16px;
  box-shadow: 0px 19px 30px -10px rgba(21, 21, 25, 0.21);
}
        </style>
        </head>
        <body>
        <div class="container" style="background-color: ${legacycolor}; color: ${legacytext};min-width:100%;padding: 50px;padding-top:0px;">
        <div style="margin-top: ${statsMarginTop}px; margin-bottom: ${statsMarginBottom}px;">
            <p class="text-center" style="color: ${captioncolor};">${statscaption}</p>
            <h2 class="text-center heading" style="color: ${contenttextheadingcolor}; font-family: ${statstitletextfont};">${legacy}</h2>
            <p class="text-center" style="color: ${contenttextinfocolor}; font-family: ${statsbodytextfont};">
                ${statsintro}
            </p>
            <div class="cards row d-flex align-items-center justify-content-evenly" >
                <div class="Statscard col-md-4" style="width: 18rem; display: ${statsnumremove1 ? 'none' : ''}; background-color: ${statsbackgroundcolor}; border: ${statsborder}px solid ${statsbordercolor}; border-radius: ${statsborderradius}px; box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21)` : '0px 19px 30px 10px rgba(21, 21, 25, 0.21)'};">
                    <div class="card-body d-flex align-items-center justify-content-center flex-column">
                        <h5 class="card-title" style="color: ${statevaluecolor ? statevaluecolor : contenttextheadingcolor}; font-family: ${statsbodytextfont};">${stateOne.prefix}${statsnumber2}${stateOne.suffix}</h5>
                        <p class="card-text" style="color: ${statslabelcolor ? statslabelcolor : contenttextinfocolor}; font-family: ${statsbodytextfont};">${statsnumber1}</p>
                    </div>
                </div>
            
                <div class="Statscard col-md-4" style="width: 18rem; display: ${statsnumremove2 ? 'none' : ''}; background-color: ${statsbackgroundcolor}; border: ${statsborder}px solid ${statsbordercolor}; border-radius: ${statsborderradius}px; box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21)` : '0px 19px 30px 10px rgba(21, 21, 25, 0.21)'};">
                    <div class="card-body d-flex align-items-center justify-content-center flex-column">
                        <h5 class="card-title" style="color: ${statevaluecolor ? statevaluecolor : contenttextheadingcolor}; font-family: ${statsbodytextfont};">${stateOne.prefix}${statsnumber4}${stateOne.suffix}</h5>
                        <p class="card-text" style="color: ${statslabelcolor ? statslabelcolor : contenttextinfocolor}; font-family: ${statsbodytextfont};">${statsnumber3}</p>
                    </div>
                </div>
    
                <div class="Statscard col-md-4" style="width: 18rem; display: ${statsnumremove3 ? 'none' : ''}; background-color: ${statsbackgroundcolor}; border: ${statsborder}px solid ${statsbordercolor}; border-radius: ${statsborderradius}px; box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21)` : '0px 19px 30px 10px rgba(21, 21, 25, 0.21)'};">
                    <div class="card-body d-flex align-items-center justify-content-center flex-column">
                        <h5 class="card-title" style="color: ${statevaluecolor ? statevaluecolor : contenttextheadingcolor}; font-family: ${statsbodytextfont};">${stateOne.prefix}${statsnumber6}${stateOne.suffix}</h5>
                        <p class="card-text" style="color: ${statslabelcolor ? statslabelcolor : contenttextinfocolor}; font-family: ${statsbodytextfont};">${statsnumber5}</p>
                    </div>
                </div>
                <!-- Repeat the same structure for the other cards -->
                ${newlegacy.map((item, index) => `
                <div class="Statscard col-md-4" style="width: 18rem; ; background-color: ${statsbackgroundcolor}; border: ${statsborder}px solid ${statsbordercolor}; border-radius: ${statsborderradius}px; box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21)` : '0px 19px 30px 10px rgba(21, 21, 25, 0.21)'};">
                    <div class="card-body d-flex align-items-center justify-content-center flex-column">
                        <h5 class="card-title" style="color: ${statevaluecolor ? statevaluecolor : contenttextheadingcolor}; font-family: ${statsbodytextfont};">${item.prefix}${item.value}${item.suffix}</h5>
                        <p class="card-text" style="color: ${statslabelcolor ? statslabelcolor : contenttextinfocolor}; font-family: ${statsbodytextfont};">${item.label}</p>
                    </div>
                </div>
                `).join('')}
            </div>
        </div>
    </div>
    
        </body>
        </html>`

        var StatsTemplate2 = `
         <!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Legacy Stats</title>
<style>
.container {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
  
    padding: 10px;
  } 
  .sidecard {
    color: #343a40;
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0px 19px 30px -10px rgba(21, 21, 25, 0.21);
    box-sizing: border-box;
    background: linear-gradient(45deg, rgb(255, 255, 255) 0%, rgb(241, 242, 243) 100%);
    border: 1px solid rgb(235, 235, 235) ;
    border-radius: 10px;
  }
  .sidecard-child {
    display: flex;
    flex-grow: 1;
    flex-basis: 0px;
    text-align: center;
    align-items: center;
    flex-direction: column;
    padding: 1em;
    border-right: 1px solid rgb(235, 235, 235);
  }
  .countparent{
    color:  #212529;
    font-size: 1.8em;
    font-weight: 600;
  }
  .countspantext {
    font-size: 16px;
  }
</style>
<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
</head>
<body>
<div class="container"   style=' backgroundColor: ${legacycolor}, color: ${legacytext},min-width: 100%; padding: 50px;padding-top:0px;'>
    <div style="margin-top: ${statsMarginTop}px; margin-bottom: ${statsMarginBottom}px;">
        <p class="text-center" style="color: #9c36b5;">${statscaption}</p>
        <h2 class="text-center heading">${legacy}</h2>
        <p class="text-center">${statsintro}</p>
        <div class="sidecard">
            <div class="sidecard-child" style="display: ${statsnumremove1 ? 'none' : ''};  background-color: ${statsbackgroundcolor}, border: ${statsborder}px solid ${statsbordercolor}, border-radius: ${statsborderradius}px, box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px -10px rgba(21, 21, 25, 0.21)'};">
                <div class="countparent">
                    <span data-value="10000" class="countup">${stateOne.prefix}${statsnumber2}${stateOne.suffix}</span>
                </div>
                <span class="countspantext">${statsnumber1}</span>
            </div>
            <div class="sidecard-child" style="display: ${statsnumremove2 ? 'none' : ''};">
                <div class="countparent">
                    <span data-value="50" class="countup">${statetwo.prefix}${statsnumber4}${statetwo.suffix}</span>
                </div>
                <span class="countspantext">${statsnumber3}</span>
            </div>
            <div class="sidecard-child" style="display: ${statsnumremove3 ? 'none' : ''};">
                <div class="countparent">
                    <span data-value="15" class="countup">${statethree.prefix}${statsnumber6}${statethree.suffix}</span>
                </div>
                <span class="countspantext">${statsnumber5}</span>
            </div>

             ${newlegacy.map((item, index) => `
                <div class="sidecard-child" >
                <div class="countparent">
                    <span data-value="15" class="countup">${item.prefix}${item.value}${item.suffix}</span>
                </div>
                <span class="countspantext">${item.label}</span>
            </div>

                `).join('')}
        </div>
    </div>
</div>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
</body>
</html>
`
        var StatsTemplate3 = `
        <!DOCTYPE html>
        <html>
        <head>
         <style>
         .container {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          
            padding: 10px;
          }
          .sidecard-child {
            display: flex;
            flex-grow: 1;
            flex-basis: 0px;
            text-align: center;
            align-items: center;
            flex-direction: column;
            padding: 1em;
            border-right: 1px solid rgb(235, 235, 235);
          }
          .countparent{
            color:  #212529;
            font-size: 1.8em;
            font-weight: 600;
          }
          .countspantext {
            font-size: 16px;
          }
          
         </style>
        </head>
        <body>
        <div class="container" style="background-color: ${legacycolor}; color: ${legacytext}; min-width: 100%; padding: 50px;padding-top:0px;">
    <div style="margin-top: ${statsMarginTop}px; margin-bottom: ${statsMarginBottom}px;">
        <p class="text-center" style="color: ${captioncolor};">${statscaption}</p>
        <h2 class="text-center heading" style="color: ${legacytext}; font-family: ${statstitletextfont};">${legacy}</h2>
        <p class="text-center" style="color: ${legacytext}; font-family: ${statsbodytextfont};">${statsintro}</p>
        <div class="d-flex flex-wrap">
            <div class="sidecard-child" style="width: 18rem; display: ${statsnumremove1 ? 'none' : ''}; background-color: ${statsbackgroundcolor}; border: ${statsborder}px solid ${statsbordercolor}; border-radius: ${statsborderradius}px; box-shadow: ${statsshowdowrange ? '0px 0px' : '0px 19px 30px -10px'} rgba(21, 21, 25, 0.21); border-right: none;">
                <div class="countparent">
                    <span data-value="10000" class="countup" style="color: ${statevaluecolor}; font-family: ${statsbodytextfont};">${stateOne.prefix}${statsnumber2}${stateOne.suffix}</span>
                </div>
                <span class="countspantext" style="color: ${statslabelcolor}; font-family: ${statsbodytextfont};">${statsnumber1}</span>
            </div>
            <div class="sidecard-child" style="width: 18rem; display: ${statsnumremove2 ? 'none' : ''}; background-color: ${statsbackgroundcolor}; border: ${statsborder}px solid ${statsbordercolor}; border-radius: ${statsborderradius}px; box-shadow: ${statsshowdowrange ? '0px 0px' : '0px 19px 30px -10px'} rgba(21, 21, 25, 0.21); border-right: none;">
                <div class="countparent">
                    <span data-value="50" class="countup" style="color: ${statevaluecolor}; font-family: ${statsbodytextfont};">${statetwo.prefix}${statsnumber4}${statetwo.suffix}</span>
                </div>
                <span class="countspantext" style="color: ${statslabelcolor}; font-family: ${statsbodytextfont};">${statsnumber3}</span>
            </div>
            <div class="sidecard-child" style="width: 18rem; display: ${statsnumremove3 ? 'none' : ''}; background-color: ${statsbackgroundcolor}; border: ${statsborder}px solid ${statsbordercolor}; border-radius: ${statsborderradius}px; box-shadow: ${statsshowdowrange ? '0px 0px' : '0px 19px 30px -10px'} rgba(21, 21, 25, 0.21); border-right: none;">
                <div class="countparent">
                    <span data-value="15" class="countup" style="color: ${statevaluecolor}; font-family: ${statsbodytextfont};">${statethree.prefix}${statsnumber6}${statethree.suffix}</span>
                </div>
                <span class="countspantext" style="color: ${statslabelcolor}; font-family: ${statsbodytextfont};">${statsnumber5}</span>
            </div>
            ${newlegacy.map((item, index) => `
              <div class="sidecard-child" style="width: 18rem;  background-color: ${statsbackgroundcolor}; border: ${statsborder}px solid ${statsbordercolor}; border-radius: ${statsborderradius}px; box-shadow: ${statsshowdowrange ? '0px 0px' : '0px 19px 30px -10px'} rgba(21, 21, 25, 0.21); border-right: none;">
                <div class="countparent">
                    <span data-value="15" class="countup" style="color: ${statevaluecolor}; font-family: ${statsbodytextfont};">${item.prefix}${item.value}${item.suffix}</span>
                </div>
                <span class="countspantext" style="color: ${statslabelcolor}; font-family: ${statsbodytextfont};">${item.label}</span>
            </div>

              `).join('')}
        </div>
    </div>
</div>
    
        </body>
        </html>`
        var StatsTemplate4 = `
        <!DOCTYPE html>
        <html>
        <head>
         <style>
         .container {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          
            padding: 10px;
          }
          .separatedcards {
            color: #343a40;
            margin: 1em;
            display: flex;
            padding: 1em;
            flex-grow: 1;
            box-shadow: 0px 19px 30px -10px rgba(21, 21, 25, 0.21);
            box-sizing: border-box;
            flex-basis: 0;
            text-align: center;
            align-items: center;
            border-color: rgb(235, 235, 235);
            border-style: solid;
            border-width: 1px;
            border-radius: 10px;
            flex-direction: column;
          }
          .countspantext {
            font-size: 16px;
          }
          .countparent{
            color:  #212529;
            font-size: 1.8em;
            font-weight: 600;
          }
          </style>
        </head>
        <body>
        <div class="container" style="background-color: ${legacycolor}; color: ${legacytext}; min-width: 100%;padding: 50px;padding-top:0px;">
    <div style="margin-top: ${statsMarginTop}; margin-bottom: ${statsMarginBottom};">
        <p class="text-center" style="color: ${captioncolor};">${statscaption}</p>
        <h2 class="text-center heading" style="color: ${legacytext}; font-family: ${statstitletextfont};">${legacy}</h2>
        <p class="text-center" style="color: ${legacytext}; font-family: ${statsbodytextfont};">${statsintro}</p>
        <div class="d-flex flex-wrap" style="margin: 0 -16px;">
            <div class="separatedcards" style="width: 18rem; display: ${statsnumremove1 ? 'none' : ''}; background-color: ${statsbackgroundcolor}; border: ${statsborder} solid ${statsbordercolor}; border-radius: ${statsborderradius}; box-shadow: ${statsshowdowrange};">
                <div class="countparent">
                    <span data-value="10000" class="countup" style="color: ${statevaluecolor}; font-family: ${statsbodytextfont};">${stateOne.prefix}${statsnumber2}${stateOne.suffix}</span>
                </div>
                <span class="countspantext" style="color: ${statslabelcolor}; font-family: ${statsbodytextfont};">${statsnumber1}</span>
            </div>
            <div class="separatedcards" style="width: 18rem; display: ${statsnumremove2 ? 'none' : ''}; background-color: ${statsbackgroundcolor}; border: ${statsborder} solid ${statsbordercolor}; border-radius: ${statsborderradius}; box-shadow: ${statsshowdowrange};">
                <div class="countparent">
                    <span data-value="50" class="countup" style="color: ${statevaluecolor}; font-family: ${statsbodytextfont};">${statetwo.prefix}${statsnumber4}${statetwo.suffix}</span>
                </div>
                <span class="countspantext" style="color: ${statslabelcolor}; font-family: ${statsbodytextfont};">${statsnumber3}</span>
            </div>
            <div class="separatedcards" style="width: 18rem; display: ${statsnumremove3 ? 'none' : ''}; background-color: ${statsbackgroundcolor}; border: ${statsborder} solid ${statsbordercolor}; border-radius: ${statsborderradius}; box-shadow: ${statsshowdowrange};">
                <div class="countparent">
                    <span data-value="15" class="countup" style="color: ${statevaluecolor}; font-family: ${statsbodytextfont};">${statethree.prefix}${statsnumber6}${statethree.suffix}</span>
                </div>
                <span class="countspantext" style="color: ${statslabelcolor}; font-family: ${statsbodytextfont};">${statsnumber5}</span>
            </div>
            ${newlegacy.map((item, index) => `
               <div class="separatedcards" style="width: 18rem; display: ${statsnumremove3 ? 'none' : ''}; background-color: ${statsbackgroundcolor}; border: ${statsborder} solid ${statsbordercolor}; border-radius: ${statsborderradius}; box-shadow: ${statsshowdowrange};">
                <div class="countparent">
                    <span data-value="15" class="countup" style="color: ${statevaluecolor}; font-family: ${statsbodytextfont};">${item.prefix}${item.value}${item.suffix}</span>
                </div>
                <span class="countspantext" style="color: ${statslabelcolor}; font-family: ${statsbodytextfont};">${item.label}</span>
            </div>

              `).join('')}
            
        </div>
    </div>
</div>
    
        </body>
        </html>`


        if (selectedStats === null) {
            var StatsTemplate = StatsTemplate1
        };
        if (selectedStats === 0) {
            var StatsTemplate = StatsTemplate2
        };
        if (selectedStats === 1) {
            var StatsTemplate = StatsTemplate3
        };
        if (selectedStats === 2) {
            var StatsTemplate = StatsTemplate4
        };

        //  --------------------------------------------Faq----------------------------------------------------------------------//

        const toggleAccordion4 = () => {
            setIsOpen1(false);
            setIsOpen2(false);
            setIsOpen3(false);
            setIsopen(false);
            setIsopen4(!isOpen4);
        }
        const toggleAccordionNew = () => {
            setIsopen(!isOpen);
            setIsOpen1(false);
            setIsOpen2(false);
            setIsOpen3(false);
            setIsopen4(false);
        }
        const toggleAccordion1 = () => {
            setIsOpen1(!isOpen1);
            setIsOpen2(false);
            setIsOpen3(false);
            setIsopen(false);
            setIsopen4(false)
        };

        const toggleAccordion2 = () => {
            setIsOpen1(false);
            setIsOpen2(!isOpen2);
            setIsOpen3(false);
            setIsopen(false);
            setIsopen4(false)
        };

        const toggleAccordion3 = () => {
            setIsOpen1(false);
            setIsOpen2(false);
            setIsOpen3(!isOpen3);
            setIsopen(false);
            setIsopen4(false)
        };

        const FaqTemplate1 = `
         <!DOCTYPE html>
         <html>
         <head>
           <style>
           .container {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          
            padding: 10px;
          }
               .accordion-summary {
            padding: 1rem 0;
            font-size: 16px;
            display:flex;
            justify-content: space-between;
            align-items: center;
          }

          </style>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
           <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/material-design-icons/3.0.1/iconfont/material-icons.min.css" />
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/mui/0.9.43/css/mui.min.css" />
         </head>
         <body>
         <div class="container" style="background-color: ${faqcolor}; min-width: 100%; padding: 50px;padding-top:0px;" ref={Section6}>
    <div style="margin-top: ${faqMargintop}px; margin-bottom: ${faqMarginBottom}px;">
        <p style="color: ${captioncolor};">${faqCaption}</p>
        <h1 class="heading fs-4" style="font-family: ${faqtitletextfont}; color: ${contenttextheadingcolor};">${faqtitle}</h1>
        <p style="font-size: 17px; font-family: ${faqbodytextfont}; color: ${contenttextinfocolor};">
            ${faqIntro}
        </p>
             <div id="accordion11" style="display:block;">
                           <div class="mui-panel">
                                 <div class="accordion-summary" style="background-color: #yourFaqColor; font-family: yourFaqBodyTextFont;">
                
                                    <span id="faqQ11" style=' background-color: ${faqcolor}, font-family: ${faqbodytextfont};cursor:pointer'  onclick="toggleAccordion('accordion11-details')">${faqQ1}</span>
                                        <i class="material-icons" onclick="toggleAccordion('accordion11-details')" style='cursor:pointer'>expand_more</i>
                          </div>
                                 <div id="accordion11-details" class="mui-panel" style="display: none; background-color: #yourFaqColor; font-family: yourFaqBodyTextFont; color: #yourContentTextHeadingColor;">
                                     <span id="faqA11"  style=' background-color: ${faqcolor}, font-fmily: ${faqbodytextfont}, color:${contenttextheadingcolor}'>${faqA1}</span>
                                  </div>
                                </div>
                     </div>

                       <div id="accordion22" style="display:block;">
                             <div class="mui-panel">
                              <div class="accordion-summary" style="background-color: #yourFaqColor; font-family: yourFaqBodyTextFont;">
                                       <span id="faqQ22"  style=' background-color: ${faqcolor}, font-family: ${faqbodytextfont};cursor:pointer' onclick="toggleAccordion('accordion22-details')">${faqQ2}</span>
                                     <i class="material-icons" onclick="toggleAccordion('accordion22-details')"  style='cursor:pointer'>expand_more</i>

                                         </div>
                              <div id="accordion22-details" class="mui-panel" style="display: none; background-color: #yourFaqColor; font-family: yourFaqBodyTextFont; color: #yourContentTextHeadingColor;">
                             <span id="faqA22" style=' background-color: ${faqcolor}, font-fmily: ${faqbodytextfont}, color:${contenttextheadingcolor}'>${faqA2}</span>
                            </div>
                              </div>

                               <div id="accordion33" style="display:block;">
                             <div class="mui-panel">
                              <div class="accordion-summary" style="background-color: #yourFaqColor; font-family: yourFaqBodyTextFont;">
                                       <span id="faqQ33"  style=' background-color: ${faqcolor}, font-family: ${faqbodytextfont};cursor:pointer' onclick="toggleAccordion('accordion33-details')">${faqQ3}</span>
                                     <i class="material-icons" onclick="toggleAccordion('accordion33-details')" style='cursor:pointer'>expand_more</i>

                                         </div>
                              <div id="accordion33-details" class="mui-panel" style="display: none; background-color: #yourFaqColor; font-family: yourFaqBodyTextFont; color: #yourContentTextHeadingColor;">
                             <span id="faqA33" style=' background-color: ${faqcolor}, font-fmily: ${faqbodytextfont}, color:${contenttextheadingcolor}'>${faqA3}</span>
                            </div>
                              </div>

                              <div id="accordion44" style="display:block;">
                             <div class="mui-panel">
                              <div class="accordion-summary" style="background-color: #yourFaqColor; font-family: yourFaqBodyTextFont;">
                                       <span id="faqQ44"  style=' background-color: ${faqcolor}, font-family: ${faqbodytextfont};cursor:pointer' onclick="toggleAccordion('accordion44-details')">${faqQ4}</span>
                                     <i class="material-icons" onclick="toggleAccordion('accordion44-details')" style='cursor:pointer' >expand_more</i>

                                         </div>
                              <div id="accordion44-details" class="mui-panel" style="display: none; background-color: #yourFaqColor; font-family: yourFaqBodyTextFont; color: #yourContentTextHeadingColor;">
                             <span id="faqA44" style=' background-color: ${faqcolor}, font-fmily: ${faqbodytextfont}, color:${contenttextheadingcolor}'>${faqA4}</span>
                            </div>
                              </div>
     </div>
        </div>
    </div>
                      

                     
                      

    <script>
        function toggleAccordion(detailsId) {
            const details = document.getElementById(detailsId);
            details.style.display = details.style.display === 'none' ? 'block' : 'none';
        }
    </script>
            <!-- Other accordion items omitted for brevity -->
            ${newFaq.map((item, index) => (`
              <div id="accordion${index}" style="display:block;">
                           <div class="mui-panel">
                            <div class="accordion-summary" style="background-color: #yourFaqColor; font-family: yourFaqBodyTextFont;">
                                     <span id="faqQ${index}"  style=' background-color: ${faqcolor}, font-family: ${faqbodytextfont};cursor:pointer' onclick="toggleAccordion('accordion${index}-details')">${item.Question}</span>
                                   <i class="material-icons" onclick="toggleAccordion('accordion${index}-details')" style='cursor:pointer' >expand_more</i>

                                       </div>
                            <div id="accordion${index}-details" class="mui-panel" style="display: none; background-color: #yourFaqColor; font-family: yourFaqBodyTextFont; color: #yourContentTextHeadingColor;">
                           <span id="faqA${index}" style=' background-color: ${faqcolor}, font-fmily: ${faqbodytextfont}, color:${contenttextheadingcolor}'>${item.Answer}</span>
                          </div>
                            </div>
          `)).join('')}
        </div>
    </div>
</div>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
         </body>
         </html>`
        const FaqTemplate2 = `
          <!DOCTYPE html>
         <html>
         <head>
           <style>
           .container {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          
            padding: 10px;
          }
               .accordion-summary {
            padding: 1rem 0;
            font-size: 16px;
            display:flex;
            justify-content: space-between;
            align-items: center;
          }

          </style>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
           <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/material-design-icons/3.0.1/iconfont/material-icons.min.css" />
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/mui/0.9.43/css/mui.min.css" />
         </head>
         <body>
         <div class="container" style="background-color: ${faqcolor}; min-width: 100%; padding: 50px;padding-top:0px;" ref={Section6}>
    <div style="margin-top: ${faqMargintop}px; margin-bottom: ${faqMarginBottom}px;">
        <p style="color: ${captioncolor};">${faqCaption}</p>
        <h1 class="heading fs-4" style="font-family: ${faqtitletextfont}; color: ${contenttextheadingcolor};">${faqtitle}</h1>
        <p style="font-size: 17px; font-family: ${faqbodytextfont}; color: ${contenttextinfocolor};">
            ${faqIntro}
        </p>
             <div id="accordion11" style="display:block;">
                           <div class="mui-panel">
                                 <div class="accordion-summary" style="background: linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgb(0, 0, 0) 100%); font-family: yourFaqBodyTextFont;">
                
                                    <span id="faqQ11" style=' background-color: ${faqcolor}, font-family: ${faqbodytextfont};cursor:pointer;color:  ${contenttextheadingcolor ? `white` : `${contenttextheadingcolor}`}'  onclick="toggleAccordion('accordion11-details')">${faqQ1}</span>
                                        <i class="material-icons" onclick="toggleAccordion('accordion11-details')" style='cursor:pointer'>expand_more</i>
                          </div>
                                 <div id="accordion11-details" class="mui-panel" style="display: none;  font-family: yourFaqBodyTextFont; background: linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgb(0, 0, 0) 100%); font-family: yourFaqBodyTextFont;">
                                     <span id="faqA11"  style='  font-fmily: ${faqbodytextfont};  color: ${contenttextinfocolor ? `${contenttextinfocolor}` : 'white'}'>${faqA1}</span>
                                  </div>
                                </div>
                     </div>

                       <div id="accordion22" style="display:block;">
                             <div class="mui-panel">
                              <div class="accordion-summary" style="background: linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgb(0, 0, 0) 100%); font-family: yourFaqBodyTextFont;">
                                       <span id="faqQ22"  style=' background-color: ${faqcolor}, font-family: ${faqbodytextfont};cursor:pointer;color:  ${contenttextheadingcolor ? `white` : `${contenttextheadingcolor}`}' onclick="toggleAccordion('accordion22-details')">${faqQ2}</span>
                                     <i class="material-icons" onclick="toggleAccordion('accordion22-details')"  style='cursor:pointer'>expand_more</i>

                                         </div>
                              <div id="accordion22-details" class="mui-panel" style="display: none; background: linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgb(0, 0, 0) 100%); font-family: yourFaqBodyTextFont; color: #yourContentTextHeadingColor;">
                             <span id="faqA22" style='  font-fmily: ${faqbodytextfont}; color: ${contenttextinfocolor ? `${contenttextinfocolor}` : 'white'}''>${faqA2}</span>
                            </div>
                              </div>

                               <div id="accordion33" style="display:block;">
                             <div class="mui-panel">
                              <div class="accordion-summary" style="background: linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgb(0, 0, 0) 100%); font-family: yourFaqBodyTextFont;">
                                       <span id="faqQ33"  style=' background-color: ${faqcolor}, font-family: ${faqbodytextfont};cursor:pointer;color:  ${contenttextheadingcolor ? `white` : `${contenttextheadingcolor}`}' onclick="toggleAccordion('accordion33-details')">${faqQ3}</span>
                                     <i class="material-icons" onclick="toggleAccordion('accordion33-details')" style='cursor:pointer'>expand_more</i>

                                         </div>
                              <div id="accordion33-details" class="mui-panel" style="display: none; background: linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgb(0, 0, 0) 100%); font-family: yourFaqBodyTextFont; color: #yourContentTextHeadingColor;">
                             <span id="faqA33" style='font-fmily: ${faqbodytextfont}; color: ${contenttextinfocolor ? `${contenttextinfocolor}` : 'white'}''>${faqA3}</span>
                            </div>
                              </div>

                              <div id="accordion44" style="display:block;">
                             <div class="mui-panel">
                              <div class="accordion-summary" style="background: linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgb(0, 0, 0) 100%); font-family: yourFaqBodyTextFont;">
                                       <span id="faqQ44"  style=' background-color: ${faqcolor}, font-family: ${faqbodytextfont};cursor:pointer;color:  ${contenttextheadingcolor ? `white` : `${contenttextheadingcolor}`}' onclick="toggleAccordion('accordion44-details')">${faqQ4}</span>
                                     <i class="material-icons" onclick="toggleAccordion('accordion44-details')" style='cursor:pointer' >expand_more</i>

                                         </div>
                              <div id="accordion44-details" class="mui-panel" style="display: none; background: linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgb(0, 0, 0) 100%); font-family: yourFaqBodyTextFont; color: #yourContentTextHeadingColor;">
                             <span id="faqA44" style=' font-fmily: ${faqbodytextfont}; color: ${contenttextinfocolor ? `${contenttextinfocolor}` : 'white'}'>${faqA4}</span>
                            </div>
                              </div>
     </div>
        </div>
    </div>
                      

                     
                      

    <script>
        function toggleAccordion(detailsId) {
            const details = document.getElementById(detailsId);
            details.style.display = details.style.display === 'none' ? 'block' : 'none';
        }
    </script>
            <!-- Other accordion items omitted for brevity -->
            ${newFaq.map((item, index) => (`
              <div id="accordion${index}" style="display:block;">
                           <div class="mui-panel">
                            <div class="accordion-summary" style="background:  linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgb(0, 0, 0) 100%); font-family: yourFaqBodyTextFont;">
                                     <span id="faqQ${index}"  style=' background-color: ${faqcolor}, font-family: ${faqbodytextfont};cursor:pointer;color:  ${contenttextheadingcolor ? `white` : `${contenttextheadingcolor}`}' onclick="toggleAccordion('accordion${index}-details')">${item.Question}</span>
                                   <i class="material-icons" onclick="toggleAccordion('accordion${index}-details')" style='cursor:pointer' >expand_more</i>

                                       </div>
                            <div id="accordion${index}-details" class="mui-panel" style="display: none; background: linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgb(0, 0, 0) 100%); font-family: yourFaqBodyTextFont; color: #yourContentTextHeadingColor;">
                           <span id="faqA${index}" style=' font-fmily: ${faqbodytextfont};color: ${contenttextinfocolor ? `${contenttextinfocolor}` : 'white'}'>${item.Answer}</span>
                          </div>
                            </div>
          `)).join('')}
        </div>
    </div>
</div>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
         </body>
         </html>`

        const FaqTemplate3 = `
         <!DOCTYPE html>
         <html>
         <head>
          <style>
          .container {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          
            padding: 10px;
          }
          ._c53b3b37 {
            color: #212529;
            margin: 0 0 0 0;
            font-size: 40px;
          }
          ._9a49c734 {
            padding: 0 0 30px 0;
          }
          ._d1646f8c {
            color: #212529;
            margin: 0;
            font-size: 1.05em;
            font-weight: 500;
          }
          </style>
         </head>
         <body>
         <div class="container" style="background-color: ${faqcolor}; min-width: 100%;padding: 50px; padding-top:0px;" >
         <section  style="margin-top: ${faqMargintop}px; margin-bottom: ${faqMarginBottom}px;">
    <div class="row">
        <div class="col-md-4">
            <p style="color: ${captioncolor};">${faqCaption}</p>
            <h1 class="_c53b3b37" style="font-family: ${faqtitletextfont}; color: ${contenttextheadingcolor};">${faqtitle}</h1>
            <p style="font-size: 17px; color: ${faqtext}; font-family: ${faqbodytextfont}; color: ${contenttextinfocolor};">
                ${faqIntro}
            </p>
        </div>
        <div class="col-md-8">
            ${removeFaq1 ? '' : `
            <div class="_9a49c734" >
                <h4 class="_d1646f8c" style="color: ${contenttextheadingcolor}; font-family: ${faqbodytextfont};">${faqQ1}</h4>
                <div class="_3840d56d" style="color: ${contenttextinfocolor}; font-family: ${faqbodytextfont};">${faqA1}</div>
            </div>` }
            ${removeFaq2 ? '' : `
            <div class="_9a49c734" >
                <h4 class="_d1646f8c" style="color: ${contenttextheadingcolor}; font-family: ${faqbodytextfont};">${faqQ2}</h4>
                <div class="_3840d56d" style="color: ${contenttextinfocolor}; font-family: ${faqbodytextfont};">${faqA2}</div>
            </div>` }
            ${removeFaq3 ? '' : `
            <div class="_9a49c734" >
                <h4 class="_d1646f8c" style="color: ${contenttextheadingcolor}; font-family: ${faqbodytextfont};">${faqQ3}</h4>
                <div class="_3840d56d" style="color: ${contenttextinfocolor}; font-family: ${faqbodytextfont};">${faqA3}</div>
            </div>` }
            ${removeFaq4 ? '' : `
            <div class="_9a49c734" >
                <h4 class="_d1646f8c" style="color: ${contenttextheadingcolor}; font-family: ${faqbodytextfont};">${faqQ4}</h4>
                <div class="_3840d56d" style="color: ${contenttextinfocolor}; font-family: ${faqbodytextfont};">${faqA4}</div>
            </div>` }
            ${newFaq.map((item, index) => (`
            <div class="_9a49c734" key=${index}>
                <h4 class="_d1646f8c" style="color: ${contenttextheadingcolor}; font-family: ${faqtitletextfont};">${item.Question}</h4>
                <div class="_3840d56d">${item.Answer}</div>
            </div>
            `)).join('')}
        </div>
    </div>
</section>
       </div>
         </body>
         </html>`
        const FaqTemplate4 = `
         <!DOCTYPE html>
        <html>
        <head>
          <style>
          .container {
           width: 100%;
           margin: 0 auto;
           overflow: hidden;
         
           padding: 10px;
         }
              .accordion-summary {
           padding: 1rem 0;
           font-size: 16px;
           display:flex;
           justify-content: space-between;
           align-items: center;
         }

         </style>
         <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
          <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
   <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/material-design-icons/3.0.1/iconfont/material-icons.min.css" />
   <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/mui/0.9.43/css/mui.min.css" />
        </head>
        <body>
        <div class="container" style="background-color: ${faqcolor}; min-width: 100%; padding: 50px;padding-top:0px;" ref={Section6}>
   <div style="margin-top: ${faqMargintop}px; margin-bottom: ${faqMarginBottom}px;">
       <p style="color: ${captioncolor};">${faqCaption}</p>
       <h1 class="heading fs-4" style="font-family: ${faqtitletextfont}; color: ${contenttextheadingcolor};">${faqtitle}</h1>
       <p style="font-size: 17px; font-family: ${faqbodytextfont}; color: ${contenttextinfocolor};">
           ${faqIntro}
       </p>
            <div id="accordion11" style="display:block;">
                          <div class="mui-panel">
                                <div class="accordion-summary" style=" font-family: yourFaqBodyTextFont;">
               
                                   <span id="faqQ11" style=' background-color: ${faqcolor}, font-family: ${faqbodytextfont};cursor:pointer;color:  ${contenttextheadingcolor ? `${contenttextheadingcolor}` : `black`};font-family: ${faqbodytextfont}'  onclick="toggleAccordion('accordion11-details')">${faqQ1}</span>
                                       <i class="material-icons" onclick="toggleAccordion('accordion11-details')" style='cursor:pointer'>expand_more</i>
                         </div>
                                <div id="accordion11-details" class="mui-panel" style="display: none;   font-family: yourFaqBodyTextFont;">
                                    <span id="faqA11"  style='  font-fmily: ${faqbodytextfont};  color: ${contenttextinfocolor ? `${contenttextinfocolor}` : 'black'} font-family: ${faqbodytextfont}'>${faqA1}</span>
                                 </div>
                               </div>
                    </div>

                      <div id="accordion22" style="display:block;">
                            <div class="mui-panel">
                             <div class="accordion-summary" style=" font-family: yourFaqBodyTextFont;">
                                      <span id="faqQ22"  style=' background-color: ${faqcolor}, font-family: ${faqbodytextfont};cursor:pointer;color:  ${contenttextheadingcolor ? `${contenttextheadingcolor}` : `black`};font-family: ${faqbodytextfont};' onclick="toggleAccordion('accordion22-details')">${faqQ2}</span>
                                    <i class="material-icons" onclick="toggleAccordion('accordion22-details')"  style='cursor:pointer'>expand_more</i>

                                        </div>
                             <div id="accordion22-details" class="mui-panel" style="display: none; font-family: yourFaqBodyTextFont; color: #yourContentTextHeadingColor;">
                            <span id="faqA22" style='  font-fmily: ${faqbodytextfont}; color: ${contenttextinfocolor ? `${contenttextinfocolor}` : 'black'};font-family: ${faqbodytextfont};'>${faqA2}</span>
                           </div>
                             </div>

                              <div id="accordion33" style="display:block;">
                            <div class="mui-panel">
                             <div class="accordion-summary" style="font-family: yourFaqBodyTextFont;">
                                      <span id="faqQ33"  style=' background-color: ${faqcolor}, font-family: ${faqbodytextfont};cursor:pointer;color:  ${contenttextheadingcolor ? `${contenttextheadingcolor}` : `black`};font-family: ${faqbodytextfont};' onclick="toggleAccordion('accordion33-details')">${faqQ3}</span>
                                    <i class="material-icons" onclick="toggleAccordion('accordion33-details')" style='cursor:pointer'>expand_more</i>

                                        </div>
                             <div id="accordion33-details" class="mui-panel" style="display: none; font-family: yourFaqBodyTextFont; color: #yourContentTextHeadingColor;">
                            <span id="faqA33" style='font-fmily: ${faqbodytextfont}; color: ${contenttextinfocolor ? `${contenttextinfocolor}` : 'black'}; font-family: ${faqbodytextfont}'>${faqA3}</span>
                           </div>
                             </div>

                             <div id="accordion44" style="display:block;">
                            <div class="mui-panel">
                             <div class="accordion-summary" style=" font-family: yourFaqBodyTextFont;">
                                      <span id="faqQ44"  style=' background-color: ${faqcolor}, font-family: ${faqbodytextfont};cursor:pointer;color:  ${contenttextheadingcolor ? `${contenttextheadingcolor}` : `black`}; font-family: ${faqbodytextfont};' onclick="toggleAccordion('accordion44-details')">${faqQ4}</span>
                                    <i class="material-icons" onclick="toggleAccordion('accordion44-details')" style='cursor:pointer' >expand_more</i>

                                        </div>
                             <div id="accordion44-details" class="mui-panel" style="display: none; font-family: yourFaqBodyTextFont; color: #yourContentTextHeadingColor;">
                            <span id="faqA44" style=' font-fmily: ${faqbodytextfont}; color: ${contenttextinfocolor ? `${contenttextinfocolor}` : 'black'};font-family: ${faqbodytextfont};'>${faqA4}</span>
                           </div>
                             </div>
    </div>
       </div>
   </div>
                     

                    
                     

   <script>
       function toggleAccordion(detailsId) {
           const details = document.getElementById(detailsId);
           details.style.display = details.style.display === 'none' ? 'block' : 'none';
       }
   </script>
           <!-- Other accordion items omitted for brevity -->
           ${newFaq.map((item, index) => (`
             <div id="accordion${index}" style="display:block;">
                          <div class="mui-panel">
                           <div class="accordion-summary" style="font-family: yourFaqBodyTextFont;">
                                    <span id="faqQ${index}"  style=' background-color: ${faqcolor}, font-family: ${faqbodytextfont};cursor:pointer;color:  ${contenttextheadingcolor ? `${contenttextheadingcolor}` : `black`};font-family: ${faqbodytextfont};' onclick="toggleAccordion('accordion${index}-details')">${item.Question}</span>
                                  <i class="material-icons" onclick="toggleAccordion('accordion${index}-details')" style='cursor:pointer' >expand_more</i>

                                      </div>
                           <div id="accordion${index}-details" class="mui-panel" style="display: none;  font-family: yourFaqBodyTextFont; color: #yourContentTextHeadingColor;">
                          <span id="faqA${index}" style=' font-fmily: ${faqbodytextfont};color: ${contenttextinfocolor ? `${contenttextinfocolor}` : 'black'};font-family: ${faqbodytextfont};'>${item.Answer}</span>
                         </div>
                           </div>
         `)).join('')}
       </div>
   </div>
</div>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
        </body>
        </html>`

        const FaqTemplate5 = `
         <!DOCTYPE html>
         <html>
         <head>
          <style>
          .container {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          
            padding: 10px;
          }
          ._c53b3b37 {
            color: #212529;
            margin: 0 0 0 0;
            font-size: 40px;
          }
          </style>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
         </head>
         <body>
          <div class="container" style="background-color: ${faqcolor}; padding: 50px; padding-top:0px;">
         <div  style="margin-top: ${faqMargintop}px; margin-bottom: ${faqMarginBottom}px;" ref="Section6">
    <header class="_e02e8965">
        <p style="color: ${captioncolor};">${faqCaption}</p>
        <h1 class="_c53b3b37" style="font-family: ${faqtitletextfont}; color: ${contenttextheadingcolor};">${faqtitle}</h1>
        <p style="font-size: 17px; color: ${faqtext}; font-family: ${faqbodytextfont}; color: ${contenttextinfocolor};">
            ${faqIntro}
        </p>
    </header>
    <div class="row">
        <div class="col-md-6 col-sm-12">
            <div class="_157660be">
                <h6 class="_790354ef" style="color: ${contenttextheadingcolor}; font-family: ${faqbodytextfont};">${faqQ1}</h6>
                <div class="_7d377080" style="color: ${contenttextinfocolor}; font-family: ${faqbodytextfont};">${faqA1}</div>
            </div>
            <div class="_157660be">
                <h6 class="_790354ef" style="color: ${contenttextheadingcolor}; font-family: ${faqbodytextfont};">${faqQ2}</h6>
                <div class="_7d377080" style="color: ${contenttextinfocolor}; font-family: ${faqbodytextfont};">${faqA2}</div>
            </div>
        </div>
        <div class="col-md-6 col-sm-12">
            <div class="_157660be">
                <h6 class="_790354ef" style="color: ${contenttextheadingcolor}; font-family: ${faqbodytextfont};">${faqQ3}</h6>
                <div class="_7d377080" style="color: ${contenttextheadingcolor}; font-family: ${faqbodytextfont};">${faqA3}</div>
            </div>
            <div class="_157660be">
                <h6 class="_790354ef" style="color: ${contenttextheadingcolor}; font-family: ${faqbodytextfont};">${faqQ4}</h6>
                <div class="_7d377080" style="color: ${contenttextinfocolor}; font-family: ${faqbodytextfont};">${faqA4}</div>
            </div>
        </div>
        ${newFaq.map((item) => (`
            <div className="col-md-6 col-sm-12">
            <div class="_157660be"><h6 class="_790354ef" style=' color: ${contenttextheadingcolor}, font-family: ${faqbodytextfont} '>${item.Question}</h6>
            <div class="_7d377080" style='color: ${contenttextheadingcolor}, font-family: ${faqbodytextfont}'>${item.Answer}</div></div>

            </div>
         `))}
    </div>
</div>
</div>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
         </body>
         </html>`



        if (selectedFaq === null) {
            var FaqTemplate = FaqTemplate1
        };
        if (selectedFaq === 0) {
            var FaqTemplate = FaqTemplate2
        };
        if (selectedFaq === 1) {
            var FaqTemplate = FaqTemplate3
        };
        if (selectedFaq === 2) {
            var FaqTemplate = FaqTemplate4
        };
        if (selectedFaq === 3) {
            var FaqTemplate = FaqTemplate5
        };

        //  -------------------------------------------Footer-----------------------------------------------------------//
        const FooterFeature = footerfeature.map((item) => {
            return item
        });
        const FooterCompany = footercompany.map((item) => {
            return item
        });
        const Footerlegal = footerlegal.map((item) => {
            return item
        })
        const Footertemplate1 = `
         <!DOCTYPE html>
         <html>
         <head>
          <style>
          .container {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          
            padding: 10px;
          }
          </style>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
         </head>
         <body>
         <div class="container" style="background-color: ${footercolor}; min-width: 100%; padding: 50px;padding-top:0px;" >
         <div class="row" style="margin-top: ${footerMargintop}px; margin-bottom: ${footerMarginBottom}px;">
             <div class="col-sm-6 d-flex align-items-start justify-content-start flex-column">
                 <span style="color: ${footertext}; font-family: ${footerbodytextfont}; color: ${contenttextinfocolor};">${footeryear}</span>
                 <div class="media mt-2">
                     <a href="" style="display: ${removeSocialIcon1 ? 'none' : ''};"><i class="fa-brands fa-facebook" style="color: ${contenttextinfocolor};"></i></a>
                     <a href="" class="ms-2" style="display: ${removeSocialIcon2 ? 'none' : ''};"><i class="fa-brands fa-instagram" style="color: ${contenttextinfocolor};"></i></a>
                     <a href="" class="ms-2" style="display: ${removeSocialIcon3 ? 'none' : ''};"><i class="fa-brands fa-twitter" style="color: ${contenttextinfocolor};"></i></a>
                     <a href="" class="ms-2" style="display: ${removeSocialIcon4 ? 'none' : ''};"><i class="fa-brands fa-youtube" style="color: ${contenttextinfocolor};"></i></a>
                     ${newfootersocialIcon.map((item) => `
                         <a href="#" class="ms-2"><i class="fa-brands">#</i>${item}</a>
                     `).join('')}
                 </div>
             </div>
             <div class="col-sm-6">
                 <div class="row">
                     <div class="col-md-4 col-sm-5 footer-links">
                         <span style="display: ${footerfeature.length == 0 ? 'none' : ''}; font-family: ${footerbodytextfont}; color: ${contenttextinfocolor};">${footerfeaturehead}</span>
                              ${footerfeature.map((item, index) => `
                                            <li key=${index} style=" font-size: '13.5px'; color: ${contenttextinfocolor}; list-style: none; margin-top: '5px'; font-family: ${footerbodytextfont}; ">
                                                ${item}
                                            </li>

                                        `).join('')}
                    
                            
                             
                     </div>
                     <div class="col-md-4 col-sm-5 footer-links">
                         <span style="display: ${footercompany.length == 0 ? 'none' : ''}; font-family: ${footerbodytextfont}; color: ${contenttextinfocolor};">${footerCompanyhead}</span>
                         ${FooterCompany.map((item, index) => `
                            <li  key=${index} style="font-size: '13.5px'; color: ${contenttextinfocolor}; list-style: none; margin-top: '5px'; font-family: ${footerbodytextfont}; ">
                                ${item}
                            </li>

                        `).join('')}
                         
                         
                             
                              
                     </div>
                     <div class="col-md-4 col-sm-5 footer-links">
                         <span style="display: ${footerlegal.length == 0 ? 'none' : ''}; font-family: ${footerbodytextfont}; color: ${contenttextinfocolor};">${footerlegalhead}</span>
                         
                                     ${Footerlegal.map((item, index) => `
                            <li key=${index} style="font-size: '13.5px'; color: ${contenttextinfocolor}; list-style: none; margin-top: '5px'; font-family: ${footerbodytextfont};">
                                ${item}
                            </li>

                        `).join('')}
                             


                         
                     </div>
                 </div>
             </div>
         </div>
     </div>
     <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
         </body>
         </html>`
        const FooterTemplate2 = `
         <!DOCTYPE html>
         <html>
         <head>
          <style>
          .container {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
          
            padding: 10px;
          }
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
          </style>
         </head>
         <body>
         <div class="container" style="background-color: ${footercolor}; min-width: 100%; padding: 50px;padding-top:0px" >
         <div class="row" style="margin-top: ${footerMargintop}px; margin-bottom: ${footerMarginBottom}px;">
             <div class="col-sm-6 d-flex align-items-start justify-content-start flex-column">
                 <span style="color: ${footertext}; font-family: ${footerbodytextfont}; color: ${contenttextinfocolor};">${footeryear}</span>
                 <div class="media mt-2">
                     <a href="" style="display: ${removeSocialIcon1 ? 'none' : ''};"><i class="fa-brands fa-facebook"></i></a>
                     <a href="" class="ms-2" style="display: ${removeSocialIcon2 ? '' : 'none'};"><i class="fa-brands fa-instagram"></i></a>
                     <a href="" class="ms-2" style="display: ${removeSocialIcon3 ? '' : 'none'};"><i class="fa-brands fa-twitter"></i></a>
                     <a href="" class="ms-2" style="display: ${removeSocialIcon4 ? '' : 'none'};"><i class="fa-brands fa-youtube"></i></a>
                     ${newfootersocialIcon.map((item) => `
                         <a href="#" class="ms-2"><i class="fa-brands">#</i>${item}</a>
                     `).join('')}
                 </div>
             </div>
     
             <div class="col-sm-6">
                 <div class="row">
                     <div class="col-md-4 col-sm-5 footer-links">
                         <span style="display: ${footerfeature.length == 0 ? 'none' : ''}; font-family: ${footerbodytextfont}; color: ${contenttextinfocolor};">${footerfeaturehead}</span>
                         
                         ${footerfeature.map((item, index) => `
                            <li key=${index} style=" font-size: '13.5px'; color: ${contenttextinfocolor}; list-style: none; margin-top: '5px'; font-family: ${footerbodytextfont}; ">
                                ${item}
                            </li>

                        `).join('')}
                        
                     </div>
                     <div class="col-md-4 col-sm-5 footer-links">
                         <span style="display: ${footercompany.length == 0 ? 'none' : ''}; font-family: ${footerbodytextfont}; color: ${contenttextinfocolor};">${footerCompanyhead}</span>
                         
                         ${FooterCompany.map((item, index) => `
                            <li  key=${index} style="font-size: '13.5px'; color: ${contenttextinfocolor}; list-style: none; margin-top: '5px'; font-family: ${footerbodytextfont}; ">
                                ${item}
                            </li>

                        `).join('')}

                         
                     </div>
                     <div class="col-md-4 col-sm-5 footer-links">
                         <span style="display: ${footerlegal.length == 0 ? 'none' : ''}; font-family: ${footerbodytextfont}; color: ${contenttextinfocolor};">${footerlegalhead}</span>
                        
                               ${Footerlegal.map((item, index) => `
                            <li key=${index} style="font-size: '13.5px'; color: ${contenttextinfocolor}; list-style: none; margin-top: '5px'; font-family: ${footerbodytextfont};">
                                ${item}
                            </li>

                        `).join('')}
                             
                         
                     </div>
                 </div>
             </div>
         </div>
     </div>
     <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
         </body>
         <html>`

        if (selectedFooter === null) {
            var FooterTemplate = Footertemplate1
        };
        if (selectedFooter === 0) {
            var FooterTemplate = FooterTemplate2
        }
        //  -----------------------------------------------------Memecoin----------------------------------------------------------------------------------//
        const Navbar = memecoinNavbar.map((item) => {
            return item
        })




        console.log('Memecoin', memeCaption.slice(13, 32))
        const Caption1 = memeCaption.slice(0, 13);
        const Caption2 = memeCaption.slice(13, 32);

        const insertLineBreaks = (text, maxChars) => {
            const lines = [];
            for (let i = 0; i < text.length; i += maxChars) {
                lines.push(text.substring(i, i + maxChars));
            }
            return lines.join('\n');
        };

        const formattedText = insertLineBreaks(memetokencaption, 20);
        const Template2memecaption = insertLineBreaks(template2memecaption, 25);



        const MemeCoin = `
    <!DOCTYPE html>
    <html>
    <head>
    <title>${memecoinsymbol}</title>
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">

     <style>
     @import url("https://fonts.googleapis.com/css2?family=Londrina+Solid:wght@100;300;400;900&display=swap");

.body {
margin-right: 250px;
overflow-x: hidden;
min-height: 768px !important;
min-width: 100vw !important;
}
.menu-memecoin {
position: relative;
background-position: center;
text-align: center;
background-repeat: no-repeat;
background-position-y: top;
/* width: 100vw; */
height: 200px;
background-size: cover;
z-index: 9;
}
.menu-inner {
margin: auto;
overflow: visible;
position: relative;

display: flex;
align-items: center;
justify-content: center;
}
.menu-item {
display: inline-block;
vertical-align: middle;
margin-left: 10px;
margin-right: 10px;
padding: 15px 15px;
transition: all 300ms ease-out;
}
.menu-item a {
color: white;
font-weight: 900;
text-decoration: none;

cursor: pointer;
}
.logo {
filter: drop-shadow(4px 4px 0px rgba(1, 1, 1, 0.2));
}
#hero {
margin-top: -15px;
padding-top: 300px;
color: white;
/* width: 100vw; */
background-size: cover;
height: calc(100vh - 200px);
position: relative;
overflow: hidden;
background-position: center;
background-repeat: no-repeat;
background-size: cover;
}
@media screen and (min-width: 201px) and (max-width: 1920px) {
#hero,
{
min-height: calc(1080px - 200px);

overflow: hidden;
}
}
#hero::before {
content: "";

left: 0px;
right: 0px;
width: 100vw;
background-size: 100% 100%;
background-repeat: repeat-x;
height: 100%;
top: 0;
position: absolute;
bottom: 57%;
}

#hero .section-inner {
text-align: center;
}
.hero-animation {
position: absolute;
top: -4% !important;
max-width: 250px;
animation: herofly 20s linear infinite;
}

.boomer-cartoon {
z-index: 4;
}
@media screen and (min-width: 701px) and (max-width: 1920px) {
.hero-animation {
max-width: 250px !important;
}
}
@keyframes herofly {
0% {
transform: translateX(-30vw) scaleX(1);
}

50% {
transform: translateX(130vw) scaleX(1);
}
51% {
transform: translateX(130vw) scaleX(-1);
}
100% {
transform: translateX(-30vw) scaleX(-1);
}
}

#hero .header-container {

-webkit-mask-repeat: no-repeat;
mask-repeat: no-repeat;

background-repeat: no-repeat;
background-position: center;
background-size: 100% 100%;
display: inline-block;
margin-bottom: 50px;
padding: 90px;
text-align: center;
margin-top: -18vh;
z-index: 20;
position: relative;
}
#hero h2 {
font-size: 1.5em;
margin: 0px;
}
h1 {
display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
}
.boomer-cig {
position: absolute;
max-width: 480px;
right: 8vw;
bottom: 1.2vh;
}
.boomer-chair {
position: absolute;
max-width: 450px;
left: 12vw;
bottom: 1.2vh;
}

.boomer-cartoon {
z-index: 4;
}
@media screen and (min-width: 701px) and (max-width: 1920px) {
.boomer-cig {
max-width: 400px !important;
right: 2vw;
}
}

@media screen and (min-width: 701px) and (max-width: 1920px) {
.boomer-chair {
max-width: 380px !important;
left: 2vw !important;
}
}

.arrow-memecoin {
position: absolute;
bottom: 3vh;
width: 70px;
height: 50px;
background-repeat: no-repeat;
background-size: contain;
left: 50%;
transform: translateX(-50%);
z-index: 1;
}
.arrow-memecoin img {
animation: swivel 1s ease infinite;
}
@keyframes swivel {
0% {
transform: translateY(20px);
}

50% {
transform: translateY(0px);
}
100% {
transform: translateY(20px);
}
}
.button-container {
margin: auto;
z-index: 800;
position: relative;
}
.button {
transition: all 300ms ease-out;
}
.button:hover {
transform: scale(1.05) !important;
}

.button-primary {
background: #023cba;
color: white;
}
.button {
padding: 15px 15px;
margin-left: 10px;
margin-right: 10px;

min-width: 175px;
display: inline-block;
font-weight: 800 !important;
}
a {
text-decoration: none;
}
a.button.button-secondary {
text-align: center;
}

.button-secondary {
background: white;
color: #023cba;
}



#vision {
background-size: 350px;

position: relative;
}
#vision {
/* background: #0452ff; */
}
#vision::before,
#howtobuy::before {
content: "";
display: block;
width: 100%;
top: 0px;

height: 100%;
position: absolute;
z-index: 0;
}
#vision .section-inner {
z-index: 1;

text-align: center;
}
.header-container {
text-align: center;
padding-top: 5vh;
}
.section.blue h1,
.section.blue p {
color: white ;
}
#vision p {
max-width: 550px;
margin: auto;
margin-top: 40px;
font-family: "Londrina Solid";
font-size: 24px;
}

}
@media screen and (min-width: 701px) and (max-width: 1920px) {
#vision {
padding-bottom: 250px !important;
}
}
@media screen and (min-width: 701px) and (max-width: 1920px) {
#hero,
 #vision
 {
min-height: calc(1080px - 400px);
}
}

@media screen and (min-width: 701px) and (max-width: 1200px) {
.section:not(#hero):not(#vision) {
min-height: auto !important;
}
}

.section:not(#hero) {
position: relative;
}

.section.white h1,
.section.white p {
color: #023cba ;
}
.section.white h1 {
letter-spacing: 1.1px;
font-family: "Londrina Solid";
}

.heading-3 {
font-size: 56px !important;
color: #023cba;
letter-spacing: 1.1px;
font-family: "Londrina Solid";
position: relative;
top:3rem;
}

.get-started-with {
position: relative;
font-size: 16px;
letter-spacing: 0.14px;
line-height: 26px;
font-weight: 500;
text-align: center;
}

.heading-3-legendary-parent {
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
gap: 29px;
}



.div1 {
position: relative;
letter-spacing: 0.14px;
line-height: 16px;
font-weight: 600;
color: #fff;
}

.wrapper {
position: absolute;
top: 61px;
left: 0px;
border-radius: 999px;
background-color: #000000;
width: 64px;
height: 64px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 10px;
box-sizing: border-box;
}

.frame-item {
position: absolute;
top: 0px;
left: 32px;
max-height: 100%;
width: 100%;
}

.group-parent {
width: 64px;
position: relative;
height: 125px;
z-index: 1;
}

.install-a-chrome {
width: 206px;
position: relative;
font-size: 16px;
line-height: 26px;
display: flex;
color: #fff;
align-items: center;
justify-content: center;
text-align: center;
}

.step-1-parent-memecoin {
width: 226px;
border-radius: 16px;

height: 220px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
padding: 44px 20px;
box-sizing: border-box;
gap: 28px;
z-index: 0;
margin-top: -28px;
font-size: 20px;
}

.div {
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
position: relative;
top:-5rem
}

.mint-mlon-eusk {
margin: 0;
}

.mint-mlon-eusk-container1 {
width: 100%;
}

.mint-mlon-eusk-container {
width: 206px;
position: relative;
font-size: 16px;
line-height: 26px;
display: flex;
color: #631f1f;
align-items: center;
text-align: center;
}

.optional {
font-size: 14px;
}

.step-5-optional-container {
position: relative;
letter-spacing: 0.14px;
line-height: 26px;
}

.step-5-optional-parent {
width: 226px;
height: 220px;
border-radius: 16px;
background: linear-gradient(180deg, #023cba, #516faf);
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
padding: 44px 20px;
box-sizing: border-box;
gap: 28px;
z-index: 0;
margin-top: -28px;
font-size: 20px;
}

.parent {
display: flex;
flex-direction: row;
justify-content: center;
gap: 56px;
text-align: center;
font-size: 36px;
flex-wrap: wrap;
}

.frame-parent {
position: relative;
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-end;
gap: 87px;
text-align: left;
font-size: 48px;
color: #fff;
font-family: Montserrat;
}

@media (max-width: 528px) {
.heading-3 {
font-size: 24px;
line-height: 32px;
}

.step-1-parent,
.step-5-optional-parent {
padding: 20px;
font-size: 16px;
}

.install-a-chrome {
width: auto;
font-size: 14px;
}

.parent {
flex-direction: column;
gap: 20px;
}

.frame-child,
.frame-item {
position: relative;
left: 0;
}

.wrapper {
top: 0;
}
}

.white-logosvg-icon {
position: absolute;
top: 45px;
left:20px;
width: 180px;
height: 40px;
overflow: hidden;
}
.meme-was-made {
position: absolute;
top: 100px;
left: 20%;
line-height: 160%;
display: inline-block;
width: 60%;
}
.link-boomer-on-base-social-m {
position: absolute;
top: 48px;
right:290px;
width: 50px;
height: 50px;
}
.link-boomer-on-base-social-m1 {
position: absolute;
top: 48px;
right:230px;
width: 50px;
height: 50px;
}
.link-boomer-on-base-social-m2 {
position: absolute;
top: 48px;
right:170px;
width: 50px;
height: 50px;
}
.link-boomer-on-base-social-m3 {
position: absolute;
top: 48px;
right:110px;
width: 50px;
height: 50px;
}
.link-boomer-on-base-social-m4 {
position: absolute;
top: 48px;
right:55px;
width: 50px;
height: 50px;
}
.link-boomer-on-base-social-m5 {
position: absolute;
top: 48px;
right:5px;
width: 50px;
height: 50px;
}
.footer {
/* width: 100%; */
width: 100vw;
position: relative;

background-color: #023cba;
height: 300px;
/* text-align: center; */
font-size: 28px;
color: #fff;
font-family: "Londrina Solid";
justify-content: center;
align-items: center;
}

.frame-container {
padding: 5vw;
/* width: 100vw; */
/* background: url(../images/tokonmicsbg.svg); */
background-repeat: no-repeat;
width: 100%;
height: 100%;

color: white !important;
display: flex;
justify-content: center;
margin: auto;
margin-top: 40px !important;
align-items: center;
gap: 80px;
}
.token-grid {
display: grid;
grid-template-columns: 1fr 1fr;
grid-gap: 20px;
text-align: center;
}
.token-grid-item{

text-align: center;
font-weight: 500;
font-size: 24px;
}
.material-symbols-outlined{
font-size: 28px;
display: block;
text-align: center;
margin: auto;
}
.token-grid span,
.token-grid .textarea {
font-weight: 300;
margin-top: 15px;
display: inline-block;
width: 246px;
}



.textarea {
resize: none;

color: white;
font-size: 22px;
border-radius: 15px;
border: none;
max-width: 250px;
word-wrap: break-word;
}
.boomer-coin-text {
Max-width: 24vw;
margin: auto;
text-align: center;
padding: 80px 80px;
padding-bottom: 30px ! Important;
color: white ;
line-height: 1.3em;
margin-left:-50px;
}
.animate-number{
font-size: 24px;
font-weight: 600 !important;
}

.container {
width: 100%;
margin: 0 auto;
overflow: hidden;
padding: 10px;
}

  #google_translate_element {
    width: 300px;
    float: right;
    text-align: right;
    display: block;
}
.goog-te-banner-frame.skiptranslate {
    display: none !important;
}
body {
    top: 0px !important;
}
#goog-gt-tt {
    display: none !important;
    top: 0px !important;
}
.goog-tooltip skiptranslate {
    display: none !important;
    top: 0px !important;
}
.activity-root {
    display: hide !important;
}
.status-message {
    display: hide !important;
}
.started-activity-container {
    display: hide !important;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
    display: none;
}

.VIpgJd-yAWNEb-VIpgJd-fmcmS-sn54Q {
    display: none;
    background-color: unset !important;
    box-shadow: none !important;
}
     </style>
    </head>
    <body style="overflow-x:hidden;position: relative;width:10px">
         <div style="position: relative;width:100vw;">
        
              <nav id="navbar" class="menu-memecoin"  style=' background-image:  ${memebackground == 0 ? `${memenavbgcolor.length > 0 ? `url(${memenavbgcolor})` : `url(${Images1})`}` : ''};background-color: ${memebackground == 1 ? `${memenavbarbgcolor}` : ''};height:auto;width:100vw;object-fit:cover;background-size:cover;'>
                  <div class="menu-inner">
                      <div class="menu-item" >
                          <a aria-label="boomer-link" href="#" style=' font-family: ${memecoinanvfont}; color: ${memenavtextcolor} ;font-size:${memenavfontsize}px;'> ${Navbar[0] !== undefined ? Navbar[0] : ''}</a>
                      </div>
                      <div class="menu-item">
                          <a aria-label="boomer-link" href="#" style=' font-family: ${memecoinanvfont}; color: ${memenavtextcolor}; font-size:${memenavfontsize}px;'>${Navbar[1] !== undefined ? Navbar[1] : ''}</a>
                      </div>
                      <div class="menu-item logo">
                          <picture>
                              <img src=${memecoinlogoImage.length > 0 ? memecoinlogoImage : Images2} style=' width: 360px; height: 200px ' alt="boomer"  />
                          </picture>
                      </div>
                      <div class="menu-item">
                          <a aria-label="boomer-link" href="#" style=' font-family: ${memecoinanvfont}; color: ${memenavtextcolor};font-size:${memenavfontsize}px; '>${Navbar[2] !== undefined ? Navbar[2] : ''}</a>
                      </div>
                      <div class="menu-item">
                          <a aria-label="boomer-link" href="#" style=' font-family: ${memecoinanvfont}; color: ${memenavtextcolor};font-size:${memenavfontsize}px; ' >${Navbar[3] !== undefined ? Navbar[3] : ''}</a>
                      </div>
                          <div style="position: absolute; right: 20px; top: 5px; cursor: pointer;color:white">
    <div onclick="toggleGoogleTranslate()">EN</div>

    <div
        style="background-color: transparent; 
               padding: 0px 8px; 
               margin-right: 10px; 
               border-radius: 8px; 
               display: ${googletransalte == false ? 'none' : 'block'};"
        id="google_translate_element">
    </div>
</div>

                  </div>
                     
              </nav>
          </div>

               <div class='' class="section" id="hero" style='background-image:${memeherobackground == 0 ? `${memecoinaboutbgImage.length > 0 ? `url(${memecoinaboutbgImage})` : `url(${Images3})`}` : ""};height: auto; width: 100vw; object-fit: cover; background-size: cover;background-color:${memeherobackground == 1 ? `${memeherobgcolor}` : ""} ;'>
              <div class="section-inner">
                  <div class="hero-animation">
                      <div class="animation-wrapper" style="position:relative;margin-top:70px;">
                          <img src=${memecoinAminitionImage.length > 0 ? memecoinAminitionImage : Images4} style='  height: 400px; width: 350px; margin-top: 30 ,object-fit:'cover';background-size:'cover' ' alt=''></img>
                      </div>
                  </div>
                  <div class="header-container" style='background-image:${memecloudimage.length > 0 ? `url(${memecloudimage})` : `url(${NewmaskImage})`}'>
                      <h2 style=' color: ${memecoinabouttextcolor};font-family: ${memecoinaboutfont} ;font-size:${memeMainfontsize}px;'>${Caption1}</h2>
                      <h2 style=' color: ${memecoinabouttextcolor};font-family: ${memecoinaboutfont} ;font-size:${memeMainfontsize}px;'>${Caption2}</h2>

                      <h1 style=' color: ${memecoinabouttextcolor};font-family: ${memecoinaboutfont} ;font-size:${memeMainfontsize}px;'>${memecoinValue}</h1>
                      <h2 style=' color: ${memecoinabouttextcolor};font-family: ${memecoinaboutfont} ; font-size:${memeMainfontsize}px;'>${memecoinname}</h2>
                  </div>
                  <div class="boomer-cartoon boomer-cig">
                      <img src=${memecoinaboutrightimage.length > 0 ? memecoinaboutrightimage : Images6} style=' width: 350px;height:400px;object-fit:cover;background-size:cover' ' alt="" />
                  </div>
                  <div class="boomer-cartoon boomer-chair">
                      <img src=${memecoinaboutleftimage.length > 0 ? memecoinaboutleftimage : Images5} style=' width: 350px;height:400px;object-fit:cover;background-size:cover' alt="" />
                  </div>
                  <div class="button-container">
                      <a aria-label="boomer-link" href="" target="_blank" class="button button-primary" style=' color: ${memecoinabouttextcolor};font-family: ${memecoinaboutfont} ;font-size:${memeMainfontsize}px;' onclick="window.open('${template2cmc}')"><div>Buy $${memecoinsymbol}</div></a>
                      <a aria-label="boomer-link" href="" target="_blank" class="button button-secondary" style=' color: ${memecoinabouttextcolor};font-family: ${memecoinaboutfont} ;font-size:${memeMainfontsize}px;' onclick="window.open('${memetelegramlink}')"><div>Join us <img src=${Newtelegram} alt='T' width=20px; height=20px/></div></a>
                  </div>
              </div>
              <div class="arrow-memecoin">
              <a href="#vision"><img width=100% src=${arrow} alt="continue to next section for more information" /></a></div>
          </div>

           <div class="section blue " id="vision" style='margin-top: -0.5px ; background-color: ${memeaboutbackground == 0 ? `${memeaboutbgcolor.length == 0 ? '#0452ff' : `${memeaboutbgcolor}`}` : ''};background-image:${memeaboutbackground == 1 ? `${memeaboutbgimage.length !== 0 ? `url(${memeaboutbgimage})` : ''}` : ''};height:fit-content;width:100vw;object-fit:cover;background-size:cover;' tabindex="-1">
              <div class="section-inner">
                  <div class="header-container">
                      <h1 style='font-family:${memecoinvisionfont};color:${memecoinvisiontextcolor};font-size:${memeaboutfontSize}px;'>ABOUT</h1>
                      <p style='font-family:${memecoinvisionfont};color:${memecoinvisiontextcolor};font-size:${memeaboutfontSize}px;'>${memecoinAbout}</p>

                  </div>
                  <div class="boomer-cartoon boomer-cig" style=' top: 50% ; ' >
                     
                          <img src=${memecoinavisionrightimage.length > 0 ? memecoinavisionrightimage : Images7} style=' width: 350px ;marginTop: 50 ;height:350px ;object-fit:'cover';background-size:cover ' alt="" />
                          
                  </div>
                  <div class="boomer-cartoon boomer-chair" style=' top: 50% ; '>
                     
                          <img src=${memecoinavisionleftimage.length > 0 ? memecoinavisionleftimage : Images8} style=' width: 350px ;marginTop: 50 ;height:350px ;object-fit:'cover';background-size:cover  ' alt="" />

                  </div>


              </div>
          </div>

              
          <div id='token' class="section white" style=' margin-bottom:4rem;top:-60px;width:100vw ' >
          <span style='position:absolute;align-items:center;left:39%;top:0.5rem ;'>
          <div class="header-container">
                      <h1 style=' font-family: ${memecointokenfont}; color: ${memecointoketextcolor};font-size:${memetokenfontSize}px ;'>TOKENOMICS</h1>
                  </div>
          </span>

          
              <div class="section-inner">

                  
                  <div class="frame-container" style=' background-image:${memetokenomicsbackground == 0 ? `${memecointokonomicsbgimage.length > 0 ? `url(${memecointokonomicsbgimage})` : `url(${Images10})`}` : ''}; width: 100%; height: 100%;object-fit: cover;background-size: cover;background-color:${memetokenomicsbackground == 1 ? `${memetokenomicsbgcolor}` : ''};'  >
                 <div class="token-grid">

                       ${newaddtokest == 1 ? '' : newaddtokest.map((item, index) => (
            `<div key=${index}>
                            <div class="flex-column-center">
                                <img src="${newiconsImage.length > 0 ? newiconsImage : Contract}" alt="" style="height: 95px; width: 80px;" />
                                <div style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor};${memetokenfontSize}px">${item.title}</div>
                            </div>
                            <div class="textarea" aria-label="wallet address container" readonly style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor};${memetokenfontSize}px">
                                ${item.value}
                            </div>
                        </div>`
        )).join('')}
                               
                          ${iSremove1 ? '' :
                `<div>
                            <div class="flex-column-center">
                                <img src=${memecoinContractImage.length > 0 ? memecoinContractImage : Contract} alt="" style="height: 95px; width: 80px;" />
                                <div style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor};${memetokenfontSize}px;">${memecoincontractaddress.title}</div>
                            </div>
                            <div class="textarea" aria-label="wallet address container" readonly="" style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor};background-color:transparent;${memetokenfontSize}px;">${memecoincontractaddress.value}</div>
                        </div>`
            }




                          ${iSremove2 ? '' :
                `<div>
                  <div class="flex-column-center">
                      <img src="${memecoinDatabaseImage.length > 0 ? memecoinDatabaseImage : Database}" alt="" style="height: 95px; width: 80px;" />
                      <div style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor};${memetokenfontSize}px;">${memecoinInitialsupply.title}</div>
                  </div>
                  <span class="animate-number" style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor};${memetokenfontSize}px;">${memecoinInitialsupply.value}</span>
                              </div>`
            }




                          ${iSremove3 ? '' :
                `
              <div>
                  <div class="flex-column-center">
                      <img src="${memecoinCirculatingImage.length > 0 ? memecoinCirculatingImage : Circulating}" alt="" style="height: 95px; width: 80px;" />
                      <div style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor};${memetokenfontSize}px;">${memecoinCirculatingSupply.title}</div>
                  </div>
                  <span class="animate-number" style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor};${memetokenfontSize}px;">${memecoinCirculatingSupply.value}</span>
              </div>
                             `
            }


                          ${iSremove4 ? '' :
                `
              <div>
                  <div class="flex-column-center">
                      <img src="${memecoinBurnedImage.length > 0 ? memecoinBurnedImage : Burned}" alt="" style="height: 95px; width: 80px;" />
                      <div style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor};${memetokenfontSize}px;">${memecoinSupplyBurned.title}</div>
                  </div>
                  <span class="animate-number" style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor};${memetokenfontSize}px;">${memecoinSupplyBurned.value}</span>
                  
              </div>
                              `
            }



                          ${iSremove5 ? '' :
                `
              <div>
                  <div class="flex-column-center">
                      <img src="${memecoinLiquidityImage.length > 0 ? memecoinLiquidityImage : Liquidity}" alt="" style="height: 95px; width: 80px;" />
                      <div style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor};">${memecoinLiquidity.title}</div>
                  </div>
                  <span class="animate-number" style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor};${memetokenfontSize}px;">${memecoinLiquidity.value}</span>
                  <span class="animate-number" style="margin: 0px; padding: 0px !important; color: ${memecointoketextcolor};${memetokenfontSize}px;">%</span>
              </div>
                                `
            }



                          ${iSremove6 ? '' :
                `
              <div>
                  <div class="flex-column-center">
                      <img src="${memecoinRenouncedImage.length > 0 ? memecoinRenouncedImage : Renounced}" alt="" style="height: 95px; width: 80px;" />
                      <div style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor};${memetokenfontSize}px;">Contract Renounced</div>
                  </div>
              </div>
                             `
            }
                     </div>


                      <div style=' text-aglin: center '>
                          <div class="animated-rotate" style=' margin: auto '>
                              <img width='50%'  src=${memecointokenomicsimage.length > 0 ? memecointokenomicsimage : Images9} alt="illustration of boomer coin" />
                              <p class="boomer-coin-text" style='font-family:${memecointokenfont};color:${memecointoketextcolor};${memetokenfontSize}px' >${memecoinname} ${formattedText}</p>
                              <a href="#howtobuy" class="button button-secondary" style='font-family:${memecointokenfont};color:${memecointoketextcolor == 'white' ? 'blue' : `${memecointoketextcolor}`};${memetokenfontSize}px' onclick="window.open('${template2cmc}')">${memetokenbuy} ${memecoinsymbol}</a>

                          </div>
                      </div>
                  </div>

              </div>

              <div class="divider"></div>
          </div>

          <div class="frame-parent" id="howtobuy" style='background-image: ${memestepbackground == 0 ? `url(${memestepbgimage})` : ''};height:100%;width:100vw;background-size:cover;object-fit:cover;background-color:${memestepbackground == 1 ? `${memestepbgcolor}` : ""};margin-bottom:0px;margin-top:-9.5rem;'>

              <h2 class="heading-3" style='font-family:${memecoinstepfont};color:${memecoinsteptextcolor}';font-size:${memestepfontSize}px ;' >HOW TO BUY</h2>


              <div class="parent">
                  <div class="div">
                      <div class="group-parent">
                          <div class="wrapper">
                              <div class="div1" style='font-family:${memecoinstepfont};color:${memecoinsteptextcolor};font-size:${memestepfontSize}px' >1</div>
                          </div>
                          <img class="frame-item" alt="" src="Line 2.svg" />
                      </div>
                      <div class="step-1-parent-memecoin" style='background:linear-gradient(130deg, ${statsbackgroundcolor != 'white' ? statsbackgroundcolor : '#023cba'}, #516faf); border: ${statsborder}px solid ${statsbordercolor}; border-radius: ${statsborderradius}px; box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21 ` : ` 0px 19px 30px -10px rgba(21, 21, 25, 0.21)`}; '>
                          <b class="step-5-optional-container" style='font-family:${memecoinstepfont};color:${memecoinsteptextcolor};font-size:${memestepfontSize}px'>Step 1</b>
                          <b class="install-a-chrome" style='font-family:${memecoinstepfont};color:${memecoinsteptextcolor};font-size:${memestepfontSize}px'>
                              Install a Chrome Extension Wallet.
                          </b>
                      </div>
                  </div>
                  <div class="div">
                      <div class="group-parent">
                          <div class="wrapper">
                              <div class="div1" style='font-family:${memecoinstepfont};color:${memecoinsteptextcolor};font-size:${memestepfontSize}px'>2</div>
                          </div>
                      </div>
                      <div class="step-1-parent-memecoin" style='background:linear-gradient(130deg, ${statsbackgroundcolor != 'white' ? statsbackgroundcolor : '#023cba'}, #516faf); border: ${statsborder}px solid ${statsbordercolor}; border-radius: ${statsborderradius}px; box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21 ` : ` 0px 19px 30px -10px rgba(21, 21, 25, 0.21)`}; '>
                          <b class="step-5-optional-container" style='font-family:${memecoinstepfont};color:${memecoinsteptextcolor};font-size:${memestepfontSize}px'>Step 2</b>
                          <b class="install-a-chrome" style='font-family:${memecoinstepfont};color:${memecoinsteptextcolor};font-size:${memestepfontSize}px'>Set Up Your Wallet & Fund it with Bitcoin.</b>
                      </div>
                  </div>
                  <div class="div">
                      <div class="group-parent">
                          <div class="wrapper">
                              <div class="div1" style='font-family:${memecoinstepfont};color:${memecoinsteptextcolor};font-size:${memestepfontSize}px'>3</div>
                          </div>
                      </div>
                      <div class="step-1-parent-memecoin" style='background:linear-gradient(130deg, ${statsbackgroundcolor != 'white' ? statsbackgroundcolor : '#023cba'}, #516faf); border: ${statsborder}px solid ${statsbordercolor}; border-radius: ${statsborderradius}px; box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21 ` : ` 0px 19px 30px -10px rgba(21, 21, 25, 0.21)`}; '>
                          <b class="step-5-optional-container" style='font-family:${memecoinstepfont};color:${memecoinsteptextcolor};font-size:${memestepfontSize}px'>Step 3</b>
                          <b class="install-a-chrome" style='font-family:${memecoinstepfont};color:${memecoinsteptextcolor};font-size:${memestepfontSize}px'>
                              Locate ${memecoinname} on a Marketplace.
                          </b>
                      </div>
                  </div>
                  <div class="div">
                      <div class="group-parent">
                          <div class="wrapper">
                              <div class="div1" style='font-family:${memecoinstepfont};color:${memecoinsteptextcolor};font-size:${memestepfontSize}px'>4</div>
                          </div>
                      </div>
                      <div class="step-1-parent-memecoin" style='background:linear-gradient(130deg, ${statsbackgroundcolor != 'white' ? statsbackgroundcolor : '#023cba'}, #516faf); border: ${statsborder}px solid ${statsbordercolor}; border-radius: ${statsborderradius}px; box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21 ` : ` 0px 19px 30px -10px rgba(21, 21, 25, 0.21)`}; '>
                          <b class="step-5-optional-container" style='font-family:${memecoinstepfont};color:${memecoinsteptextcolor};font-size:${memestepfontSize}px'>Step 4</b>
                          <b class="mint-mlon-eusk-container">
                              <span class="mint-mlon-eusk-container1">
                                  <p class="install-a-chrome" style='font-family:${memecoinstepfont};color:${memecoinsteptextcolor};font-size:${memestepfontSize}px'>Mint ${memecoinname}</p>
                                  <p class="install-a-chrome" style='font-family:${memecoinstepfont};color:${memecoinsteptextcolor};font-size:${memestepfontSize}px'>${memecoinsymbol}</p>
                              </span>
                          </b>
                      </div>
                  </div>

              </div>
          </div>

          <div class="footer"  style='background-image:${memefootrbackground == 1 ? `${memefooterbgimage.length !== 0 ? `url(${memefooterbgimage})` : ''}` : ''};object-fit:cover;background-size:cover;background-color: ${memefootrbackground == 0 ? `${memefooterbgcolor.length == 0 ? '#0452ff' : memefooterbgcolor}` : ''};'>
              <div class="container">
                  <span class="white-logosvg-icon" style='color:${memefootertextcolor};font-size:${memefooterfontSize}px;font-family:${memefootertextfont};'>$${memecoinsymbol}</span>
                  <div class="meme-was-made"  style='color:${memefootertextcolor};font-family:${memefootertextfont};font-size:${memefooterfontSize}px'>$${memecoinsymbol}${memefooterabout}</div>
              </div>
              <img class="link-boomer-on-base-social-m" alt="" src=${twitter} style="cursor: pointer;" onclick="window.open('${memetwitterlink}')" />
              <img class="link-boomer-on-base-social-m1" alt="" src=${telegram} style="cursor: pointer;" onclick="window.open('${memetelegramlink}')"/>
              <img class="link-boomer-on-base-social-m2" alt="" src=${youtube}   style="cursor: pointer;" onclick="window.open('${memeYoutubelink}')"/>
              <img class="link-boomer-on-base-social-m3" alt="" src=${tiktok}   style="cursor: pointer;" onclick="window.open('${memetiktoklink}')"/>
              <img class="link-boomer-on-base-social-m4" alt="" src=${instagram}   style="cursor: pointer;" onclick="window.open('${memeinstagramlink}')"/>
              <img class="link-boomer-on-base-social-m5" alt="" src=${warpcast}   style="cursor: pointer;" onclick="window.open('${memewarpcastlink}')"/>
          </div>
      </div>
   
         <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
                          
        
          </body>
           <script>
    let googletransalte = ${googletransalte};

    function toggleGoogleTranslate() {
        googletransalte = !googletransalte;
        document.getElementById('google_translate_element').style.display = googletransalte ? 'block' : 'none';
    }
</script>
      <script type="text/javascript">
        function googleTranslateElementInit() {
            new google.translate.TranslateElement(
                { pageLanguage: 'en' },
                'google_translate_element'  
            );
        }
    </script>
    <script
        type="text/javascript"
        src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    ></script>


    </html>`



        const NewmemeTemplate1 = `
     <html>
     <head>
     <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
     <meta name="viewport" content="width=device-width, initial-scale=1.0">
     <meta http-equiv="X-UA-Compatible" content="ie=edge">
     <title>${memecoinname}</title>
     <style>
     
    .navbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100px;
    background-color: #7b42cc;
    color: white;
    width: 100%;
}
.navbar a {
    color: white;
    text-decoration: none;
    padding: 0 10px;
    font-size: 16px;
}
.navbar a:hover {
    text-decoration: underline;
}

.container {
   
    text-align: center;
    background-color: #7b42cc;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.containerDiv1 {
   
    text-align: center;
   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
}

.containerDiv2 {
   
    text-align: center;
   
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
}
.container h1 {
    font-size: 2.5rem;
}
.container p {
    font-size: 1.1rem;
    margin: 10px 0;
}

.telegrambutton {
    margin-top: 20px;
}
.telegrambutton button {
    background-color: #e4e4f1;
    border: none;
    padding: 12px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 8px;
    margin-right: 10px;
}
.telegrambutton button:hover {
    background-color: #ccc;
}

.heroimage {
    width: 100%;
    max-width: 400px;
    margin: 20px auto;
}

.sectionred {
    position: relative;
    background-color: #8e3134;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
   
    flex-direction: row;
}

.sectionredDiv1 {
    /* background-color: #8e3134; */
    width: 50%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    position: relative;
    padding: 20px;
    
}

.sectionredDiv2 {
    /* background-color: #8e3134; */
    width: 50%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 30px;
}
.sectionred h1 {
    font-size: 2rem;
}
.sectionred p {
    font-size: 1rem;
    line-height: 1.5;
    max-width: 800px;
    margin: 0 auto;
}

.tokenomics {
    position: relative;
    width: 100%;
    height: 100%;
    /* background-color: #7b42cc; */
    text-align: center;
}
.tokenomics h2 {
    font-size: 2rem;
    margin-bottom: 10px;
}
.tokenomics p {
    font-size: 1.1rem;
}

.stats {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 20px;
    margin-top: 30px;
    width: 100%;
    align-items: center;
}
.stats div {
    width: 30%;
    text-align: center;
}
.stats img {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
}
.stats h3 {
    font-size: 1.2rem;
}
.stats p {
    font-size: 1rem;
}

.socials {

    text-align: center;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    
   
}

.socialsDiv1 {
    
    text-align: center;
    position: relative;
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 20px;
    padding: 20px;
    margin-top:30px;
}

.socialsDiv2 {
     display: flex;
     align-items: center;
     justify-content: center;
    height: 50%;
   
    position: relative;
    width: 100%;
    
   
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
   margin:35px;
}
.socials img {
    width: 60px;
    height: 60px;
    margin: 15px;
}
.contract {
    margin-top: 20px;
    background-color: #ffffff;
    color: #8e3134;
    padding: 10px;
    font-size: 1.1rem;
    border-radius: 5px;
    text-align: center;
   
   
    width: 50%;
    height: 100%;
}

.footer {
   position: relative;
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
    background-color: #7b42cc;
    text-align: center;
    font-size: 0.9rem;
    padding-top:10%;
}

.footerdiv1 {
   position: relative;
   width: 50%;
   height: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
    text-align: center;
    font-size: 0.9rem;
    padding: 20px;
}
.footerdiv2 {
   position: relative;
   width: 100%;
   height: 100%;
   display: flex;
  
   align-items: center;
   justify-content: center;
    
    text-align: center;
    font-size: 0.9rem;
}
.footer p {
    margin: 10px 0;
}

/* Responsive Styles */
@media only screen and (max-width: 768px) {
    .stats div {
        width: 45%;
    }
    .navbar a {
        font-size: 14px;
        padding: 0 5px;
    }
    .container h1 {
        font-size: 2rem;
    }
    .container p {
        font-size: 1rem;
    }
    .sectionred h1 {
        font-size: 1.8rem;
    }
    .sectionred p {
        font-size: 0.9rem;
    }
    .tokenomics h2 {
        font-size: 1.8rem;
    }
    .tokenomics p {
        font-size: 1rem;
    }
    .container {
   
        text-align: center;
        background-color: #7b42cc;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding-top:15%;
        position: relative;
    }
    .containerDiv2{
        width: 100%;
    }
    .containerDiv1{
        width: 100%;
    }
    .sectionred{
        flex-direction: column;
    }
    
    
    .stats{
        flex-direction: column;
    }
    .socialsDiv1{
        flex-direction: column;
        padding-top:80px;
    }
    
    
    .footerdiv2{
        width: 100%;
    }
}

@media only screen and (max-width: 480px) {
    .title{
      font-size: 20px;
    }
    .stats div {
        width: 100%;
    }
    .navbar {
        display: flex;
        font-size: 5px;
        
    }
    .container h1 {
        font-size: 1.5rem;
    }
    .container p {
        font-size: 0.9rem;
    }
    .telegram-button button {
        padding: 10px 15px;
        font-size: 0.9rem;
    }
    .heroimage {
        max-width: 100%;
    }
    .sectionred h1 {
        font-size: 1.5rem;
    }
    .sectionred p {
        font-size: 0.9rem;
    }
    .socials img {
        width: 50px;
        height: 50px;
    }
        .footer {
   position: relative;
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
    background-color: #7b42cc;
    text-align: center;
    font-size: 0.9rem;
    padding-top:10%;
}
}

@media only screen and (max-width: 320px) {
   .navbar {
        display: flex;
        font-size: 5px;
        flex-direction: column;
        
    }
}
    @media only screen and (max-width:440px){
       .socialsDiv2 {
     display: flex;
     align-items: center;
     justify-content: center;
    height: 50%;
   
    position: relative;
    width: 100%;
    
   
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
   margin:35px;
   line-break:anywhere;
}
    }

 #google_translate_element {
    width: 300px;
    float: right;
    text-align: right;
    display: block;
}
.goog-te-banner-frame.skiptranslate {
    display: none !important;
}
body {
    top: 0px !important;
}
#goog-gt-tt {
    display: none !important;
    top: 0px !important;
}
.goog-tooltip skiptranslate {
    display: none !important;
    top: 0px !important;
}
.activity-root {
    display: hide !important;
}
.status-message {
    display: hide !important;
}
.started-activity-container {
    display: hide !important;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
    display: none;
}

.VIpgJd-yAWNEb-VIpgJd-fmcmS-sn54Q {
    display: none;
    background-color: unset !important;
    box-shadow: none !important;
}


     </style>
     <body >
         <div style='position:relative;width:100%;height:100%;display:flex;flex-direction:column;overflow-x:hidden'>
         <div style="position: absolute; right: 0px; top: 5px; cursor: pointer;color:white;display:flex;flex-direction:column;align-items:center">
    <div onclick="toggleGoogleTranslate()">EN</div>

    <div
        style="background-color: transparent; 
               padding: 0px 8px; 
               margin-right: 10px; 
               border-radius: 8px; 
               display: ${googletransalte == false ? 'none' : 'block'};"
        id="google_translate_element">
    </div>
</div>

    <div id="Navbar" class="navbar" style="background-image: ${memebackground === 0 ? (memenavbgcolor.length > 0 ? `url(${memenavbgcolor})` : '') : ''}; object-fit: cover; background-size: cover; background-color: ${memebackground === 1 ? memenavbarbgcolor : (memenavbgcolor.length === 0 ? '#7b42cc' : '')};padding-top:100px;padding-bottom: 30px;">
     <a href="#" style="color: white; text-decoration: none; font-family: ${memecoinanvfont}; color: ${memenavtextcolor}; font-size: ${memenavfontsize}px;">${Navbar[0]}</a>
    <a href="#" style="color: white; text-decoration: none; font-family: ${memecoinanvfont}; color: ${memenavtextcolor}; font-size: ${memenavfontsize}px;">${Navbar[1]}</a>
    <h1 class="title" style="color: white; font-family: ${memecoinanvfont}; ">${memecoinname}</h1>
    <a href="#" style="color: white; text-decoration: none; font-family: ${memecoinanvfont}; color: ${memenavtextcolor}; font-size: ${memenavfontsize}px;">${Navbar[2]}</a>
    <a href="#" style="color: white; text-decoration: none; font-family: ${memecoinanvfont}; color: ${memenavtextcolor}; font-size: ${memenavfontsize}px;">${Navbar[3]}</a>
  </div>

  <div id="Hero" class="container" style="background-image: ${memeherobackground == 0 ? (memecoinaboutbgImage.length > 0 ? `url(${memecoinaboutbgImage})` : '') : ''}; background-color: ${memeherobackground == 1 ? memeherobgcolor : (memecoinaboutbgImage.length > 0 ? '' : '#7b42cc')}; background-size: cover; background-repeat: no-repeat;">
    <div class="containerDiv1">
        <h1 style="color: ${memecoinabouttextcolor.length > 0 ? memecoinabouttextcolor : 'white'}; font-family: ${memecoinaboutfont}; font-size: ${memeMainfontsize}px;">${template1caption} ${memecoinsymbol}</h1>
        <p style="color: ${memecoinabouttextcolor.length > 0 ? memecoinabouttextcolor : 'white'}; font-family: ${memecoinaboutfont}; font-size: ${memeMainfontsize}px;">${template1Info}</p>
        <div class="telegrambutton">
            <button style="margin-right: 10px; color: ${memecoinabouttextcolor}; font-family: ${memecoinaboutfont}; font-size: ${memeMainfontsize}px;" onclick="window.open('${memetelegramlink}')">Telegram</button>
            <button style="color: ${memecoinabouttextcolor}; font-family: ${memecoinaboutfont}; font-size: ${memeMainfontsize}px;" onclick="window.open('${memetwitterlink}')">X</button>
        </div>
    </div>
    <div class="containerDiv2">
        <img class="hero-image" src="${memecoinaboutrightimage.length > 0 ? memecoinaboutrightimage : Template1mainimage}" alt="Molly Image" style="width: 100%; max-width: 400px; margin: 20px auto;" />
    </div>
</div>
  <div id="Vision" class="sectionred" style="background-color: ${memeaboutbackground == 0 ? (memeaboutbgcolor.length == 0 ? '#8e3134' : memeaboutbgcolor) : ''}; background-image: ${memeaboutbackground == 1 ? (memeaboutbgimage.length !== 0 ? `url(${memeaboutbgimage})` : '') : ''}; width: 100%; height: 100%;padding-top:30px;">
    <div class="sectionredDiv1">
        <h1 style="font-family: ${memecoinvisionfont}; color: ${memecoinvisiontextcolor.length > 0 ? memecoinvisiontextcolor : 'white'}; font-size: ${memeaboutfontSize}px;">${memecoinsymbol}</h1>
        <p style="font-family: ${memecoinvisionfont}; color: ${memecoinvisiontextcolor.length > 0 ? memecoinvisiontextcolor : 'white'}; font-size: ${memeaboutfontSize}px;">
            ${memecoinAbout}
        </p>
    </div>
    <div class="sectionredDiv2">
        <img class="heroimage" src="${memecoinavisionrightimage.length > 0 ? memecoinavisionrightimage : Template1Solly}" alt="Molly Image" style="width: 100%; max-width: 400px; margin: 20px auto;" />
    </div>
</div>

<div id="Token" class="tokenomics" style="background-image: ${memetokenomicsbackground == 0 ? (memecointokonomicsbgimage.length > 0 ? `url(${memecointokonomicsbgimage})` : '') : ''}; object-fit: cover; background-size: cover; background-color: ${memetokenomicsbackground == 1 ? memetokenomicsbgcolor : (memecointokonomicsbgimage.length === 0 ? '#7b42cc' : '')};width: 100%;height: fit-content;">
    <h2 style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor.length > 0 ? memecointoketextcolor : 'white'}; font-size: ${memestepfontSize}px;">${template1tokenomics}</h2>
    <p style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor.length > 0 ? memecointoketextcolor : 'white'}; font-size: ${memestepfontSize}px;">${template1tokenomicssub1}</p>
    <div class="stats">
        <div style="text-align: center;">
            <img src="${memecointokenomicsimage.length > 0 ? memecointokenomicsimage : Tokenomic1}" alt="Supply Image" style="width: 200px; height: 200px; margin-bottom: 10px;" />
            <h3 style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor.length > 0 ? memecointoketextcolor : 'white'}; font-size: ${memestepfontSize}px;">${template1tokenomisupply.label}</h3>
            <p style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor.length > 0 ? memecointoketextcolor : 'white'}; font-size: ${memestepfontSize}px;">${template1tokenomisupply.value}</p>
        </div>
        <div style="text-align: center;">
            <img src="${template1tokenimage2.length > 0 ? template1tokenimage2 : Tokenomics2}" alt="Contract Image" style="width: 200px; height: 200px; margin-bottom: 10px;" />
            <h3 style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor.length > 0 ? memecointoketextcolor : 'white'}; font-size: ${memestepfontSize}px;">${template1tokenomicontract.label}</h3>
            <p style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor.length > 0 ? memecointoketextcolor : 'white'}; font-size: ${memestepfontSize}px;">${template1tokenomicontract.value}</p>
        </div>
        <div style="text-align: center;">
            <img src="${template1tokenimage3.length > 0 ? template1tokenimage3 : Tokenomics3}" alt="Tax Image" style="width: 200px; height: 200px; margin-bottom: 10px;" />
            <h3 style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor.length > 0 ? memecointoketextcolor : 'white'}; font-size: ${memestepfontSize}px;">${template1tokenomiTax.label}</h3>
            <p style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor.length > 0 ? memecointoketextcolor : 'white'}; font-size: ${memestepfontSize}px;">${template1tokenomiTax.value}</p>
        </div>
    </div>
    <div>
        <h1 style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor.length > 0 ? memecointoketextcolor : 'white'}; font-size: ${memestepfontSize}px;">${template1tokenomicssub2}</h1>
        <p style="font-family: ${memecointokenfont}; color: ${memecointoketextcolor.length > 0 ? memecointoketextcolor : 'white'}; font-size: ${memestepfontSize}px;">${template1tokenomicssub3}</p>
    </div>
</div>
  
  <div id="HowTOBuy" class="socials" style="background-image: ${memestepbackground == 0 ? `url(${memestepbgimage})` : ''}; background-size: cover; object-fit: cover; background-color: ${memestepbackground == 1 ? memestepbgcolor : (memestepbgimage.length === 0 ? '#8e3134' : '')}; ">
    <div class="socialsDiv1">
        <div style="padding: 20px; background-color: white; border-radius: 5px;">
            <img src="${ContractImage1}" alt="Telegram" style="width: 100px; height: 100px; margin: 15px; cursor:pointer;"  onclick="window.open('${memetelegramlink}')"/>
        </div>
        <div style=" padding: 20px; background-color: white; border-radius: 5px;">
            <img src="${ContractImage2}" alt="Telegram" style="width: 100px; height: 100px; margin: 15px; cursor:pointer;"  onclick="window.open('${template2cmc}')"/>
        </div>
        <div style=" padding: 20px; background-color: white; border-radius: 5px;">
            <img src="${ContractImage3}" alt="Telegram" style="width: 100px; height: 100px; margin: 15px; cursor:pointer;"  onclick="window.open('${template2uniswap}')"/>
        </div>
    </div>
    <div class="socialsDiv2" >
       <div style="background-color: #ffffff; color: ${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#8e3134'};  font-size: 1.1rem; border-radius: 5px; margin-top: 20px; font-family: ${memecoinstepfont}; height: 100px;width: fit-content; display: flex; align-items: center; justify-content: center;"> ${template1stepcontract} ${template1stepAdd}</div>
    </div>
</div>

        <div id="Footer" class="footer" style=" background-image: ${memefootrbackground == 1 ? (memefooterbgimage.length !== 0 ? `url(${memefooterbgimage})` : '') : ''}; object-fit: cover; background-size: cover; background-color: ${memefootrbackground == 0 ? (memefooterbgcolor.length == 0 ? '#7b42cc' : memefooterbgcolor) : ''};">
    <div class="footerdiv1">
        <p style="color: ${memefootertextcolor.length > 0 ? memefootertextcolor : 'white'}; font-size: ${memefooterfontSize}px; font-family: ${memefootertextfont};">${memecoinsymbol}</p>
        <p style="color: ${memefootertextcolor.length > 0 ? memefootertextcolor : 'white'}; font-size: ${memefooterfontSize}px; font-family: ${memefootertextfont};">${template1footerabout}</p>
        <p style="color: ${memefootertextcolor.length > 0 ? memefootertextcolor : 'white'}; font-size: ${memefooterfontSize}px; font-family: ${memefootertextfont};">${memecoinsymbol} ALL RIGHTS RESERVED</p>
    </div>
    <div class="footerdiv2">
        <img src="${template1footerImage.length > 0 ? template1footerImage : Template1Footer}" alt="Logo"  height="90%" width="70%" />
    </div>
</div>


         </div>
     </body>
     <script>
    let googletransalte = ${googletransalte};

    function toggleGoogleTranslate() {
        googletransalte = !googletransalte;
        document.getElementById('google_translate_element').style.display = googletransalte ? 'block' : 'none';
    }
</script>
      <script type="text/javascript">
        function googleTranslateElementInit() {
            new google.translate.TranslateElement(
                { pageLanguage: 'en' },
                'google_translate_element'  
            );
        }
    </script>
     <script
        type="text/javascript"
        src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    ></script>
     </html>`




        const NewmemeTemplate2 = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
     <title>${memecoinsymbol}</title>
        <meta charset="UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>${memecoinsymbol}</title>
    </head>
    <style>
       @import url('https://fonts.googleapis.com/css2?family=Itim:wght@400&display=swap');
body {
    margin: 0;
    line-height: normal;
}

.image207svgFillIcon {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 900px;
    overflow: hidden;
    object-fit: cover;
}
.logopngIcon {
    width: 50px;
    height: 60px;
    overflow: hidden;
    object-fit: cover;
}
.heading1 {
    display: flex;
    align-items: center;
    width: 307.5px;
    height: 42px;
    text-shadow: 2px 0 0 #000, 0 2px 0 #000, -2px 0 0 #000, 0 -2px 0 #000;
}
.link {
    width: 372.2px;
    height: 70px;
}
.homeLinkText {
    line-height: 30px;
    display: flex;
    align-items: center;
    width: 50.8px;
    height: 30px;
}
.itemLink {
    width: 50.4px;
    height: 30px;
}
.aboutLinkText {
    line-height: 30px;
    display: flex;
    align-items: center;
    width: 52.2px;
    height: 30px;
}
.itemLink1 {
    left: 102.41px;
    width: 51.8px;
    height: 30px;
}
.tokenomicsLinkText {
    line-height: 30px;
    display: flex;
    align-items: center;
    width: 100.7px;
    height: 30px;
}
.itemLink2 {
    left: 206.25px;
    width: 100.4px;
    height: 30px;
}
.howToBuy {
    line-height: 30px;
    display: flex;
    align-items: center;
    width: 102px;
    height: 30px;
}
.itemLink3 {
    left: 358.64px;
    width: 101.7px;
    height: 30px;
}
.list {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 460.3px;
    height: 30px;
    font-size: 20px;
    color: #000;
}
.linkIcon {
    width: 40px;
    height: 40px;
    object-fit: cover;
}
.linkIcon1 {
    width: 40px;
    height: 36.8px;
    object-fit: cover;
}
.nav {
    height: 86px;
}

.backgroundbordershadow {
    box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.8);
    border-radius: 8px;
    background-color: #fff;
    border: 2px solid #000;
    box-sizing: border-box;
    height: 90px;

    font-size: 35px;
    margin: 10px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.heading11 {
    display: flex;
    align-items: center;
    width: 268.5px;

    text-shadow: 2px 0 0 #000, 0 2px 0 #000, -2px 0 0 #000, 0 -2px 0 #000;
    font-size: 37px;
    margin-top: 94px;
    margin-left: 101px;
    font-size: 53px;
}
.heading6 {
   font-size: 32px;
    display: flex;
    align-items: center;
    
    margin-left: 103px; 
    text-shadow: 1.5px 0 0 #000, 0 1.5px 0 #000, -1.5px 0 0 #000,
        0 -1.5px 0 #000;
    margin-bottom: -35px;
}
.logopngIcon1 {
    width: 358px;
    height: 391px;
    margin-left: -61px;
    overflow: hidden;
    object-fit: cover;
}
.container {
    position: absolute;
    height: calc(100% - 318px);
    width: calc(100% - 855px);
    top: 236px;
    right: 720px;
    bottom: 82px;
    left: 135px;
    text-align: left;
    font-size: 72px;
}
.g {
    color: #ffcd4e;
}
.welcomeTextTxtContainer {
    width: 100%;
}
.welcomeText {
    margin-top: 100px;
    font-size: 35px;
    text-shadow: 1.5px 0 0 #000, 0 1.5px 0 #000, -1.5px 0 0 #000,
        0 -1.5px 0 #000;
}
.on {
    color: #fff;
}
.andrewTatesDogContainer {
    font-size: 30px;
    text-shadow: 1.5px 0 0 #000, 0 1.5px 0 #000, -1.5px 0 0 #000,
        0 -1.5px 0 #000;
    color: #ffcd4e;
}
.ethereumDeclaration {
    font-size: 30px;
    text-shadow: 1.5px 0 0 #000, 0 1.5px 0 #000, -1.5px 0 0 #000,
        0 -1.5px 0 #000;
}
.memeCoinIntroductionContainer {
    font-size: 30px;
    margin-top: 14px;
    text-shadow: 1.5px 0 0 #000, 0 1.5px 0 #000, -1.5px 0 0 #000,
        0 -1.5px 0 #000;
}
.marketsTailText {
    font-size: 30px;
    margin-top: 14px;
    text-shadow: 1.5px 0 0 #000, 0 1.5px 0 #000, -1.5px 0 0 #000,
        0 -1.5px 0 #000;
}
.heading3 {
    position: absolute;
    width: calc(100% - 885px);
    top: 236px;
    right: 150px;
    left: 735px;
    height: 288px;
    text-align: left;
    font-size: 29px;
}
.buyLinkText {
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top:30%;
    height: 24px;
}
.link1 {
    box-shadow: 6px 6px 0px #000;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #000;
    box-sizing: border-box;
    width: fit-content;
    height: 45px;
    color: #000;
    display: flex;
    /* align-items: center;
    justify-content: center; */
}
.communityLinkText {
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 129.4px;
    height: 24px;
}
.link2 {
    box-shadow: 6px 6px 0px #000;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #000;
    box-sizing: border-box;
    width: 163px;
    height: 45px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
}
.contractLabel {
    display: flex;
    align-items: center;
    width: 49.4px;
    height: 34px;
    text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
        0 -1px 0 #181818;
}
.contractAddress {
    display: flex;
    align-items: center;
    width: 635.6px;
    height: 34px;
    text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
        0 -1px 0 #181818;
}
.heading31 {
    margin-top: 30px;
    height: 68px;
    text-align: left;
    font-size: 28px;
}
.uniswapLinkText {
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 58.5px;
    height: 24px;
}
.unipngIcon {
    width: 34px;
    height: 34px;
    overflow: hidden;
    object-fit: cover;
}
.link3 {
    box-shadow: 6px 6px 0px #8c3483;
    border-radius: 5px;
    background-color: #ff94f4;
    border: 1px solid #000;
    box-sizing: border-box;
    width: 128.1px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cmcLinkText {
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33.8px;
    height: 24px;
}
.mpngIcon {
    width: 56px;
    height: 56px;
    overflow: hidden;
    object-fit: cover;
}
.link4 {
    box-shadow: 6px 6px 0px #1a48b5;
    border-radius: 5px;
    background-color: #2b6aff;
    border: 1px solid #000;
    box-sizing: border-box;
    width: 125.5px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.coingeckoLinkText {
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 74.4px;
    height: 24px;
}
.gecopngIcon {
    width: 35px;
    height: 34px;
    overflow: hidden;
    object-fit: cover;
}
.link5 {
    box-shadow: 6px 6px 0px #507125;
    border-radius: 5px;
    background-color: #8cc63f;
    border: 1px solid #000;
    box-sizing: border-box;
    width: 145.1px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.section {
    align-self: stretch;
    position: relative;
    height: 900px;
    width: 100%;
    overflow: hidden;
    flex-shrink: 0;
    background-repeat: no-repeat;
}
.heading12 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 233.1px;
    height: 86px;
    text-shadow: 2px 0 0 #000, 0 2px 0 #000, -2px 0 0 #000, 0 -2px 0 #000;
    /* margin-top: 60px; */
    margin-left: 192px;
}
.memeIntroTextContainer {
    display: flex;
    align-items: center;
    width: 470.1px;
    height: 42px;
    text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
        0 -1px 0 #181818;
    color: #ffcd4e;
    font-size: 30px;
    margin-top: 99px;
    margin-left: 55px;
}
.memeCulture {
    display: flex;
    align-items: center;
    width: 445.5px;
    height: 42px;
    text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
        0 -1px 0 #181818;
}
.tequilaHumorTextContainer {
    position: absolute;
    top: 84px;
    left: 0px;
    display: flex;
    align-items: center;
    width: 484.7px;
    height: 42px;
    text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
        0 -1px 0 #181818;
}
.haveAGood {
    position: absolute;
    top: 126px;
    left: 0px;
    display: flex;
    align-items: center;
    width: 513.9px;
    height: 42px;
    text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
        0 -1px 0 #181818;
}
.partyText {
    position: absolute;
    top: 168px;
    left: 0px;
    display: flex;
    align-items: center;
    width: 499.8px;
    height: 42px;
    text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
        0 -1px 0 #181818;
}
.memeCultureText {
    position: absolute;
    top: 210px;
    left: 0px;
    display: flex;
    align-items: center;
    width: 51.1px;
    height: 42px;
    text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
        0 -1px 0 #181818;
}
.heading32 {
    position: absolute;
    width: calc(100% - 30px);
    top: 94px;
    right: 15px;
    left: 15px;
    height: 252px;
    text-align: left;
    font-size: 35px;
}
.link6 {
    box-shadow: 6px 6px 0px #000;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #000;
    box-sizing: border-box;
    width: 86.8px;
    height: 45px;
    font-size: 16px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.logopngIcon2 {
    width: 50px;
    height: 50px;
    overflow: hidden;
    object-fit: cover;
    margin-left: 20px;
}
.container1 {
    position: absolute;
    top: calc(50% - 248.5px);
    left: calc(50% - 570px);
    width: 570px;
    height: 497px;
}

.logopngIconSect3 {
    width: 427px;
    height: 427px;
    overflow: hidden;
    object-fit: cover;
    margin-top: 13px;
    margin-left: 30px;
}
.logopngIcon3 {
    width: 427px;
    height: 427px;
    overflow: hidden;
    object-fit: cover;
    margin-top: 160px;
    margin-left: 30px;
}
.section1 {
    align-self: stretch;
    position: relative;
    height: 777px;
    flex-shrink: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    font-size: 72px;
}
.heading13 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 361.7px;
    height: 86px;
    text-shadow: 2px 0 0 #000, 0 2px 0 #000, -2px 0 0 #000, 0 -2px 0 #000;
    font-size: 40px;
}
.logopngIcon4 {
    width: 50px;
    height: 50px;
    overflow: hidden;
    object-fit: cover;
}
.logopngIcon5 {
    position: absolute;
    top: calc(50% - 159.5px);
    left: 15px;
    width: 437px;
    height: 437px;
    overflow: hidden;
    object-fit: cover;
}
.tokenSupply {
    display: flex;
    align-items: center;

    height: 48px;
    text-shadow: 1.5px 0 0 #000, 0 1.5px 0 #000, -1.5px 0 0 #000,
        0 -1.5px 0 #000;
}
.supplyStatement {
    display: flex;
    align-items: center;
    width: 506.9px;
    height: 48px;
    text-shadow: 1.5px 0 0 #000, 0 1.5px 0 #000, -1.5px 0 0 #000,
        0 -1.5px 0 #000;
}
.heading33 {
    /* background-color: #ea9a46; */
    width: 528.9px;
    height: 118.4px;
    margin-top: 29px;
    font-size: 26px;
}
.taxPromo {
    display: flex;
    align-items: center;
    width: 132.5px;
    height: 48px;
    text-shadow: 1.5px 0 0 #000, 0 1.5px 0 #000, -1.5px 0 0 #000,
        0 -1.5px 0 #000;
}
.heading34 {
    /* background-color: #ea9a46; */
    width: 154.6px;
    height: 70.4px;
}
.burntLpPromo {
    display: flex;
    align-items: center;
    width: 178.7px;
    height: 48px;
    text-shadow: 1.5px 0 0 #000, 0 1.5px 0 #000, -1.5px 0 0 #000,
        0 -1.5px 0 #000;
}
.heading35 {
    /* background-color: #ea9a46; */
    width: 200.8px;
    height: 70.4px;
}
.memesPromo {
    display: flex;
    align-items: center;
    width: 235.3px;
    height: 48px;
    text-shadow: 1.5px 0 0 #000, 0 1.5px 0 #000, -1.5px 0 0 #000,
        0 -1.5px 0 #000;
}
.heading36 {
    /* background-color: #ea9a46; */
    width: 257.3px;
    height: 70.4px;
}
.joinLinkText {
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 104.4px;
    height: 24px;
}
.tgpngIcon {
    width: 20px;
    height: 20px;
    overflow: hidden;
    object-fit: cover;
}
.link7 {
    box-shadow: 6px 6px 0px #000;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #000;
    box-sizing: border-box;
    width: 163.1px;
    height: 45px;
    text-align: center;
    font-size: 16px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 40px;
}

.l3 {
    width: 50px;
    height: 50px;
    overflow: hidden;
    object-fit: cover;
}
.logopngIcon6 {
    width: 50px;
    height: 50px;
    overflow: hidden;
    object-fit: cover;
    margin-top: -61px;
    margin-left: -109px;
}
.container3 {
    position: absolute;
    width: calc(100% - 570px);
    top: calc(50% - 193.25px);
    right: 0px;
    left: 570px;
    height: 504.5px;
    text-align: left;
    font-size: 40px;
}
.container2 {
    position: absolute;
    top: 94px;
    left: calc(50% - 570px);
    width: 1140px;
    height: 762.5px;
}
.section2 {
    align-self: stretch;
    position: relative;
    height: 926.5px;
    flex-shrink: 0;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    font-size: 72px;
}
.heading14 {
    position: absolute;
    top: 164px;
    left: calc(50% - 365px);
    font-size: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 526.3px;
    height: 86px;
    text-shadow: 2px 0 0 #000, 0 2px 0 #000, -2px 0 0 #000, 0 -2px 0 #000;
}
.logopngIcon7 {
    position: absolute;
    top: calc(50% - 579.5px);
    left: 890.97px;
    width: 50px;
    height: 50px;
    overflow: hidden;
    object-fit: cover;
}
.step1InstructionContainer {
    position: absolute;
    top: 0px;
    left: calc(50% - 364.16px);
    display: flex;
    align-items: center;
    width: 728.7px;
    height: 42px;
    text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
        0 -1px 0 #181818;
    color: #ffcd4e;
}
.appDownloadMethods {
    position: absolute;
    top: 52px;
    left: calc(50% - 339.98px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 681.9px;
    height: 42px;
    text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
        0 -1px 0 #181818;
}
.appStoreInstruction {
    position: absolute;
    top: 84px;
    left: calc(50% - 350.83px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 702px;
    height: 42px;
    text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
        0 -1px 0 #181818;
}
.downloadExtensionInstruction {
    position: absolute;
    top: 126px;
    left: calc(50% - 363.72px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 729px;
    height: 42px;
    text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
        0 -1px 0 #181818;
}
.extensionLinkInstruction {
    position: absolute;
    top: 168px;
    left: calc(50% - 296.09px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 592.5px;
    height: 42px;
    text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
        0 -1px 0 #181818;
}
.step2InstructionContainer {
    position: absolute;
    top: 210px;
    left: calc(50% - 307.97px);
    display: flex;
    align-items: center;
    width: 616.2px;
    height: 84px;
    text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
        0 -1px 0 #181818;
    color: #ffcd4e;
}
.ethRequiredInstruction {
    position: absolute;
    top: 294px;
    left: calc(50% - 342.37px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 685.1px;
    height: 42px;
    text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
        0 -1px 0 #181818;
}
.ethAcquisitionMethods {
    position: absolute;
    top: 336px;
    left: calc(50% - 330.53px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 661.4px;
    height: 42px;
    text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
        0 -1px 0 #181818;
}
.transferMethodInstruction {
    position: absolute;
    top: 378px;
    left: calc(50% - 313.87px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 632px;
    height: 42px;
    text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
        0 -1px 0 #181818;
}
.ethTransferInstruction {
    position: absolute;
    top: 420px;
    left: calc(50% - 311.58px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 623.5px;
    height: 42px;
    text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
        0 -1px 0 #181818;
}
.walletText {
    position: absolute;
    top: 462px;
    left: calc(50% - 50.06px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100.5px;
    height: 42px;
    text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
        0 -1px 0 #181818;
}
.step3InstructionContainer {
    position: absolute;
    top: 504px;
    left: calc(50% - 355.55px);
    display: flex;
    align-items: center;
    width: 711.4px;
    height: 84px;
    text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
        0 -1px 0 #181818;
    color: #ffcd4e;
}
.appLinkInstruction {
    position: absolute;
    top: 588px;
    left: calc(50% - 323.58px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 647.5px;
    height: 42px;
    text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
        0 -1px 0 #181818;
}
.uniswapLinkInstruction {
    position: absolute;
    top: 630px;
    left: calc(50% - 329.36px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 659px;
    height: 42px;
    text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
        0 -1px 0 #181818;
}
.walletConnectionInstruction {
    position: absolute;
    top: 672px;
    left: calc(50% - 329.19px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 658.7px;
    height: 42px;
    text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
        0 -1px 0 #181818;
}
.confirmationText {
    position: absolute;
    top: 714px;
    left: calc(50% - 255.44px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 512.7px;
    height: 42px;
    text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
        0 -1px 0 #181818;
}
.step4InstructionContainer {
    position: absolute;
    top: 756px;
    left: calc(50% - 360.52px);
    display: flex;
    align-items: center;
    width: 721.4px;
    height: 84px;
    text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
        0 -1px 0 #181818;
    color: #ffcd4e;
}
.slippageInformation {
    position: absolute;
    top: 840px;
    left: calc(50% - 336.31px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 672.9px;
    height: 42px;
    text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
        0 -1px 0 #181818;
}
.volatilityCaution {
    position: absolute;
    top: 882px;
    left: calc(50% - 343.09px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 686.8px;
    height: 42px;
    text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
        0 -1px 0 #181818;
}
.warningText {
    position: absolute;
    top: 924px;
    left: calc(50% - 349.73px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 699.8px;
    height: 42px;
    text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
        0 -1px 0 #181818;
}
.heading37 {
    position: absolute;
    width: calc(100% - 710px);
    top: 282px;
    right: 355px;
    left: 355px;
    height: 966px;
    font-size: 35px;
}
.link8 {
    position: absolute;
    top: calc(50% + 516.5px);
    left: calc(50% - 112.8px);
    box-shadow: 6px 6px 0px #000;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #000;
    box-sizing: border-box;
    width: 86.8px;
    height: 45px;
    color: #000;
}
.viewChartLink {
    position: absolute;
    top: 8.5px;
    left: calc(50% - 37.4px);
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75.1px;
    height: 24px;
}
.link9 {
    position: absolute;
    top: calc(50% + 516.5px);
    left: calc(50% + 4.02px);
    box-shadow: 6px 6px 0px #000;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #000;
    box-sizing: border-box;
    width: 108.8px;
    height: 45px;
    color: #000;
}
.section3 {
    align-self: stretch;
    position: relative;
    height: 1531px;
    flex-shrink: 0;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}
.logopngIcon8 {
    position: absolute;
    height: calc(100% - 476px);
    top: 80px;
    bottom: 396px;
    left: calc(50% - 60px);
    max-height: 100%;
    width: 120px;
    overflow: hidden;
    object-fit: cover;
}
.introductionText {
    position: absolute;
    top: 250px;
    left: calc(50% - 419.98px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 840.3px;
    height: 102px;
    text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
        0 -1px 0 #181818;
}
.socialMediaPrompt {
    position: absolute;
    top: 302px;
    left: calc(50% - 437.91px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 879.4px;
    height: 42px;
    text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
        0 -1px 0 #181818;
}
.callToAction {
    position: absolute;
    top: 344px;
    left: calc(50% - 449.55px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 899.5px;
    height: 42px;
    text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
        0 -1px 0 #181818;
}
.slogan {
    position: absolute;
    top: 386px;
    left: calc(50% - 280.7px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 561.7px;
    height: 42px;
    text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
        0 -1px 0 #181818;
}
.footer {
    position: absolute;
    top: 428px;
    left: calc(50% - 81.39px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 163.1px;
    height: 143px;
    text-shadow: 1px 0 0 #181818, 0 1px 0 #181818, -1px 0 0 #181818,
        0 -1px 0 #181818;
}
.section4 {
    align-self: stretch;
    position: relative;
    height: 596px;
    flex-shrink: 0;
    font-size: 35px;
}
.topgDogvipByHtmltodesign {
    width: 100%;
    /* background: linear-gradient(#fe9c34, #fe9c34), #fff; */
    /* overflow-y: auto; */
    /* flex-shrink: 0; */
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: flex-start; */
    /* justify-content: flex-start; */
    /* overflow-x: hidden; */
}
.topgDogvipByHtmltodesignParent {
    position: relative;
    width: auto;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
    font-size: 16px;
    color: #fff;
    font-family: Itim;
    overflow-x: hidden;
}
.navBar {
    height: 90px;
    display: flex;
}
.dogImage {
    display: flex;

    height: 60px;
    width: 410px;
    align-items: center;
    justify-content: space-between;
    margin-left: 8px;
}
.List {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 600px;
}
.leftlogos {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Section1 {
    margin-top: 20px;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.left {
    height: 100vh;
    width: 40%;
}
.right {
    height: 100vh;
    width: 60%;

    text-align: start;
}
.buyJoin {
    margin-top: 28px;
    display: flex;
}
.lastlogos {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    width: 450px;
}

.Section2 {
    width: 100%;
    height: 100vh;

    display: flex;
}
.Left_side {
    width: 50%;
    height: 100vh;
}
.Right_side {
    width: 50%;
    height: 100vh;
}
.section2logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 134px;
    margin-left: -203px;
}
.Section3 {
    width: 100%;
    height: 100vh;
}
.S3Main {
    display: flex;
}
.S3left {
    height: 90vh;
    width: 100%;
}
.S3right {
    height: 90vh;
    width: 100%;
}
.Head {
    width: 100%;
    height: 100px;

    display: flex;
    align-items: center;
    justify-content: center;
}

    </style>
    <body>
    <div class='topgDogvipByHtmltodesignParent'>
    <div class='topgDogvipByHtmltodesign'>

         <div
        class="section"
        style="
            background-color: ${memeherobackground == 0 ? (memeherobgcolor.length == 0 ? '#fe9c34' : memeherobgcolor) : ''};
            background-image: ${memeherobackground == 1 ? (memecoinaboutbgImage.length !== 0 ? `url(${memecoinaboutbgImage})` : '') : `url(${Template2sectionbg})`};
            object-fit: cover;
            background-size: cover;
        "
    >
        <div class="navBar">
            <div
                class="backgroundbordershadow"
                style="
                    background-image: ${memebackground == 0 ? (memenavbgcolor.length > 0 ? `url(${memenavbgcolor})` : '') : ''};
                    object-fit: cover;
                    background-size: cover;
                    background-color: ${memebackground == 1 ? memenavbarbgcolor : ''};
                "
            >
                <div class="dogImage">
                    <img
                        class="logopngIcon"
                        alt=""
                        src="${memecoinlogoImage.length > 0 ? memecoinlogoImage : Template2Logo}"
                    />
                    <div class="heading1" style="font-family: ${memecoinanvfont}; color: ${memenavtextcolor}; font-size: ${memenavfontsize}px;">
                        ${memecoinname}
                    </div>
                </div>

                <div class="List">
                    <div class="list">
                        <div class="itemLink">
                            <div
                                class="homeLinkText"
                                style="font-family: ${memecoinanvfont}; color: ${memenavtextcolor}; font-size: ${memenavfontsize}px;"
                            >
                            ${Navbar[0] !== undefined ? Navbar[0] : ''}
                            </div>
                        </div>
                        <div class="itemLink1">
                            <div
                                class="aboutLinkText"
                                style="font-family: ${memecoinanvfont}; color: ${memenavtextcolor}; font-size: ${memenavfontsize}px;"
                            >
                            ${Navbar[1] !== undefined ? Navbar[1] : ''}
                            </div>
                        </div>
                        <div class="itemLink2">
                            <div
                                class="tokenomicsLinkText"
                                style="font-family: ${memecoinanvfont}; color: ${memenavtextcolor}; font-size: ${memenavfontsize}px;"
                            >
                            ${Navbar[2] !== undefined ? Navbar[2] : ''}
                            </div>
                        </div>
                        <div class="itemLink3">
                            <div
                                class="howToBuy"
                                style="font-family: ${memecoinanvfont}; color: ${memenavtextcolor}; font-size: ${memenavfontsize}px;"
                            >
                            ${Navbar[3] !== undefined ? Navbar[3] : ''}
                            </div>
                        </div>
                    </div>
                    <div class="leftlogos" style='cursor:pointer'>
                    <a href=${template2telegramlink} >
                        <img
                            class="linkIcon"
                            alt=""
                            src="${Template2telegram}"
                            onclick="Template2opentelegram()"
                        />
                        </a>
                        <a href=${template2twitterlink} >
                        <img
                            class="linkIcon1"
                            alt=""
                            src="${Template2twitter}"
                            onclick="Template2opentwitter()"
                        />
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="Section1">
            <div class="left">
                <div class="heading11" style=' color: ${memecoinabouttextcolor}; font-family: ${memecoinaboutfont};font-size:${memeMainfontsize}px'>
                   <p> $${memecoinsymbol}</p>
                   <br></br>
                <p class'heading6' style=' color: ${memecoinabouttextcolor}; font-family: ${memecoinaboutfont};font-size:${memeMainfontsize}px'>${Template2memecaption}</p>

                </div>

                <img
                    class="logopngIcon1"
                    alt=""
                    src="${memecoinlogoImage.length > 0 ? memecoinlogoImage : Template2Logo}"
                />
            </div>
            <div class="right">
                <div class="welcomeText">
                    <span class="welcomeTextTxtContainer">
                        <span style=' color: ${memecoinabouttextcolor}; font-family: ${memecoinaboutfont};font-size:${memeMainfontsize}px'>${template2caption.slice(0, 28)} </span>
                        <span class="g" style=' color: ${memecoinabouttextcolor}; font-family: ${memecoinaboutfont};font-size:${memeMainfontsize}px'>${memecoinsymbol}</span>
                    </span>
                </div>
                <div class="andrewTatesDogContainer">
                    <span class="welcomeTextTxtContainer">
                        <span style=' color: ${memecoinabouttextcolor}; font-family: ${memecoinaboutfont};font-size:${memeMainfontsize}px'>${template2caption.slice(29, 47)}</span>
                        <span class="on" style=' color: ${memecoinabouttextcolor}; font-family: ${memecoinaboutfont};font-size:${memeMainfontsize}px'>${template2caption.slice(48, 50)}</span>
                    </span>
                </div>
                <div class="ethereumDeclaration" style=' color: ${memecoinabouttextcolor}; font-family: ${memecoinaboutfont};font-size:${memeMainfontsize}px'>
                    ${template2caption.slice(51, 60)}
                </div>
                <div class="memeCoinIntroductionContainer">
                    <span class="welcomeTextTxtContainer">
                        <span style=' color: ${memecoinabouttextcolor}; font-family: ${memecoinaboutfont};font-size:${memeMainfontsize}px'>${template2info.slice(0, 13)} </span>
                        <span class="g" style=' color: ${memecoinabouttextcolor}; font-family: ${memecoinaboutfont};font-size:${memeMainfontsize}px'>${template2info.slice(14, 15)}${memecoinsymbol}</span>
                        <span style=' color: ${memecoinabouttextcolor}; font-family: ${memecoinaboutfont};font-size:${memeMainfontsize}px'> ${template2info.slice(16, 32)}</span>
                    </span>
                </div>

                <div class="marketsTailText" style=' color: ${memecoinabouttextcolor}; font-family: ${memecoinaboutfont};font-size:${memeMainfontsize}px'>
                    ${template2info.slice(33, 53)}
                </div>
                <div class="buyJoin">
                    <div class="link1">
                        <div class="buyLinkText" style=' color: ${memecoinabouttextcolor}; font-family: ${memecoinaboutfont};font-size:${memeMainfontsize}px'>
                            BUY ${memecoinsymbol}
                        </div>
                    </div>
                    <div class="link2">
                        <div class="communityLinkText" style=' color: ${memecoinabouttextcolor}; font-family: ${memecoinaboutfont};font-size:${memeMainfontsize}px'>
                            JOIN COMMUNITY
                        </div>
                    </div>
                </div>
                <div class="heading31">
                    <div class="contractLabel" style=' color: ${memecoinabouttextcolor}; font-family: ${memecoinaboutfont};font-size:${memeMainfontsize}px'>
                        CA :
                    </div>
                    <div class="contractAddress" style=' color: ${memecoinabouttextcolor}; font-family: ${memecoinaboutfont};font-size:${memeMainfontsize}px'>
                        ${template2tokenAddress}
                    </div>
                </div>
                <div class="lastlogos">
                    <div
                         class="link3"
                            onclick="Opentemplate2uniswap()"
                            style='cursor:pointer'
                                 >
                        <div class="uniswapLinkText">
                            Uniswap
                        </div>
                        <a href=${template2uniswap}>
                          <img
                         class="unipngIcon"
                           alt=""
                             src="${Template2unicorn}"
                               />
                        </a>
                          </div>
                    <div class="link4" onclick="OpenTemplate2CMC()"  style='cursor:pointer'>
                        <div class="cmcLinkText">
                            CMC
                        </div>
                        <a href=${template2cmc}>
                        <img
                            class="mpngIcon"
                            alt=""
                            src="${Template2M}"
                        />
                        </a>
                    </div>
                    <div class="link5" onclick="OpenTemplate2Coingecko()"  style='cursor:pointer'>
                        <div class="coingeckoLinkText">
                            CoinGecko
                        </div>
                        <a href=${template2coingecko}>
                        <img
                            class="gecopngIcon"
                            alt=""
                            src="${Template2Forg}"
                        />
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="container"></div>
    </div>


     <div
        class="section1"
        style="
            background-color: ${memeaboutbackground == 0 ? (memeaboutbgcolor.length == 0 ? '#fe9c34' : memeaboutbgcolor) : ''};
            background-image: ${memeaboutbackground == 1 ? (memeaboutbgimage.length !== 0 ? `url(${memeaboutbgimage})` : '') : `url(${Template2sectionbg})`};
            object-fit: cover;
            background-size: cover;
        "
    >
        <div class="Section2">
            <div class="Left_side">
                <div class="heading12" style="font-family: ${memecoinvisionfont}; color: ${memecoinvisiontextcolor}; font-size: ${memeaboutfontSize}px;">
                    ABOUT
                </div>
                <br/>
                <div class="memeIntroTextContainer">
                    <span class="welcomeTextTxtContainer">
                        <span style="font-family: ${memecoinvisionfont}; color: ${memecoinvisiontextcolor}; font-size: ${memeaboutfontSize}px;">${memecoinsymbol}</span>
                        <span class="on" style="font-family: ${memecoinvisionfont}; color: ${memecoinvisiontextcolor}; font-size: ${memeaboutfontSize}px;">
                            ${memecoinAbout} ${memecoinsymbol}!
                        </span>
                    </span>
                </div>
                <br/>
                <div class="section2logo">
                    <div class="link6">
                        <div  style="font-family: ${memecoinvisionfont}; color: ${memecoinvisiontextcolor}; font-size: ${memeaboutfontSize}px;">
                            BUY $${memecoinsymbol}
                        </div>
                    </div>
                    <img
                        class="logopngIcon2"
                        alt=""
                        src="${memecoinlogoImage.length > 0 ? memecoinlogoImage : Template2Logo}"
                    />
                </div>
            </div>
            <div class="Right_side">
                <img
                    class="logopngIcon3"
                    alt=""
                    src="${memecoinlogoImage.length > 0 ? memecoinlogoImage : Template2Logo}"
                />
            </div>
        </div>

        <div class="container1">
            <div class="heading32">
                <div class="memeCulture"></div>
                <div class="tequilaHumorTextContainer">
                    <span class="welcomeTextTxtContainer"></span>
                </div>
                <div class="haveAGood"></div>
                <div class="partyText"></div>
                <div class="memeCultureText"></div>
            </div>
        </div>
    </div>


    <div
    class="section2"
    style="
        background-color: ${memetokenomicsbackground == 0 ? (memetokenomicsbgcolor.length == 0 ? '#fe9c34' : memetokenomicsbgcolor) : ''};
        background-image: ${memetokenomicsbackground == 1 ? (memecointokonomicsbgimage.length !== 0 ? `url(${memecointokonomicsbgimage})` : '') : `url(${Template2sectionbg})`};
        object-fit: cover;
        background-size: cover;
    "
>
    <div class="Section3">
        <div class="Head">
            <div class="heading13"
            style=' font-family: ${memecointokenfont}; color: ${memecointoketextcolor};font-size:${memetokenfontSize}px'

            >
                ${template2tokenheading}
            </div>
        </div>
        <div class="S3Main">
            <div class="S3left">
                <img
                    class="logopngIconSect3"
                    alt=""
                    src="${memecoinlogoImage.length > 0 ? memecoinlogoImage : Template2Logo}"
                />
            </div>
            <div class="S3right">
                <div class="heading33" style="background-color: ${memetokenomicsbgcolor ? '' : '#ea9a46'};">
                    <div class="tokenSupply"
            style=' font-family: ${memecointokenfont}; color: ${memecointoketextcolor};font-size:${memetokenfontSize}px'

                    >
                        ${template2tokensupply}
                    </div>
                    <div class="supplyStatement" 
            style=' font-family: ${memecointokenfont}; color: ${memecointoketextcolor};font-size:${memetokenfontSize}px'
                    >
                        ${template2tokentatesupply.slice(0, 5)}${memecoinsymbol} ${template2tokentatesupply.slice(6, 28)}
                    </div>
                    <div class="heading34" style="background-color: ${memetokenomicsbgcolor ? '' : '#ea9a46'};">
                        <div class="taxPromo"
            style=' font-family: ${memecointokenfont}; color: ${memecointoketextcolor};font-size:${memetokenfontSize}px'
                        >
                            ${template2tokentax}
                        </div>
                    </div>
                    <div class="heading35" style="background-color: ${memetokenomicsbgcolor ? '' : '#ea9a46'};">
                        <div class="burntLpPromo"
            style=' font-family: ${memecointokenfont}; color: ${memecointoketextcolor};font-size:${memetokenfontSize}px'
                        >
                            ${template2tokenlp}
                        </div>
                    </div>
                    <div class="heading36" style="background-color: ${memetokenomicsbgcolor ? '' : '#ea9a46'};">
                        <div class="memesPromo"
            style=' font-family: ${memecointokenfont}; color: ${memecointoketextcolor};font-size:${memetokenfontSize}px'
                        >
                            ${template2tokenMeme}
                        </div>
                    </div>
                    <div class="link7">
                        <div class="joinLinkText"  onclick="Template2opentelegram()"  
            style=' font-family: ${memecointokenfont}; color: ${memecointoketextcolor != 'white' ? memecointoketextcolor : 'black'};font-size:${memetokenfontSize}px'
                        >JOIN WITH ${memecoinsymbol}</div>
                        <img
                            class="tgpngIcon"
                            alt=""
                            src="${Template2telegram}"
                        />
                    </div>
                    <img
                        class="logopngIcon6"
                        alt=""
                        src="${memecoinlogoImage.length > 0 ? memecoinlogoImage : Template2Logo}"
                    />
                </div>
                
            </div>
        </div>
    </div>
</div>


        <div
    class="section3"
    style="
        background-color: ${memestepbackground == 0 ? (memestepbgcolor.length == 0 ? '#fe9c34' : memestepbgcolor) : ''};
        background-image: ${memestepbackground == 1 ? (memestepbgimage.length !== 0 ? `url(${memestepbgimage})` : '') : `url(${Template2sectionbg})`};
        object-fit: cover;
        background-size: cover;
    "
>
    <div class="heading14"
    style="
            font-family: ${memecoinstepfont};
            color: ${memecoinsteptextcolor};
            font-size: ${memestepfontSize}px;
        ">
        ${template2stepheading}${memecoinsymbol}
    </div>

    <div
        class="heading37"
        style="
            font-family: ${memecoinstepfont};
            color: ${memecoinsteptextcolor};
            font-size: ${memestepfontSize}px;
        "
    >
        <div class="step1InstructionContainer">
            <span class="welcomeTextTxtContainer">
                <span style="
                    font-family: ${memecoinstepfont};
                    color: ${memecoinsteptextcolor};
                    font-size: ${memestepfontSize}px;
                ">${step1Test1}</span>
                <span class="on" style="
                    font-family: ${memecoinstepfont};
                    color: ${memecoinsteptextcolor};
                    font-size: ${memestepfontSize}px;
                ">${step1Test2}</span>
            </span>
        </div>
        <div class="appDownloadMethods">
            ${step1Test3}
        </div>
        <div class="appStoreInstruction">
            ${step1Test4}
        </div>
        <div class="downloadExtensionInstruction">
            ${step1Test5}
        </div>
        <div class="extensionLinkInstruction">
            ${Step1Test6}
        </div>
        <div class="extensionLinkInstruction">
            ${Step1Test7}
        </div>

        <div class="step2InstructionContainer">
            <span class="welcomeTextTxtContainer">
                <span style="
                    font-family: ${memecoinstepfont};
                    color: ${memecoinsteptextcolor};
                    font-size: ${memestepfontSize}px;
                ">${template2step2.slice(0, 34)}</span>
            </span>
        </div>
        <div class="ethRequiredInstruction">
            ${template2step2.slice(35, 58)}${memecoinsymbol} ${template2step2.slice(65, 82)}
        </div>
        <div class="ethAcquisitionMethods">
            ${template2step2.slice(83, 124)}
        </div>
        <div class="transferMethodInstruction">
            ${template2step2.slice(125, 166)}
        </div>
        <div class="ethTransferInstruction">
            ${template2step2.slice(167, 207)}
        </div>
        <div class="walletText">
            ${template2step2.slice(208, 228)}
        </div>
        <div class="step3InstructionContainer">
            <span class="welcomeTextTxtContainer">
                <span style="
                    font-family: ${memecoinstepfont};
                    color: ${memecoinsteptextcolor};
                    font-size: ${memestepfontSize}px;
                ">${template2step3.slice(0, 38)}</span>
                <span class="on" style="
                    font-family: ${memecoinstepfont};
                    color: ${memecoinsteptextcolor};
                    font-size: ${memestepfontSize}px;
                ">${template2step3.slice(39, 45)}</span>
            </span>
        </div>
        <div class="appLinkInstruction">
            ${template2step3.slice(46, 88)}
        </div>
        <div class="uniswapLinkInstruction">
            ${template2step3.slice(89, 129)}
        </div>
        <div class="walletConnectionInstruction">
            ${template2step3.slice(130, 152)}${memecoinsymbol}${template2step3.slice(159, 176)}
        </div>
        <div class="confirmationText">
            ${template2step3.slice(177, 210)}
        </div>
        <div class="step4InstructionContainer">
            <span class="welcomeTextTxtContainer">
                <span style="
                    font-family: ${memecoinstepfont};
                    color: ${memecoinsteptextcolor};
                    font-size: ${memestepfontSize}px;
                ">${template2step4.slice(0, 22)}${memecoinsymbol}</span>
                <span class="on" style="
                    font-family: ${memecoinstepfont};
                    color: ${memecoinsteptextcolor};
                    font-size: ${memestepfontSize}px;
                ">${template2step4.slice(24, 44)}</span>
            </span>
        </div>
        <div class="slippageInformation">
            ${template2step4.slice(46, 90)}
        </div>
        <div class="volatilityCaution">
            ${template2step4.slice(91, 136)}
        </div>
        <div class="warningText">
            ${template2step4.slice(137, 184)}
        </div>
    </div>

    <div class="link8">
        <div class="buyLinkText" style="
            font-family: ${memecoinstepfont};
            color: ${memecoinsteptextcolor};
            font-size: ${memestepfontSize}px;
        ">BUY $${memecoinsymbol}</div>
    </div>
    <div class="link9">
        <div class="viewChartLink" style="
            font-family: ${memecoinstepfont};
            color: ${memecoinsteptextcolor};
            font-size: ${memestepfontSize}px;
        ">View Chart</div>
    </div>
</div>


   <div
    class="section4"
    style="
        background-image: ${memefootrbackground == 1 ? (memefooterbgimage.length !== 0 ? `url(${memefooterbgimage})` : '') : ''};
        object-fit: cover;
        background-size: cover;
        background-color: ${memefootrbackground == 0 ? (memefooterbgcolor.length == 0 ? '#fe9c34' : memefooterbgcolor) : ''};
    "
>
    <img
        class="logopngIcon8"
        alt=""
        src="${memecoinlogoImage.length > 0 ? memecoinlogoImage : Template2Logo}"
    />
    <div
        class="introductionText"
        style="
            color: ${memefootertextcolor};
            font-family: ${memefootertextfont};
            font-size: ${memefooterfontSize}px;
        "
    >
        ${template2footeringo}
    </div>
    <div
        class="footer"
        style="
            color: ${memefootertextcolor};
            font-family: ${memefootertextfont};
            font-size: ${memefooterfontSize}px;
        "
    >
        ${test2}
    </div>
</div>


    </div>
    </div>
      <script>
       
        function Template2opentelegram() {
            window.open('https://t.me/your_telegram_channel', '_blank');
        }
        function Template2opentwitter() {
            window.open('https://twitter.com/your_twitter_profile', '_blank');
        }

         function Opentemplate2uniswap () {
         window.open(${template2uniswap})

      }
  function OpenTemplate2CMC(){
    window.open(${template2cmc})
  }
  function OpenTemplate2Coingecko(){
    window.open(${template2coingecko})
  }
    </script>
    </body>
    </html>`

        const NewmemeTemplate3 = `
    <!DOCTYPE html>
    <html>
    <head>
     <title>${memecoinsymbol}</title>
    <style>
        @import url('https://fonts.googleapis.com/css2?family=Finger+Paint&display=swap');
.Section1 {
    width: 100%;

    z-index: 2;
}
.imagCont {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Head {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
}
.extra {
    width: 100%;
    height: 100vh;
    /* background-color: #000; */
}

.p {
    position: absolute;
    top: -2.5rem;
    left: 65px;
    color: black;
    font-size: 2vw;
}
.lp {
    position: absolute;
    top: -2.5rem;
    left: 96px;
    color: #000;
    font-size: 2vw;
}

.bottom {
    height: 15vh;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}
.Iconsbottom {
    width: 200px;

    background-color: #834d3b;
    border: 6px solid black;
    border-radius: 10px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.Section2 {
    width: 100%;
    /* background-color: rgb(0, 0, 0); */
    padding: 4px;
}
.Imgbottom {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
}
.Sect3Front {
    width: 100%;
    height: 300px;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Sect2Head {
    height: 140px;
    width: 60%;
    background-color: #834d3b;
    margin: 71px auto;
    border: 10px solid white;
    border-radius: 30px;
    padding: 10px;
}
.s3p {
    margin-top: 10px;
    word-spacing: 37px;
    
    display: flex;
    justify-content: center;
}
.Sect3Middle {
    margin: 20px auto;
    width: 60%;
}
.Sect3Para {
    height: 100vh;
    width: 100%;
    background-color: #000000;
}

.Sect3bottom {
    width: 100%;

    position: relative;
}

.Section3 {
    width: 100%;
    height: auto;
    /* background-color: rgb(0, 0, 0); */
    padding: 10px;
}

.Section4 {
    height: 100vh;
    width: 100%;
    /* background-color: #834d3b; */
    display: flex;
}

.Sect4Main {
    width: 40%;
    height: 100vh;

    position: relative;
}
.Sect4right {
    height: 100vh;
    overflow: hidden;
}

.Section5 {
    height: 110vh;
    width: 100%;
    /* background-color: #834d3b; */
    display: flex;
    padding: 10px;
    overflow: hidden;
    padding-bottom: 90px;
}
.Sect5Right {
    width: 46%;
    height: 100vh;
    margin-top: 60px;
    position: relative;
    overflow: hidden;
}
.Sect5Left {
    width: 65%;
    height: 100vh;
    margin-top: 70px;
    padding: 20px;
    overflow: hidden;
}
.Section6 {
    width: 100%;
    padding: 40px;
}
.Joinus {
    margin: 0px auto;
    width: 414px;
    height: 128px;
    background-color: #834d3b;
    border: 8px solid black;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Sect6content {
    padding: 20px;
    margin: 4px auto;

    height: fit-content;
    background-color: #834d3b;
    border: 8px solid black;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right:5%;
}
.welgomeDoDa6 {
    font-family: Finger Paint;
    font-size: 3vh;
    font-weight: 400;
    line-height: 51px;
    text-align: center;
}
.Sect6Button {
    padding: 20px;
    margin: 0px auto;
    width: 314px;
    height: 268px;

    position: relative;
}
.wrap {
    margin-bottom: 20px;
}
.Sect7Poster {
    width: 30%;

    margin: 0px auto;
}

.Section7 {
    width: 100%;
    height: 118vh;
}

.Sect7Code {
    margin: 40px auto;

    height: 60px;
    background-color: #834d3b;
    border: 8px solid black;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right:10%;
}

.Sect7containerIcon10 {
    max-width: 80%;
    overflow: hidden;
    height: 455.2px;
    object-fit: cover;

    position: absolute;
    width: calc(100% - 1.4px);
    top: -20.24px;
    left: 50.61px;
    max-width: 100%;
    overflow: hidden;
    object-fit: cover;
}

.Sect7last h4 {
    color: #834d3b;
    margin-left: 50px;
}
.Poster {
    height: 100vh;
    width: 500px;

    position: relative;
}
.gedDdUrContainer2 {
    margin-bottom: 20px;
}
.gedDdUrContainer {
    width: 800.6px;
}
.backgroundIcon {
    z-index: -1;
    position: relative;
    height: 100%;
    width: 100%;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}
.containerIcon {
    max-width: 100%;
    overflow: hidden;
}
.snuppdOnEth {
    line-height: 76px;
    width: 329.9px;
    height: 76px;

    position: relative;
}
.containerIcon1 {
    max-width: 110%;
    overflow: hidden;
}
.linkBuy {
    position: relative;
    font-size: 36px;
    line-height: 76px;

    height: 76px;
    width: 360px;
}
.svgIcon {
    position: absolute;
    top: 0px;
    width: 271.3px;
    height: 80.7px;
    overflow: hidden;
    object-fit: cover;
    background-color: #844f36;
}
.linkIcon {
    max-width: 100%;
    overflow: hidden;

    object-fit: cover;
}
.linkIcon1 {
    max-width: 100%;
    overflow: hidden;

    object-fit: cover;
}
.linkIcon2 {
    max-width: 100%;
    overflow: hidden;

    object-fit: cover;
}
.containerIcon2 {
    width: 47%;
    overflow: hidden;

    object-fit: cover;
}
.section {
    position: absolute;
    width: 100%;
    top: 0px;
    right: 0px;
    left: 0px;
    height: 1166px;
    font-size: 34.08px;
    color: #000;
}
.background {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: #07060d;
    overflow: hidden;
}
.svgIcon1 {
    position: absolute;
    top: 136.59px;
    left: 180.5px;
    width: 1079px;
    height: 205.7px;
    overflow: hidden;
    object-fit: cover;
    background-color: #fcfafa;
}
.welgomeDoDa {
    margin: 0;
    word-spacing: 10px;
    margin-bottom: 38;
    font-size: 4vh;
    display: flex;
    justify-content: center;
}

.welgomeDoDaContainer {
    position: absolute;
    top: 140.92px;
    left: calc(50% - 404.28px);
    /* font-size: 49.36px; */
    letter-spacing: 7.04px;
    line-height: 98px;
    font-family: Inter;
    display: flex;
    align-items: center;
    width: 791.3px;
    height: 196px;
}

.maskGroupIcon {
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
    width: 387px;
}

.containerIcon3 {
    overflow: hidden;
    object-fit: cover;
    position: absolute;
    top: 70px;
    left: auto;
    right: auto;
    width: 400px;
}
.containerIcon4 {
    position: absolute;
    width: calc(100% - 551.7px);
    top: 920.72px;
    right: 256.93px;
    left: 294.77px;
    max-width: 100%;
    overflow: hidden;
    height: 417.5px;
    object-fit: cover;
}

.sbrdoIsRebrosendinContainer {
    position: absolute;
    top: 607.48px;
    left: calc(50% - 534.47px);
    line-height: 47px;
    display: flex;
    align-items: center;
    width: 1069.6px;
    height: 235px;
}
.section1 {
    position: absolute;
    width: 100%;
    top: 1164.97px;
    right: 0px;
    left: 0px;
    height: 1791.6px;
    overflow: hidden;
    font-size: 33.6px;
    font-family: 'Finger Paint';
}
.Svg {
    margin: 0pc auto;
    width: 70%;
    background: rgb(255, 255, 255);
    height: 133.3px;
    overflow: hidden;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin-top: 76px;
}

.Card {
    width: 70%;
    margin: 0px auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 80px;
}
.cards {
    height: 330px;
    width: 378px;
    background-color: #834d3b;
    text-align: start;
    border: 10px solid white;
    border-radius: 20px;
    padding: 20px;
    margin: 15px 20px;
    overflow: hidden;
}
.cards p {
    font-size: 20px;
    padding: 4px 20px;
}
.SvgInner {
    height: 48px;
    width: 90%;
    background-color: #834d3b;
    display: flex;
    align-items: center;
    justify-content: center;
}
.whyGhooseSburdo {
    line-height: 75px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3vw;
    height: 75px;
    /* background-color: #844F36; */
}
.backgroundIcon1 {
    position: absolute;
    /* height: calc(100% - 820.4px);
    width: calc(100% - 1061.8px); */
    top: 370.08px;
    right: 755.35px;
    bottom: 450.32px;
    left: 306.45px;
    border-radius: 10px;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}
.mergingIgonigMeemsContainer {
    position: absolute;
    top: 484.86px;
    left: 339.02px;
    font-size: 17.8px;
    line-height: 26px;
    font-family: 'Finger Paint';
    display: flex;
    align-items: center;
    width: 318.2px;
    height: 130px;
}
.unstoppableModuleMasteriezContainer {
    position: absolute;
    top: 403.9px;
    left: 339.02px;
    font-size: 15.95px;
    line-height: 34px;
    display: flex;
    align-items: center;
    width: 162.6px;
    height: 68px;
}
.backgroundIcon2 {
    position: absolute;
    /* height: calc(100% - 820.3px);
    width: calc(100% - 1058.6px); */
    top: 370.08px;
    right: 306.49px;
    bottom: 450.22px;
    left: 752.11px;
    border-radius: 10px;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}
.engageInShabingContainer {
    position: absolute;
    top: 485.18px;
    left: 784.67px;
    font-size: 19.1px;
    line-height: 28px;
    font-family: 'Finger Paint';
    display: flex;
    align-items: center;
    width: 286.6px;
    height: 112px;
}
.dedicatedCommunityTxtContainer {
    width: 100%;
    text-align: justify;

    text-align: start;

    /* font-family: 'Finger Paint', sans-serif; */
    font-size: 4vh;
    font-weight: 400;

    margin-left: 300px;
}
.welgomeDoDa5 {
    margin-left: 50px;
}
.dedicatedCommunity {
    position: absolute;
    top: 403.9px;
    left: 784.67px;
    font-size: 4vw;
    line-height: 34px;
    display: flex;
    align-items: center;
    width: 110.5px;
    height: 68px;
}
.backgroundIcon3 {
    position: absolute;
    /* height: calc(100% - 821.3px);
    width: calc(100% - 1061.8px); */
    top: 682.23px;
    right: 755.35px;
    bottom: 139.07px;
    left: 306.45px;
    border-radius: 10px;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}
.sburdoDogensFordifiedContainer {
    position: absolute;
    top: 793.59px;
    left: 337.84px;
    line-height: 28px;
    font-family: 'Finger Paint';
    display: flex;
    align-items: center;
    width: 317.5px;
    height: 112px;
}
.secureNTrustworthyContainer {
    position: absolute;
    top: 712.18px;
    left: 337.84px;
    font-size: 15.23px;
    line-height: 34px;
    display: flex;
    align-items: center;
    width: 117px;
    height: 68px;
}
.backgroundIcon4 {
    position: absolute;
    /* height: calc(100% - 821.3px);
    width: calc(100% - 1058.6px); */
    top: 682.23px;
    right: 306.49px;
    bottom: 139.07px;
    left: 752.11px;
    border-radius: 10px;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}
.egsdendReaghAgrossContainer {
    position: absolute;
    top: 782.87px;
    left: 785.45px;
    line-height: 28px;
    font-family: 'Finger Paint';
    display: flex;
    align-items: center;
    width: 320.8px;
    height: 140px;
}
.metrixOfLegendContainer {
    position: absolute;
    top: 701.47px;
    left: 785.45px;
    font-size: 15.63px;
    line-height: 34px;
    display: flex;
    align-items: center;
    width: 88px;
    height: 68px;
}
.section2 {
    position: absolute;
    width: 100%;
    top: 2955.55px;
    right: 0px;
    left: 0px;
    height: 1082.5px;
    overflow: hidden;
    text-align: left;
    font-size: 19.2px;
}
.background2 {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: #844f36;
    overflow: hidden;
}
.containerIcon5 {
    overflow: hidden;
    height: 109.6px;
    object-fit: cover;
}
.containerIcon6 {
    position: absolute;
    width: calc(100% - 1029px);
    top: 142.24px;
    right: 937.9px;
    left: 91.1px;
    max-width: 100%;
    overflow: hidden;
    height: 412.9px;
    object-fit: contain;
}
.containerIcon7 {
    max-width: 100%;
    overflow: hidden;
    height: 484px;
    object-fit: cover;
    position: relative;
}
.sburdoRebresendsDynamigContainer {
    position: absolute;
    top: 289.67px;
    left: 583.44px;
    line-height: 52px;
    display: flex;
    align-items: center;
    width: 758.7px;
    height: 364px;
}
.welgomeDoDa1 {
    margin-left: 100px;
    /* font-family: Finger Paint; */

    font-weight: 400;
    line-height: 32px;
    text-align: left;
}
.whatIsSnuppd {
    font-size: 6vh;

    /* font-family: Inter; */

    width: 491.5px;

    margin-left: 96px;
    margin-top: 20px;
    text-align: start;
}
.section3 {
    position: absolute;
    width: 100%;
    top: 4037.05px;
    right: 0px;
    left: 0px;
    height: 768px;
    overflow: hidden;
    text-align: left;
    font-family: 'Finger Paint';
}
.eaghSburdoEdhContainer {
    position: absolute;
    top: 345.25px;
    left: 128.41px;
    line-height: 52px;
    display: flex;
    align-items: center;
    width: 624.1px;
    height: 260px;
}
.ourMission {
    font-size: 6vh;
    letter-spacing: -1.92px;
    line-height: 105px;
    /* font-family: Inter; */
    display: flex;
    align-items: center;
    width: 356.8px;
    height: 105px;
    margin-left: 50px;
}
.containerIcon8 {
    overflow: hidden;

    object-fit: cover;
}
.base {
    height: 152px;
    width: 461px;
    background-color: bisque;
    position: absolute;
    top: 260px;
    left: 23px;
    margin-left: -103;
}
.Sect7containerIcon8 {
    max-width: 100%;

    height: 153.1px;
    object-fit: cover;
}
.containerIcon9 {
    position: absolute;
    width: calc(100% - 955.7px);
    top: 115.42px;
    right: 115.13px;
    left: 840.57px;
    max-width: 100%;
    overflow: hidden;
    height: 486.3px;
    object-fit: contain;
}
.containerIcon10 {
    max-width: 90%;
    overflow: hidden;
    height: 555.2px;
    object-fit: cover;
    position: relative;
}

.front {
    height: 200px;
    width: 100%;

    position: absolute;
    top: 419px;
    left: -20px;
    padding: 20px;
}
.back {
    width: 100%;
}
.Front {
    width: 100%;
}

.Back {
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 400px;
}
.section4 {
    position: absolute;
    width: 100%;
    top: 4804.02px;
    right: 0px;
    left: 0px;
    height: 768px;
    overflow: hidden;
    text-align: left;
    font-family: 'Finger Paint';
}
.svgIcon3 {
    width: 414.1px;
    height: 137.1px;
    overflow: hidden;
    object-fit: cover;
}
.Sect7last {
    height: 60px;

    display: flex;
    align-items: center;
    justify-content: center;
}
.joinUs {
    font-size: 6vh;
    line-height: 128px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 280.2px;
    height: 128px;
}
.svgIcon4 {
    width: 924.8px;
    height: 252.6px;
    overflow: hidden;
    object-fit: cover;
}
.beginYourJourneyContainer {
    font-size: 37.1px;
    line-height: 51px;
    font-family: 'Finger Paint';
    display: flex;
    align-items: center;
    width: 827.7px;
    height: 204px;
}
.svgIcon5 {
    width: 924.8px;
    height: 66.6px;
    overflow: hidden;
    object-fit: cover;
}
.ca0x3927fb89f34bbee63351a63 {
    font-size: 2vw;
    line-height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 806.8px;
    height: 52px;
    overflow: hidden;
}
.containerIcon11 {
    /* left: 556.19px; */
    max-width: 100%;
    overflow: hidden;
    height: 59.6px;

}
.teamsnuppdErcxyz {
    position: absolute;
    top: 1518.48px;
    left: calc(50% - 84.69px);
    font-size: 21.85px;
    line-height: 43px;
    color: #98573a;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 228.9px;
    height: 43px;
}
.containerIcon12 {
    overflow: hidden;
    height: 448px;
    object-fit: cover;
    margin-left: -190px;
}
.containerIcon13 {
    position: absolute;
    width: calc(100% - 1200.5px);
    top: 518.34px;
    right: 600.33px;
    left: 600.17px;
    max-width: 100%;
    overflow: hidden;
    height: 60.5px;
}
.containerIcon14 {
    position: absolute;
    width: calc(100% - 1200.5px);
    top: 613.95px;
    right: 600.33px;
    left: 600.17px;
    max-width: 100%;
    overflow: hidden;
    height: 60.5px;
}
.containerIcon15 {
    max-width: 100%;
    overflow: hidden;
    height: 60.5px;
}
.linkTwitter {
    position: absolute;
    top: 16px;
    left: 90px;
    line-height: 62px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 109.4px;
    height: 62px;
}
.linkTelegram {
    position: absolute;
    top: 94px;
    left: 90px;
    font-size: 28.96px;
    line-height: 62px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 126.9px;
    height: 62px;
}
.linkDextools {
    position: absolute;
    top: 188.5px;
    left: 80px;
    line-height: 62px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 136.4px;
    height: 62px;
}
.section5 {
    position: absolute;
    width: 100%;
    top: 5572px;
    right: 0px;
    left: 0px;
    height: 1592.1px;
    overflow: hidden;
    font-size: 32.95px;
}
.spurdoErcxyzByHtmltodesi {
    width: 100%;
    position: relative;
    background-color: #fff;

    overflow-y: auto;
    text-align: center;
    font-size: 34.7px;
    color: #fff;
    font-family: Inter;
    overfow-x: hidden;
}
.last {
    height: 200px;
    width: 200px;
}
.mainbanner {
    height: 80vh;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}
.innerMain {
    height: 100%;
    width: 400px;
}
.Footer {
    height: 100px;
    width: 100%;
}
@media (max-width: 767px) {
    /* Your CSS rules for mobile devices go here */
    .Joinus {
        width: 250px;
    }
    .Sect6Button {
        width: 214px;
    }
    .linkTwitter {
        left: 54px;
    }
    .linkTelegram {
        left: 44px;
    }
    .linkDextools {
        left: 40px;
    }
    .innerMain {
        margin-left: 189px;
    }
    .containerIcon12 {
        height: 281px;
    }
    .containerIcon1 {
        max-width: 66%;
    }
    .containerIcon {
        max-width: 66%;
    }
    .lp {
        left: 75px;
    }
}

    </style>
    </head>
    <body>
     <div style='overflow-x:hidden'  class='spurdoErcxyzByHtmltodesi'>
          <div
    class="Section1"
    style="
        background-image: ${memeherobackground == 0 ? (memecoinaboutbgImage.length > 0 ? `url(${memecoinaboutbgImage})` : `url(${Template3BG})`) : ''};
        background-color: ${memeherobackground == 1 ? memeherobgcolor : ''};
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    "
>
    <div class="Head">
        <div class="snuppdOnEth">
            <p class="p" style="
                font-family: ${memecoinanvfont.length > 0 ? `${memecoinanvfont}` : 'Finger Paint'};
                color: ${memenavtextcolor};
                font-size: ${memenavfontsize}px;
                position: absolute;
                top:-2rem;
            ">
                ${Navbar[0]}
            </p>
            <img
                class="containerIcon"
                alt=""
                src="${Template3Container}"
            />
        </div>

        <div class="linkBuy">
            <p class="lp" style="
                font-family: ${memecoinanvfont.length > 0 ? `${memecoinanvfont}` : 'Finger Paint'};
                color: ${memenavtextcolor};
                font-size: ${memenavfontsize}px;
                position: absolute;
                top:-2rem;
            ">
                ${Navbar[1]}
            </p>
            <img
                class="containerIcon1"
                alt=""
                src="${Template3Container}"
            />
        </div>
    </div>
    <div class="middle">
        <div class="imagCont">
            <img
                class="containerIcon2"
                alt="Template3Sunppd"
                src="${memecoinAminitionImage.length > 0 ? memecoinAminitionImage : Template3Sunppd}"
            />
        </div>
    </div>
    <div class="bottom">
        <div class="Iconsbottom">
            <img
                class="linkIcon"
                alt="Template3Telegram"
                src="${Template3Telegram}"
                onclick="OpenTemplate3telegram()"
                style="cursor: pointer;"
            />
            <img
                class="linkIcon1"
                alt="Template3Twitter"
                src="${Template3Twitter}"
                onclick="OpenTemplate3twitter()"
                style="cursor: pointer;"
            />
            <img
                class="linkIcon2"
                alt="Template3Chart"
                src="${Template3Chart}"
                onclick="OpenTemplate3Uniswap()"
                style="cursor: pointer;"
            />
        </div>
    </div>
</div>

    <div
    class="Section2"
    style="
        background-color: ${memeaboutbackground == 0 ? (memeaboutbgcolor.length == 0 ? '#000' : memeaboutbgcolor) : ''};
        background-image: ${memeaboutbackground == 1 ? (memeaboutbgimage.length !== 0 ? `url(${memeaboutbgimage})` : '') : ''};
        object-fit: cover;
        background-size: cover;
    "
>
    <div class="Sect2Head" style='background-color:${statsbackgroundcolorT3}; border: ${statsborder.length > 0 ? statsborder : '10'}px solid ${statsbordercolor.length > 0 ? statsbordercolor : 'white'}; border-radius: ${statsborderradius.length > 0 ? statsborderradius : '30'}px; box-shadow: ${statsshowdowrange ? ` 0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'};' >
        <p class="welgomeDoDa" style="
            font-family: ${memecoinanvfont.length > 0 ? `${memecoinanvfont}` : 'Finger Paint'};
            color: ${memecoinvisiontextcolor};
            font-size: ${memeaboutfontSize}px;
        ">
            ${template3heading.slice(0, 22)} <br />
            <p class="s3p" style="
                font-family: ${memecoinvisionfont.length > 0 ? memecoinvisionfont : 'Finger Paint'};
                color: ${memecoinvisiontextcolor};
                font-size: ${memeaboutfontSize}px;
            ">
                ${template3heading.slice(23, 30)} ${memecoinname} ${template3heading.slice(31, 43)}
            </p>
        </p>
    </div>
    <div class="Sect3Middle">
        <div class="gedDdUrContainer2">
            <span class="dedicatedCommunityTxtContainer">
                <p class="welgomeDoDa" style="
                    font-family: ${memecoinvisionfont.length > 0 ? memecoinvisionfont : 'Finger Paint'};
                    color: ${memecoinvisiontextcolor};
                    font-size: ${memeaboutfontSize}px;
                ">
                    $${memecoinAbout}
                </p>
            </span>
        </div>
    </div>
    <div class="extra">
        <div class="Sect3bottom">
            <div class="Sect3Front">
                <img
                    class="containerIcon3"
                    alt=""
                    src="${memecoinavisionleftimage.length > 0 ? memecoinavisionleftimage : Template3Maks}"
                />
            </div>
            <div class="Imgbottom">
                <img
                    class="maskGroupIcon"
                    alt=""
                    src="${memecoinavisionrightimage.length > 0 ? memecoinavisionrightimage : Template3Section3svg}"
                />
            </div>
        </div>
    </div>
</div>

      <div class="Section3" style="
    background-image: ${memetokenomicsbackground == 0 && memecointokonomicsbgimage.length > 0 ? `url(${memecointokonomicsbgimage})` : ''}; 
    width: 100%; 
    height: 100%; 
    object-fit: cover; 
    background-size: cover; 
    background-color: ${memetokenomicsbackground == 1 ? memetokenomicsbgcolor : memecointokonomicsbgimage.length == 0 ? '#000' : ''};
">
    <div class="Svg">
        <div class="SvgInner">
            <div class="whyGhooseSburdo" style="
                font-family: ${memecointokenfont.length > 0 ? memecointokenfont : 'Finger Paint'}; 
                color: ${memecointoketextcolor}; 
                font-size: ${memetokenfontSize}px;
            ">
                Why choose ${memecoinname}?
            </div>
        </div>
    </div>

    <div class="Card">
        <div class="cards" style="
            font-family: ${memecointokenfont.length > 0 ? memecointokenfont : 'Finger Paint'}; 
            color: ${memecointoketextcolor}; 
            font-size: ${memetokenfontSize}px;
            background-color:${statsbackgroundcolorT3}; border: ${statsborder.length > 0 ? statsborder : '10'}px solid ${statsbordercolor.length > 0 ? statsbordercolor : 'white'}; border-radius: ${statsborderradius.length > 0 ? statsborderradius : '30'}px; box-shadow: ${statsshowdowrange ? ` 0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'};
        ">
            <p>${template3section1.slice(0, 13)}</p>
            <p class="welgomeDoDa">
                ${template3section1.slice(14, 36)}
            </p>
            <p class="welgomeDoDa">
                ${template3section1.slice(37, 62)}
            </p>
            <p class="welgomeDoDa">
                ${template3section1.slice(63, 94)}
            </p>
            <p class="welgomeDoDa">
                ${template3section1.slice(95, 124)}
            </p>
            <p class="welgomeDoDa">
                ${template3section1.slice(127, 137)} ${memecoinsymbol}
            </p>
        </div>
        <div class="cards" style="
            font-family: ${memecointokenfont.length > 0 ? memecointokenfont : 'Finger Paint'}; 
            color: ${memecointoketextcolor}; 
            font-size: ${memetokenfontSize}px;
            background-color:${statsbackgroundcolorT3}; border: ${statsborder.length > 0 ? statsborder : '10'}px solid ${statsbordercolor.length > 0 ? statsbordercolor : 'white'}; border-radius: ${statsborderradius.length > 0 ? statsborderradius : '30'}px; box-shadow: ${statsshowdowrange ? ` 0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'};
        ">
            <p>${template3Section2.slice(0, 12)}</p>
            <p class="welgomeDoDa">
                ${template3Section2.slice(12, 39)}
            </p>
            <p class="welgomeDoDa">
                ${template3Section2.slice(40, 62)}
            </p>
            <p class="welgomeDoDa">
                ${template3Section2.slice(62, 86)}
            </p>
            <p class="welgomeDoDa">
                ${template3Section2.slice(86, 110)}
            </p>
        </div>
        <div class="cards" style="
            font-family: ${memecointokenfont.length > 0 ? memecointokenfont : "Finger Paint"}; 
            color: ${memecointoketextcolor}; 
            font-size: ${memetokenfontSize}px;
            background-color:${statsbackgroundcolorT3}; border: ${statsborder.length > 0 ? statsborder : '10'}px solid ${statsbordercolor.length > 0 ? statsbordercolor : 'white'}; border-radius: ${statsborderradius.length > 0 ? statsborderradius : '30'}px; box-shadow: ${statsshowdowrange ? ` 0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'};
        ">
            <p>${template3Section3.slice(0, 25)}</p>
            <p class="welgomeDoDa">
                ${memecoinname} ${template3Section3.slice(25, 48)}
            </p>
            <p class="welgomeDoDa">
                ${template3Section3.slice(48, 78)}
            </p>
            <p class="welgomeDoDa">
                ${template3Section3.slice(78, 105)}
            </p>
            <p class="welgomeDoDa">
                ${template3Section3.slice(106, 130)}
            </p>
        </div>
        <div class="cards" style="
            font-family: ${memecointokenfont.length > 0 ? memecointokenfont : 'Finger Paint'}; 
            color: ${memecointoketextcolor}; 
            font-size: ${memetokenfontSize}px;
            background-color:${statsbackgroundcolorT3}; border: ${statsborder.length > 0 ? statsborder : '10'}px solid ${statsbordercolor.length > 0 ? statsbordercolor : 'white'}; border-radius: ${statsborderradius.length > 0 ? statsborderradius : '30'}px; box-shadow: ${statsshowdowrange ? ` 0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'};
        ">
            <p>${template3Section4.slice(0, 19)}</p>
            <p class="welgomeDoDa">
                ${template3Section4.slice(19, 42)}
            </p>
            <p class="welgomeDoDa">
                ${template3Section4.slice(42, 71)}
            </p>
            <p class="welgomeDoDa">
                ${template3Section4.slice(71, 91)} ${memecoinname}
            </p>
            <p class="welgomeDoDa">
                ${template3Section4.slice(92, 120)}
            </p>
            <p class="welgomeDoDa">
                ${template3Section4.slice(120, 150)}
            </p>
        </div>
    </div>
</div>
                 

             <div class="Section4" style="
    background-image: ${memestepbackground == 0 ? `url(${memestepbgimage})` : ''}; 
    height: 100%; 
    width: 100%; 
    background-size: cover; 
    object-fit: cover; 
    background-color: ${memestepbackground == 1 ? memestepbgcolor : memestepbgimage.length === 0 ? '#834d3b' : ''};
">
    <div class="Sect4Main">
        <div class="Back">
            <!-- Add content if needed -->
        </div>
        <div class="Front">
            <img
                class="containerIcon7"
                alt="6"
                src="${memecoinlogoImage.length > 0 ? memecoinlogoImage : Template3SectionSnuppd}"
            />
        </div>
    </div>
    <div class="Sect4right" style="
        font-family: ${memecoinstepfont.length > 0 ? memecoinstepfont : 'Finger Paint'}; 
        color: ${memecoinsteptextcolor}; 
        font-size: ${memestepfontSize}px;
    ">
        <div class="whatIsSnuppd">
            ${template3stepquestion} <br /> ${memecoinsymbol}?
        </div>
        <span class="dedicatedCommunityTxtContainer">
            <p class="welgomeDoDa1">
                ${memecoinname} ${template3stepsection1.slice(0, 31)}
            </p>
            <p class="welgomeDoDa1">
                ${template3stepsection1.slice(32, 75)}
            </p>
            <p class="welgomeDoDa1">
                ${template3stepsection1.slice(76, 106)}
            </p>
            <p class="welgomeDoDa1">
                ${template3stepsection1.slice(107, 144)}
            </p>
            <p class="welgomeDoDa1">
                ${template3stepsection1.slice(145, 160)} ${memecoinname} ${template3stepsection1.slice(161, 172)}
            </p>
            <p class="welgomeDoDa1">
                ${template3stepsection1.slice(172, 212)}
            </p>
            <p class="welgomeDoDa1">
                ${template3stepsection1.slice(212, 252)}
            </p>
        </span>
    </div>
</div>
           

    <div class="Section5" style="
    background-image: ${memestepbackgroundt3 == 0 ? `url(${template3stepsection2bg})` : ''}; 
    height: 100%; 
    width: 100%; 
    background-size: cover; 
    object-fit: cover; 
    background-color: ${memestepbackgroundt3 == 1 ? template3stepsection2color : template3stepsection2bg.length === 0 ? '#834d3b' : ''};
">
    <div class="Sect5Left" style="
        font-family: ${memecoinstepfont.length > 0 ? memecoinstepfont : 'Finger Paint'}; 
        color: ${memecoinsteptextcolor}; 
        font-size: ${memestepfontSize}px;
    ">
        <div class="ourMission">
            ${template3step3section2heading}
        </div>
        <span class="dedicatedCommunityTxtContainer">
            <p class="welgomeDoDa5">
                ${template3stepsection2.slice(0, 4)} ${memecoinname} ${template3stepsection2.slice(5, 24)}
            </p>
            <p class="welgomeDoDa5">
                ${template3stepsection2.slice(25, 54)}
            </p>
            <p class="welgomeDoDa5">
                ${template3stepsection2.slice(55, 87)}
            </p>
            <p class="welgomeDoDa5">
                ${template3stepsection2.slice(87, 115)}
            </p>
            <p class="welgomeDoDa5">
                ${template3stepsection2.slice(115, 150)}
            </p>
        </span>
    </div>
    <div class="Sect5Right">
        <!-- <img class="containerIcon9" alt="" src="Background.png" /> -->
        <div class="front">
            <!-- Add content if needed -->
        </div>
        <div class="back">
            <img
                class="containerIcon10"
                alt="3"
                src="${template3stepimage.length > 0 ? template3stepimage : Template3DogImage}"
            />
        </div>
    </div>
</div>

        <div class="Section6" style="
    background-image: ${memefootrbackground == 1 ? (memefooterbgimage.length !== 0 ? `url(${memefooterbgimage})` : '') : (memefooterbgcolor.length === 0 ? `url(${Template3BG})` : '')}; 
    background-position: center; 
    background-size: cover; 
    background-repeat: no-repeat; 
    background-color: ${memefootrbackground == 0 ? (memefooterbgcolor.length == 0 ? '' : memefooterbgcolor) : ''};
">
    <div class="Joinus" style='background-color:${statsbackgroundcolorT3}; border: ${statsborder.length > 0 ? statsborder : '8'}px solid ${statsbordercolor != 'white' ? statsbordercolor : 'black'}; border-radius: ${statsborderradius.length > 0 ? statsborderradius : '10'}px; box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'};'>
        <div class="joinUs"style='color:${memefootertextcolor};font-family:${memefootertextfont};font-size:${memefooterfontSize}px;' >
            ${template3footerheading}
        </div>
    </div>
    <div class="Sect6content" style='background-color:${statsbackgroundcolorT3}; border: ${statsborder.length > 0 ? statsborder : '8'}px solid ${statsbordercolor != 'white' ? statsbordercolor : 'black'}; border-radius: ${statsborderradius.length > 0 ? statsborderradius : '10'}px; box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'};'>
        <p class="welgomeDoDa6" style='color:${memefootertextcolor};font-family:${memefootertextfont.length > 0 ? memefootertextfont : 'Finger Paint'};font-size:${memefooterfontSize}px;'>
            ${template3footerinfo.slice(0, 48)} ${memecoinname} ${template3footerinfo.slice(48, 150)}
        </p>
    </div>
    <div class="Sect6Button">
        <div class="wrap" style="cursor: pointer;" onclick="OpenTemplate3twitter()">
            <img
                class="containerIcon15"
                alt="6"
                src="${Template3Container}"
            />
            <div class="linkTwitter" style='color:${memefootertextcolor};font-family:${memefootertextfont.length > 0 ? memefootertextfont : 'Finger Paint'};font-size:${memefooterfontSize}px;'>
                Twitter
            </div>
        </div>
        <div class="wrap" style="cursor: pointer;" onclick="OpenTemplate3telegram()">
            <img
                class="containerIcon15"
                alt="6"
                src="${Template3Container}"
            />
            <div class="linkTelegram" style='color:${memefootertextcolor};font-family:${memefootertextfont.length > 0 ? memefootertextfont : 'Finger Paint'};font-size:${memefooterfontSize}px;'>
                Telegram
            </div>
        </div>
        <div class="wrap" style="cursor: pointer;" onclick="OpenTemplate3Uniswap()">
            <img
                class="containerIcon15"
                alt="6"
                src="${Template3Container}"
            />
            <div class="linkDextools" style='color:${memefootertextcolor};font-family:${memefootertextfont.length > 0 ? memefootertextfont : 'Finger Paint'};font-size:${memefooterfontSize}px;'>
                Dextools
            </div>
        </div>
        
    </div>
    <div class="Section7">
    <div class="mainbanner">
        <div class="innerMain">
            <img
                class="containerIcon12"
                alt="3"
                src="${template1footerImage.length > 0 ? template1footerImage : Template3FooterSunppd}"
            />
        </div>
    </div>
    <div class="Footer">
        <div class="Sect7Code" style='background-color:${statsbackgroundcolorT3}; border: ${statsborder.length > 0 ? statsborder : '8'}px solid ${statsbordercolor != 'white' ? statsbordercolor : 'black'}; border-radius: ${statsborderradius.length > 0 ? statsborderradius : '10'}px; box-shadow: ${statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'};'>
            <div class="ca0x3927fb89f34bbee63351a63" style='color:${memefootertextcolor};font-family:${memefootertextfont.length > 0 ? memefootertextfont : 'Finger Paint'};font-size:${memefooterfontSize}px;'>
                ${template3memeaddress}
            </div>
        </div>
        <div class="Sect7last" style="cursor: pointer;" onclick="OpenTemplate3Email()">
            <img
                class="containerIcon11"
                alt="2"
                src="${Template3FooterEmail}"
            />
            <h4 style='color:${memefootertextcolor};font-family:${memefootertextfont.length > 0 ? memefootertextfont : 'Finger Paint'};font-size:${memefooterfontSize}px;'>${template3email}</h4>
        </div>
    </div>
</div>


</div>


      </div>
    </body>
    </html >`

        const NewmemeTemplate5 = `
    <!DOCTYPE html>
    <html lang="en" >
     <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0"
    <head>
       <title>${memecoinsymbol}</title>
    <style>
              





    .wrapper {
      width: 100%;
      overflow-x: hidden;
     position: relative;
  
    }
    
   
    .header {
      text-align: center;
      
      padding: 50px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      
    }
    
    .logo {
      width: 50%;
      height: 50%;
      margin-bottom: 20px;
    }
    
    .button {
      background-color: #f44336;
     
      padding: 10px 20px;
      border: none;
      cursor: pointer;
      font-size: 16px;
      margin-top: 20px;
      width: 150px;
      overflow: hidden;
     
    }
    
    .button:hover {
      background-color: #d32f2f;
    }
    
   
    .exchanges {
      text-align: center;
      background-color: #121212;
      padding: 50px 0;
      color: white;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
    
    .exchangesGrid {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      width: 65%;
      height: 100%;
      align-items: start;
    }
    
    .exchangeIcon {
      width: 250px;
      height: 100px;
      margin: 20px;
    }
    
    
    .howToBuy {
      text-align: center;
     
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    
    .buySteps {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      align-items: center;
       width: 100%;
       height: 100%;
    }
    
    .buyStep {
      width: 23%;
      position: relative;
      background-color: #1d1d1d;
      height: 224px;
      text-align: left;
      font-size: 30px;
      color: #fff;
      font-family: 'Luckiest Guy';
      }
    
    
   
    .helmet {
      text-align: center;
      
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    
    .helmetImage {
      width: 200px;
      margin-bottom: 20px;
    }
    
    
    .tokenomics {
      text-align: center;
      
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: white;
    }
      
    
   
    .socials {
      text-align: center;
      
     display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    
    .socialIcons {
      display: flex;
      justify-content: center;
    }
    
    .socialIcon {
      width: 50px;
      margin: 10px;
    }
    
    
    @media (max-width: 768px) {
      .header, .exchanges, .howToBuy, .helmet, .tokenomics, .socials {
        padding: 30px 0;
      }
    
      .buySteps {
        flex-direction: column;
      }
    
      .buyStep {
        width: 100%;
      }
    
      .exchangesGrid {
        flex-direction: column;
      }
    
      .socialIcons {
        flex-direction: column;
      }
    }
  
  
    .poweredBySolana {
      width: 50%;
      position: relative;
      font-size: 20px;
      line-height: 28px;
      
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      height: fit-content;
     
      overflow: hidden;
  }

     
  
     #google_translate_element {
  width: 300px;
  float: right;
  text-align: right;
  display: block;
}
.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
body {
  top: 0px !important;
}
#goog-gt-tt {
  display: none !important;
  top: 0px !important;
}
.goog-tooltip skiptranslate {
  display: none !important;
  top: 0px !important;
}
.activity-root {
  display: hide !important;
}
.status-message {
  display: hide !important;
}
.started-activity-container {
  display: hide !important;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
  display: none;
}

.VIpgJd-yAWNEb-VIpgJd-fmcmS-sn54Q {
  display: none;
  background-color: unset !important;
  box-shadow: none !important;
}

.Icons{
position: relative;
 width: 70%;
  height: 100%; 
  display: flex; 
  justify-content: space-around;
 align-items: center;
}

.Helemtdiv{
 width: 100%;
  height: 100%; 
  position: relative;
   display: flex; 
   align-items: center;
    justify-content: center;
}
@media screen and (max-width: 725px) {
.Icons {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}

.Helemtdiv {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
}


.SectionTwo{
   display: flex; 
   justify-content: center;
     align-items: center; 
}
.HemeltText{
 font-size: 90px;
}
 .TokenText{
  font-size: 90px;
 }
  .TokenImageDIv{
 
  
  display: flex;
   align-items: center;
    justify-content: center;
 }

 .howtobuy{
  font-size: 86px;
 }
  .Socialtext{
   font-size: 90px;
  }

  .SocailIcon{
   
   display: flex; 
   align-items: center;
    justify-content: space-evenly;
   }

   .abouttext{
    width: 90%;
   }

@media screen and (max-width: 725px) {
    .SectionTwo{
   display: flex; 
   justify-content:center; 
   align-items:center; 
   flex-direction: column;
}
   .HemeltText{
 font-size: 30px;
}
   .TokenText{
  font-size: 30px;
 }
 .TokenImageDIv{
  flex-direction: column;
  display: flex;
   align-items: center;
    justify-content: center;
 }
    .howtobuy{
  font-size: 30px;
 }
  .Socialtext{
   font-size: 30px;
  }
   .SocailIcon{
   
   display: flex; 
   align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
   }

   .abouttext{
    width: 90vw;
   }



}






  
  
    

    </style>
    </head>
    <body>
   
           <div class='wrapper'>
                       <section class="header"
          style="background-color: ${memebackground == 0 ? memenavbarbgcolor.length == 0 ? '#f6b400' : memenavbarbgcolor : ''}; 
              background-image: ${memebackground == 1 ? memenavbgcolor.length !== 0 ? `url(${memenavbgcolor})` : '' : ''}; 
              height: 100%; width: 100%; object-fit: cover; background-size: cover;">
              <div style="position: absolute; right: 20px; top: 5px; cursor: pointer;">
  <div onclick="toggleGoogleTranslate()">EN</div>

  <div
      style="background-color: transparent; 
             padding: 0px 8px; 
             margin-right: 10px; 
             border-radius: 8px; 
             display: ${googletransalte == false ? 'none' : 'block'};"
      id="google_translate_element">
  </div>
</div>


  <img src="${memecoinlogoImage.length > 0 ? memecoinlogoImage : T5image1}" alt="Ponke Logo" class="logoT5" />
  
  <h1 class="poweredBySolana" 
      style="color: ${memecoinabouttextcolor.length > 0 ? memecoinabouttextcolor : '#000'}; 
             font-family: ${memecoinaboutfont.length > 0 ? memecoinaboutfont : 'Helvetica Neue'};">
      ${t5Nav1}
  </h1>
  
  <button class="button" 
          style="color: ${memecoinabouttextcolor.length > 0 ? memecoinabouttextcolor : 'white'}; 
                 font-family: ${memecoinaboutfont.length > 0 ? memecoinaboutfont : 'Helvetica Neue'}; cursor: pointer;"  onclick="window.open('${template2cmc}')">
      ${t5nav2} ${memecoinname}
  </button>
</section>
          <div class="SectionTwo" style="position: relative; width: 100%; height: 100%; 
          background-color: ${memeherobackground == 0 ? memeherobgcolor.length == 0 ? '#f6b400' : memeherobgcolor : ''}; 
          background-image: ${memeherobackground == 1 ? memecoinaboutbgImage.length !== 0 ? `url(${memecoinaboutbgImage})` : '' : ''}; 
          background-repeat: no-repeat; background-size: cover; background-position: center;">
  
  <div style="position: relative; width: 50%; height: 100%; display: flex; justify-content: center; align-items: center;">
      <img src="${memecoinAminitionImage.length > 0 ? memecoinAminitionImage : T5image2}" alt="Ponke Logo" height="100%" width="90%">
  </div>
  
  <div style="position: relative; width: 85%; height: 100%;  ">
      <div class="abouttext" style=" 
          font-size: 28.8px; 
          color: ${memecoinabouttextcolor.length > 0 ? memecoinabouttextcolor : '#000'}; 
          display: flex; 
          align-items: center; 
          justify-content: center; 
          overflow: hidden; 
          word-wrap: break-word; 
          font-family: ${memecoinaboutfont}; 
          line-height: 1.5;">
  ${memecoinname} ${memecoinAbout}
</div>

      
      <div class='Icons'>
          <img src="${T5image3}" alt="Ponke Logo" height="50px" style="cursor: pointer;" onclick="window.open('${template2cmc}')">
          <img src="${T5image4}" alt="Ponke Logo" height="50px" style="cursor: pointer;" onclick="window.open('${memetwitterlink}')">
          <img src="${T5image5}" alt="Ponke Logo" height="50px" style="cursor: pointer;" onclick="window.open('${memetelegramlink}')">
          <img src="${T5image6}" alt="Ponke Logo" height="50px" style="background-color: #000; border-radius: 50%; padding: 8px; cursor: pointer;" onclick="window.open('${memeinstagramlink}')">
      </div>
  </div>
</div>
                 <section class="helmet" 
                  style="background-color: ${memeaboutbackground == 0 ? memeaboutbgcolor.length == 0 ? '#f6b400' : memeaboutbgcolor : ''}; 
              background-image: ${memeaboutbackground == 1 ? memeaboutbgimage.length !== 0 ? `url(${memeaboutbgimage})` : '' : ''}; 
              height: fit-content; width: 100%; object-fit: cover; background-size: cover;">
  
  <div>
      <h2 class="HemeltText" style="width: 100%; position: relative;  text-align: center; display: flex; align-items: center; 
                 justify-content: center; height: fit-content; overflow: hidden; 
                 font-family: ${memecoinvisionfont.length > 0 ? memecoinvisionfont : 'Helvetica Neue'}; 
                 color: ${memecoinvisiontextcolor.length > 0 ? memecoinvisiontextcolor : '#000'};">
          ${t5about1}
      </h2>
  </div>
  
  <div class='Helemtdiv'>
      <div style="width: 50%; height: 100%; position: relative; display: flex; align-items: center; justify-content: center; padding: 5%;">
          <img src="${memecoinavisionrightimage.length > 0 ? memecoinavisionrightimage : T5image18}" alt="T5image18" height="80%" width="350px">
      </div>
      
      <div style="width: 50%; height: 100%; position: relative; display: flex; align-items: center; justify-content: center; flex-direction: column; padding-left: -5%;">
          <h3 style="width: 80%; position: relative; font-size: 30px; text-align: center; display: flex; align-items: center; justify-content: center; 
                     height: fit-content; overflow: hidden;  
                     font-family: ${memecoinvisionfont.length > 0 ? memecoinvisionfont : 'Helvetica Neue'}; 
                     color: ${memecoinvisiontextcolor.length > 0 ? memecoinvisiontextcolor : '#000'};">
              ${t5about2}
          </h3>
          <img src="${T5image19}" alt="T5image19" width="300px" height="100px" style="cursor: pointer;" onclick="window.open('${template2cmc}')">
      </div>
  </div>
</section>
                      
                     <section class="tokenomics" 
       style="background-image: ${memetokenomicsbackground == 1 ? memecointokonomicsbgimage.length > 0 ? `url(${memecointokonomicsbgimage})` : '' : ''}; 
              width: 100%; height: fit-content; object-fit: cover; background-size: cover; 
              background-color: ${memetokenomicsbackground == 0 ? memetokenomicsbgcolor.length == 0 ? '#121212' : memetokenomicsbgcolor : ''};">
  
  <div style="width: 100%; height: 100%; position: relative; display: flex; align-items: center; justify-content: center; flex-direction: column;">
      <h3 class="TokenText" style=" width: 90%; height: fit-content; display: flex; align-items: center; justify-content: center; 
                 overflow: hidden; text-align: center; position: relative; 
                 font-family: ${memecointokenfont.length > 0 ? memecointokenfont : 'Helvetica Neue'}; 
                 color: ${memecointoketextcolor.length > 0 ? memecointoketextcolor : '#f6b400'};">
          ${t5Token1}
      </h3>
      
      <p style="font-size: 30px; width: 90%; height: fit-content;  display: flex; align-items: center; justify-content: center; 
                overflow: hidden; text-align: center; position: relative; 
                font-family: ${memecointokenfont.length > 0 ? memecointokenfont : 'Helvetica Neue'}; 
                color: ${memecointoketextcolor.length > 0 ? memecointoketextcolor : '#FFFFFF'};">
          ${t5Token3}
      </p>
      
      <p style="font-size: 30px; width: 90%; height: fit-content;  display: flex; align-items: center; justify-content: center; 
                overflow: hidden; text-align: center; position: relative; 
                font-family: ${memecointokenfont.length > 0 ? memecointokenfont : 'Helvetica Neue'}; 
                color: ${memecointoketextcolor.length > 0 ? memecointoketextcolor : '#FFFFFF'};">
          ${t5Token4}
      </p>
  </div>
  
  <div class="TokenImageDIv"style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;">
      <div style="width: 50%; height: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center; padding: 5%; gap: 10px;">
          <img src="${memecointokenomicsimage.length > 0 ? memecointokenomicsimage : T5image20}" height="90%" width="90%">
          <img src="${T5image3}" height="70px" width="50%" style="cursor: pointer;" onclick="window.open('${template2cmc}')">
      </div>
      
      <div style="width: 50%; height: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column; gap: 10px;">
          <img src="${newiconsImage.length > 0 ? newiconsImage : T5image22}" width="40%" height="20%">
          
          <div style="background-color: #f6b400; width: 90%; border: 4px solid #000; height: 100%; 
                      overflow: hidden; display: flex; align-items: center; flex-direction: column; justify-content: center; 
                      font-size: 30px; text-align: center; position: relative; 
                      font-family: ${memecointokenfont.length > 0 ? memecointokenfont : 'Helvetica Neue'}; 
                      color: ${memecointoketextcolor.length > 0 ? memecointoketextcolor : '#000'};">
              <p>TOTAL SUPPLY</p>
              <p style="font-size: 20px;">${t5Token5}</p>
          </div>
          
          <div style="background-color: #f6b400; width: 90%; border: 4px solid #000; height: 100%;
                      overflow: hidden; display: flex; align-items: center; flex-direction: column; justify-content: center; 
                      font-size: 30px; text-align: center; position: relative; 
                      font-family: ${memecointokenfont.length > 0 ? memecointokenfont : 'Helvetica Neue'}; 
                      color: ${memecointoketextcolor.length > 0 ? memecointoketextcolor : '#000'};">
              <p>TOKEN ADDRESS</p>
              <p style="font-size: 20px;line-break:anywhere; ">${t5Token6}</p>
          </div>
      </div>
  </div>
</section>
                    <section class="howToBuy" 
       style="background-image: ${memestepbackground == 1 ? `url(${memestepbgimage})` : ''}; 
              height: 100%; width: 100%; background-size: cover; object-fit: cover; 
              background-color: ${memestepbackground == 0 ? memestepbgcolor.length == 0 ? '#f6b400' : memestepbgcolor : ''};">
  
  <h2 class="howtobuy" style="width: 70%; position: relative;  text-align: center; display: flex; align-items: center; justify-content: center; 
             height: fit-content; overflow: hidden; word-break: break-word; 
             font-family: ${memecoinstepfont.length > 0 ? memecoinstepfont : 'Helvetica Neue'}; 
             color: ${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#000'};">
      ${stepheading}
  </h2>
  
  <span style="width: 75%; position: relative; font-size: 30px; text-align: center; display: flex; align-items: center; justify-content: center; 
               height: fit-content; overflow: hidden;  word-break: break-word; 
               font-family: ${memecoinstepfont.length > 0 ? memecoinstepfont : 'Helvetica Neue'}; 
               color: ${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#000'};">
      Follow these easy steps to become a ${memecoinname} holder.
  </span>
  
  <div class="buySteps">
      
      <div class="buyStep">
          <h3 style="width: 100%; position: relative; font-size: 25px; text-align: center; display: flex; align-items: start; justify-content: center; 
                      overflow: hidden;  
                     font-family: ${memecoinstepfont.length > 0 ? memecoinstepfont : 'Helvetica Neue'}; 
                     color: ${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff'};">
              1. Create a Wallet
          </h3>
          <p style="width: 95%; position: relative; font-size: 14px; text-align: center; display: flex; align-items: start; justify-content: center; 
                     overflow: hidden; padding: 10px; 
                    font-family: ${memecoinstepfont.length > 0 ? memecoinstepfont : 'Helvetica Neue'}; 
                    color: ${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : 'rgba(255, 255, 255, 0.8)'};">
              ${t5step1}
          </p>
      </div>
      
      <div class="buyStep">
          <h3 style="width: 100%; position: relative; font-size: 25px; text-align: center; display: flex; align-items: start; justify-content: center; 
                      overflow: hidden;  
                     font-family: ${memecoinstepfont.length > 0 ? memecoinstepfont : 'Helvetica Neue'}; 
                     color: ${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff'};">
              2. Get some SOL
          </h3>
          <p style="width: 95%; position: relative; font-size: 14px; text-align: center; display: flex; align-items: start; justify-content: center; 
                     padding: 10px; overflow: hidden; 
                    font-family: ${memecoinstepfont.length > 0 ? memecoinstepfont : 'Helvetica Neue'}; 
                    color: ${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : 'rgba(255, 255, 255, 0.8)'};">
              ${t5step2}
          </p>
      </div>
      
      <div class="buyStep">
          <h3 style="width: 100%; position: relative; font-size: 25px; text-align: center; display: flex; align-items: start; justify-content: center; 
                      overflow: hidden; 
                     font-family: ${memecoinstepfont.length > 0 ? memecoinstepfont : 'Helvetica Neue'}; 
                     color: ${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff'};">
              3. Swap $SOL for ${memecoinname}
          </h3>
          <p style="width: 95%; position: relative; font-size: 14px; text-align: center; display: flex; align-items: start; justify-content: center; 
                    padding: 10px; overflow: hidden;  
                    font-family: ${memecoinstepfont.length > 0 ? memecoinstepfont : 'Helvetica Neue'}; 
                    color: ${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : 'rgba(255, 255, 255, 0.8)'};">
              ${t5step3}
          </p>
      </div>
      
      <div class="buyStep">
          <h3 style="width: 100%; position: relative; font-size: 25px; text-align: center; display: flex; align-items: start; justify-content: center; 
                      overflow: hidden; 
                     font-family: ${memecoinstepfont.length > 0 ? memecoinstepfont : 'Helvetica Neue'}; 
                     color: ${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff'};">
              4. You are now a ${memecoinname} Holder!
          </h3>
          <p style="width: 95%; position: relative; font-size: 14px; text-align: center; display: flex; align-items: start; justify-content: center; 
                     padding: 10px; overflow: hidden; 
                    font-family: ${memecoinstepfont.length > 0 ? memecoinstepfont : 'Helvetica Neue'}; 
                    color: ${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : 'rgba(255, 255, 255, 0.8)'};">
              ${t5step4}
          </p>
      </div>
      
  </div>
</section>

                  <section class="socials" 
       style="background-image: ${memefootrbackground == 1 ? memefooterbgimage.length !== 0 ? `url(${memefooterbgimage})` : '' : ''}; 
              object-fit: cover; background-size: cover; 
              background-color: ${memefootrbackground == 0 ? memefooterbgcolor.length == 0 ? '#f6b400' : memefooterbgcolor : ''};">
  
  <div style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column;">
      <h3 class="Socialtext" style="width: 50%; font-family: 'Helvetica Neue';  height: fit-content;  overflow: hidden;
      font-family: ${memefootertextfont.length > 0 ? memefootertextfont : 'Helvetica Neue'}; 
                     color: ${memefootertextcolor.length > 0 ? memefootertextcolor : '#000'};">
      
          SOCIALS
      </h3>
      <p style="width: 50%; font-family: 'Helvetica Neue'; font-size: 30px; height: fit-content;  overflow: hidden;
        font-family: ${memefootertextfont.length > 0 ? memefootertextfont : 'Helvetica Neue'}; 
                     color: ${memefootertextcolor.length > 0 ? memefootertextcolor : '#000'};
      ">
          Join the ${memecoinname} community
      </p>
  </div>
  
  <div  class="SocailIcon"style="width: 50%; height: 100%; ">
      <img src="${T5image4}" alt="Ponke Logo" height="50px" style="cursor: pointer;" onclick="window.open('${memetwitterlink}')">
      <img src="${T5image5}" alt="Ponke Logo" height="50px" style="cursor: pointer;" onclick="window.open('${memetelegramlink}')">
      <img src="${T5image6}" alt="Ponke Logo" height="50px" style="background-color: #000; border-radius: 50%; padding: 8px; cursor: pointer;" onclick="window.open('${memeinstagramlink}')">
  </div>
  
  <div style="display: flex; width: 100%; align-items: center; justify-content: space-around; gap: 30px; padding: 2%;">
      <div style="display: flex; width: 50%; align-items: center; justify-content: center; height: 100%;">
          <img src="${memecoinAminitionImage.length > 0 ? memecoinAminitionImage : T5image2}" height="90%" width="70%">
      </div>
      <div style="display: flex; width: 50%; align-items: center; justify-content: center; height: 100%; flex-direction: column;">
          <img src="${T5image3}" height="20%" width="40%" style="cursor: pointer;" onclick="window.open('${template2cmc}')">
      </div>
  </div>

</section>



           </div>

              
             
            
        
              
    </body>
       <script>
  let googletransalte = ${googletransalte};

  function toggleGoogleTranslate() {
      googletransalte = !googletransalte;
      document.getElementById('google_translate_element').style.display = googletransalte ? 'block' : 'none';
  }
</script>
    <script type="text/javascript">
      function googleTranslateElementInit() {
          new google.translate.TranslateElement(
              { pageLanguage: 'en' },
              'google_translate_element'  
          );
      }
  </script>
  <script
      type="text/javascript"
      src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
  ></script>
    </html>
    `

        const NewmemeTemplate6 = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
       <title>${memecoinsymbol}</title>
   <meta charset="utf-8" />
<meta name="viewport" content="width=device-width, initial-scale=1" />
<meta name="theme-color" content="#000000" />
<meta name="description" content="Web site created using create-react-app" />

      <style>
         .buyPremt {
  background-color: #d93731;
  width: 150px;
  height: 57.8px;
  text-transform: uppercase;
  font-family: 'Inter';
  color: #fff;
  display: flex;
  font-weight: 800;
  align-items: center;
  justify-content: center;
  border-radius: 6.4px;
  padding: 10px;
  overflow: hidden;
}

.NavLabel {
  text-transform: uppercase;
  font-weight: 800;
  font-family: 'Inter';
  color: #254a9a;
  text-align: right;
  display: flex;
  align-items: center;
}


.mekMemesGretContainer {
  width: 50vw;
  position: relative;
  font-size: 87.94px;
  letter-spacing: -1.16px;
  line-height: 107.11px;
  text-transform: uppercase;
  font-weight: 800;
  font-family: 'Inter';
  color: #fff;
  text-align: left;
  display: flex;
  align-items: center;
  height: 100%;
  overflow: hidden;
  }
  
  .Navbar{
     height: 100%; 
     width: 100vw;
      display: flex; 
      align-items: center;
       gap:20px;
       padding-top: 100px;
  }

   @media only screen and (max-width: 700px) {
         .Navbar{
     height: 100%; 
     width: 100vw;
      display: flex; 
      align-items: center;
       gap:20px;
       flex-direction: column;
  }
       .Navbartitle{
       position: relative;
        width: 100vw;
         display: flex;
          flex-direction: row;
           align-items: center;
            justify-content: space-between;
   }
   } 
  
   @media only screen and (min-width: 701px) {
       .Navbartitle{
       position: relative;
        width: 80vw;
         display: flex;
          flex-direction: row;
           align-items: center;
            justify-content: space-between;
   }
   }
   @media only screen and (max-width: 489px) {
              .Navbartitle{
       position: relative;
        width: 100vw;
         display: flex;
          flex-direction: column;
           align-items: center;
            justify-content: space-between;
   }
         } 
   
         

      #google_translate_element {
  width: 300px;
  float: right;
  text-align: right;
  display: block;
}
.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
body {
  top: 0px !important;
}
#goog-gt-tt {
  display: none !important;
  top: 0px !important;
}
.goog-tooltip skiptranslate {
  display: none !important;
  top: 0px !important;
}
.activity-root {
  display: hide !important;
}
.status-message {
  display: hide !important;
}
.started-activity-container {
  display: hide !important;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
  display: none;
}

.VIpgJd-yAWNEb-VIpgJd-fmcmS-sn54Q {
  display: none;
  background-color: unset !important;
  box-shadow: none !important;
}
  .footerdiv2{
     width: 100%;
    }
     .howtobuy1{
      flex-direction: row;
      padding: 50px;
     }
      .howtobuy2{
       width: 70%;
      }
       .Main2{
        width: 50%;
       }
        .Main1{
            padding: 20px;
        }

        Hero2{
         width: 50vw;
        }
  @media only screen and (max-width: 700px) {
    .footerdiv1{
    flex-direction: column;
    }
    .footerdiv2{
     width: 100%;
    }
     .footerdiv3{
     flex-direction: column;
     }
     .howtobuy1{
      flex-direction: column;
      padding: 0px;
     }
      .howtobuy2{
       width:100%;
      }
       .Main1{
        flex-direction: column;
       }
        .Main2{
         width: 100%;
        }
         .Hero1{
           flex-direction: column;
         }
           .Hero2{
            width: 100%;
           }
            .mekMemesGretContainer{
            width: 100%;
            }
  }
  
    
      </style>
      </head>
      <body>
      
 <div style='position: relative; height: 100%; width: 100%; overflow-x: hidden; '>
           
<div style="position: absolute; right: 2px; top: 1px; cursor: pointer;">
  <div onclick="toggleGoogleTranslate()">EN</div>

  <div
      style="background-color: transparent; 
             padding: 0px 8px; 
             margin-right: 10px; 
             border-radius: 8px; 
             display: ${googletransalte == false ? 'none' : 'block'};"
      id="google_translate_element">
  </div>
</div>
                      <div class="Navbar" style="position: relative; background-image: url('${memenavbgcolor}'); object-fit: cover; background-size: cover; background-color: '${memenavbarbgcolor}'">
  
  
  <div style="position: relative; display: flex; align-items: center; padding: 10px; padding-left: 35px;">
      <img src="${memecoinlogoImage.length > 0 ? memecoinlogoImage : T4Navlogo}"alt="logo" height="70px">
  </div>

  <div class="Navbartitle">
      <span class="NavLabel" style="font-family: '${memecoinanvfont}'; color: '${memenavtextcolor}'; font-size: '${memenavfontsize}px';">
          ${Navbar[0]}
      </span>
      <span class="NavLabel" style="font-family: '${memecoinanvfont}'; color: '${memenavtextcolor}'; font-size: '${memenavfontsize}px';">
          ${Navbar[1]}
      </span>
      <span class="NavLabel" style="font-family: '${memecoinanvfont}'; color: '${memenavtextcolor}'; font-size: '${memenavfontsize}px';">
          ${Navbar[2]}
      </span>
      <span class="NavLabel" style="font-family: '${memecoinanvfont}'; color: '${memenavtextcolor}'; font-size: '${memenavfontsize}px';">
          ${Navbar[3]}
      </span>
      <button class="buyPremt" style="font-family: '${memecoinanvfont}'; color: '${memenavtextcolor}'; font-size: '${memenavfontsize}px';">
          ${t4navbutton}
      </button>
  </div>
</div>

               <div
  style="
      position: relative;
      height: 100%;
      width: 100%;
      background-image: url('${memecoinaboutbgImage.length > 0 ? memecoinaboutbgImage : T4MainSectionImage}');
      background-position-y: center;
      background-repeat: no-repeat;
      background-size: cover;
  "
>
  <div
    class='Hero1'
      style="
          background-color: ${memeherobackground == 1 ? memeherobgcolor : '#254a9a'};
          opacity: 0.9;
          position: relative;
          height: 100%;
          width: 100vw;
          display: flex;
          justify-content: center;
          align-items: center;
      "
  >
  
      <div
       class="Hero2"
          style="
              color: white;
            
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
              margin-left: 50px;
             
          "
      >
          <div
              class="mekMemesGretContainer"
              style="
                  color: ${memecoinabouttextcolor};
                  font-family: ${memecoinaboutfont};
                  font-size: ${memeMainfontsize}px;
              "
          >
              ${t4caption}
          </div>
          <div
              style="
                  color: ${memecoinabouttextcolor};
                  font-family: ${memecoinaboutfont};
                  font-size: ${memeMainfontsize}px;
              "
          >
              ${t4memeaddress}
          </div>
          <div
              style="
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  gap: 20px;
                  margin-top: 30px;
              "
          >
              <button
                  style="
                      width: 150px;
                      position: relative;
                      border-radius: 6.4px;
                      background-color: #d93731;
                      height: 69.2px;
                      text-align: center;
                      font-size: 16px;
                      color: ${memecoinabouttextcolor.length > 0 ? memecoinabouttextcolor : '#fff'};
                      font-family: 'Inter';
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      font-family: ${memecoinaboutfont};
                      font-size: ${memeMainfontsize}px;
                      cursor: pointer;
                  "
                  onclick="window.open('https://app.uniswap.org/')"
              >
                  ${t4mainbutton1}
              </button>
              <button
                  style="
                      width: 150px;
                      position: relative;
                      border-radius: 6.4px;
                      background-color: blue;
                      height: 69.2px;
                      text-align: center;
                      font-size: 16px;
                      color: ${memecoinabouttextcolor.length > 0 ? memecoinabouttextcolor : '#fff'};
                      font-family: 'Inter';
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      font-family: ${memecoinaboutfont};
                      font-size: ${memeMainfontsize}px;
                      cursor: pointer;
                  "
                 
                  onclick="window.open('${memetwitterlink}')"
              >
                  ${t4mainbutton2}
              </button>
          </div>
      </div>
      <div
          style="
              color: white;
              width: 50vw;
              display: flex;
              align-items: center;
              justify-content: center;
          "
      >
          <img
              src="${memecoinAminitionImage.length > 0 ? memecoinAminitionImage : T4Memelogo}"
              alt="logo"
              style="height: 100%;"
          />
      </div>
  </div>
</div>
                <div class='Main1'
  style="
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      
      background-color: ${memeaboutbackground == 0 ? (memeaboutbgcolor.length == 0 ? 'white' : memeaboutbgcolor) : ''};
      background-image: ${memeaboutbackground == 1 ? (memeaboutbgimage.length !== 0 ? `url(${memeaboutbgimage})` : '') : ''};
      object-fit: cover;
      background-size: cover;
  "
>
  <div
      style="
          position: relative;
          height: 100%;
          width: 50vw;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 20px;
      "
  >
      <div
          style="
              line-height: 71.64px;
              text-transform: uppercase;
              font-weight: 800;
              font-family: Inter;
              color: ${memecoinvisiontextcolor.length > 0 ? memecoinvisiontextcolor : '#254a9a'};
              text-align: left;
              display: flex;
              align-items: center;
              height: 71.6px;
              overflow: hidden;
              font-family: ${memecoinvisionfont};
              font-size: ${memeaboutfontSize}px;
          "
      >
          ${memecoinname}
      </div>
      <div
          style="
              width: 50vw;
              position: relative;
              font-size: 14.5px;
              letter-spacing: -0.19px;
              line-height: 29.18px;
              font-family: Inter;
              color: ${memecoinvisiontextcolor.length > 0 ? memecoinvisiontextcolor : '#254a9a'};
              text-align: left;
              display: flex;
              align-items: center;
              height: 100%;
              margin-top: 10px;
              overflow: hidden;
              font-family: ${memecoinvisionfont};
              font-size: ${memeaboutfontSize}px;
             
          "
      >
          ${memecoinAbout}
      </div>
      <div
          style="
              width: 179.7px;
              position: relative;
              font-size: 19.5px;
              letter-spacing: -0.19px;
              line-height: 29.18px;
              font-family: Inter;
              color: ${memecoinvisiontextcolor.length > 0 ? memecoinvisiontextcolor : '#254a9a'};
              text-align: left;
              display: flex;
              align-items: center;
              height: 29.2px;
              overflow: hidden;
              font-family: ${memecoinvisionfont};
              font-size: ${memeaboutfontSize}px;
          "
      >
          ${memecoinsymbol}
      </div>
      <div>
          <img src="${T4twitter}" alt="Twitter" height="46px" style="cursor: pointer;" onclick="window.open('${memetwitterlink}')">
          <img src="${T4telegram}" alt="Telegram" height="46px" style="cursor: pointer;" onclick="window.open('${memetelegramlink}')">
          <img src="${T4Instagram}" alt="Instagram" height="46px" style="cursor: pointer;" onclick="window.open('${memeinstagramlink}')">
          <img src="${T4trend}" alt="Trend" height="46px" style="cursor: pointer;" onclick="window.open('${memewarpcastlink}')">
      </div>
  </div>
  <div
    class='Main2'
      style="
          position: relative;
          height: 100%;
          
          display: flex;
          justify-content: center;
          align-items: center;
      "
  >
      <img src="${memecoinavisionleftimage.length > 0 ? memecoinavisionleftimage : T4MemeImage1}" alt="Vision Image" height="100%" width="100%">
  </div>
</div>
            <div
  style="
      background-image: ${memestepbackground == 0 ? `url(${memestepbgimage})` : `url(${T4Section3bgImage})`};
      position: relative;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
  "
>
  <div
  class='howtobuy1'
      style="
          position: relative;
          height: 100%;
          width: 100vw;
          background-color: ${memestepbackground == 1 ? memestepbgcolor : '#254a9a'};
          opacity: 0.9;
          display: flex;
          justify-content: center;
          align-items: center;
          
      "
  >
      <div
          style="
              color: white;
              position: relative;
              height: 100%;
              width: 50vw;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
          "
      >
          <span
              style="
                  position: relative;
                  font-size: 81.93px;
                  letter-spacing: -1.16px;
                  line-height: 107.11px;
                  text-transform: uppercase;
                  font-weight: 800;
                 
              
                  text-align: left;
                  display: flex;
                  align-items: center;
<<<<<<< HEAD
                  height: 100%;
                  width:50vw;
=======
                  height: 107.1px;
>>>>>>> 7d16ccbd0c19929c3e2a9ade556e914b12337507
                  font-family:${memecoinstepfont.length > 0 ? memecoinstepfont : 'Inter'};color:${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff'};
              "
          >
              ${t4step1}
          </span>
          <img src="${t4stepimage.length > 0 ? t4stepimage : T4MemeImage2}" alt="logo" height="100%">
      </div>
      <div
          style="
              color: white;
              position: relative;
              height: 100%;
              width: 50vw;
              display: flex;
              align-items: flex-start;
              justify-content: center;
              flex-direction: column;
             
          "
      >
          <span
              style="
                  text-transform: uppercase;
                  font-weight: 800;
  
          
                  font-size: 17.1px;
                  width: 167.6px;
                  height: 29px;
                  font-family:${memecoinstepfont.length > 0 ? memecoinstepfont : 'Inter'};color:${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff'};

              "
          >
              CREATE A WALLET
          </span>
          <span
            class='howtobuy2'
              style="
                  font-size: 14.3px;
                 
                  
          
                  overflow: hidden;
                  height: 150px;
                  font-family:${memecoinstepfont.length > 0 ? memecoinstepfont : 'Inter'};color:${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff'};

              "
          >
              ${t4step2}
          </span>
          <span
              style="
                  text-transform: uppercase;
                  font-weight: 800;
          
  
                  font-size: 17.1px;
                  width: 157.6px;
                  height: 29px;
                  font-family:${memecoinstepfont.length > 0 ? memecoinstepfont : 'Inter'};color:${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff'};

              "
          >
              Get some sol
          </span>
          <span
          class='howtobuy2'
              style="
                  font-size: 14.3px;
              
              
                  overflow: hidden;
                  height: 150px;
                  font-family:${memecoinstepfont.length > 0 ? memecoinstepfont : 'Inter'};color:${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff'};

              "
          >
              ${t4step3}
          </span>
          <span
              style="
                  text-transform: uppercase;
                  font-weight: 800;
              
              
                  font-size: 17.1px;
                  width: 157.6px;
                  height: 29px;
                  font-family:${memecoinstepfont.length > 0 ? memecoinstepfont : 'Inter'};color:${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff'};

              "
          >
              GO TO JUPITER
          </span>
          <span
              class='howtobuy2'
              style="
                  font-size: 14.3px;
                
              
          
                  overflow: hidden;
                  height: 150px;
                  font-family:${memecoinstepfont.length > 0 ? memecoinstepfont : 'Inter'};color:${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff'};

              "
          >
              ${t4step4}
          </span>
          <span
              style="
                  text-transform: uppercase;
                  font-weight: 800;
                  
              
                  font-size: 17.1px;
                  width: 157.6px;
                  height: 29px;
                  font-family:${memecoinstepfont.length > 0 ? memecoinstepfont : 'Inter'};color:${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff'};

              "
          >
              SWAP FOR PREMT
          </span>
          <span
              class='howtobuy2'
              style="
                  font-size: 14.3px;
                
              
          
                  overflow: hidden;
                  height: 150px;
                  font-family:${memecoinstepfont.length > 0 ? memecoinstepfont : 'Inter'};color:${memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff'};

              "
          >
              ${t4step5}
          </span>
      </div>
  </div>
</div>      
       <div
  style="
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: ${memetokenomicsbackground == 1 ? memetokenomicsbgcolor : 'white'};
      
      background-image: ${memetokenomicsbackground == 0 ? (memecointokonomicsbgimage.length > 0 ? `url(${memecointokonomicsbgimage})` : '') : ''};
      object-fit: cover;
      background-size: cover;
  "
>
  <span
      style="
          font-weight: 800;
          font-family: Inter;
          color: ${memecointoketextcolor != 'white' ? memecointoketextcolor : '#0B0F3A'};
          font-size: 49.26px;
          width: 281.8px;
          height: 71.6px;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          font-family: ${memecointokenfont};
          font-size: ${memetokenfontSize}px;
      "
  >
      ${t4token}
  </span>
  <span
      style="
      
          
          font-size: 14.3px;
          width: 116.8px;
          height: 21.4px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: ${memecointokenfont.length > 0 ? `${memecointokenfont}` : "Inter"}; color: ${memecointoketextcolor.length > 0 ? `${memecointoketextcolor}` : "#d93731"};
      "
  >
      See more on X.
  </span>
  <img
      alt="logo"
      src="${memecointokenomicsimage.length > 0 ? memecointokenomicsimage : T4MemeImage3}"
      height="60%"
      width="80%"
  >
</div>
             <div
  style="
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 40px;
      padding-top: 46px;
      background-image: ${memefootrbackground == 1 ? (memefooterbgimage.length !== 0 ? `url(${memefooterbgimage})` : '') : ''};
      object-fit: cover;
      background-size: cover;
      background-color: ${memefootrbackground == 0 ? (memefooterbgcolor.length == 0 ? '#0b0f3a' : memefooterbgcolor) : ''};
  "
>
  <div style="
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 800;
      font-family: Inter;
      color: #fff;
      height: 71.6px;
      width: 315.3px;
      display: flex;
      align-items: center;
      justify-content: center;
     color:${memefootertextcolor.length > 0 ? `${memefootertextcolor}` : "white"};font-family:${memefootertextfont.length > 0 ? `${memefootertextfont}` : 'Inter'};

  ">
      ${t4footer4}
  </div>
  
  <div class='footerdiv3' style="
      color: white;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 70%;
      gap: 40px;
      padding:20px
  ">
      <span
          style="
              font-size: 16px;
  
              width: 274px;
              height: fit-content;
              display: flex;
              align-items: center;
              justify-content: center;
              overflow: hidden;
              word-break: break-word;
              color:${memefootertextcolor.length > 0 ? `${memefootertextcolor}` : "white"};font-family:${memefootertextfont.length > 0 ? `${memefootertextfont}` : 'Inter'};

          "
      >
          ${t4footer1}
      </span>
      
      <span
          style="
              font-size: 16px;
          
              width: 274px;
              height: fit-content;
              display: flex;
              align-items: center;
              justify-content: center;
              overflow: hidden;
              word-break: break-word;
              color:${memefootertextcolor.length > 0 ? `${memefootertextcolor}` : "white"};font-family:${memefootertextfont.length > 0 ? `${memefootertextfont}` : 'Inter'};

          "
      >
          ${t4footer2}
      </span>
      
      <span
          style="
              font-size: 16px;
              
              width: 274px;
              height: fit-content;
              display: flex;
              align-items: center;
              justify-content: center;
              overflow: hidden;
              word-break: break-word;
              color:${memefootertextcolor.length > 0 ? `${memefootertextcolor}` : "white"};font-family:${memefootertextfont.length > 0 ? `${memefootertextfont}` : 'Inter'};
          "
      >
          ${t4footer3}
      </span>
  </div>
</div>
               <div
  style="
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #254a9a;
  "
>
  <div
   class="footerdiv1"
      style="
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: 46px;
      "
     
  >
      <div
          style="
              position: relative;
              width: 50%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              
          "
      >
          <img src="${memecoinlogoImage.length > 0 ? memecoinlogoImage : T4Navlogo}" height="119.4px">
      </div>

      <div
       class="footerdiv2"
          style="
              position: relative;
             
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: 30px;
          "
      >
          <div>
              <img src="${T4footertelegram}" height="89.3px" style="cursor:pointer"  onclick="window.open('${memetwitterlink}')">
              <img src="${T4footerTwitter}" height="89.3px" style="cursor:pointer" onclick="window.open('${memetelegramlink}')">
              <img src="${T4footerInstagram}" height="89.3px" style="cursor:pointer" onclick="window.open('${memeinstagramlink}')">
              <img src="${T4footertrend}" height="89.3px" style="cursor:pointer"  onclick="window.open('${memewarpcastlink}')">
          </div>

          <span
              style='color:${memefootertextcolor.length > 0 ? `${memefootertextcolor}` : "white"};font-size:'14.3px';font-family:${memefootertextfont};
          >
              Media contact: realdolandPremt@proton.me
          </span>
      </div>
  </div>

  <div
      style="
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
      "
  >
      <hr
          style="
              position: relative;
              color: white;
              height: 2px;
              overflow: hidden;
              width: 90%;
              line-height: 1px;
          "
      >

      <span style='color:${memefootertextcolor.length > 0 ? `${memefootertextcolor}` : "white"};font-size:'10px';font-family:${memefootertextfont};>
          $${memecoinsymbol} has no association with Donald Trump. This token is simply a meme coin with no intrinsic value or expectation of financial return.
      </span>

      <span style='color:${memefootertextcolor.length > 0 ? `${memefootertextcolor}` : "white"};font-size:'10px';font-family:${memefootertextfont};>
          © 2024 by doland Premt $PREMT. All rights reserved.
      </span>
  </div>
</div>
   

        </div>
      </body>
       <script>
  let googletransalte = ${googletransalte};

  function toggleGoogleTranslate() {
      googletransalte = !googletransalte;
      document.getElementById('google_translate_element').style.display = googletransalte ? 'block' : 'none';
  }
</script>
    <script type="text/javascript">
      function googleTranslateElementInit() {
          new google.translate.TranslateElement(
              { pageLanguage: 'en' },
              'google_translate_element'  
          );
      }
  </script>
  <script
      type="text/javascript"
      src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
  ></script>

      </html>`
        console.log("randomId", randomId)

        var htmlContent1 = NavbarTemplate + HeroTemplate + TrustTemplate + ServiceTemplate + StatsTemplate + FaqTemplate + FooterTemplate;
        // var htmlContent2 = selectMemeCoinTemplates==1?MemeCoin:selectMemeCoinTemplates==1?NewmemeTemplate1:selectMemeCoinTemplates==2?NewmemeTemplate2
        // var htmlContent2 = selectMemeCoinTemplates === 1
        //     ? MemeCoin
        //     : selectMemeCoinTemplates === 2
        //         ? NewmemeTemplate1
        //         : selectMemeCoinTemplates === 4 ? NewmemeTemplate3 : NewmemeTemplate2;

        // var htmlContent2 = selectMemeCoinTemplates === 1
        //     ? MemeCoin
        //     : selectMemeCoinTemplates === 2
        //         ? NewmemeTemplate1
        //         : selectMemeCoinTemplates === 4
        //             ? NewmemeTemplate3
        //             : selectMemeCoinTemplates === 6
        //                 ? NewmemeTemplate6
        //                 : NewmemeTemplate2;


        var htmlContent2 = selectMemeCoinTemplates === 1
            ? MemeCoin
            : selectMemeCoinTemplates === 2
                ? NewmemeTemplate1
                : selectMemeCoinTemplates === 4
                    ? NewmemeTemplate3
                    : selectMemeCoinTemplates === 5
                        ? NewmemeTemplate5
                        : selectMemeCoinTemplates === 6
                            ? NewmemeTemplate6
                            : NewmemeTemplate2;


        if (memecoin == 'false') {
            var htmlContent = htmlContent1
            return htmlContent
        };
        if (memecoin == 'true') {

            var htmlContent = htmlContent2
            return htmlContent
        }
    };






    return (
        <PublishContext.Provider value={{ publishWebsite }}>
            {children}
        </PublishContext.Provider>
    );
}

export const usePublish = () => useContext(PublishContext);
