import React, { useEffect, useState } from "react";
import Memecoin from "../Memecoin";
import Theme from "../../Theme";
import TrustDesign from "../../SaveandEdit/Trustedby/trustedesing";
import MainNavBar from "../../MainNavBar";
import ContentDesign from "../../ContentDesign";
import { useContext } from "react";
import MyContext from "../../../context/MyContext";
import tokonmocismask from '../../../assets/images/tokonomicsmask.png'
import Contract from '../../../assets/images/Contract.svg'
import Database from '../../../assets/images/Database.svg'
import Circulating from '../../../assets/images/Circulating.png'
import Burned from '../../../assets/images/Burned.png'
import Liquidity from '../../../assets/images/Liquidity.svg'
import Renounced from '../../../assets/images/Renounced.svg'
import Tokonemoicsbgimage from '../../../assets/images/tokonmicsbg.svg'
import MemeTokendesing from "./Memetokendesing";
import { SketchPicker } from "react-color";
import axios from "axios";
import { useAccount } from "wagmi";




const MemeTokenamics = () => {
  const {
    memecoinname, SetMemecoinName,
    memecoinsymbol, setMemecoinSymbol,
    memecoinNavbar, setMemecoinNavbar,
    memecoinAbout, setMemecoinAbout, contentdesing,
    mainnavbarbutton,
    memeCaption, setMemecaption,
    memecoinValue, setMemecoinValue,
    memecoincontractaddress, setMemecoincontractAddress,
    memecoinInitialsupply, setMemecoinInitialsupply,
    memecoinCirculatingSupply, setMemecoinCirculatingSupply,
    memecoinSupplyBurned, setMemecoinSupplyBurned,
    memecoinLiquidity, setMemecoinLiquidity,
    memecointokenomicsimage, setMemecointokenomicsimage,
    memecoinContractImage, setMemecoinContractImage,
    memecoinDatabaseImage, setMemecoinDatabaseImage,
    memecoinCirculatingImage, setMemecoinCirculatingImage,
    memecoinBurnedImage, setMemecoinBurnedImage,
    memecoinLiquidityImage, setMemecoinLiquidityImage,
    memecoinRenouncedImage, setMemecoinRenouncedImage,
    memecointokonomicsbgimage, setMemecointokonomicsbgimage,
    removelogotokan, setRemovelogotoken,
    newaddtokest, setNewaddtokesst,
    iSremove1, setIsremove1,
    iSremove2, setIsremove2,
    iSremove3, setIsremove3,
    iSremove4, setIsremove4,
    iSremove5, setIsremove5,
    iSremove6, setIsremove6,
    iSremove, setIsremove,
    newiconsImage, setNewiconImage,
    memetokenomicsbgcolor, setMemetokenomicsbgcolor,
    setMemetokenomicsbackground, uniqueID,
    contractRenounced, setContractRenounced,
    selectMemeCoinTemplates,
    template1tokenimage2, setTemplate1tokenimage2,
    template1tokenimage3, setTemplate1tokenimage3,
    template1tokenomics, setTemplate1Tokenomics,
    template1tokenomicssub1, setTemplate1tokenomicssub1,
    template1tokenomicssub2, setTemplate1tokenomicssub2,
    template1tokenomicssub3, setTemplate1tokenomicssub3,
    template1tokenomisupply, setTemplate1tokenomisupply,
    template1tokenomicontract, setTemplate1tokenomicontract,
    template1tokenomiTax, setTemplate1tokenomiTax,
    template2tokenheading, setTemplate2tokenheading,
    template2tokensupply, setTemplate2tokensupply,
    template2tokentatesupply, setTemplate2tokentatesupply,
    template2tokentax, setTemplate2tokentax,
    template2tokenlp, setTemplate2tokenlp,
    template2tokenMeme, setTemplate2tokenMeme,
    template3section1, setTemplate3section1,
    template3Section2, setTemplate3section2,
    template3Section3, setTemplate3Section3,
    template3Section4, setTemplate3Section4,
    memetokencaption, setMemetokenCaption,
    memetokenbuy, setMemetokenBuy,
    t4token, setT4token,
    t5Token1, setT5token1,

    t5Token3, setT5token3,
    t5Token4, setT5token4,
    t5Token5, setT5token5,
    t5Token6, setT5token6,


  } = useContext(MyContext);


  const [addicons, setAddicons] = useState(false);
  const [shownewicon, setshownewicon] = useState(false);
  const [isHeaderDesignText, setisHeaderDesignText] = useState(false);

  // API to store meme tockenomics content data. For testing purpose using use effect

  const {address} = useAccount()

  useEffect(() => {

    const updateMemeTokenomicsContentData = async () => {

      const response = await axios.post('https://trendifyweb.ai/pyapi/updateMemeTokenomicsContentData', {
        params: {
          address: address,
          siteid: uniqueID,
          memecoinContractImage: memecoinContractImage,
          memecoincontractaddress: memecoincontractaddress,
          memecoinDatabaseImage: memecoinDatabaseImage,
          memecoinInitialsupply: memecoinInitialsupply,
          memecoinCirculatingImage: memecoinCirculatingImage,
          memecoinCirculatingSupply: memecoinCirculatingSupply,
          memecoinBurnedImage: memecoinBurnedImage,
          memecoinSupplyBurned: memecoinSupplyBurned,
          memecoinLiquidityImage: memecoinLiquidityImage,
          memecoinLiquidity: memecoinLiquidity,
          memecoinRenouncedImage: memecoinRenouncedImage,
          memecointokenomicsimage: memecointokenomicsimage,
          memecointokonomicsbgimage: memecointokonomicsbgimage,
          iSremove1: iSremove1,
          iSremove2: iSremove2,
          iSremove3: iSremove3,
          iSremove4: iSremove4,
          iSremove5: iSremove5,
          iSremove6: iSremove6,
          memetokenomicsbgcolor: memetokenomicsbgcolor,
          contractRenounced: contractRenounced,
          template1tokenimage2: template1tokenimage2,
          template1tokenimage3: template1tokenimage3,
          template1tokenomics: template1tokenomics,
          template1tokenomicssub1: template1tokenomicssub1,
          template1tokenomicssub2: template1tokenomicssub2,
          template1tokenomicssub3: template1tokenomicssub3,
          template1tokenomisupply: template1tokenomisupply,
          template1tokenomicontract: template1tokenomicontract,
          template1tokenomiTax: template1tokenomiTax,
          template2tokenheading: template2tokenheading,
          template2tokensupply: template2tokensupply,
          template2tokentatesupply: template2tokentatesupply,
          template2tokentax: template2tokentax,
          template2tokenlp: template2tokenlp,
          template2tokenMeme: template2tokenMeme,
          template3section1: template3section1,
          template3Section2: template3Section2,
          template3Section3: template3Section3,
          template3Section4: template3Section4,
          memetokencaption: memetokencaption,
          memetokenbuy: memetokenbuy,
          t4token: t4token,
          t5Token1: t5Token1,
          t5Token3: t5Token3,
          t5Token4: t5Token4,
          t5Token5: t5Token5,
          t5Token6: t5Token6,
        }
      });
      console.log('memeAboutContentAPIresponse', response)
    }
    updateMemeTokenomicsContentData()
  }, [memetokenomicsbgcolor])




  const HandelmemecoinContractAddress = (e) => {

    const { value } = e.target;
    setMemecoincontractAddress((prevState) => ({
      ...prevState,
      value: value
    }));
  };

  const HandelContracttitle = (e) => {
    const { value } = e.target;
    setMemecoincontractAddress((prevState) => ({
      ...prevState,
      title: value
    }));
  }

  const HandelmemecoinInitialSupply = (e) => {
    const { value } = e.target;
    setMemecoinInitialsupply((prevState) => ({
      ...prevState,
      value: value
    }));
  };

  const HandelInitialsupplytitle = (e) => {
    const { value } = e.target;
    setMemecoinInitialsupply((prevState) => ({
      ...prevState,
      title: value
    }));
  }
  const HandelmemecoinCirculationsupply = (e) => {
    const { value } = e.target;
    setMemecoinCirculatingSupply((prevState) => ({
      ...prevState,
      value: value
    }));
  };

  const HandelCirculationsupply = (e) => {
    const { value } = e.target;
    setMemecoinCirculatingSupply((prevState) => ({
      ...prevState,
      title: value
    }));
  }

  const HandelmemecoinSupplyBurned = (e) => {
    const { value } = e.target;
    setMemecoinSupplyBurned((prevState) => ({
      ...prevState,
      value: value
    }));
  };

  const HandelSupplyburnedtitle = (e) => {
    const { value } = e.target;
    setMemecoinSupplyBurned((prevState) => ({
      ...prevState,
      title: value
    }));
  }
  const HandelmemecoinLiquidity = (e) => {
    const { value } = e.target;
    setMemecoinLiquidity((prevState) => ({
      ...prevState,
      value: value
    }));
  }

  const Handelliquiditytitle = (e) => {
    const { value } = e.target;
    setMemecoinLiquidity((prevState) => ({
      ...prevState,
      title: value
    }));
  };

  const HandelContractRenouncedtitle = (e) => {
    const { value } = e.target;
    setContractRenounced((prevState) => ({
      ...prevState,
      title: value
    }));
  };

  const HandelContractRenouncedvalue = (e) => {
    const { value } = e.target;
    setContractRenounced((prevState) => ({
      ...prevState,
      value: value
    }));
  };
  const handleLogoImage = (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...memecointokenomicsimage];
        updatedTrustLogo[index] = event.target.result;
        setMemecointokenomicsimage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };
  const handleT1tokenImage2 = (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...template1tokenimage2];
        updatedTrustLogo[index] = event.target.result;
        setTemplate1tokenimage2(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };

  const handleT1tokenImage3 = (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...template1tokenimage3];
        updatedTrustLogo[index] = event.target.result;
        setTemplate1tokenimage3(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };

  const handleContractImage = (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...memecoinContractImage];
        updatedTrustLogo[index] = event.target.result;
        setMemecoinContractImage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };


  const handleDatabaseImage = (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...memecoinDatabaseImage];
        updatedTrustLogo[index] = event.target.result;
        setMemecoinDatabaseImage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };


  const handleCirculatingImage = (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...memecoinCirculatingImage];
        updatedTrustLogo[index] = event.target.result;
        setMemecoinCirculatingImage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };

  const handleBurnedImage = (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...memecoinBurnedImage];
        updatedTrustLogo[index] = event.target.result;
        setMemecoinBurnedImage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };


  const handleLiquidityImage = (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...memecoinLiquidityImage];
        updatedTrustLogo[index] = event.target.result;
        setMemecoinLiquidityImage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  }

  const handleRenouncedImage = (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...memecoinRenouncedImage];
        updatedTrustLogo[index] = event.target.result;
        setMemecoinRenouncedImage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  }

  const handleBackgrounddImage = (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...memecointokonomicsbgimage];
        updatedTrustLogo[index] = event.target.result;
        setMemecointokonomicsbgimage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  }


  useEffect(() => {
    if (iSremove1 == true && iSremove2 == true && iSremove3 == true && iSremove4 == true && iSremove5 == true && iSremove6 == true) {
      setAddicons(true)
    }
  }, [iSremove1, iSremove2, iSremove3, iSremove4, iSremove5, iSremove6]);


  const handleRemoveIcons = (indexToRemove) => {
    setNewaddtokesst(prevMenu => {
      const updatedMenu = [...prevMenu];
      updatedMenu.splice(indexToRemove, 1);
      return updatedMenu;

    });
  };

  const Handelupdatenewtokens = (index, key, newValue) => {
    setNewaddtokesst(prevState => {
      return prevState.map((item, idx) => {
        if (idx === index) {
          return { ...item, [key]: newValue };
        }
        return item;
      });
    });
  };
  const handleNewtokensImage = (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...newiconsImage];
        updatedTrustLogo[index] = event.target.result;
        setNewiconImage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo);
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };
  // console.log('length',newaddtokest.length,shownewicon)


  const Addnewtokens = (newtokens,) => {
    setNewaddtokesst(prevtakoens => [...prevtakoens, newtokens])
    setshownewicon(true)

  };
  console.log('Icon', newaddtokest);

  const handelchangecolor = (titlecolor) => {
    setMemetokenomicsbgcolor(titlecolor.hex)

  };

  const handeltitle = () => {
    setisHeaderDesignText(!isHeaderDesignText)
  }
  useEffect(() => {
    function handleClickOutside(event) {
      const target = event.target;
      const divToExclude = document.getElementById('one');

      if (divToExclude && !divToExclude.contains(target)) {
        setisHeaderDesignText(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isHeaderDesignText]);



  return (
    <>
      <div className='container-fluid d-flex flex-column' style={{ height: '100vh', overflow: 'auto' }}>
        <div>
          <MainNavBar />
        </div>
        <div className='flex-grow-1 d-flex flex-row mt-1' style={{ overflow: 'hidden' }}>
          <div style={{ width: '400px' }} >
            {mainnavbarbutton == false ?
              <div className='editing_wrapper__Wijfx h-100  overflow-auto'>
                <ContentDesign headerText="Tokenomics" />
                {contentdesing == false ?
                  <div >
                    {selectMemeCoinTemplates == 1 ? <div className="editing_content__RXzxk">
                      <div className="form_form__Sku4X" >
                        <div className="form_form__Sku4X">
                          <div className="form_group__kDXNb">
                            <div className="form_form__Sku4X">

                              <div class="form_row__2+BJ3">

                                <div className="form_group__kDXNb">
                                  <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                    <div class="form_field__d31bq" >
                                      <span>Caption</span>
                                      <textarea placeholder="Caption" style={{ height: "200px" }} value={` ${memetokencaption}`} onChange={(e) => { setMemetokenCaption(e.target.value); }} >
                                      </textarea>
                                    </div>
                                    <br />
                                    <div class="form_field__d31bq" >
                                      <span>Buy Button</span>
                                      <textarea placeholder="Caption" style={{ height: "fit-content" }} value={` ${memetokenbuy}`} onChange={(e) => { setMemetokenBuy(e.target.value); }} >
                                      </textarea>
                                    </div>
                                    <br />
                                    {iSremove1 == true ? null : <div className='form_listItem__puOqW'  >
                                      <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                                      <div className='form_headerRemove__mJ7no' onClick={() => setIsremove1(true)} >
                                        <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path>
                                        </svg>
                                      </div>

                                      <div className="form_form__Sku4X">
                                        <div className='form_row__2\+BJ3 form_hasMore__JC1Yq d-flex'>
                                          <div className='form_field__d31bq'>
                                            <textarea placeholder="Title" style={{ height: 32, padding: 5, resize: "none", overflowY: 'hidden' }} value={memecoincontractaddress.title} onChange={HandelContracttitle}></textarea>
                                          </div>
                                          <div className='form_field__d31bq'>
                                            <input type="url" className='w-100' placeholder="/your-page" value={memecoincontractaddress.value} onChange={HandelmemecoinContractAddress} />
                                          </div>
                                          <div style={{ marginLeft: 5, position: "relative" }} class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed" >
                                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path>
                                            </svg>
                                          </div>
                                        </div>
                                        <div className="media_preview__okAyH">
                                          <label>Logo</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecoinContractImage.length > 0 ?
                                              <div>
                                                {memecoinContractImage.map((image, index) => (
                                                  <img
                                                    onClick={() => handleContractImage(0)}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => handleContractImage(0)} style={{ height: 30, width: 40 }} src={memecoinContractImage.length > 0 ? memecoinContractImage : Contract} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>

                                      </div>
                                    </div>}

                                    <br />

                                    {iSremove2 == true ? null : <div className='form_listItem__puOqW'  >
                                      <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                                      <div className='form_headerRemove__mJ7no' onClick={() => setIsremove2(true)}   >
                                        <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                                      </div>

                                      <div className="form_form__Sku4X">
                                        <div className='form_row__2\+BJ3 form_hasMore__JC1Yq d-flex'>
                                          <div className='form_field__d31bq'>
                                            <textarea placeholder="Title" style={{ height: 32, padding: 5, resize: "none", overflowY: 'hidden' }} value={memecoinInitialsupply.title} onChange={HandelInitialsupplytitle}></textarea>
                                          </div>
                                          <div className='form_field__d31bq'>
                                            <input type="url" className='w-100' placeholder="/your-page" value={memecoinInitialsupply.value} onChange={(e) => { HandelmemecoinInitialSupply(e) }} />
                                          </div>
                                          <div style={{ marginLeft: 5, position: "relative" }} class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                                        </div>
                                        <div className="media_preview__okAyH">
                                          <label>Logo</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecoinDatabaseImage.length > 0 ?
                                              <div>
                                                {memecoinDatabaseImage.map((image, index) => (
                                                  <img
                                                    onClick={() => handleDatabaseImage(0)}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => handleDatabaseImage(0)} style={{ height: 30, width: 40 }} src={memecoinDatabaseImage.length > 0 ? memecoinDatabaseImage : Database} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>

                                      </div>
                                    </div>}


                                    <br />
                                    {iSremove3 == true ? null : <div className='form_listItem__puOqW'  >
                                      <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                                      <div className='form_headerRemove__mJ7no' onClick={() => setIsremove3(true)}  >
                                        <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                                      </div>

                                      <div className="form_form__Sku4X">
                                        <div className='form_row__2\+BJ3 form_hasMore__JC1Yq d-flex'>
                                          <div className='form_field__d31bq'>
                                            <textarea placeholder="Title" style={{ height: 32, padding: 5, resize: "none", overflowY: 'hidden' }} value={memecoinCirculatingSupply.title} onChange={HandelCirculationsupply}></textarea>
                                          </div>
                                          <div className='form_field__d31bq'>
                                            <input type="url" className='w-100' placeholder="/your-page" value={memecoinCirculatingSupply.value} onChange={(e) => { HandelmemecoinCirculationsupply(e) }} />
                                          </div>
                                          <div style={{ marginLeft: 5, position: "relative" }} class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                                        </div>
                                        <div className="media_preview__okAyH">
                                          <label>Logo</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecoinCirculatingImage.length > 0 ?
                                              <div>
                                                {memecoinCirculatingImage.map((image, index) => (
                                                  <img
                                                    onClick={() => handleCirculatingImage(0)}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => handleCirculatingImage(0)} style={{ height: 30, width: 40 }} src={memecoinCirculatingImage.length > 0 ? memecoinCirculatingImage : Circulating} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>

                                      </div>
                                    </div>}



                                    <br />
                                    {iSremove4 == true ? null : <div className='form_listItem__puOqW'  >
                                      <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                                      <div className='form_headerRemove__mJ7no' onClick={() => setIsremove4(true)}  >
                                        <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                                      </div>

                                      <div className="form_form__Sku4X">
                                        <div className='form_row__2\+BJ3 form_hasMore__JC1Yq d-flex'>
                                          <div className='form_field__d31bq'>
                                            <textarea placeholder="Title" style={{ height: 32, padding: 5, resize: "none", overflowY: 'hidden' }} value={memecoinSupplyBurned.title} onChange={HandelSupplyburnedtitle}></textarea>
                                          </div>
                                          <div className='form_field__d31bq'>
                                            <input type="url" className='w-100' placeholder="/your-page" value={memecoinSupplyBurned.value} onChange={(e) => HandelmemecoinSupplyBurned(e)} />
                                          </div>
                                          <div style={{ marginLeft: 5, position: "relative" }} class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                                        </div>
                                        <div className="media_preview__okAyH">
                                          <label>Logo</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecoinBurnedImage.length > 0 ?
                                              <div>
                                                {memecoinBurnedImage.map((image, index) => (
                                                  <img
                                                    onClick={() => handleBurnedImage(0)}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => handleBurnedImage(0)} style={{ height: 30, width: 40 }} src={memecoinBurnedImage.length > 0 ? memecoinBurnedImage : Burned} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>

                                      </div>
                                    </div>}
                                    <br />
                                    {iSremove5 == true ? null : <div className='form_listItem__puOqW'  >
                                      <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                                      <div className='form_headerRemove__mJ7no' onClick={() => setIsremove5(true)} >
                                        <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                                      </div>

                                      <div className="form_form__Sku4X">
                                        <div className='form_row__2\+BJ3 form_hasMore__JC1Yq d-flex'>
                                          <div className='form_field__d31bq'>
                                            <textarea placeholder="Title" style={{ height: 32, padding: 5, resize: "none", overflowY: 'hidden' }} value={memecoinLiquidity.title} onChange={Handelliquiditytitle}></textarea>
                                          </div>
                                          <div className='form_field__d31bq'>
                                            <input type="url" className='w-100' placeholder="/your-page" value={memecoinLiquidity.value} onChange={(e) => HandelmemecoinLiquidity(e)} />
                                          </div>
                                          <div style={{ marginLeft: 5, position: "relative" }} class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                                        </div>
                                        <div className="media_preview__okAyH">
                                          <label>Logo</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecoinLiquidityImage.length > 0 ?
                                              <div>
                                                {memecoinLiquidityImage.map((image, index) => (
                                                  <img
                                                    onClick={() => handleLiquidityImage(0)}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => handleLiquidityImage(0)} style={{ height: 30, width: 40 }} src={memecoinLiquidityImage.length > 0 ? memecoinLiquidityImage : Liquidity} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>

                                      </div>
                                    </div>}


                                    <br />
                                    {iSremove6 == true ? null : <div className='form_listItem__puOqW'  >
                                      <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                                      <div className='form_headerRemove__mJ7no' onClick={() => setIsremove6(true)}  >
                                        <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                                      </div>

                                      <div className="form_form__Sku4X">
                                        <div className='form_row__2\+BJ3 form_hasMore__JC1Yq d-flex'>
                                          <div className='form_field__d31bq'>
                                            <textarea placeholder="Title" style={{ height: 37, padding: 5, resize: "none", overflowY: 'hidden' }} value={contractRenounced.title} onChange={HandelContractRenouncedtitle}></textarea>
                                          </div>
                                          <div className='form_field__d31bq'>
                                            <input type="url" className='w-100' placeholder="value" onChange={HandelContractRenouncedvalue} value={contractRenounced.value} style={{ height: 37, padding: 5, resize: "none", overflowY: 'hidden' }} />
                                          </div>
                                          <div style={{ marginLeft: 5, position: "relative" }} class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                                        </div>
                                        <div className="media_preview__okAyH">
                                          <label>Logo</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecoinRenouncedImage.length > 0 ?
                                              <div>
                                                {memecoinRenouncedImage.map((image, index) => (
                                                  <img
                                                    onClick={() => handleRenouncedImage(0)}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => handleRenouncedImage(0)} style={{ height: 30, width: 40 }} src={memecoinRenouncedImage.length > 0 ? memecoinLiquidityImage : Renounced} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>

                                      </div>
                                    </div>}


                                    {newaddtokest.length == 1 ? null : (newaddtokest.map((item, index) => (

                                      <div className='form_listItem__puOqW' key={index} style={{ margin: 10 }}>
                                        <div class="form_headerHandle__33dpI" tabindex="0" role="button" aria-describedby="rbd-hidden-text-4-hidden-text-17" data-rbd-drag-handle-draggable-id="draggable-0" data-rbd-drag-handle-context-id="4" draggable="false"></div>
                                        <div className='form_headerRemove__mJ7no' onClick={() => handleRemoveIcons()}>
                                          <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                                        </div>

                                        <div className="form_form__Sku4X">
                                          <div className='form_row__2\+BJ3 form_hasMore__JC1Yq d-flex'>
                                            <div className='form_field__d31bq'>
                                              <textarea placeholder="Title" style={{ height: 32, padding: 5, resize: "none", overflowY: 'hidden' }} value={item.title} onChange={(e) => Handelupdatenewtokens(index, 'title', e.target.value)}></textarea>
                                            </div>
                                            <div className='form_field__d31bq'>
                                              <input type="url" className='w-100' placeholder="Value" value={item.value} onChange={(e) => Handelupdatenewtokens(index, 'value', e.target.value)} />
                                            </div>
                                            <div style={{ marginLeft: 5, position: "relative" }} class="form_more__6E9XQ" type="button" id="radix-:rhl:" aria-haspopup="menu" aria-expanded="false" data-state="closed"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z" fill="#8c8c8c" fill-rule="evenodd" clip-rule="evenodd"></path></svg></div>
                                          </div>
                                          <div className="media_preview__okAyH">
                                            <label>Logo</label>
                                            <div className="media_value__\+4KKG ">
                                              {newiconsImage.length > 0 ?
                                                <div>
                                                  {newiconsImage.map((image, index) => (
                                                    <img
                                                      onClick={() => handleNewtokensImage(0)}
                                                      key={index}
                                                      style={{ height: 30, width: 40 }}
                                                      src={image}
                                                      alt={`Image ${index}`}
                                                      className="media_image__kN9DM"
                                                    />
                                                  ))}</div>
                                                :
                                                <img onClick={() => handleNewtokensImage(0)} style={{ height: 30, width: 40 }} src={newiconsImage.length > 0 ? newiconsImage : Renounced} alt="" className="media_image__kN9DM" />
                                              }
                                            </div>
                                          </div>

                                        </div>
                                      </div>

                                    )))}
                                    {addicons == true ? <div className='form_emptyList__KNV3N'>
                                      <div>Your list is empty</div>
                                      <button class="button1 button--small" onClick={Addnewtokens}>Add Item</button>
                                    </div> : null}

                                    <div class='mt-3'>
                                      <span>Images</span>
                                      <div className="media_preview__okAyH">
                                        <label>Logo Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {memecointokenomicsimage.length > 0 ?
                                            <div>
                                              {memecointokenomicsimage.map((image, index) => (
                                                <img
                                                  onClick={() => handleLogoImage(0)}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => handleLogoImage(0)} style={{ height: 30, width: 40 }} src={memecointokenomicsimage.length > 0 ? memecointokenomicsimage : tokonmocismask} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div>
                                      <br />
                                      <div className="media_preview__okAyH">
                                        <label>background Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {memecointokonomicsbgimage.length > 0 ?
                                            <div>
                                              {memecointokonomicsbgimage.map((image, index) => (
                                                <img
                                                  onClick={() => { handleBackgrounddImage(0); setMemetokenomicsbackground(0); }}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => { handleBackgrounddImage(0); setMemetokenomicsbackground(0); }} style={{ height: 30, width: 40 }} src={memecointokonomicsbgimage.length > 0 ? memecointokonomicsbgimage : Tokonemoicsbgimage} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div>

                                      <span >Background color</span>

                                      <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemetokenomicsbackground(1); }}>
                                        <label>Color</label>
                                        <div className="media_value__\+4KKG ">

                                          <div style={{ height: 30, width: 40, backgroundColor: memetokenomicsbgcolor.length == 0 ? '#0452ff' : memetokenomicsbgcolor, position: 'relative' }} className="media_image__kN9DM" ></div>

                                        </div>


                                      </div>
                                      {isHeaderDesignText == false ? null :
                                        <div id='one' style={{ position: 'relative', }}>
                                          <SketchPicker color={memetokenomicsbgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>




                    </div> : null
                    }

                    {selectMemeCoinTemplates == 2 ?
                      <div className="editing_content__RXzxk">
                        <div className="form_form__Sku4X" >
                          <div className="form_form__Sku4X">
                            <div className="form_group__kDXNb">
                              <div className="form_form__Sku4X">

                                <div class="form_row__2+BJ3">

                                  <div className="form_group__kDXNb">
                                    <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>

                                      <div className='form_field__d31bq'>
                                        <input type="text" className='w-100' placeholder="text.." value={template1tokenomics} onChange={(e) => setTemplate1Tokenomics(e.target.value)} />
                                      </div>
                                      <br />
                                      <div className='form_field__d31bq'>
                                        <input type="text" className='w-100' placeholder="text.." value={template1tokenomicssub1} onChange={(e) => setTemplate1tokenomicssub1(e.target.value)} />
                                      </div>
                                      <br />
                                      <div className='form_field__d31bq'>
                                        <input type="text" className='w-100' placeholder="text.." value={template1tokenomicssub2} onChange={(e) => setTemplate1tokenomicssub2(e.target.value)} />
                                      </div>
                                      <br />
                                      <div className='form_field__d31bq'>
                                        <input type="text" className='w-100' placeholder="text.." value={template1tokenomicssub3} onChange={(e) => setTemplate1tokenomicssub3(e.target.value)} />
                                      </div>




                                      <br />

                                      <div className='form_listItem__puOqW'  >
                                        <div className='form_field__d31bq'>
                                          <textarea placeholder="Title" style={{ height: 32, padding: 5, resize: "none", overflowY: 'hidden' }} value={template1tokenomisupply.label}
                                            onChange={(e) => setTemplate1tokenomisupply((prevState) => ({
                                              ...prevState,
                                              label: e.target.value
                                            }))}
                                          ></textarea>
                                        </div>
                                        <div className='form_field__d31bq'>
                                          <input type="text" className='w-100' placeholder="value" value={template1tokenomisupply.value} onChange={(e) => setTemplate1tokenomisupply((prevState) => ({
                                            ...prevState,
                                            value: e.target.value
                                          }))} />
                                        </div>



                                      </div>
                                      <br />
                                      <div className='form_listItem__puOqW'  >
                                        <div className='form_field__d31bq'>
                                          <textarea placeholder="Title" style={{ height: 32, padding: 5, resize: "none", overflowY: 'hidden' }} value={template1tokenomicontract.label} onChange={(e) => setTemplate1tokenomicontract((prevState) => ({
                                            ...prevState,
                                            label: e.target.value
                                          }))}></textarea>
                                        </div>
                                        <div className='form_field__d31bq'>
                                          <input type="text" className='w-100' placeholder="value" value={template1tokenomicontract.value} onChange={(e) => setTemplate1tokenomicontract((prevState) => ({
                                            ...prevState,
                                            value: e.target.value
                                          }))} />
                                        </div>



                                      </div>


                                      <br />

                                      <div className='form_listItem__puOqW'  >
                                        <div className='form_field__d31bq'>
                                          <textarea placeholder="Title" style={{ height: 32, padding: 5, resize: "none", overflowY: 'hidden' }} value={template1tokenomiTax.label} onChange={(e) => setTemplate1tokenomiTax((prevState) => ({
                                            ...prevState,
                                            label: e.target.value
                                          }))}></textarea>
                                        </div>
                                        <div className='form_field__d31bq'>
                                          <input type="text" className='w-100' placeholder="value" value={template1tokenomiTax.value} onChange={(e) => setTemplate1tokenomiTax((prevState) => ({
                                            ...prevState,
                                            value: e.target.value
                                          }))} />
                                        </div>



                                      </div>




                                      <div class='mt-3'>
                                        <span>Images</span>
                                        <div className="media_preview__okAyH">
                                          <label> Image 1</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecointokenomicsimage.length > 0 ?
                                              <div>
                                                {memecointokenomicsimage.map((image, index) => (
                                                  <img
                                                    onClick={() => handleLogoImage(0)}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => handleLogoImage(0)} style={{ height: 30, width: 40 }} src={memecointokenomicsimage.length > 0 ? '' : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>

                                        </div>
                                        <br />
                                        <div className="media_preview__okAyH">
                                          <label> Image 2</label>
                                          <div className="media_value__\+4KKG ">
                                            {template1tokenimage2.length > 0 ?
                                              <div>
                                                {template1tokenimage2.map((image, index) => (
                                                  <img
                                                    onClick={() => handleT1tokenImage2(0)}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => handleT1tokenImage2(0)} style={{ height: 30, width: 40 }} src={template1tokenimage2.length > 0 ? template1tokenimage2 : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>

                                        </div>
                                        <br />
                                        <div className="media_preview__okAyH">
                                          <label> Image 3</label>
                                          <div className="media_value__\+4KKG ">
                                            {template1tokenimage3.length > 0 ?
                                              <div>
                                                {template1tokenimage3.map((image, index) => (
                                                  <img
                                                    onClick={() => handleT1tokenImage3(0)}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => handleT1tokenImage3(0)} style={{ height: 30, width: 40 }} src={template1tokenimage3.length > 0 ? template1tokenimage3 : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>

                                        </div>
                                        <br />
                                        <div className="media_preview__okAyH">
                                          <label>background Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecointokonomicsbgimage.length > 0 ?
                                              <div>
                                                {memecointokonomicsbgimage.map((image, index) => (
                                                  <img
                                                    onClick={() => { handleBackgrounddImage(0); setMemetokenomicsbackground(0); }}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => { handleBackgrounddImage(0); setMemetokenomicsbackground(0); }} style={{ height: 30, width: 40 }} src={memecointokonomicsbgimage.length > 0 ? memecointokonomicsbgimage : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>

                                        <span >Background color</span>

                                        <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemetokenomicsbackground(1); }}>
                                          <label>Color</label>
                                          <div className="media_value__\+4KKG ">

                                            <div style={{ height: 30, width: 40, backgroundColor: memetokenomicsbgcolor.length == 0 ? '' : memetokenomicsbgcolor, position: 'relative' }} className="media_image__kN9DM" ></div>

                                          </div>


                                        </div>
                                        {isHeaderDesignText == false ? null :
                                          <div id='one' style={{ position: 'relative', }}>
                                            <SketchPicker color={memetokenomicsbgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                      </div> : null}

                    {selectMemeCoinTemplates == 3 ?
                      <div className="editing_content__RXzxk">
                        <div className="form_form__Sku4X" >
                          <div className="form_form__Sku4X">
                            <div className="form_group__kDXNb">
                              <div className="form_form__Sku4X">

                                <div class="form_row__2+BJ3">

                                  <div className="form_group__kDXNb">
                                    <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>

                                      <div className='form_field__d31bq'>
                                        <input type="url" className='w-100' placeholder="/your-page" value={template2tokenheading} onChange={(e) => setTemplate2tokenheading(e.target.value)} />
                                      </div>
                                      <br />
                                      <div className='form_field__d31bq'>
                                        <input type="url" className='w-100' placeholder="/your-page" value={template2tokensupply} onChange={(e) => setTemplate2tokensupply(e.target.value)} />
                                      </div>
                                      <br />
                                      <div className='form_field__d31bq'>
                                        <input type="url" className='w-100' placeholder="/your-page" value={template2tokentatesupply} onChange={(e) => setTemplate2tokentatesupply(e.target.value)} />
                                      </div>
                                      <br />
                                      <div className='form_field__d31bq'>
                                        <input type="url" className='w-100' placeholder="/your-page" value={template2tokentax} onChange={(e) => setTemplate2tokentax(e.target.value)} />
                                      </div>
                                      <br />
                                      <div className='form_field__d31bq'>
                                        <input type="url" className='w-100' placeholder="/your-page" value={template2tokenlp} onChange={(e) => setTemplate2tokenlp(e.target.value)} />
                                      </div>
                                      <br />
                                      <div className='form_field__d31bq'>
                                        <input type="url" className='w-100' placeholder="/your-page" value={template2tokenMeme} onChange={(e) => setTemplate2tokenMeme(e.target.value)} />
                                      </div>




                                      <br />







                                      <div class='mt-3'>


                                        <br />
                                        <div className="media_preview__okAyH">
                                          <label>background Image</label>
                                          <div className="media_value__\+4KKG ">
                                            {memecointokonomicsbgimage.length > 0 ?
                                              <div>
                                                {memecointokonomicsbgimage.map((image, index) => (
                                                  <img
                                                    onClick={() => { handleBackgrounddImage(0); setMemetokenomicsbackground(1); }}
                                                    key={index}
                                                    style={{ height: 30, width: 40 }}
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    className="media_image__kN9DM"
                                                  />
                                                ))}</div>
                                              :
                                              <img onClick={() => { handleBackgrounddImage(0); setMemetokenomicsbackground(1); }} style={{ height: 30, width: 40 }} src={memecointokonomicsbgimage.length > 0 ? memecointokonomicsbgimage : ''} alt="" className="media_image__kN9DM" />
                                            }
                                          </div>
                                        </div>
                                        <br />
                                        <span >Background color</span>

                                        <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemetokenomicsbackground(0); }}>
                                          <label>Color</label>
                                          <div className="media_value__\+4KKG ">

                                            {/* <div style={{ height: 30, width: 40, backgroundColor: memetokenomicsbgcolor.length == 0 ? '#0452ff' : memetokenomicsbgcolor, position: 'relative' }} className="media_image__kN9DM" ></div> */}

                                          </div>


                                        </div>
                                        {isHeaderDesignText == false ? null :
                                          <div id='one' style={{ position: 'relative', }}>
                                            <SketchPicker color={memetokenomicsbgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                      </div> : null}

                    {selectMemeCoinTemplates == 4 ? <div className="editing_content__RXzxk">
                      <div className="form_form__Sku4X" >
                        <div className="form_form__Sku4X">
                          <div className="form_group__kDXNb">
                            <div className="form_form__Sku4X">

                              <div class="form_row__2+BJ3">

                                <div className="form_group__kDXNb">
                                  <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>

                                    <div class="form_field__d31bq" >
                                      <span>Section 1</span>
                                      <textarea placeholder="Section 1" style={{ height: '120px', }} value={template3section1} onChange={(e) => setTemplate3section1(e.target.value)} >
                                      </textarea>
                                    </div>
                                    <div class="form_field__d31bq" >
                                      <span>Section 1</span>
                                      <textarea placeholder="Section 1" style={{ height: '120px', }} value={template3section1} onChange={(e) => setTemplate3section1(e.target.value)} >
                                      </textarea>
                                    </div>

                                    <div class="form_field__d31bq" >
                                      <span>Section 2</span>
                                      <textarea placeholder="Section 2" style={{ height: '120px', }} value={template3Section2} onChange={(e) => setTemplate3section2(e.target.value)} >
                                      </textarea>
                                    </div>

                                    <div class="form_field__d31bq" >
                                      <span>Section 3</span>
                                      <textarea placeholder="Section 3" style={{ height: '120px', }} value={template3Section3} onChange={(e) => setTemplate3Section3(e.target.value)} >
                                      </textarea>
                                    </div>

                                    <div class="form_field__d31bq" >
                                      <span>Section 4</span>
                                      <textarea placeholder="Section 4" style={{ height: '120px', }} value={template3Section4} onChange={(e) => setTemplate3Section4(e.target.value)} >
                                      </textarea>
                                    </div>





                                    <div class='mt-3'>

                                      <br />
                                      <span >Background Image</span>

                                      <div className="media_preview__okAyH">
                                        <label>background Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {memecointokonomicsbgimage.length > 0 ?
                                            <div>
                                              {memecointokonomicsbgimage.map((image, index) => (
                                                <img
                                                  onClick={() => { handleBackgrounddImage(0); setMemetokenomicsbackground(0); }}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => { handleBackgrounddImage(0); setMemetokenomicsbackground(0); }} style={{ height: 30, width: 40 }} src={memecointokonomicsbgimage.length > 0 ? memecointokonomicsbgimage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div>

                                      <span >Background color</span>

                                      <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemetokenomicsbackground(1); }}>
                                        <label>Color</label>
                                        <div className="media_value__\+4KKG ">

                                          <div style={{ height: 30, width: 40, backgroundColor: memetokenomicsbgcolor.length == 0 ? '' : memetokenomicsbgcolor, position: 'relative' }} className="media_image__kN9DM" ></div>

                                        </div>


                                      </div>
                                      {isHeaderDesignText == false ? null :
                                        <div id='one' style={{ position: 'relative', }}>
                                          <SketchPicker color={memetokenomicsbgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>




                    </div> : null}

                    {selectMemeCoinTemplates == 6 ? <div className="editing_content__RXzxk">
                      <div className="form_form__Sku4X" >
                        <div className="form_form__Sku4X">
                          <div className="form_group__kDXNb">
                            <div className="form_form__Sku4X">

                              <div class="form_row__2+BJ3">

                                <div className="form_group__kDXNb">
                                  <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>

                                    <div class="form_field__d31bq" >
                                      <span>Meme</span>
                                      <textarea placeholder="Caption" style={{ height: "fit-content" }} value={` ${t4token}`} onChange={(e) => { setT4token(e.target.value); }} >
                                      </textarea>
                                    </div>
                                    <br />








                                    <div class='mt-3'>
                                      <span>Images</span>
                                      <div className="media_preview__okAyH">
                                        <label>Meme Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {memecointokenomicsimage.length > 0 ?
                                            <div>
                                              {memecointokenomicsimage.map((image, index) => (
                                                <img
                                                  onClick={() => handleLogoImage(0)}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => handleLogoImage(0)} style={{ height: 30, width: 40 }} src={memecointokenomicsimage.length > 0 ? memecointokenomicsimage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div>
                                      <br />
                                      <div className="media_preview__okAyH">
                                        <label>background Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {memecointokonomicsbgimage.length > 0 ?
                                            <div>
                                              {memecointokonomicsbgimage.map((image, index) => (
                                                <img
                                                  onClick={() => { handleBackgrounddImage(0); setMemetokenomicsbackground(0); }}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => { handleBackgrounddImage(0); setMemetokenomicsbackground(0); }} style={{ height: 30, width: 40 }} src={memecointokonomicsbgimage.length > 0 ? memecointokonomicsbgimage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div>

                                      <span >Background color</span>

                                      <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemetokenomicsbackground(1); }}>
                                        <label>Color</label>
                                        <div className="media_value__\+4KKG ">

                                          <div style={{ height: 30, width: 40, backgroundColor: memetokenomicsbgcolor.length == 0 ? '' : memetokenomicsbgcolor, position: 'relative' }} className="media_image__kN9DM" ></div>

                                        </div>


                                      </div>
                                      {isHeaderDesignText == false ? null :
                                        <div id='one' style={{ position: 'relative', }}>
                                          <SketchPicker color={memetokenomicsbgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>




                    </div> : null
                    };

                    {selectMemeCoinTemplates == 5 ? <div className="editing_content__RXzxk">
                      <div className="form_form__Sku4X" >
                        <div className="form_form__Sku4X">
                          <div className="form_group__kDXNb">
                            <div className="form_form__Sku4X">

                              <div class="form_row__2+BJ3">

                                <div className="form_group__kDXNb">
                                  <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                    <div class="form_field__d31bq" >
                                      <span>TOKENOMICS</span>
                                      <textarea placeholder="Caption" style={{ height: "fit-content" }} value={` ${t5Token1}`} onChange={(e) => { setT5token1(e.target.value); }} >
                                      </textarea>
                                    </div>
                                    <br />
                                    <div class="form_field__d31bq" >
                                      <span>Caption</span>
                                      <textarea placeholder="Caption" style={{ height: "fit-content" }} value={` ${t5Token3}`} onChange={(e) => { setT5token3(e.target.value); }} >
                                      </textarea>
                                      <br/>
                                      <textarea placeholder="Caption" style={{ height: "fit-content" }} value={` ${t5Token4}`} onChange={(e) => { setT5token4(e.target.value); }} >
                                      </textarea>
                                    </div>
                                    <br />
                                    <div class="form_field__d31bq" >
                                      <span>TOTAL SUPPLY</span>
                                      <textarea placeholder="Caption" style={{ height: "fit-content" }} value={` ${t5Token5}`} onChange={(e) => { setT5token5(e.target.value); }} >
                                      </textarea>
                                      
                                    </div>
                                    <br/>
                                    <div class="form_field__d31bq" >
                                      <span>TOKEN ADDRESS</span>
                                      <textarea placeholder="Caption" style={{ height: "fit-content" }} value={` ${t5Token6}`} onChange={(e) => { setT5token6(e.target.value); }} >
                                      </textarea>
                                      
                                    </div>







                                    <div class='mt-3'>
                                      <span>Images</span>
                                      <div className="media_preview__okAyH">
                                        <label> Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {memecointokenomicsimage.length > 0 ?
                                            <div>
                                              {memecointokenomicsimage.map((image, index) => (
                                                <img
                                                  onClick={() => handleLogoImage(0)}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => handleLogoImage(0)} style={{ height: 30, width: 40 }} src={memecointokenomicsimage.length > 0 ? memecointokenomicsimage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div>
                                      <br />
                                      <div className="media_preview__okAyH">
                                        <label>Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {newiconsImage.length > 0 ?
                                            <div>
                                              {newiconsImage.map((image, index) => (
                                                <img
                                                  onClick={() => handleNewtokensImage(0)}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => handleNewtokensImage(0)} style={{ height: 30, width: 40 }} src={newiconsImage.length > 0 ? newiconsImage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div>
                                      <br />
                                      <div className="media_preview__okAyH">
                                        <label>background Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {memecointokonomicsbgimage.length > 0 ?
                                            <div>
                                              {memecointokonomicsbgimage.map((image, index) => (
                                                <img
                                                  onClick={() => { handleBackgrounddImage(0); setMemetokenomicsbackground(1); }}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => { handleBackgrounddImage(0); setMemetokenomicsbackground(1); }} style={{ height: 30, width: 40 }} src={memecointokonomicsbgimage.length > 0 ? memecointokonomicsbgimage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div>

                                      <span >Background color</span>

                                      <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemetokenomicsbackground(0); }}>
                                        <label>Color</label>
                                        <div className="media_value__\+4KKG ">

                                          <div style={{ height: 30, width: 40, backgroundColor: memetokenomicsbgcolor.length == 0 ? '' : memetokenomicsbgcolor, position: 'relative' }} className="media_image__kN9DM" ></div>

                                        </div>


                                      </div>
                                      {isHeaderDesignText == false ? null :
                                        <div id='one' style={{ position: 'relative', }}>
                                          <SketchPicker color={memetokenomicsbgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>




                    </div> : null
                    }

                  </div>
                  : <MemeTokendesing />}

              </div> : <div className='editing_wrapper__Wijfx h-100  overflow-auto'><Theme /></div>}
          </div>
          <div className=' h-100 overflow-auto editing_wrapper__Wijfxnew' style={{ width: 'calc(100% - 400px)' }}>
            <Memecoin />
          </div>
        </div>
      </div>
    </>
  )
};

export default MemeTokenamics;