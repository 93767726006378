import React, { useContext, useEffect, useState } from "react";
import Memecoin from "../Memecoin";
import Theme from "../../Theme";
import TrustDesign from "../../SaveandEdit/Trustedby/trustedesing";
import MyContext from "../../../context/MyContext";
import ContentDesign from "../../ContentDesign";
import MainNavBar from "../../MainNavBar";
import MemeStepdesing from "./Memecoinstepdesing";
import { SketchPicker } from "react-color";
import axios from "axios";
import { useAccount } from 'wagmi';


const MemecoinSteps = () => {
  const { mainnavbarbutton, contentdesing,
    step1, setStep1,
    step2, setStep2,
    step3, setStep3,
    step4, setStep4,
    stepheading, setStepheading,
    memestepbgimage, setMemestepbgimage,
    memestepbgcolor, setMemestepbgcolor,
    setMemestepbackground, uniqueID, 
    template1stepcontract, setTemplatestepcontract,
    selectMemeCoinTemplates,
    template1stepAdd, setTemplate1Add,
    template2stepheading, setTemplate2stepheading,
    template2step1, setTemplate2step1,
    template2step3, setTemplate2step3,
    template2step2, setTemplate2step2,
    template2step4, setTemplate2step4,
    memecoinlogoImage, setMemecoinlogoImage,
    template3stepimage, setTemplate3stepimage,
    template3stepsection2, setTemplate3stepsection2,
    template3stepquestion, setTemplate3question,
    template3step3section2heading, setTemplate3stepsection2heading,
    template3stepsection1, setTemplate3stepsection1,
    template3stepsection2bg, setTemplate3stepsection2bg,
    template3stepsection2color, setTemplate3section2color,
    setMemestepbackgroundt3,
    t4token,setT4token,
      t4step1,setT4step1,
      t4step2,setT4step2,
      t4step3,setT4step3,
      t4step4,setT4step4,
      t4step5,setT4step5,
      t4stepimage,setT4stepImage,
      t5step1,setT5Step1,
      t5step2,setT5Step2,
      t5step3,setT5Step3,
      t5step4,setT5Step4,

  } = useContext(MyContext);
  const [isHeaderDesignText, setisHeaderDesignText] = useState(false);
  const [template3stepcbgolor, setTemplate3stepcolor] = useState(false);

  // API to store meme step content data. For testing purpose using use effect
  const {address} = useAccount()

  useEffect(() => {
    const updateMemeStepsContentData = async () => {
      const response = await axios.post('https://trendifyweb.ai/pyapi/updateMemeStepsContentData', {
        params: {
          address: address,
          siteid: uniqueID,
          stepheading: stepheading,
          step1: step1,
          step2: step2,
          step3: step3,
          step4: step4,
          memestepbgimage: memestepbgimage,
          memestepbgcolor: memestepbgcolor,
          template1stepcontract: template1stepcontract,
          template1stepAdd: template1stepAdd,
          template2stepheading: template2stepheading,
          template2step1: template2step1,
          template2step3: template2step3,
          template2step2: template2step2,
          template2step4: template2step4,
          memecoinlogoImage: memecoinlogoImage,
          template3stepimage: template3stepimage,
          template3stepsection2: template3stepsection2,
          template3stepquestion: template3stepquestion,
          template3step3section2heading: template3step3section2heading,
          template3stepsection1: template3stepsection1,
          template3stepsection2bg: template3stepsection2bg,
          template3stepsection2color: template3stepsection2color,
          t4step1:t4step1,
          t4step2:t4step2,
          t4step3:t4step3,
          t4step4:t4step4,
          t4step5:t4step5,
          t4stepimage:t4stepimage,
          t5step1:t5step1,
          t5step2:t5step2,
          t5step3:t5step3,
          t5step4:t5step4
    }
      })
      console.log('memeStepsContentAPIresponse', response)
    }
    updateMemeStepsContentData()

  }, [memestepbgcolor])

  const handleBackgrounddImage = (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...memestepbgimage];
        updatedTrustLogo[index] = event.target.result;
        setMemestepbgimage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  }

  const handleBackgroundtemplate3step = (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...template3stepsection2bg];
        updatedTrustLogo[index] = event.target.result;
        setTemplate3stepsection2bg(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  }

  const handelchangecolor = (titlecolor) => {
    setMemestepbgcolor(titlecolor.hex)
    // if ((titlecolor !== 'white') || (titlecolor.hex !== '#000000')) {
    //     setnavbartextcolor('white')
    // }
    // if ((titlecolor == '#ffffff') || (titlecolor.hex == '#ffffff')) {
    //     setnavbartextcolor('Black')
    // }
  };
  const handelchangeTemplate3stepcolor = (titlecolor) => {
    setTemplate3section2color(titlecolor.hex)
    // if ((titlecolor !== 'white') || (titlecolor.hex !== '#000000')) {
    //     setnavbartextcolor('white')
    // }
    // if ((titlecolor == '#ffffff') || (titlecolor.hex == '#ffffff')) {
    //     setnavbartextcolor('Black')
    // }
  };

  const handeltitle = () => {
    setisHeaderDesignText(!isHeaderDesignText)
  };
  const handelstepcolor = () => {
    setTemplate3stepcolor(!template3stepcbgolor)
  }
  useEffect(() => {
    function handleClickOutside(event) {
      const target = event.target;
      const divToExclude = document.getElementById('one');

      // Check if the click occurred outside of the specific div
      if (divToExclude && !divToExclude.contains(target)) {
        setisHeaderDesignText(false);
        setTemplate3stepcolor(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isHeaderDesignText]);

  const handleSvgClick = (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...memecoinlogoImage];
        updatedTrustLogo[index] = event.target.result;
        setMemecoinlogoImage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };

  const handleSvgClick1 = (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...template3stepimage];
        updatedTrustLogo[index] = event.target.result;
        setTemplate3stepimage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };

  const handleStepImage = (index) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...t4stepimage];
        updatedTrustLogo[index] = event.target.result;
        setT4stepImage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };
  return (
    <>
      <div className='container-fluid d-flex flex-column' style={{ height: '100vh', overflow: 'auto' }}>
        <div>
          <MainNavBar />
        </div>
        <div className='flex-grow-1 d-flex flex-row mt-1' style={{ overflow: 'hidden' }}>
          <div style={{ width: '400px' }} >
            {mainnavbarbutton == false ?
              <div className='editing_wrapper__Wijfx h-100  overflow-auto'>
                <ContentDesign headerText="Steps" />
                {contentdesing == false ?
                  <div >
                    {selectMemeCoinTemplates == 1 ? <div className="editing_content__RXzxk">
                      <div className="form_form__Sku4X" >
                        <div className="form_form__Sku4X">
                          <div className="form_group__kDXNb">
                            <div className="form_form__Sku4X">
                              <div class="form_row__2+BJ3">

                                <div className="form_group__kDXNb">
                                  <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                    <div class="form_field__d31bq mt-3" >
                                      <span>Heading</span>
                                      <textarea placeholder="Step 1" style={{ height: "32px" }} value={stepheading} onChange={(e) => setStepheading(e.target.value)}>
                                      </textarea>
                                    </div>
                                    <div class="form_field__d31bq mt-3" >
                                      <span>Step 1</span>
                                      <textarea placeholder="Step 1" style={{ height: "32px" }} value={step1} onChange={(e) => setStep1(e.target.value)}>
                                      </textarea>
                                    </div>
                                    <div class="form_field__d31bq mt-3" >
                                      <span>Step 2</span>
                                      <textarea placeholder="Step 2" style={{ height: "32px" }} value={step2} onChange={(e) => setStep2(e.target.value)}>
                                      </textarea>
                                    </div>
                                    <div class="form_field__d31bq mt-3" >
                                      <span>Step 3</span>
                                      <textarea placeholder="Step 3" style={{ height: "32px" }} value={step3} onChange={(e) => setStep3(e.target.value)}>
                                      </textarea>
                                    </div>
                                    <div class="form_field__d31bq mt-3" >
                                      <span>Step 4</span>
                                      <textarea placeholder="Step 4" style={{ height: "32px" }} value={step4} onChange={(e) => setStep4(e.target.value)} >
                                      </textarea>
                                    </div>
                                    <br />
                                    <span>Background Image</span>
                                    <div className="media_preview__okAyH">
                                      <label>Image</label>
                                      <div className="media_value__\+4KKG ">
                                        {memestepbgimage.length > 0 ?
                                          <div>
                                            {memestepbgimage.map((image, index) => (
                                              <img
                                                onClick={() => { handleBackgrounddImage(0); setMemestepbackground(0); }}
                                                key={index}
                                                style={{ height: 30, width: 40 }}
                                                src={image}
                                                alt={`Image ${index}`}
                                                className="media_image__kN9DM"
                                              />
                                            ))}</div>
                                          :
                                          <img onClick={() => { handleBackgrounddImage(0); setMemestepbackground(0); }} style={{ height: 30, width: 40 }} src={memestepbgimage.length > 0 ? memestepbgimage : ''} alt="" className="media_image__kN9DM" />
                                        }
                                      </div>
                                    </div>

                                    <span >Background color</span>

                                    <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemestepbackground(1) }}>
                                      <label>Color</label>
                                      <div className="media_value__\+4KKG ">

                                        {/* <div  style={{ height: 30, width: 40,  backgroundColor: memestepbgcolor.length==0 ? '' :  memestepbgcolor,position:'relative' }}  className="media_image__kN9DM" ></div> */}

                                      </div>


                                    </div>
                                    {isHeaderDesignText == false ? null :
                                      <div id='one' style={{ position: 'relative', }}>
                                        <SketchPicker color={memestepbgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                    }
                                  </div>
                                </div>




                              </div>
                            </div>
                          </div>
                        </div>
                      </div>




                    </div> : null}

                    {selectMemeCoinTemplates == 2 ? <div className="editing_content__RXzxk">
                      <div className="form_form__Sku4X" >
                        <div className="form_form__Sku4X">
                          <div className="form_group__kDXNb">
                            <div className="form_form__Sku4X">
                              <div class="form_row__2+BJ3">

                                <div className="form_group__kDXNb">
                                  <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                    <div class="form_field__d31bq mt-3" >
                                      <span>Heading</span>
                                      <textarea placeholder="Heading" style={{ height: "32px" }} value={template1stepcontract} onChange={(e) => setTemplatestepcontract(e.target.value)}>
                                      </textarea>
                                    </div>
                                    <div class="form_field__d31bq mt-3" >
                                      <span>Address</span>
                                      <textarea placeholder="Address" style={{ height: "45px" }} value={template1stepAdd} onChange={(e) => setTemplate1Add(e.target.value)}>
                                      </textarea>
                                    </div>

                                    <br />
                                    <span>Background Image</span>
                                    <div className="media_preview__okAyH">
                                      <label>Image</label>
                                      <div className="media_value__\+4KKG ">
                                        {memestepbgimage.length > 0 ?
                                          <div>
                                            {memestepbgimage.map((image, index) => (
                                              <img
                                                onClick={() => { handleBackgrounddImage(0); setMemestepbackground(0); }}
                                                key={index}
                                                style={{ height: 30, width: 40 }}
                                                src={image}
                                                alt={`Image ${index}`}
                                                className="media_image__kN9DM"
                                              />
                                            ))}</div>
                                          :
                                          <img onClick={() => { handleBackgrounddImage(0); setMemestepbackground(0); }} style={{ height: 30, width: 40 }} src={memestepbgimage.length > 0 ? memestepbgimage : ''} alt="" className="media_image__kN9DM" />
                                        }
                                      </div>
                                    </div>

                                    <span >Background color</span>

                                    <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemestepbackground(1) }}>
                                      <label>Color</label>
                                      <div className="media_value__\+4KKG ">

                                        <div style={{ height: 30, width: 40, backgroundColor: memestepbgcolor.length == 0 ? '' : memestepbgcolor, position: 'relative' }} className="media_image__kN9DM" ></div>

                                      </div>


                                    </div>
                                    {isHeaderDesignText == false ? null :
                                      <div id='one' style={{ position: 'relative', }}>
                                        <SketchPicker color={memestepbgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                    }
                                  </div>
                                </div>




                              </div>
                            </div>
                          </div>
                        </div>
                      </div>




                    </div> : null}

                    {selectMemeCoinTemplates == 3 ? <div className="editing_content__RXzxk">
                      <div className="form_form__Sku4X" >
                        <div className="form_form__Sku4X">
                          <div className="form_group__kDXNb">
                            <div className="form_form__Sku4X">
                              <div class="form_row__2+BJ3">

                                <div className="form_group__kDXNb">
                                  <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                    <div class="form_field__d31bq mt-3" >
                                      <span>Heading</span>
                                      <textarea placeholder="Heading" style={{ height: "32px" }} value={template2stepheading} onChange={(e) => setTemplate2stepheading(e.target.value)}>
                                      </textarea>
                                    </div>
                                    <div class="form_field__d31bq mt-3" >
                                      <span>Step 1</span>
                                      <textarea placeholder="Address" style={{ height: "95px" }} value={template2step1} onChange={(e) => setTemplate2step1(e.target.value)}>
                                      </textarea>
                                    </div>

                                    <div class="form_field__d31bq mt-3" >
                                      <span>Step 2</span>
                                      <textarea placeholder="Address" style={{ height: "95px" }} value={template2step2} onChange={(e) => setTemplate2step2(e.target.value)}>
                                      </textarea>
                                    </div>

                                    <div class="form_field__d31bq mt-3" >
                                      <span>Step 3</span>
                                      <textarea placeholder="Address" style={{ height: "95px" }} value={template2step3} onChange={(e) => setTemplate2step3(e.target.value)}>
                                      </textarea>
                                    </div>

                                    <div class="form_field__d31bq mt-3" >
                                      <span>Step 4</span>
                                      <textarea placeholder="Address" style={{ height: "95px" }} value={template2step4} onChange={(e) => setTemplate2step4(e.target.value)}>
                                      </textarea>
                                    </div>

                                    <br />
                                    <span>Background Image</span>
                                    <div className="media_preview__okAyH">
                                      <label>Image</label>
                                      <div className="media_value__\+4KKG ">
                                        {memestepbgimage.length > 0 ?
                                          <div>
                                            {memestepbgimage.map((image, index) => (
                                              <img
                                                onClick={() => { handleBackgrounddImage(0); setMemestepbackground(1); }}
                                                key={index}
                                                style={{ height: 30, width: 40 }}
                                                src={image}
                                                alt={`Image ${index}`}
                                                className="media_image__kN9DM"
                                              />
                                            ))}</div>
                                          :
                                          <img onClick={() => { handleBackgrounddImage(0); setMemestepbackground(1); }} style={{ height: 30, width: 40 }} src={memestepbgimage.length > 0 ? memestepbgimage : ''} alt="" className="media_image__kN9DM" />
                                        }
                                      </div>
                                    </div>

                                    <span >Background color</span>

                                    <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemestepbackground(0) }}>
                                      <label>Color</label>
                                      <div className="media_value__\+4KKG ">

                                        {/* <div  style={{ height: 30, width: 40,  backgroundColor: memestepbgcolor.length==0 ? '' :  memestepbgcolor,position:'relative' }}  className="media_image__kN9DM" ></div> */}

                                      </div>


                                    </div>
                                    {isHeaderDesignText == false ? null :
                                      <div id='one' style={{ position: 'relative', }}>
                                        <SketchPicker color={memestepbgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                    }
                                  </div>
                                </div>




                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> : null}


                    {selectMemeCoinTemplates == 4 ?
                      <div className="editing_content__RXzxk">
                        <div className="form_form__Sku4X" >
                          <div className="form_form__Sku4X">
                            <div className="form_group__kDXNb">
                              <div className="form_form__Sku4X">
                                <div class="form_row__2+BJ3">

                                  <div className="form_group__kDXNb">
                                    <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                      <div class="form_field__d31bq mt-3" >
                                        <span>Question</span>
                                        <textarea placeholder="Question" style={{ height: "32px" }} value={template3stepquestion} onChange={(e) => setTemplate3question(e.target.value)}>
                                        </textarea>
                                      </div>
                                      <div class="form_field__d31bq mt-3" >
                                        <span>Section 1</span>
                                        <textarea placeholder="Step 1" style={{ height: "132px" }} value={template3stepsection1} onChange={(e) => setTemplate3stepsection1(e.target.value)}>
                                        </textarea>
                                      </div>
                                      <div class="form_field__d31bq mt-3" >
                                        <span>Section 2 heading</span>
                                        <textarea placeholder="Step 2" style={{ height: "32px" }} value={template3step3section2heading} onChange={(e) => setTemplate3stepsection2heading(e.target.value)}>
                                        </textarea>
                                      </div>
                                      <div class="form_field__d31bq mt-3" >
                                        <span>Section 2</span>
                                        <textarea placeholder="Step 3" style={{ height: "132px" }} value={template3stepsection2} onChange={(e) => setTemplate3stepsection2(e.target.value)}>
                                        </textarea>
                                      </div>

                                      <br />
                                      <div className="media_preview__okAyH">
                                        <label>Iamge 1</label>
                                        <div className="media_value__\+4KKG ">
                                          {memecoinlogoImage.length > 0 ?
                                            <div>
                                              {memecoinlogoImage.map((image, index) => (
                                                <img
                                                  onClick={() => handleSvgClick(0)}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => handleSvgClick(0)} style={{ height: 30, width: 40 }} src={memecoinlogoImage ? memecoinlogoImage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>


                                      </div>

                                      <br />
                                      <div className="media_preview__okAyH">
                                        <label>Iamge 2</label>
                                        <div className="media_value__\+4KKG ">
                                          {template3stepimage.length > 0 ?
                                            <div>
                                              {template3stepimage.map((image, index) => (
                                                <img
                                                  onClick={() => handleSvgClick1(0)}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => handleSvgClick1(0)} style={{ height: 30, width: 40 }} src={template3stepimage ? template3stepimage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>


                                      </div>
                                      <br />
                                      <h5>Section 1</h5>
                                      <span>Background Image</span>
                                      <div className="media_preview__okAyH">
                                        <label>Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {memestepbgimage.length > 0 ?
                                            <div>
                                              {memestepbgimage.map((image, index) => (
                                                <img
                                                  onClick={() => { handleBackgrounddImage(0); setMemestepbackground(0); }}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => { handleBackgrounddImage(0); setMemestepbackground(0); }} style={{ height: 30, width: 40 }} src={memestepbgimage.length > 0 ? memestepbgimage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div>

                                      <span >Background color</span>

                                      <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemestepbackground(1) }}>
                                        <label>Color</label>
                                        <div className="media_value__\+4KKG ">

                                          {/* <div  style={{ height: 30, width: 40,  backgroundColor: memestepbgcolor.length==0 ? '' :  memestepbgcolor,position:'relative' }}  className="media_image__kN9DM" ></div> */}

                                        </div>


                                      </div>
                                      {isHeaderDesignText == false ? null :
                                        <div id='one' style={{ position: 'relative', }}>
                                          <SketchPicker color={memestepbgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                      }

                                      <h5>Section 2</h5>
                                      <span>Background Image</span>
                                      <div className="media_preview__okAyH">
                                        <label>Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {template3stepsection2bg.length > 0 ?
                                            <div>
                                              {template3stepsection2bg.map((image, index) => (
                                                <img
                                                  onClick={() => { handleBackgroundtemplate3step(0); setMemestepbackgroundt3(0); }}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => { handleBackgroundtemplate3step(0); setMemestepbackgroundt3(0); }} style={{ height: 30, width: 40 }} src={template3stepsection2bg.length > 0 ? template3stepsection2bg : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div>

                                      <span >Background color</span>

                                      <div className="media_preview__okAyH" onClick={() => { handelstepcolor(); setMemestepbackgroundt3(1) }}>
                                        <label>Color</label>
                                        <div className="media_value__\+4KKG ">

                                          {/* <div  style={{ height: 30, width: 40,  backgroundColor: memestepbgcolor.length==0 ? '' :  memestepbgcolor,position:'relative' }}  className="media_image__kN9DM" ></div> */}

                                        </div>


                                      </div>
                                      {template3stepcbgolor == false ? null :
                                        <div id='one' style={{ position: 'relative', }}>
                                          <SketchPicker color={template3stepsection2color} onChange={handelchangeTemplate3stepcolor}></SketchPicker></div>
                                      }

                                    </div>
                                  </div>




                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                      </div>
                      : null}

                    {selectMemeCoinTemplates == 6 ? <div className="editing_content__RXzxk">
                      <div className="form_form__Sku4X" >
                        <div className="form_form__Sku4X">
                          <div className="form_group__kDXNb">
                            <div className="form_form__Sku4X">
                              <div class="form_row__2+BJ3">

                                <div className="form_group__kDXNb">
                                  <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                    <div class="form_field__d31bq mt-3" >
                                      <span>Heading</span>
                                      <textarea placeholder="Step 1" style={{ height: "32px" }} value={t4step1} onChange={(e) => setT4step1(e.target.value)}>
                                      </textarea>
                                    </div>
                                    <div class="form_field__d31bq mt-3" >
                                      <span>Step 1</span>
                                      <textarea placeholder="Step 1" style={{ height: "fit-content" }} value={t4step2} onChange={(e) => setT4step2(e.target.value)}>
                                      </textarea>
                                    </div>
                                    <div class="form_field__d31bq mt-3" >
                                      <span>Step 2</span>
                                      <textarea placeholder="Step 2" style={{ height: "fit-content" }} value={t4step3} onChange={(e) => setT4step3(e.target.value)}>
                                      </textarea>
                                    </div>
                                    <div class="form_field__d31bq mt-3" >
                                      <span>Step 3</span>
                                      <textarea placeholder="Step 3" style={{ height: "fit-content" }} value={t4step4} onChange={(e) => setT4step4(e.target.value)}>
                                      </textarea>
                                    </div>
                                    <div class="form_field__d31bq mt-3" >
                                      <span>Step 4</span>
                                      <textarea placeholder="Step 4" style={{ height: "fit-content" }} value={t4step5} onChange={(e) => setT4step5(e.target.value)} >
                                      </textarea>
                                    </div>
                                    <br />
                                    <label>Meme Image</label>
                                    <div className="media_preview__okAyH">
                                        <label>Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {t4stepimage.length > 0 ?
                                            <div>
                                              {t4stepimage.map((image, index) => (
                                                <img
                                                  onClick={() => handleStepImage(0)}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => handleStepImage(0)} style={{ height: 30, width: 40 }} src={t4stepimage.length > 0 ? t4stepimage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div>
                                      <br/>
                                    <span>Background Image</span>
                                    <div className="media_preview__okAyH">
                                      <label>Image</label>
                                      <div className="media_value__\+4KKG ">
                                        {memestepbgimage.length > 0 ?
                                          <div>
                                            {memestepbgimage.map((image, index) => (
                                              <img
                                                onClick={() => { handleBackgrounddImage(0); setMemestepbackground(0); }}
                                                key={index}
                                                style={{ height: 30, width: 40 }}
                                                src={image}
                                                alt={`Image ${index}`}
                                                className="media_image__kN9DM"
                                              />
                                            ))}</div>
                                          :
                                          <img onClick={() => { handleBackgrounddImage(0); setMemestepbackground(0); }} style={{ height: 30, width: 40 }} src={memestepbgimage.length > 0 ? memestepbgimage : ''} alt="" className="media_image__kN9DM" />
                                        }
                                      </div>
                                    </div>

                                    <span >Background color</span>

                                    <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemestepbackground(1) }}>
                                      <label>Color</label>
                                      <div className="media_value__\+4KKG ">

                                        {/* <div  style={{ height: 30, width: 40,  backgroundColor: memestepbgcolor.length==0 ? '' :  memestepbgcolor,position:'relative' }}  className="media_image__kN9DM" ></div> */}

                                      </div>


                                    </div>
                                    {isHeaderDesignText == false ? null :
                                      <div id='one' style={{ position: 'relative', }}>
                                        <SketchPicker color={memestepbgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                    }
                                  </div>
                                </div>




                              </div>
                            </div>
                          </div>
                        </div>
                      </div>




                    </div> : null}


                    {selectMemeCoinTemplates == 5 ? <div className="editing_content__RXzxk">
                      <div className="form_form__Sku4X" >
                        <div className="form_form__Sku4X">
                          <div className="form_group__kDXNb">
                            <div className="form_form__Sku4X">
                              <div class="form_row__2+BJ3">

                                <div className="form_group__kDXNb">
                                  <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                    <div class="form_field__d31bq mt-3" >
                                      <span>Heading</span>
                                      <textarea placeholder="Step 1" style={{ height: "fit-content" }} value={stepheading} onChange={(e) => setStepheading(e.target.value)}>
                                      </textarea>
                                    </div>
                                    <div class="form_field__d31bq mt-3" >
                                      <span>Step 1</span>
                                      <textarea placeholder="Step 1" style={{ height: "fit-content" }} value={t5step1} onChange={(e) => setT5Step1(e.target.value)}>
                                      </textarea>
                                    </div>
                                    <div class="form_field__d31bq mt-3" >
                                      <span>Step 2</span>
                                      <textarea placeholder="Step 2" style={{ height: "fit-content" }} value={t5step2} onChange={(e) => setT5Step2(e.target.value)}>
                                      </textarea>
                                    </div>
                                    <div class="form_field__d31bq mt-3" >
                                      <span>Step 3</span>
                                      <textarea placeholder="Step 3" style={{ height: "fit-content" }} value={t5step3} onChange={(e) => setT5Step3(e.target.value)}>
                                      </textarea>
                                    </div>
                                    <div class="form_field__d31bq mt-3" >
                                      <span>Step 4</span>
                                      <textarea placeholder="Step 4" style={{ height: "fit-content" }} value={t5step4} onChange={(e) => setT5Step4(e.target.value)} >
                                      </textarea>
                                    </div>
                                    <br />
                                    <span>Background Image</span>
                                    <div className="media_preview__okAyH">
                                      <label>Image</label>
                                      <div className="media_value__\+4KKG ">
                                        {memestepbgimage.length > 0 ?
                                          <div>
                                            {memestepbgimage.map((image, index) => (
                                              <img
                                                onClick={() => { handleBackgrounddImage(0); setMemestepbackground(1); }}
                                                key={index}
                                                style={{ height: 30, width: 40 }}
                                                src={image}
                                                alt={`Image ${index}`}
                                                className="media_image__kN9DM"
                                              />
                                            ))}</div>
                                          :
                                          <img onClick={() => { handleBackgrounddImage(0); setMemestepbackground(1); }} style={{ height: 30, width: 40 }} src={memestepbgimage.length > 0 ? memestepbgimage : ''} alt="" className="media_image__kN9DM" />
                                        }
                                      </div>
                                    </div>

                                    <span >Background color</span>

                                    <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemestepbackground(0) }}>
                                      <label>Color</label>
                                      <div className="media_value__\+4KKG ">

                                        {/* <div  style={{ height: 30, width: 40,  backgroundColor: memestepbgcolor.length==0 ? '' :  memestepbgcolor,position:'relative' }}  className="media_image__kN9DM" ></div> */}

                                      </div>


                                    </div>
                                    {isHeaderDesignText == false ? null :
                                      <div id='one' style={{ position: 'relative', }}>
                                        <SketchPicker color={memestepbgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                    }
                                  </div>
                                </div>




                              </div>
                            </div>
                          </div>
                        </div>
                      </div>




                    </div> : null}





                  </div>
                  : <MemeStepdesing />}

              </div> : <div className='editing_wrapper__Wijfx h-100  overflow-auto'><Theme /></div>}
          </div>
          <div className=' h-100 overflow-auto editing_wrapper__Wijfxnew' style={{ width: 'calc(100% - 400px)' }}>
            <Memecoin />
          </div>
        </div>
      </div>
    </>
  )
};

export default MemecoinSteps;